import React, {Component} from 'react';
import TransComponent from '../../../TransComponent.js';

class ShareTableRow extends TransComponent {

  constructor(props) {
    super(props);
  }

  componentDidMount () {
    this.t_load();
  }

  render () {
    var p = this.props;
    var obj = p.elem;
    var ind = p.ind;
    var rights = (p.Bot.Share.UsersWithRights[ind].Rights);
    var u = null;
    for (var i = 0; i < p.Users.length; i++) {
      if ( p.Users[i]._id == obj.User )
        u = p.Users[i];
    }
    // <td><input name={'Configure'} checked={rights.indexOf('Configure')!=-1}
    // onChange={(e)=>this.handleInputChange(e,ind)} type={'checkbox'}/></td>
    var onChangeHandler = (e) => p.onChangeHandler(e, ind);
    var onDeleteHandler = () => p.onDeleteHandler(obj, ind);
    return (
      <tr key={u._id}>
        <td>{u.Name}</td>

        <td>{u.Email}</td>

        <td>
          <input name={"Build"} checked={rights.indexOf("Build") != -1}
            onChange={onChangeHandler} type={"checkbox"}/>
        </td>

        <td>
          <input name={"Broadcast"} checked={rights.indexOf("Broadcast") != -1}
            onChange={onChangeHandler} type={"checkbox"}/>
        </td>

        <td>
          <input name={"Grow"} checked={rights.indexOf("Grow") != -1}
            onChange={onChangeHandler} type={"checkbox"}/>
        </td>

        <td>
          <input name={"Analyze"} checked={rights.indexOf("Analyze") != -1}
            onChange={onChangeHandler} type={"checkbox"}/></td>
        <td>
          <input name={"RTC"} checked={rights.indexOf("RTC") != -1} onChange={onChangeHandler}
            type={"checkbox"}/>
        </td>

        <td>
          <input name={"Share"} checked={rights.indexOf("Share") != -1}
            onChange={onChangeHandler} type={"checkbox"}/>
        </td>

        <td>
          <button className={"btn__all--small"} onClick={onDeleteHandler}>
            <i className="fas fa-trash-alt"></i>
          </button>
        </td>
      </tr>
    );
  }
}

export default ShareTableRow;
