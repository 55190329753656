import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
} from 'redux';
import createSagaMiddleware from 'redux-saga';
import { takeEvery } from 'redux-saga/effects';

import UserRequestSaga from './User/Saga/UserRequestSaga';

import {
  USER_REQUEST,
  userReducer,
} from './User';
import {SIGN_IN_REQUEST} from "./Login/constains";
import LoginRequestSaga from "./Login/Saga/LoginRequestSaga";
import {loginReducer} from "./Login";
import {BOTS_ADD, BOTS_BIND_TO, BOTS_COPY, BOTS_DELETE, BOTS_EDIT, BOTS_REQUEST, BOTS_SHARE, botsReducer} from "./Bots";
import BotsRequestSaga from "./Bots/Saga/BotsRequestSaga";
import BotDeleteSaga from "./Bots/Saga/BotDeleteSaga";
import BotEditSaga from "./Bots/Saga/BotEditSaga";
import BotShareAddSaga from "./Bots/Saga/BotShareAddSaga";
import BotAddSaga from "./Bots/Saga/BotAddSaga";
import BotCopySaga from "./Bots/Saga/BotCopySaga";
import BotBindTo from "./Bots/Saga/BotBindTo";
import {tariffReducer} from "./Tariff";
import {globalSettingsReducer} from './Settings/reducer';

// preparing sagas
//const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
// preparing app reducer
const appReducer = combineReducers(
  {
    user: userReducer,
    login: loginReducer,
    bots: botsReducer,
    tariff: tariffReducer,
    globalSettings: globalSettingsReducer,
  }
);

export const loadState = () => {
  try
  {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null ) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch (err) {
    // ignore write errors
  }
};

function* appSaga () {
  yield takeEvery(USER_REQUEST, UserRequestSaga );
  yield takeEvery(SIGN_IN_REQUEST, LoginRequestSaga);
  yield takeEvery(BOTS_REQUEST, BotsRequestSaga);
  yield takeEvery(BOTS_DELETE, BotDeleteSaga);
  yield takeEvery(BOTS_EDIT, BotEditSaga);
  yield takeEvery(BOTS_SHARE, BotShareAddSaga);
  yield takeEvery(BOTS_ADD, BotAddSaga);
  yield takeEvery(BOTS_COPY, BotCopySaga);
  yield takeEvery(BOTS_BIND_TO, BotBindTo);
}

const sagaMiddleware = createSagaMiddleware();

export const store = createStore(
  appReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);

// running all sagas
sagaMiddleware.run(appSaga);
