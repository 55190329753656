import React from 'react';
import TransComponent from '../../../TransComponent.js';

class ProductOrder extends TransComponent {

  constructor(props) {
    super(props);
  }

  componentDidMount () {
    this.t_load();
  }

  render() {
    var p = this.props;
    var e = p.elem;
    var i = p.ind;

    var onShowDetailsHandler = () => p.onShowDetailsHandler(i);
    var date = new Date(e.datetime);
    return (<tr>
      <td>{date.toLocaleDateString("ru-Ru")}</td>

      <td>{date.toLocaleTimeString().substr(0, date.toLocaleTimeString().length - 3)}</td>

      <td>{e.price}</td>

      <td>{e.name}</td>

      <td className={"tc"}>
        <button className={"btn__all--small"} onClick={onShowDetailsHandler}>
          {this.t("Показать")}
        </button>
      </td>
    </tr>);
  }

}

export default ProductOrder;
