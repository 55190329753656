import React from "react";
import ReactTooltip from "react-tooltip";
import styles from "./styles.module.scss";

export const TooltipTitle = ({
  name,
  tooltipDescription,
  idTooltip,
  isLink,
  place = "top",
}) => {
  return (
    <>
      <label className={styles.tooltip_name} htmlFor={idTooltip}>
        {name} {isLink}
      </label>

      <span
        data-tip="custom show"
        data-event="click focus"
        data-for={idTooltip}
        className={styles.tooltip_tag}
      >
        <i className="fz15 far fa-question-circle" />
      </span>

      <ReactTooltip
        globalEventOff="click"
        id={idTooltip}
        effect="float"
        type="light"
        place={place}
        backgroundColor="white"
        className={styles.tooltip}
      >
        <span className={styles.tooltip_description}>{tooltipDescription}</span>
      </ReactTooltip>
    </>
  );
};
