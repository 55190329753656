import {
  BOTS_REQUEST,
  BOTS_SUCCESS,
  BOTS_FAILURE,
  BOTS_DELETE,
  BOTS_DELETE_SUCCESS,
  BOTS_EDIT,
  BOTS_EDIT_SUCCESS,
  BOTS_SHARE,
  BOTS_ADD,
  BOTS_ADD_SUCCESS,
  BOTS_COPY,
  BOTS_COPY_SUCCESS,
  BOTS_BIND_TO,
  BOTS_BIND_CLEAR,
  SET_GROUPS,
  ADD_GROUP,
  EDIT_GROUP,
  DELETE_GROUP,
  SET_TEMPLATES,
  BOT_MESSAGE
} from './constains';

export const setBots = () => ({
  type: BOTS_REQUEST,
});

export const successBots = (bots) => ({
  type: BOTS_SUCCESS,
  bots,
});

export const setTemplates = (templates) => ({
  type: SET_TEMPLATES,
  templates,
});

export const failureBots = (errorMessage) => ({
  type: BOTS_FAILURE,
  errorMessage,
});

export const setGroups = ({ botId, groups }) => ({
  type: SET_GROUPS,
  botId,
  groups,
});

export const addGroup = ({ botId, group }) => ({
  type: ADD_GROUP,
  botId,
  group,
});

export const editGroup = ({ botId, group }) => ({
  type: EDIT_GROUP,
  botId,
  group,
});

export const deleteGroup = ({ botId, group }) => ({
  type: DELETE_GROUP,
  botId,
  group,
});

export const addBots = (name, bindTo) => ({
  type: BOTS_ADD,
  name,
  bindTo,
});

export const addBotSuccess = (bot) => ({
  type: BOTS_ADD_SUCCESS,
  bot,
});

export const deleteBots = (id) => ({
  type: BOTS_DELETE,
  id,
});

export const deleteBotsSuccess = (id) => ({
  type: BOTS_DELETE_SUCCESS,
  id,
});

export const editBot = (id, name) => ({
  type: BOTS_EDIT,
  id,
  name,
});

export const editBotSuccess = (bot) => ({
  type: BOTS_EDIT_SUCCESS,
  bot,
});

export const shareBots = (email, botId) => ({
  type: BOTS_SHARE,
  email,
  botId,
});

export const copyBots = () => ({
  type: BOTS_COPY,
});

export const bindBot = (url) => ({
  type: BOTS_BIND_TO,
  url,
});

export const bindBotClear = () => ({
  type: BOTS_BIND_CLEAR,
});
