import { call, put } from 'redux-saga/effects';

import {
  METHOD_POST,
  requestBuilder, } from '../../../helpers/requestBuilder'

import Cookies from "universal-cookie";
import {passwordCoding} from "../../../helpers/passwordCoding";
import {signInSuccess, signInFailure, signInRequest} from "../actions";
const cookies = new Cookies();

export default function* LoginRequestSaga (action) {

  const id = cookies.get('Id');

  try {
    const data = yield call(
      requestBuilder,
      '/login',
      METHOD_POST,
      {
        Email: action.email,
        Password: passwordCoding(action.password),
        // Id: id
      }
    );
    if (data.data.Error) {
      yield put (signInFailure(data.data.Error))
    }
    else {
      yield put(signInSuccess(data.data));
    }
  }
  catch (error) {
    yield put(signInFailure('Неверный email или пароль'));
  }
}
