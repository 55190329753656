import React from 'react';
import classNames from 'classnames/bind';
import styles from './styles.module.scss';

// Settings
// Payments

export const Tab = ({currentTab = 'Settings', activeAccout, activePayment}) => {

  const cx = classNames.bind(styles);

  const colorAccount = cx({
    'blue-btn': currentTab === 'Settings',
    'tab_btn': true,
  });

  const colorSettings = cx({
    'blue-btn': currentTab === 'Payments',
    'tab_btn': true,
  });

  return (

    <div className={styles.tab}>
      <button className={colorAccount} onClick={activeAccout}>
        Аккаунт
      </button>

      <button className={colorSettings}  onClick={activePayment}>
        Оплата
      </button>
    </div>
  )
}