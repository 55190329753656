import React from 'react';

export const BasedBotOnAnother = (props) => {

  let {template} = props;

  return (
    <span>
      {'Бот будет создан на основе бота'} -{' '}
      {template.Name + "(#" + template ._id + ")"}
    </span>
  )
}