import React from 'react';

class DefaultRegQuestion extends React.Component {

  constructor(props) {
    super(props);
  }

  render () {
    var p = this.props;
    var e = p.elem;
    var i = p.ind;

    var onChangeQuestionHandler = (event) => p.onChangeQuestionHandler(event, i);

    return (
      <div className={"row__grid form-group"}>
        <div className="col__9">
          <input disabled={1} className={"grid__input"} type={"text"} value={e}
                 onChange={onChangeQuestionHandler}/>
        </div>
      </div>
    );
  }

}

export default DefaultRegQuestion;
