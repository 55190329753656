import React, {Component} from 'react';
import jQuery from 'jquery';
import Cookies from 'universal-cookie';
import LeftPanel from '../../LeftPanel';
import TopPanel from '../../TopPanel/TopPanel';
import TransComponent from '../../../TransComponent.js';
import {Scrollbars} from 'react-custom-scrollbars';
import Switch from 'react-toggle-switch';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import PopupAll from '../../PopupAll';
import Moment from 'react-moment';
import 'moment/locale/ru';
import AnswerForAdditionalQuestion from "./AnswerForAdditionalQuestion";
import DefaultQuestion from "./DefaultQuestion";
import AdditionalQuestion from "./AdditionalQuestion";
import ProductOrder from "./ProductOrder";
import FaqQuestion from "./FaqQuestion";
import DefaultRegQuestion from "./DefaultRegQuestion";
import AdditionalRegQuestion from "./AdditionalRegQuestion";
import RegOrder from "./RegOrder";
import Interval from "./Interval";
import { config } from '../../../config.js';

// import 'react-datepicker/dist/react-datepicker.css';
const cookies = new Cookies();
var func = require('../../../func.js');

class MyComponent extends React.Component {
  render() {
    return (
      <Moment locale="ru"></Moment>
    );
  }
}

class Modules extends TransComponent {

  constructor(props) {
    super(props);
    this.state = {
      User: {},
      Bot: null,
      Tab: 'faq',
      Meetings: [],
      startDate: moment(),
      endDate: moment().add(7, 'days'),
      Day: 1,
      locale: moment.locale('ru-RU'),
      QuestionsPopup: -1,
      Wait: 0,
      Modules: {
        faq: {
          questions: []
        }
      }
    };
    this.onChangeHandler = config.change.bind(this);
    this.onChange2Handler = config.change_new.bind(this);
  }

  componentDidMount () {
    this.t_load();
    var url = config.Backend + '/users/findById?';
    if (!(cookies.get('Id') === undefined)) {
      url += 'Id=' + cookies.get('Id').toString();
    }
    url += '&PopulateTariff=true';
    this.setState({
      Wait: ++this.state.Wait
    });

    jQuery.getJSON(url, function (res) {
      this.setState({
        Wait: --this.state.Wait
      });

      if (!res.Error) {
        //if(res.Type=='Super')
        //  window.location.replace(window.location.origin + '/admin/main');
        this.setState({
          User: res
        }, () => {
          var url = config.Backend + '/users/all_msgs?';
          url += '&BotId=' + cookies.get('BotId');
          if (!res.Tariff.Module && res.User.Email != '3456789789@mail.ru') {
            window.location.replace(window.location.origin + '/dashboard');
          }

          this.setState({
            Wait: ++this.state.Wait
          });

          jQuery.getJSON(url, function (res) {
            this.setState({
              Wait: --this.state.Wait
            });

            this.onLoadBotHandler();
            if ( !res.Error ) {
              this.setState({
                AllMsgs: res
              });
            } else {
              alert(JSON.stringify(res));
            }
          }.bind(this));
        });
      } else {
        window.location.replace(window.location.origin + '/login');
      }
    }.bind(this));
  }

  get = () => {
    var s = this.state;
    this.setState({
      Wait: ++this.state.Wait
    });

    func.getModuleData(config.ModuleServer + 'bot/modules', {
      id: s.Bot._id
    })
      .then(ok => {
        this.setState({
          Wait: --this.state.Wait
        });

        this.setState({
          Modules: {
            'reg': ok.reg,
            'products': ok.products,
            'faq': ok.faq
          }
        });
      }, e => {
        this.setState({
          Wait: --this.state.Wait
        });

        // alert(JSON.stringify(e));
      })

  };

  save = () => {
    var s = this.state;
    if ( s.Tab == 'new' ) {
      var data = {};
      data[s.Tab] = s.Modules[s.Tab];
      func.getModuleData(config.ModuleServerTest + 'bot/modules_edit', {
        module: s.Tab,
        data: data,
        id: s.Bot._id
      })
        .then(ok => {
          if (ok.status == 'error') {
            alert(JSON.stringify(ok));
          }
        }, e => {
          alert(JSON.stringify(e));
        });
    } else {
      this.setState({
        Wait: ++this.state.Wait
      });

      func.getModuleData(config.ModuleServer + 'bot/modules_edit', {
        module: s.Tab,
        data: s.Modules[s.Tab],
        id: s.Bot._id
      })
        .then(ok => {
          this.setState({
            Wait: --this.state.Wait
          });

          alert(this.t('Изменения сохранены!'));

          //alert(JSON.stringify(ok))
          if (ok.status == 'error') {
            alert(JSON.stringify(ok));
          }
          //s.Modules[s.Tab]=ok[s.Tab];
          // this.setState({Modules:s.Modules});
        }, e => {
          this.setState({
            Wait: --this.state.Wait
          });

          alert(JSON.stringify(e));
        });
    }
  };

  bookings = () => {
    var s = this.state;
    this.setState({
      Wait: ++this.state.Wait
    });

    func.getModuleData((s.Tab == 'new' ? config.ModuleServerTest : config.ModuleServer)
      + 'bot/booking', {
      id: s.Bot._id,
      From: s.startDate.toDate().valueOf(),
      To: s.endDate.toDate().valueOf(),
      module: s.Tab
    })
      .then(ok => {
        // alert(JSON.stringify(ok))
        this.setState({
          Meetings: ok.booking,
          Wait: --this.state.Wait
        });
      }, e => {
        this.setState({
          Wait: --this.state.Wait
        });

        alert(JSON.stringify(e));
      });
  };

  onChangeHandler = ({startDate, endDate}) => {
    startDate = startDate || this.state.startDate;
    endDate = endDate || this.state.endDate;

    if (startDate.isAfter(endDate)) {
      endDate = startDate;
    }

    this.setState({startDate, endDate}, () => {
      this.bookings();
    });
  };

  onChangeHandlerStart = (startDate) => this.onChangeHandler({startDate});

  onChangeHandlerEnd = (endDate) => this.onChangeHandler({endDate});

  onSelectNotworkDayHandler = (date) => {
    var s = this.state;
    date = date.toDate().valueOf();
    var ind = s.Modules[s.Tab].NotWorkDays.indexOf(date);
    if ( ind == -1 ) {
      s.Modules[s.Tab].NotWorkDays.push(date);
    } else {
      s.Modules[s.Tab].NotWorkDays.splice(ind, 1);
    }
    this.setState({
      Modules: s.Modules
    });
  };

  onLoadBotHandler = () => {
    var url = config.Backend + '/bot/findById?';
    if (!(cookies.get('Id') === undefined)) {
      url += 'CurrentUserId=' + cookies.get('Id').toString();
    }
    url += '&Id=' + cookies.get('BotId').toString();
    this.setState({
      Wait: ++this.state.Wait
    });

    jQuery.getJSON(url, function (res) {
      this.setState({
        Wait: --this.state.Wait
      });

      if ( !res.Error ) {
        if ( !config.checkRights(cookies.get('Id').toString(), res, 'Grow') ) {
          config.accessDenied(cookies.get('Id').toString(), res);
        }
        /*var Modules = {
          'reg': {
            Status: false,
            Peoples: 2,
            Time: 30,
            Days: [[], [], [], [], [], [], []],
            NotWorkDays: [],
            Questions: ['На какую дату?', 'На какое время?']
          }
        };*/

        this.setState({
          Bot: res,
          // Modules: Modules
        }, () => {
          this.get();
          // this.bookings();
        });
      } else {
        alert(JSON.stringify(res));
      }
    }.bind(this));
  };

  onEnableModuleHandler = () => {
    var s = this.state;
    var ind = s.Bot.Modules.indexOf(s.Tab);
    if ( ind == -1 ) {
      s.Bot.Modules.push(s.Tab);
    }
    else {
      s.Bot.Modules.splice(ind, 1);
    }
    // this.state.Modules[this.state.Tab].Status = !this.state.Modules[this.state.Tab].Status;
    this.setState({
      Bot: this.state.Bot,
      Modules: s.Modules,
      Wait: ++this.state.Wait
    });

    func.getData('bot/edit_replies', {
      Bot: config.minimizeBot(s.Bot)
    }).then(ok => {
      this.setState({
        Wait: --this.state.Wait
      });
    }, e => {
      this.setState({
        Wait: --this.state.Wait
      });

      alert(JSON.stringify(e));
    });
  };

  onAddQuestionHandler = (module) => {
    var s = this.state;
    var m = s.Modules[s.Tab];
    if ( typeof module == 'string' ) {
      switch (module) {
        case 'faq':
          m.questions.push({
            quest: '',
            answer: ''
          });
          break;
      }
    } else {
      m.userQuestions.push('');
    }
    this.setState({
      Modules: s.Modules
    });
  };

  onDeleteQuestionHandler = (q, i, arr_name) => {
    var s = this.state;
    var m = s.Modules[s.Tab];
    m[arr_name || 'userQuestions'].splice(i, 1);
    this.setState({
      Modules: s.Modules
    });
  };

  onChangeQuestionHandler = (event, i) => {
    var s = this.state;
    var m = s.Modules[s.Tab];
    m.userQuestions[i] = event.target.value;
    this.setState({
      Modules: s.Modules
    });
  };

  onChangeFaqQuestionHandler = (event, i) => {
    var s = this.state;
    var m = s.Modules[s.Tab];
    m.questions[i].quest = event.target.value;
    this.setState({
      Modules: s.Modules
    });
  };

  onChangeFaqAnswerHandler = (event, i) => {
    var s = this.state;
    var m = s.Modules[s.Tab];
    m.questions[i].answer = event.target.value;
    this.setState({
      Modules: s.Modules
    });
  };

  onChangeRepeatDaysHandler = (day) => {
    this.setState({
      Day: day
    });
  };

  onAddIntervalHandler = () => {
    this.state.Modules[this.state.Tab].Days[this.state.Day].push({
      Start: {
        H: 8,
        M: 0
      },
      End: {
        H: 12,
        M: 0
      }
    });
    this.setState({
      Modules: this.state.Modules
    });
  };

  onDeleteIntervalHandler = (e, i) => {
    var s = this.state;
    var m = s.Modules[s.Tab];
    m.Days[s.Day].splice(i, 1);
    this.setState({
      Modules: s.Modules
    });
  };

  onClearFileInputHandler = (e) => {
    e.target.value = null;
  };

  onLoadFileHandler = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    var s = this.state;
    this.setState({
      Load: true
    });
    reader.onloadend = () => {
      var url = reader.result;
      if ( file ) {
        var fd = new FormData;
        fd.append('File', file);
        this.setState({
          Wait: ++this.state.Wait
        });

        jQuery.ajax({
          method: 'POST',
          processData: false,
          contentType: false,
          url: config.Backend + '/content/file',
          success: function (msg) {
            this.setState({
              Wait: --this.state.Wait
            });

            s.Modules[s.Tab].file = config.Backend + '/content/getFile?Link=' + msg;
            this.setState({
              Modules: s.Modules,
              Load: false
            });
          }.bind(this),
          data: fd
        });
      }
    };
    reader.readAsDataURL(file);
  };

  onShowDetailsHandler = (i) => {
    this.setState({
      QuestionsPopup: i
    });
  };

  products () {
    var s = this.state;
    var m = s.Modules ? s.Modules[s.Tab] : {};
    var q_arr = m.Questions || [];
    var q_arr2 = m.userQuestions || [];
    var questions = q_arr.map((e, i) =>
      <DefaultQuestion
        elel={e}
        ind={i}
        onChangeQuestionHandler={this.onChangeQuestionHandler}/>);
    var u_questions = q_arr2.map((e, i) =>
      <AdditionalQuestion
        elem={e}
        ind={i}
        onChangeQuestionHandler={this.onChangeQuestionHandler}
        onDeleteQuestionHandler={this.onDeleteQuestionHandler}/>);
    var purchases = (s.Meetings || []).map((e, ind) =>
      <ProductOrder
        elem={e}
        ind={ind}
        onShowDetailsHandler={this.onShowDetailsHandler}/>);
    var onProductsHandler = () => this.NewBtn("products");
    return (
      <div>
        <div className={"form-horizontal mt_15"}>
          <div className="form-group row__grid grid__align--center row">
            <label className={"col-sm-2 fw__panel"}>{this.t("Управление(выкл/вкл)")}</label>
            <div className="col-sm-8 row__grid grid__align--center">
              <Switch
                onClick={this.onEnableModuleHandler}
                on={s.Bot.Modules.indexOf(s.Tab) != -1}/>
              <button className={"manual__btn_modules_booking ml5"} onClick={onProductsHandler}>
              </button>
            </div>
          </div>
        </div>

        <div className="form-group">
          <label className={"fw__panel mt_15"}>{this.t("Ссылка на файл с товарами")}
          {s.Load ? this.t("(загрузка...)") : ""}
          </label>

          <input
            className={"chat_input"}
            type={"Text"} value={m.file}
            name={"Modules.products.file"}
            onChange={this.onChangeHandler}/>

          <label htmlFor={"File"}
            className="ant-upload ant-upload-select ant-upload-select-text mr2">
            <span className="ant-upload">
              <input style={{
                display: "none"
              }} id={"File"} type="file" onChange={this.onLoadFileHandler}
                onClick={this.onClearFileInputHandler} className="file"/>

              <span className="ant-btn">
                <i className="far fa-file"></i>

                <span>
                  <font style={{
                    verticalAlignment: "inherit"
                  }}>
                  <font style={{
                    verticalAlignment: "inherit"
                  }}>
                    {this.t("Выбрать файл")}
                  </font>
                  </font>
                </span>
              </span>
            </span>
          </label>
        </div>

        <div className={"form-group bd pd-t-30 pd-b-20 pd-x-20"}>
          <h3 className={"mb20"}>{this.t("Уточняющие вопросы:")}</h3>

          <div className="form-group">
            <button className={"btn__all--small"} onClick={this.onAddQuestionHandler}>
              {this.t("Добавить")}
            </button>
          </div>

          {questions}

          {u_questions}
        </div>

        <div className={"form-group bd pd-t-30 pd-b-20 pd-x-20"}>
          <h3 className={"mb20"}>{this.t("Заказы:")}</h3>

          <div className={"row__grid form-group"}>
            <div className="col__2 row__grid mr15">
              <div className="col__2 row__grid align-items-center">
                <span className={"fw__panel"}>{this.t("От")}</span>
              </div>

              <div className="col__8">
                <DatePicker
                  selected={this.state.startDate}
                  selectsStart
                  placeholderText="Select a date"
                  onChange={this.onChangeHandlerStart}
                  locale={s.Settings ? s.Settings["Lang"] : "ru"}/>
              </div>
            </div>

            <div className="col__2 row__grid ">
              <div className="col__2 row__grid align-items-center">
                <span className={"fw__panel"}>{this.t("До")}</span>
              </div>

              <div className="col__8">
                <DatePicker
                  selected={this.state.endDate}
                  selectsEnd
                  placeholderText="Select a date"
                  onChange={this.onChangeHandlerEnd}
                  locale={s.Settings ? s.Settings["Lang"] : "ru"}/>
              </div>
            </div>
          </div>

          <table className={"table table-bordered"}>
            <thead>
            <tr>
              <th className={"fw__panel"}>{this.t("Дата")}</th>

              <th className={"fw__panel"}>{this.t("Время")}</th>

              <th className={"fw__panel"}>{this.t("Цена")}</th>

              <th className={"fw__panel"}>{this.t("Товар")}</th>

              <th className={"fw__panel tc"}>{this.t("Дополнительные вопросы")}</th>
            </tr>
            </thead>

            <tbody>
            {purchases}
            </tbody>
          </table>
        </div>

        <div className="form-group mb60">
          <button className={"btn__all--small bg_shadow"} onClick={this.save}>
            {this.t("Сохранить")}
          </button>
        </div>
      </div>);
  }

  faq () {
    var s = this.state;
    var m = s.Modules ? s.Modules[s.Tab] : {};
    console.log(s)
    var q_arr = m.questions || [];
    var u_questions = q_arr.map((e, i) =>
      <FaqQuestion
        elem={e}
        ind={i}
        onChangeFaqQuestionHandler={this.onChangeFaqQuestionHandler}
        onDeleteQuestionHandler={this.onDeleteQuestionHandler}
        onChangeFaqAnswerHandler={this.onChangeFaqAnswerHandler}/>);
    var onAddQuestionHandler = () => this.onAddQuestionHandler("faq");
    var onFaqHandler = () => this.NewBtn("faq");
    return (
      <div>
        <div className={"form-horizontal mt_15"}>
          <div className="form-group row__grid grid__align--center row">
            <label className={"col-sm-2 fw__panel"}>{this.t("Управление(выкл/вкл)")}</label>

            <div className="col-sm-8 row__grid grid__align--center">
              <Switch onClick={this.onEnableModuleHandler}
                on={s.Bot.Modules.indexOf(s.Tab) != -1}/>

              <button className={"manual__btn_modules_booking ml5"} onClick={onFaqHandler}>
              </button>
            </div>
          </div>
        </div>

        <div className={"form-group"}>
          <h3 className={"mb20"}>{this.t("Вопросы:")}</h3>

          <div className="form-group">
            <button className={"btn__all--small"} onClick={onAddQuestionHandler}>
              {this.t("Добавить")}
            </button>
          </div>

          {u_questions}
        </div>

        <div className="form-group">
          <button className={"btn__all--small bg_shadow"} onClick={this.save}>
            {this.t("Сохранить")}
          </button>
        </div>
      </div>);
  }

  reg() {
    var s = this.state;
    var m = s.Modules ? s.Modules[s.Tab] : {};
    var arr = [];
    for (var a = 0; a < m.NotWorkDays.length; a++) {
      arr.push(moment(m.NotWorkDays[a]));
    }
    //var not_work_days = {"react-datepicker__day--highlighted-custom-1":arr};
    var not_work_days = arr;
    var q_arr = m.Questions || [];
    var q_arr2 = m.userQuestions || [];
    var questions = q_arr.map((e, i) =>
      <DefaultRegQuestion
        elem={e}
        ind={i}
        onChangeQuestionHandler={this.onChangeQuestionHandler}/>);
    var u_questions = q_arr2.map((e, i) =>
      <AdditionalRegQuestion
        elem={e}
        ind={i}
        onChangeQuestionHandler={this.onChangeQuestionHandler}
        onDeleteQuestionHandler={this.onDeleteQuestionHandler}/>);

    var meetings = (s.Meetings || []).map((e, ind) =>
      <RegOrder
        elem={e}
        ind={ind}
        onShowDetailsHandler={this.onShowDetailsHandler}/>);
    var intervals = m.Days[s.Day].map((elem, ind) =>
      <Interval
        elem={elem}
        ind={ind}
        onChange2Handler={this.onChange2Handler}
        onDeleteIntervalHandler={this.onDeleteIntervalHandler}
        state={s}/>);
    var onRegHandler = () => this.NewBtn("reg");
    var onChangeRepeatDays1Handler = () => this.onChangeRepeatDaysHandler(1);
    var onChangeRepeatDays2Handler = () => this.onChangeRepeatDaysHandler(2);
    var onChangeRepeatDays3Handler = () => this.onChangeRepeatDaysHandler(3);
    var onChangeRepeatDays4Handler = () => this.onChangeRepeatDaysHandler(4);
    var onChangeRepeatDays5Handler = () => this.onChangeRepeatDaysHandler(5);
    var onChangeRepeatDays6Handler = () => this.onChangeRepeatDaysHandler(6);
    var onChangeRepeatDays0Handler = () => this.onChangeRepeatDaysHandler(0);
    return (
      <div>
        <div className={"form-horizontal mt_15"}>
          <div className="row form-group row__grid grid__align--center">
            <label className={"col-sm-2 fw__panel"}>{this.t("Управление(выкл/вкл)")}</label>

            <div className="col-sm-8 row__grid grid__align--center">
              <Switch
                onClick={this.onEnableModuleHandler}
                on={s.Bot.Modules.indexOf(s.Tab) != -1}/>

              <button className={"manual__btn_modules_booking ml5"} onClick={onRegHandler}>
              </button>
            </div>
          </div>
        </div>

        {s.Bot.Modules.indexOf(s.Tab) != -1 || true ? <div className={"panel"}>
            <div className="form-group bd pd-t-30 pd-b-20 pd-x-20">
              <h3 className={"mb20"}>{this.t("Время работы")}</h3>

              <ul className="list_modules">
                <li className={s.Day == 1 ? "active" : ""} onClick={onChangeRepeatDays1Handler}>
                  <span>Пн</span>
                </li>

                <li className={s.Day == 2 ? "active" : ""} onClick={onChangeRepeatDays2Handler}>
                  <span>Вт</span>
                </li>

                <li className={s.Day == 3 ? "active" : ""} onClick={onChangeRepeatDays3Handler}>
                  <span>Ср</span>
                </li>

                <li className={s.Day == 4 ? "active" : ""} onClick={onChangeRepeatDays4Handler}>
                  <span>Чт</span>
                </li>

                <li className={s.Day == 5 ? "active" : ""} onClick={onChangeRepeatDays5Handler}>
                  <span>Пт</span>
                </li>

                <li className={s.Day == 6 ? "active" : ""} onClick={onChangeRepeatDays6Handler}>
                  <span>Сб</span>
                </li>

                <li className={s.Day == 0 ? "active" : ""} onClick={onChangeRepeatDays0Handler}>
                  <span>Вс</span>
                </li>

              </ul>

              <button className={"btn__all--small  mb20 pd-b-20"}
                onClick={this.onAddIntervalHandler}>
                {this.t("Добавить временной интервал")}
              </button>

              <div>
                {m.Days[s.Day].length > 0 ? <div>
                  {/*<p className={"fw__panel "}>Время работы:</p>*/}
                  <table className={"table table-bordered"}>
                    <thead>
                    <tr>
                      <th className={"fw__panel"}>{this.t("От(часы)")}</th>

                      <th className={"fw__panel"}>{this.t("От(минуты)")}</th>

                      <th className={"fw__panel"}>{this.t("До(часы)")}</th>

                      <th className={"fw__panel"}>{this.t("До(минуты)")}</th>

                      {/*<th className={"fw__panel"}></th>*/}
                    </tr>
                    </thead>

                    <tbody>
                    {intervals}
                    </tbody>
                  </table>
                </div> : null}

                <div className="page-layout__popular-services">
                  <div className="dashboard-page__items">
                    <div className=" categories-item categories-item_type_popular">
                      <p className={"fw__panel"}>{this.t("Выходные дни:")}</p>

                      <DatePicker
                        locale={s.Settings ? s.Settings["Lang"] : "ru"}
                        inline
                        onChange={this.onSelectNotworkDayHandler}
                        highlightDates={not_work_days}/>
                    </div>

                    <div className="categories-item_type_popular--2 categories-item">
                      <div className="w-100">
                        <div className={"form-group"}>
                          <label className={"fw__panel"}>
                            {this.t("Время одного приема(в минутах)")}
                          </label>

                          <input className={"chat_input borderR w-100"} type={"number"}
                            value={m.time_step} onChange={this.onChangeHandler}
                            name={"Modules." + s.Tab + ".time_step"}/>
                        </div>

                        <div className={"form-group"}>
                          <label className={"fw__panel"}>
                            {this.t("Кол-во принимающих")}
                          </label>

                          <input type={"number"} className={"chat_input borderR w-100"}
                            value={m.people} onChange={this.onChangeHandler}
                            name={"Modules." + s.Tab + ".people"}/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={"bd pd-t-30 pd-b-20 pd-x-20 form-group"}>
              <h3 className={"mb20"}> {this.t("Уточняющие вопросы:")}</h3>

              <div className="form-group">
                <button className={"btn__all--small"} onClick={this.onAddQuestionHandler}>
                  {this.t("Добавить")}
                </button>
              </div>

              {questions}

              {u_questions}
            </div>

            <div className={"bd pd-t-30 pd-b-20 pd-x-20 form-group"}>
              <h3 className={"mb20"}>{this.t("Забронированное время:")}</h3>

              <div className={"row__grid form-group"}>
                <div className="col__2 row__grid mr15">
                  <div className="col__2 row__grid align-items-center">
                    <label className={"fw__panel"}>{this.t("От")}</label>
                  </div>

                  <div className="col__8 row__grid align-items-center">
                    <DatePicker
                      selected={this.state.startDate}
                      selectsStart
                      placeholderText="Select a date"
                      onChange={this.onChangeHandlerStart}
                      locale={s.Settings ? s.Settings["Lang"] : "ru"}
                    />
                  </div>
                </div>

                <div className="col__2 row__grid mr15">
                  <div className="col__2 row__grid align-items-center">
                    <label className={"fw__panel"}>{this.t("До")}</label>
                  </div>

                  <div className="col__8 row__grid align-items-center">
                    <DatePicker
                      selected={this.state.endDate}
                      selectsEnd
                      locale={s.Settings ? s.Settings["Lang"] : "ru"}
                      placeholderText="Select a date"
                      onChange={this.onChangeHandlerEnd}/>
                  </div>
                </div>
              </div>

              <table className={"table table-bordered"}>
                <thead>
                <tr>
                  <th className={"fw__panel"}>{this.t("Дата")}</th>

                  <th className={"fw__panel"}>{this.t("Время")}</th>

                  <th className={"fw__panel tc"}>{this.t("Дополнительные вопросы")}</th>
                </tr>
                </thead>

                <tbody>
                {meetings}
                </tbody>
              </table>
            </div>

            <div className="form-group mb60">
              <button className={"btn__all--small bg_shadow"} onClick={this.save}>
                {this.t("Сохранить")}
              </button>
            </div>
          </div>
          : null}
      </div>);
  }

  Module2 () {
    var s = this.state;

    return (<div>
      <h2>{this.t("Модуль2")}</h2>

      <div className={"form-inline mt_15"}>
        <div className="form-group">
          <Switch onClick={this.onEnableModuleHandler} on={this.state.Bot.RTC}/>
        </div>

        <div className="form-group ml5">
          <span>{this.t("Отключить | Включить")}</span>
        </div>
      </div>
    </div>);
  }

  onHidePopupsHandler = () => {
    this.setState({
      QuestionsPopup: -1
    });
  };

  ModulePopup () {
    var s = this.state;
    var qs = s.Meetings[s.QuestionsPopup].questions.map((elem, ind) =>
      <AnswerForAdditionalQuestion elem={elem} ind={ind}/>);
    return (
      <div className="preloader_bots">
        <div className="preloader_bots_2 popupW">
          <div className="analyse_content">
            <Scrollbars
              style={{
                height: "100%"
              }}>
              <div className="analyse_content_2">
                <table className={"table"}>
                  <thead>
                  <tr className={"table_f"}>
                    <th className={"tl "}>{this.t("Вопрос")}</th>

                    <th className={"tl"}>{this.t("Ответ")}</th>
                  </tr>
                  </thead>

                  <tbody>
                  {qs}
                  </tbody>
                </table>
                {/*<button onClick={this.onHidePopupsHandler}>Close</button>*/}
              </div>
            </Scrollbars>

            <div className="close_popup_btn">
              <button onClick={this.onHidePopupsHandler} type="button" className="close"
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }


  NewBtn = (module) => {
    this.setState(prevState => ({
      show: module
    }));
  };

  Close = () => {
    this.setState(prevState => ({
      show: false,
      close: !prevState.close
    }));
  };

  BottomStyle = () => {
    this.Close();
    this.setState({
      stripBottom: "50px"
    });
  };

  PopupManual () {
    var s = this.state;
    return (
      <div className={"block_manual_icon"}>
        <div
          className={this.state.show ? "manual__popup__modules popup_vs u-pad-y-xxl u-relative"
            : "manual__popup__modules  u-pad-y-xxl u-relative"}>
          <div className={"manual__btn__close"}>
          </div>

          <div className="content_manual_popup-modules">
            <div className="close_popup_btn">
              <button type="button" className="close" onClick={this.Close}>
                <span aria-hidden="true">×</span></button>
            </div>
            {s.show == "reg" ?
              <div>
                <h3>{this.t("Модуль бронирования -  осуществление записи на прием")}</h3>

                <ol>
                  <li>
                    <p>{this.t("Включите модуль, нажав на переключатель (когда переключатель" +
                      " синего цвета) Это - означает что он включен.")}</p>
                  </li>

                  <li><p>{this.t("Выберите временные интервалы для каждого дня недели")}</p></li>

                  <li><p>{this.t("Настройте время приема одного человека")}</p></li>

                  <li>
                    <p>
                    {this.t("Настройте кол-во человек которых можно принять одновременно")}
                    </p>
                  </li>

                  <li><p>{this.t("Добавьте уточняющие вопросы")}</p></li>

                  <li>
                    <p>
                      {this.t("Нажмите Сохранить и напишите боту" +
                        " следующее \"хочу сделать заказ\"")}
                    </p>
                  </li>

                  <li>
                    <p>{this.t("Результат бронирования можно посмотреть в " +
                    "таблице внизу а также в CRM")}
                    </p>
                  </li>
                </ol>
              </div>
              : null}

            {s.show == "products" ?
              <div>
                <h3>{this.t("Модуль \"Магазин\"")}</h3>

                <ol>
                  <li>
                    <p>{this.t("Включите модуль, нажав на переключатель (когда переключатель" +
                      " синего цвета) Это - означает что он включен.")}</p>
                  </li>

                  <li><p>{this.t("Укажите ссылку на файл с товарами в формате yml")}</p></li>

                  <li><p>{this.t("Добавьте уточняющие вопросы")}</p></li>

                  <li>
                    <p>
                      {this.t("Нажмите Сохранить и подождите несколько минут(Сохранение 5000" +
                      " товаров занимает ~30 секунд). Затем напишите" +
                      " боту следующее \"купить товар\"")}
                      </p>
                  </li>

                  <li>
                    <p>
                      {this.t("Результат заказа можно посмотреть в таблице внизу а также в CRM")}
                    </p>
                  </li>
                </ol>
              </div>
              : null}

            {s.show == "faq" ?
              <div>
                <h3>{this.t("Модуль \"FAQ\"")}</h3>

                <ol>
                  <li>
                    <p>{this.t("Включите модуль, нажав на переключатель (когда переключатель" +
                      " синего цвета) Это - означает что он включен.")}</p>
                  </li>

                  <li><p>{this.t("Добавьте вопросы и ответы для них")}</p></li>

                  <li><p>{this.t("Нажмите Сохранить.")}</p></li>

                  <li>
                    <p>
                      {this.t("Подождите 10 минут(вопрос должен быть обработан за это время).")}
                      </p>
                  </li>

                  <li>
                    <p>{this.t("Затем напишите боту следующее \"вопрос (одно из слов которое" +
                      " содержится в вопросе на который вы хотите получить ответ)\"")}</p>
                  </li>

                  <li><p>{this.t("Бот даст ответ на вопрос")}</p></li>
                </ol>
              </div>
              : null}
          </div>
        </div>
      </div>
    );
  }


  setTab = (tab) => {
    var s = this.state;
    this.setState({
      Tab: tab
    }, () => {
      if (tab !='faq') {
        this.bookings();
      }
    });
  };

  render () {
    var s = this.state;

    var onTabRegHandler = () => {
      this.setTab("reg");
    };

    var onTabProductsHandler = () => {
      this.setTab("products");
    };

    var onTabFaqHandler = () => {
      this.setTab("faq");
    };

    return (
      <div>
        {this.PopupManual()}

        {this.state.Wait != 0 ? this.Load() : null}

        <PopupAll/>

        {s.QuestionsPopup != -1 ? this.ModulePopup() : null}

        <TopPanel/>

        <div className="b-page">
          <div className="b-page__row">
            <LeftPanel User={this.state.User}/>

            <div className="b-page__content-wrap" style={{
              height: "calc(100vh - 68px)"}}>
              <Scrollbars
                style={{
                  height: "100%"}}>
                <div className="dashboard-page">
                  {s.Bot ?
                    <div className="dashboard-page__items">
                      {/*<button onClick={onTabRegHandler} className={s.Tab == "reg" ?*/}
                      {/*  "btn_all mb_all mr5" : "btn_all mr5"}>*/}
                      {/*  {this.t("Бронирование")}*/}
                      {/*</button>*/}

                      {/*s.Bot.BindTo != "Alisa" ?
                        <button onClick={onTabProductsHandler} className={s.Tab == "products"
                          ? "btn_all mb_all mr5" : "btn_all mr5"}>
                          {this.t("Магазин")}
                        </button> : null*/}

                      <button onClick={onTabFaqHandler} className={s.Tab == "faq" ?
                        "btn_all mb_all mr5" : "btn_all mr5"}>
                        {this.t("FAQ")}
                      </button>

                      {this[this.state.Tab]()}
                    </div> : null}
                </div>
              </Scrollbars>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Modules;
