import {
  BOTS_SUCCESS,
  BOTS_FAILURE,
  BOTS_REQUEST,
  BOTS_DELETE,
  BOTS_DELETE_SUCCESS,
  BOTS_EDIT,
  BOTS_EDIT_SUCCESS,
  BOTS_SHARE,
  BOTS_ADD,
  BOTS_ADD_SUCCESS,
  BOTS_COPY,
  BOTS_COPY_SUCCESS,
  BOTS_BIND_TO,
  BOTS_BIND_SUCCESS,
  BOTS_BIND_CLEAR,
  SET_GROUPS,
  EDIT_GROUP,
  DELETE_GROUP,
  ADD_GROUP,
  SET_TEMPLATES,

} from "./constains";

const initialState = {
  loading: false,
  success: false,
  error: false,
  botsWasLoad: false,
  templatesWasLoad: false,
  bots: null,
  templates: null,
  groups: {}, // {[botId]: arrayOfBotGroups}
  botMessage: []
};

export const botsReducer = (state = initialState, action) => {
  switch (action.type) {
    case BOTS_REQUEST:
    case BOTS_EDIT:
    case BOTS_SHARE:
    case BOTS_BIND_TO:
    case BOTS_ADD:
    case BOTS_COPY:
      return {
        ...state,
        loading: true,
        success: false,
        error: false,
        shareError: false,
      };

    case BOTS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        bots: action.bots,
        botsWasLoad: true,
      };

    case SET_TEMPLATES:
      return {
        ...state,
        templates: action.templates,
        templatesWasLoad: true,
      };

    case SET_GROUPS:
      return {
        ...state,
        groups: {
          ...state.groups,
          [action.botId]: action.groups,
        },
      };

    case ADD_GROUP:
      return {
        ...state,
        groups: {
          ...state.groups,
          [action.botId]: [...state.groups[action.botId] || [], action.group],
        },
      };

    case EDIT_GROUP:
      return {
        ...state,
        groups: {
          ...state.groups,
          [action.botId]: [...state.groups[action.botId]
            .map(group => action.group._id === group._id ? action.group : group)],
        },
      };

    case DELETE_GROUP:
      return {
        ...state,
        groups: {
          ...state.groups,
          [action.botId]: [...state.groups[action.botId]
            .filter(group => action.group._id !== group._id)],
        },
      };

    case BOTS_EDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        bots: state.bots.map( bot => bot._id !== action.bot._id ? bot: {
          ...action.bot
        })
      };

    case BOTS_ADD_SUCCESS:
      return  {
        ...state,
        loading: false,
        success: true,
        bots: [
          ...(state.bots || []),
          {
            ...action.bot,
          }],
      };

    case BOTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
        shareError: true
      };

    case BOTS_DELETE:
      return {
        ...state,
        loading: false,
        success: true,
      };

    case BOTS_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        bots: state.bots.filter(bot => bot._id !== action.id),
      };

    case BOTS_BIND_CLEAR:
      return  {
      ...state,
        botId: '',
    };

    default:
      return state;
  }
};
