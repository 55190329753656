import React, { Component } from 'react';
import Cookies from 'universal-cookie';
import { Popover, Button, Spin } from 'antd';
import { config } from './config.js';

const cookies = new Cookies();
const func = require('./func.js');

class TransComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.t = config.trans.bind(this);
    this.t_load = this.t_load.bind(this);
  }

  Load() {
    return (
      <div className="loader__spinner">
        <div>
          <Spin
            size="large"
          />
        </div>
      </div>
    );
  }

  t_load() {
    // return new Promise((resolve, reject) => {
    //   const cash_lang = cookies.get('Lang');
    //   const cash_langs = sessionStorage.getItem('TLangs');
    //   const cash_trans = sessionStorage.getItem('Translates');
    //   if (!cash_lang || !cash_langs || !cash_trans) {
    //     const arr = {};
    //     let language = window.navigator ? (window.navigator.language
    //       || window.navigator.systemLanguage
    //       || window.navigator.userLanguage) : (navigator.userLanguage || 'ru');
    //     language = language.substr(0, 2).toLowerCase();
    //     arr.Lang = cookies.get('Lang') || language;
    //
    //     this.setState({
    //       Wait: true,
    //       Settings: arr,
    //     });
    //     cookies.set('Lang', arr.Lang, {
    //       path: '/',
    //     });
    //     func.getData('langs/all').then((l) => {
    //       const langs = [];
    //       for (const i in l) {
    //         if (l[i].Status) {
    //           langs.push(l[i]);
    //         }
    //       }
    //
    //       // translates правильно так
    //       sessionStorage.setItem('TLangs', JSON.stringify(langs));
    //       func.getData('translates/all').then((trans) => {
    //         const trans_obj = {};
    //         trans.forEach((t) => {
    //           if (trans_obj[t.Slug.toLowerCase()] === undefined) {
    //             trans_obj[t.Slug.toLowerCase()] = t;
    //           }
    //         });
    //         sessionStorage.setItem('Translates', JSON.stringify(trans_obj));
    //         this.setState({
    //           Wait: false,
    //           Translates: trans_obj,
    //           TLangs: langs,
    //         }, () => {
    //           resolve();
    //         });
    //       }, (err) => {
    //         this.setState({
    //           Wait: false,
    //         }, () => {
    //           resolve();
    //         });
    //         // alert(JSON.stringify(err));
    //       });
    //     }, (err) => {
    //       this.setState({
    //         Wait: false,
    //       }, () => {
    //         resolve();
    //       });
    //       alert(JSON.stringify(err));
    //     });
    //   } else {
    //     const trans = JSON.parse(cash_trans);
    //     /*
    //     var trans_obj ={};
    //     trans.forEach(t=>{
    //         if(trans_obj[t.Slug.toLowerCase()]===undefined) {
    //             trans_obj[t.Slug.toLowerCase()]=t;
    //         }
    //     }) */
    //     this.setState({
    //       Settings: {
    //         Lang: (cash_lang),
    //       },
    //       TLangs: JSON.parse(cash_langs),
    //       Translates: trans,
    //     }, () => {
    //       resolve();
    //     });
    //   }
    // });
  }
}

export default TransComponent;
