import React, {Component} from 'react';
import TransComponent from '../../TransComponent.js';
import { config } from '../../config.js';
import { Button } from "../Button/Button";

import styles from './styles.module.scss';


export const PopupChild = ({ title, visibleInput, submitHandler, onChangeHandler, inputValue, cancelBtn, isRename }) => {
  return (
    <div className={styles.popup}>
      <div className={styles.popup_wrap}>

        <form onSubmit={submitHandler}>
          <div className={styles.popup_title}>{title}</div>

          {visibleInput ?
            <input
              className="chat_input mb10"
              type="text"
              value={inputValue}
              name="Name"
              onChange={onChangeHandler}/>
            : null
          }

          <Button
            name="Да"
            className="mr5"
            color={isRename ? 'dark-blue' : 'red'}
            type="submit"/>

          <Button
            name="Отмена"
            color="grey"
            action={cancelBtn}/>

        </form>
      </div>
    </div>
  )
}


class Popup extends TransComponent {
  constructor(props) {

    super(props);
    this.state = {
      Bot: this.props.Bot,
      Group: this.props.Group,
      Name: this.props.Name
    };

    this.onChangeHandler = config.onChangeHandler.bind(this);
  }


  onSubmitHandler = (e) => {
    e.preventDefault();
    if (this.props.RenameGroup) {
      this.props.Rename(this.state.Group, this.state.Name);
    }
    else {
      this.props.Yes();
    }
  }

  render () {

    return (
      <div>
        {this.props.isDashboard ?
          <PopupChild
            title={this.props.text}
            submitHandler={this.onSubmitHandler}
            onChangeHandler={this.onChangeHandler}
            visibleInput={this.props.RenameGroup}
            inputValue={this.state.Name}
            cancelBtn={this.props.No}
            isRename={this.props.RenameGroup}
          />
          : null }

        {this.props.FromBroadcast ?
          <PopupChild
            title={this.props.text}
            submitHandler={this.onSubmitHandler}
            onChangeHandler={this.onChangeHandler}
            visibleInput={this.props.RenameGroup}
            inputValue={this.state.Name}
            cancelBtn={this.props.No}
            isRename={this.props.RenameGroup}
          /> : null }

        {
          !this.props.FromBroadcast && !this.props.isDashboard ?
            <PopupChild
              title={this.props.text}
              submitHandler={this.onSubmitHandler}
              onChangeHandler={this.onChangeHandler}
              visibleInput={this.props.RenameGroup}
              inputValue={this.state.Name}
              cancelBtn={this.props.No}
              isRename={this.props.RenameGroup}
            /> : null
        }
      </div>
    );
  }

}


export default Popup;
