import React from 'react';

class AnswerForAdditionalQuestion extends React.Component {

  constructor(props) {
    super(props);
  }

  render () {
    var p = this.props;
    var e = p.elem;
    var i = p.ind;

    return (<tr className={"table_f"}>
      <td className={"tl"}>
        {e.Question}
      </td>

      <td className={"tl"}>
        {e.Answer}
      </td>
    </tr>);
  }

}

export default AnswerForAdditionalQuestion;
