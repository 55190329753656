import React, { Component}  from 'react';
import jQuery from 'jquery';
import Cookies from 'universal-cookie';
import * as d3 from "d3";
import { config } from '../../../config.js';

const cookies = new Cookies();


class SvgComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dragging: true,
            width: this.props.width,
            height: this.props.height,
            binds: [],
            startX: 0,
            startY: 0,
            CurrentDragLinkId: "",
        };

        this.onDragStart = this.onDragStart.bind(this);
        this.onDragMove = this.onDragMove.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
    }
    //shouldComponentUpdate(props,state)
    componentWillReceiveProps(props)
    {
        if(props==this.props && props.childrens.length == this.props.childrens.length)
            return false

        function func() {
            var msgs = []
            var binds = []
            if(props.isGroups && props.Bot.AiPos) {
                msgs.push({_id: "-1", x: +props.Bot.AiPos.split('*')[0], y: +props.Bot.AiPos.split('*')[1]})
                for(var k=0;k<props.Bot.AiLinks.length;k++)
                {
                    for(var j=0;j<props.arr.length;j++) {
                        if(props.arr[j]._id==props.Bot.AiLinks[k].Link) {
                            var obj = {
                                _id: "-1",
                                x:+props.Bot.AiPos.split('*')[0],
                                y:+props.Bot.AiPos.split('*')[1]}
                            var obj1 = {
                                _id: props.arr[j]._id,
                                x: +props.arr[j].Pos.split('*')[0],
                                y: +props.arr[j].Pos.split('*')[1]
                            }
                            binds.push({target: obj1, source: obj})
                        }
                    }
                }
            }
            for(var i=0;i<props.arr.length;i++)
            {
                msgs.push({_id:props.arr[i]._id,x:+props.arr[i].Pos.split('*')[0],y:+props.arr[i].Pos.split('*')[1],cond:props.arr[i].Condition});
                if(props.isGroups)
                {
                }
                else {
                    if (props.arr[i].Btns && props.arr[i].Btns.length > 0) {
                        for (var k = 0; k < props.arr[i].Btns.length; k++) {
                            var btn = props.arr[i].Btns[k];
                            //alert(JSON.stringify(btn))
                            if(btn.Type=="postBack") {
                                var ind = 0;
                                for (var j = 0; j < props.arr.length; j++) {
                                    if (props.arr[j]._id == btn.Redirect)
                                        ind = j;
                                }
                                if (props.arr[ind]._id == btn.Redirect) {
                                    var obj = {
                                        _id: props.arr[ind]._id,
                                        x: +props.arr[ind].Pos.split('*')[0],
                                        y: +props.arr[ind].Pos.split('*')[1]
                                    }
                                    var obj1 = {
                                        _id: props.arr[i]._id,
                                        x: +props.arr[i].Pos.split('*')[0],
                                        y: +props.arr[i].Pos.split('*')[1]
                                    }
                                    binds.push({target: obj, source: obj1})
                                }
                            }
                        }
                    }
                    else if(props.arr[i].Links && props.arr[i].Links.length > 0) {
                        for (var k = 0; k < props.arr[i].Links.length; k++) {
                            var btn = props.arr[i].Links[k];
                            var ind = 0;
                            for (var j = 0; j < props.arr.length; j++) {
                                if (props.arr[j]._id == btn.Redirect)
                                    ind = j;
                            }
                            if (props.arr[ind]._id == btn.Redirect) {
                                var obj = {
                                    _id: props.arr[ind]._id,
                                    x: +props.arr[ind].Pos.split('*')[0],
                                    y: +props.arr[ind].Pos.split('*')[1]
                                }
                                var obj1 = {
                                    _id: props.arr[i]._id,
                                    x: +props.arr[i].Pos.split('*')[0],
                                    y: +props.arr[i].Pos.split('*')[1]
                                }
                            binds.push({target: obj, source: obj1})
                            }
                        }
                    }
                    var ids = [props.arr[i].DefaultLink, props.arr[i].LinkAfterPaymentSuccess];
                    for (var k = 0; k < props.arr.length; k++) {
                        if (ids.indexOf(props.arr[k]._id) !== -1) {
                            var obj = {
                                _id: props.arr[k]._id,
                                x: +props.arr[k].Pos.split('*')[0],
                                y: +props.arr[k].Pos.split('*')[1]
                            }
                            var obj1 = {
                                _id: props.arr[i]._id,
                                x: +props.arr[i].Pos.split('*')[0],
                                y: +props.arr[i].Pos.split('*')[1]
                            }
                            binds.push({target: obj, source: obj1})
                        }
                    }
                }
            }
            var data = {
                nodes: msgs,
                links: binds,
            };

            //if(JSON.stringify(this.state.binds)!=JSON.stringify(binds)) {
            //alert(JSON.stringify(this.state.binds))
            //alert(JSON.stringify(binds))
            d3.selectAll("path").remove();
            this.setState({binds: binds});
            //Initializing chart
            const chart = d3.select('.svg_full');

            //Initializing force simulation
            const simulation = d3.forceSimulation()
            //.force('link', d3.forceLink());
            //.force('charge', d3.forceManyBody())
            // .force('collide', d3.forceCollide())
            //.force("y", d3.forceY(0))
            //.force("x", d3.forceX(0));


            //Drag functions
            const dragStart = d => {
                this.setState({dragging:true})
                if (!d3.event.active) simulation.alphaTarget(0.3).restart();
                d.fx = d.x;
                d.fy = d.y;
            };

            var drag = function (d) {
                var id = d._id;

                for (var i = 0; i < data.links.length; i++) {
                    if (data.links[i].target._id == id) {
                        data.links[i].target.x = d.fx;
                        data.links[i].target.y = d.fy;
                    }
                    if (data.links[i].source._id == id) {
                        data.links[i].source.x = d.fx;
                        data.links[i].source.y = d.fy;
                    }
                }

                var rect = d3.select('.svg_full').node().getBoundingClientRect();

                if (d3.event.x < 0 || d3.event.y < 0 || d3.event.x > rect.width
                    || d3.event.y > rect.height || !this.state.dragging) {

                }
                else {
                    d.fx = d3.event.x;
                    d.fy = d3.event.y;
                }
            }.bind(this);

            const dragEnd = d => {
                const newX = parseFloat(d.x);
                const newY = parseFloat(d.y);

                if (d._id != "-1") {
                    const botMsg = props.arr.find(botMsg => botMsg._id === d._id);
                    const oldX = botMsg && botMsg.Pos && +botMsg.Pos.split('*')[0];
                    const oldY = botMsg && botMsg.Pos && +botMsg.Pos.split('*')[1];


                    if (botMsg && (oldX !== newX || oldY !== newY)) {
                      let url = config.Backend + (props.isGroups ? '/group' : '/botMsg') + '/edit?';
                      if (!(cookies.get('Id') === undefined)) {
                        url += '&CurrentUserId=' + cookies.get('Id').toString();
                      }
                      url += '&Id=' + d._id;
                      url += '&Bot=' + props.Bot._id;
                      url += '&Pos=' + newX + "*" + newY;
                      props.Refresh(d._id, newX + "*" + newY);

                      jQuery.getJSON(url);
                    }
                }
                else {
                    var url1 = config.Backend + '/bot/edit?';
                    if (!(cookies.get('Id') === undefined)) {
                        url1 += '&CurrentUserId=' + cookies.get('Id').toString();
                    }
                    url1 += '&Id=' + props.Bot._id;
                    url1 += '&AiPos=' + newX + "*" + newY;
                    props.Refresh(d._id, newX + "*" + newY);
                    jQuery.getJSON(url1);
                }
            }

            d3.selectAll(".svg_full > g").remove();
            d3.selectAll(".svg_full > defs").remove();
            //Creating links

            const link = chart.insert('g', ":first-child")
            //.attr('className', 'path') включить PATH
                .attr('className', 'line')
                //.selectAll('path') включить PATH
                .selectAll('line')
                .data(data.links).enter()
                //.append('path') включить PATH
                .append('line')
                .attr("stroke", "#374BB7")
                .attr("stroke-width", 2)
                .attr("fill", "none")
                .attr("marker-end", "url(#arrowhead)")

            //Creating nodes
            const node = d3
                .selectAll('.canva-block')
                .data(data.nodes)
                .attr("id", d => d._id)
                .attr("idX", d =>'x'+ d._id)
                .on('mouseenter',function (d) {
                    if(!this.state.dragging && d._id!=this.state.CurrentDragLinkId)
                    {
                        props.dragLink(this.state.CurrentDragLinkId,d._id);
                    }
                }.bind(this))
                .call(d3.drag()
                    .on('start', dragStart)
                    .on('drag', drag)
                    .on('end', dragEnd)
                );

            chart.append("defs").append("marker")
                .attr("id", "arrowhead")
                .attr("refX", 10)
                .attr("refY", 6)
                .attr("markerWidth", 10)
                .attr("markerHeight", 10)
                .style("fill", "#374BB7")
                .attr("orient", "auto")
                .append("path")
                .attr("d", "M2,2 L10,6 L2,10 L6,6 L2,2"); //this is actual shape for arrowhead
            var notCond = [];
            for(var y=0;y<data.nodes.length;y++)
            {
                if(!data.nodes[y].cond)
                {
                    notCond.push(data.nodes[y]);
                }
            }
            var icons = d3.selectAll('.btn_icon')
                .data(notCond)
                .call(d3.drag()
                    .on("start",function (d) {
                        //alert(JSON.stringify(d));
                        var t = d3.select('.canva-block');
                        //alert(JSON.stringify(t ));
                        var icon = d3.select('.canva-block[idX=x'+d._id+']').select('.btn_icon')
                        //alert(JSON.stringify(icon.left ));


                        this.setState({dragging:false,CurrentDragLinkId:d._id})
                        chart.select('g').append('line')
                            .attr('class', 'dragLink')
                            .attr("stroke", "#8792AA")
                            .attr('x1',d3.event.x )
                            .attr('x2',d3.event.x)
                            .attr('y1',d3.event.y)
                            .attr('y2',d3.event.y)
                            .attr("stroke-width", 2)
                            .attr("fill", "none")
                            .attr("marker-end", "url(#arrowhead)")
                    }.bind(this))
                    .on("drag",function (d) {
                        chart.selectAll(".dragLink")
                            .attr('x2',d3.event.x)
                            .attr('y2',d3.event.y)
                    }.bind(this))
                    .on("end",function (d) {
                        chart.selectAll(".dragLink").remove()
                        this.setState({dragging:true,CurrentDragLinkId:""})
                    }.bind(this)))

            function getTargetY(d) {
                var topPos = {x: 110, y: -10}
                var botPos = {x: 110, y: 127}
                var leftPos = {x: -10, y: 52}
                var rightPos = {x: 225, y: 52}
                var positions = [];
                positions.push(Math.sqrt(Math.pow((d.source.y + 100 - (d.target.y + topPos.y)), 2) + Math.pow((d.source.x + 110 - (d.target.x + topPos.x)), 2)));
                positions.push(Math.sqrt(Math.pow((d.source.y + 100 - (d.target.y + botPos.y)), 2) + Math.pow((d.source.x + 110 - (d.target.x + botPos.x)), 2)));
                positions.push(Math.sqrt(Math.pow((d.source.y + 100 - (d.target.y + leftPos.y)), 2) + Math.pow((d.source.x + 110 - (d.target.x + leftPos.x)), 2)));
                positions.push(Math.sqrt(Math.pow((d.source.y + 100 - (d.target.y + rightPos.y)), 2) + Math.pow((d.source.x + 110 - (d.target.x + rightPos.x)), 2)));
                var min = 0;
                for (var i = 1; i < 4; i++) {
                    if (positions[i] < positions[min]) {
                        min = i;
                    }
                }
                if (min == 0)
                    return d.target.y + topPos.y;// 72 + высота на 2
                else if (min == 1)
                    return d.target.y + botPos.y;// 72 + высота на 2
                else
                    return d.target.y + rightPos.y;// 72 + высота на 2
            }

            function getTargetX(d) {
                var topPos = {x: 110, y: -10};
                var botPos = {x: 110, y: 127};
                var leftPos = {x: -10, y: 60};
                var rightPos = {x: 225, y: 60};
                var positions = [];
                positions.push(Math.sqrt(Math.pow((d.source.y + 100 - (d.target.y + topPos.y)), 2) + Math.pow((d.source.x + 110 - (d.target.x + topPos.x)), 2)));
                positions.push(Math.sqrt(Math.pow((d.source.y + 100 - (d.target.y + botPos.y)), 2) + Math.pow((d.source.x + 110 - (d.target.x + botPos.x)), 2)));
                positions.push(Math.sqrt(Math.pow((d.source.y + 100 - (d.target.y + leftPos.y)), 2) + Math.pow((d.source.x + 110 - (d.target.x + leftPos.x)), 2)));
                positions.push(Math.sqrt(Math.pow((d.source.y + 100 - (d.target.y + rightPos.y)), 2) + Math.pow((d.source.x + 110 - (d.target.x + rightPos.x)), 2)));
                var min = 0;
                for (var i = 1; i < 4; i++) {
                    if (positions[i] < positions[min]) {
                        min = i;
                    }
                }
                if (min == 2)
                    return d.target.x + leftPos.x;// 20 + ширина на 2
                else if (min == 3)
                    return d.target.x + rightPos.x;// 20 + ширина на 2
                else
                    return d.target.x + botPos.x;
            }

            //Setting location when ticked

//ВОТ ТУТ НАДО БУДЕТ ПОМЕНЯТЬ КООРДИНАТЫ ПОСЛЕ ИЗМЕНЕНИЯ РАЗМЕРОВ БЛОКА
            const ticked = () => {
                link
                    .attr("x1", d => {
                        return d.source.x + 110; // 20 + ширина на 2
                    })
                    .attr("y1", d => {
                        return d.source.y + 50;// 72 + высота на 2
                    })
                    .attr("x2", d => {
                        return getTargetX(d);
                    })
                    .attr("y2", d => {
                        return getTargetY(d);
                    })
                //включить PATH
                /*  .attr("d", d => {
                      function getLineCoords(square1, square2) {
                          var deltaX = square2.cx - square1.cx;
                          var deltaY = square2.cy - square1.cy;

                          if (deltaX === 0) {
                              if (deltaY > 0) {
                                  return [
                                      {
                                          "x": square1.cx,
                                          "y": square1.cy + square1.height / 2
                                      },
                                      {
                                          "x": square1.cx,
                                          "y": square2.cy - square2.height / 2
                                      }
                                  ];
                              } else {
                                  return [
                                      {
                                          "x": square1.cx,
                                          "y": square1.cy - square1.height / 2
                                      },
                                      {
                                          "x": square1.cx,
                                          "y": square2.cy + square2.height / 2
                                      }
                                  ];
                              }
                          }

                          if (deltaY === 0) {
                              if (deltaY > 0) {
                                  return [
                                      {
                                          "x": square1.cx + square1.width / 2,
                                          "y": square1.cy
                                      },
                                      {
                                          "x": square2.cx - square2.width / 2,
                                          "y": square2.cy
                                      }
                                  ];
                              } else {
                                  return [
                                      {
                                          "x": square1.cx - square1.width / 2,
                                          "y": square1.cy
                                      },
                                      {
                                          "x": square2.cx + square2.width / 2,
                                          "y": square2.cy
                                      }
                                  ];
                              }
                          }

                          if (Math.abs(deltaX) > Math.abs(deltaY)) {
                              return [
                                  {
                                      "x": square1.cx,
                                      "y": (deltaY > 0) ? square1.cy + square1.height / 2 : square1.cy - square1.height / 2
                                  },
                                  {
                                      "x": square1.cx,
                                      "y": square2.cy
                                  },
                                  {
                                      "x": (deltaX > 0) ? square2.cx - square2.width / 2 : square2.cx + square2.width / 2,
                                      "y": square2.cy
                                  }
                              ]
                          } else {
                              return [
                                  {
                                      "x": (deltaX > 0) ? square1.cx + square1.width / 2 : square1.cx - square1.width / 2,
                                      "y": square1.cy
                                  },
                                  {
                                      "x": square2.cx,
                                      "y": square1.cy
                                  },
                                  {
                                      "x": square2.cx,
                                      "y": (deltaY > 0) ? square2.cy - square2.height / 2 : square2.cy + square2.height / 2
                                  }
                              ]
                          }
                      }

                      var src = {
                          cx: d.source.x + 110,
                              cy: d.source.y + 60,
                              width: 220,
                              height: 120
                      };
                      if(props.IsGroups)
                      {}
                      src = {
                          cx: d.source.x + 64,
                          cy: d.source.y + 56,
                          width: 128,
                          height: 113
                      }
                      var lineData = getLineCoords(src,
                          {cx: d.target.x + 110, cy: d.target.y + 60, width: 220, height: 120})

                      var lineFunction = d3.line()
                          .x(function (d) {
                              return d.x;
                          })
                          .y(function (d) {
                              return d.y;
                          })
                      //.curve(d3.curveStep);

                      return lineFunction(lineData);
                  })
              */
                node
                    .attr("style", d => {
                        return 'x: ' + d.x + 'px; y: ' + (d.y + 0) + 'px';
                    });

            };

            //Starting simulation
            simulation.nodes(data.nodes)
                .on('tick', ticked);

            //simulation.force('link')
            // .links(data.links);
            //   }
        }

        if(props.childrens.length>0 || props.Bot.AiPos) {
            //if(JSON.stringify(props.arr)!=JSON.stringify(this.props.arr)) {
            //alert(JSON.stringify(d3.selectAll("line")));
            setTimeout(func.bind(this), 1)
            //}
        } else {
            d3.selectAll(".svg_full > g").remove();
        }

        return true;
    }

    componentDidMount() {


    }

    onDragStart(e) {
        // Find start position of drag based on touch/mouse coordinates.
        const startX = typeof e.clientX === 'undefined' ? e.changedTouches[0].clientX : e.clientX;
        const startY = typeof e.clientY === 'undefined' ? e.changedTouches[0].clientY : e.clientY;

        // Update state with above coordinates, and set dragging to true.
        const state = {
            dragging: true,
            startX,
            startY,
        };

        this.setState(state);
    }

    onDragMove(e) {
        // First check if the state is dragging, if not we can just return
        // so we do not move unless the user wants to move
        if (!this.state.dragging) {
            return;
        }

        // Get the new x coordinates
        const x = typeof e.clientX === 'undefined' ? e.changedTouches[0].clientX : e.clientX;
        const y = typeof e.clientY === 'undefined' ? e.changedTouches[0].clientY : e.clientY;

        // Take the delta where we are minus where we came from.
        const dx = x - this.state.startX;
        const dy = y - this.state.startY;

        // Pan using the deltas
        //this.pan(dx, dy);

        // Update the state
        this.setState({
            startX: x,
            startY: y,
        });

    }

    onDragEnd() {
        this.setState({ dragging: false });
    }


    render() {

        const { width, height } = this.props;

        return (
            <div className="main_bot_center_content">
                    <svg  className="svg_full"
                         width={100}
                         height={100}
                         xmlns="http://www.w3.org/2000/svg"
                         onMouseDown={this.onDragStart}
                         onTouchStart={this.onDragStart}
                         onMouseMove={this.onDragMove}
                         onTouchMove={this.onDragMove}
                         onMouseUp={this.onDragEnd}
                         onTouchEnd={this.onDragEnd}
                    >
                        {this.props.isGroups ? this.props.Ai : null}
                        {this.props.childrens}
                    </svg>
                </div>

        );
    }
}



export default SvgComponent;
