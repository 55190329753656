import { call, put } from 'redux-saga/effects';

import {
   METHOD_POST, requestBuilder,
} from "../../../helpers/requestBuilder";

import Cookies from "universal-cookie";
import {deleteBotsSuccess, failureBots, successBots} from "../actions";
const cookies = new Cookies();

export default function* BotShareAddSaga (action) {
  const id = cookies.get('Id');

  try {
    const data = yield call(
      requestBuilder,
      '/share/add',
      METHOD_POST,
      {
        Email: action.email,
        BotId: action.botId,
        Rights: 'RTC'
      });

    if (data.data.Error) {
      yield put (failureBots('Обьект User с данным Email не найден'))
    }
    // else {
    //   yield (failureBots('Error'))
    // }
  }

  catch (error) {
    yield put(failureBots('Bots error'))
  }
}
