import jQuery from 'jquery';
import Cookies from 'universal-cookie';
import { config } from './config';
const cookies = new Cookies();

export function searchToObj(search) {
  var search = search || window.location.search.substring(1);
  if (search) {
    try {
      return JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
        function (key, value) {
          return key === "" ? value : decodeURIComponent(value);
        })
    } catch (e) {
      return {};
    }
  } else {
    return {};
  }
}

export function getBotUsers(field) {
  var s = this.state;

  var route = 'fb';
  if (s.Bot.BindTo == 'Telegram') {
    route = "telegram";
  }
  else if (s.Bot.BindTo == 'Ok') {
    route = "ok";
  }
  else if (s.Bot.BindTo == "Vk") {
    route = "vk";
  }
  else if (s.Bot.BindTo == 'Viber') {
    route = "viber";
  } else if (s.Bot.BindTo  == 'WhatsApp' ) {
    route = 'whatsapp';
  }
  var url = config.Backend + '/' + route + '/getUsers?BotId=' + s.Bot._id;
  if (!(cookies.get('Id') === undefined)) {
    url += '&CurrentUserId=' + cookies.get('Id').toString();
  }
  this.setState({
    Wait: ++this.state.Wait
  });

  jQuery.getJSON(url, (res) => {
    if (!res.Error) {
      this.setState({
        Wait: --this.state.Wait,
        [field]: res
      });
    }
    else {
      this.setState({
        Wait: --this.state.Wait
      });
      alert(JSON.stringify(res));
    }
  });
}

export function shortUrlApiCall(route, body, headers) {
  var data = body ? body : {};
  if (data.Id === undefined) {
    data.Id = cookies.get('Id');
  }
  return getData(config.ShortUrlApi + '/' + route, data, headers);
}

export function getData(route, body, headers) {
  return new Promise(function (resolve, reject) {
    var data = body ? body : {};
    if (data.Id === undefined && route.indexOf('http') == -1) {
      data.Id = cookies.get('Id');
    }
    var url = route.indexOf('http') == -1 ? (config.Backend + '/' + route) : route;
    headers = headers || {};
    var o = {
      method: 'POST',
      contentType: 'application/json',
      url: url,
      success: function (res) {
        if (res.Error === undefined) {
          resolve(res);
        } else {
          reject(res);
        }
      },
      error: function (err) {
        if (err.readyState == 0) {
          reject('Сервер не отвечает!');
        } else {
          reject(err);
        }
      },
      data: JSON.stringify(data)
    }
    for (var key in headers) {
      if (key == 'Authorization') {
        o.beforeSend = function (xhr) {
          xhr.setRequestHeader('Authorization', headers[key]);
        }
      }
      else {
        o[key] = headers[key];
      }
    }
    jQuery.ajax(o);
  })
}

export function t(str) {//работает медленее чем config.trans
  //return str;
  if (str) {
    try {
      var s = {};
      s.Settings = {
        Lang: cookies.get('Lang')
      };
      s.Translates = JSON.parse(sessionStorage.getItem('Translates'));
      var slug = str.toLowerCase();
      return s.Translates[slug].Names[s.Settings['Lang']] || str;
    } catch (e) {
    }
  }
  return str;
}

export function loadBots() {
  var url = config.Backend + '/users/showBots?';
  if ( !(cookies.get('Id') === undefined) )
  {
    url += 'Id=' + cookies.get('Id').toString();
  }
  url += "&NotTemplates=1";
  this.setState({
    Wait: ++ this.state.Wait
  });

  jQuery.getJSON(url, function (res) {
    this.setState({
      Wait: --this.state.Wait
    });

    if ( !res.Error ) {
      this.setState({
        Bots: res
      });
    } else {
      alert(JSON.stringify(res));
    }
  }.bind(this));
}

export function loadAllBotMsgs(bot_id) {
  var url = config.Backend + '/users/all_msgs?';
  url += '&BotId=' + bot_id;
  this.setState({
    Wait: ++this.state.Wait
  });

  jQuery.getJSON(url, function (res) {
    if (!res.Error) {
      this.setState({
        AllMsgs: res,
        Wait: --this.state.Wait
      });
    } else {
      this.setState({
        Wait: --this.state.Wait
      });
      alert(JSON.stringify(res));
    }
  }.bind(this));
}

export function loadGroupMsgs(bot_id, group_id, callback, options) {
  var url = config.Backend + '/group/showMsgs?';
  if (!(cookies.get('Id') === undefined)) {
    url += '&CurrentUserId=' + cookies.get('Id').toString();
  }
  url += '&Bot=' + bot_id;
  url += '&Id=' + group_id;
  this.setState({
    Wait: ++this.state.Wait
  });

  jQuery.getJSON(url, function (res) {
    if (!res.Error) {

      var state = {};
      if (options && options.setup === false) {
        state = {
          Wait:--this.state.Wait
        };
      } else {
        state = {
          BotMsgs: res,
          Wait:--this.state.Wait
        };
      }

      this.setState(state);
      if (callback) {
        callback(res);
      }
    } else {
      this.setState({
        Wait: --this.state.Wait
      });
      alert(JSON.stringify(res));
    }
  }.bind(this));
}

export function loadGroups(bot_id, callback, options) {
  var url = config.Backend + '/bot/groups?';
  if (!(cookies.get('Id') === undefined))
  {
    url += '&CurrentUserId=' + cookies.get('Id').toString();
  }
  url+='&Id=' + bot_id;
  this.setState({
    Wait:++this.state.Wait
  });

  jQuery.getJSON(url, function (res) {
    if (!res.Error) {

      var state = {};
      if (options && options.setup === false) {
        state = {
          Wait:--this.state.Wait
        };
      } else {
        state = {
          Groups: res,
          Wait:--this.state.Wait
        };
      }

      this.setState(state);
      if (callback) {
        callback(res);
      }
    } else {
      this.setState({
        Wait:--this.state.Wait
      });
      alert(JSON.stringify(res));
    }
  }.bind(this));
}

export function have_content(msg) {
  try {
    if (['DeepLink', 'CRMoutput', 'RTC', 'Process', 'Location', 'Api', 'CRM'].indexOf(msg.Type)
      != -1) {
      return false;
    }
    return true;
  }
  catch (e) {
    return false;
  }
}

export function returnUniqueUsers(labels_times, msgs) {
  var users = [];
  for (var k = 0; k < labels_times.length; k++) {
    var default_obj = {
      Cnt: 0,
      Users: []
    };
    users.push(default_obj);
  }
  for (var i = 0; i < msgs.length; i++) {
    var nearIndex = 0;
    var min = 1000 * 60 * 60 * 24 * 900;
    for (var j in labels_times) {
      if (Math.abs(msgs[i].Time - labels_times[j]) < min) {
        min = Math.abs(msgs[i].Time - labels_times[j]);
        nearIndex = j;
      }
    }
    if (users[nearIndex].Users.indexOf(msgs[i].User) == -1) {
      users[nearIndex].Cnt += 1;
      users[nearIndex].Users.push(msgs[i].User);
    }
  }
  return users;
}

export function refreshTutorial(place, step) {
  if (this.props.user) {
    if (step == undefined) {
      var t = [{
        Place: 'Groups',
        Step: 0
      }
        , {
          Place: 'Broadcast',
          Step: 0
        }, {
          Place: 'Modules',
          Step: 0
        }, {
          Place: 'RTC',
          Step: 0
        }];
    } else {
      var t = this.props.user.Tutorial;
      t.forEach(e => {
        if (e.Place == place) {
          e.Step = step;
        }
      })
    }

    getData('/users/edit_new', {
      Tutorial: t
    }).then((u) => {
      if (this.props.editUserInStore) {
        this.props.editUserInStore({
          ...u,
          Tariff: this.props.user,
        });
      }
    });
  }
}

export function get_tutorial(t, place) {
  if (!Array.isArray(t)) {
    return undefined;
  }
  var res = undefined;
  t.forEach(e => {
    if (e.Place == place) {
      res = e;
    }
  })
  return res;
}

export function get_url(o)//ищет юрл в массиве,обьекте или строке(рекурсивно)
{
  if (typeof o == 'string' && o.search(/http/) != -1) {
    return o;
  }
  else if (typeof o == 'object') {
    for (var key in o) {
      var url = get_url(o[key]);
      if (url)
        return url;
    }
  }
  else if (Array.isArray(o)) {
    for (var i = 0; i > o.length; i++) {
      var url = get_url(o[i]);
      if (url) {
        return url;
      }
    }
  }
}

export function find(arr, search) {
  var res = [];
  arr.forEach(e => {
    var add = true;
    for (var key in search) {
      if (e[key] != search[key]) {
        add = false;
        break;
      }
    }
    if (add) {
      res.push(e);
    }
  })
  return res;
}

export function formatDate(dateString) {
  //valid format is dd.mm.yyyy (25.05.2015), but this format not support in Date.parse
  let formatted_date;
  if (typeof dateString === 'string' && dateString.length >= 10) {
    formatted_date = dateString.substr(3, 2) + '.' + dateString.substr(0, 2) + '.' + dateString.substr(6, 4);
  }
  return formatted_date;
}

function inner_cond(param, cond, val) {
  if (param !== null && param !== undefined && val !== undefined) {
    const formatted_date = formatDate(val);
    const isDate = isNaN(Date.parse(formatted_date)) == false;
    if (isDate) {
      val = new Date(formatted_date).valueOf();
      param = new Date(formatDate(param)).valueOf();
    }

    if (cond == 'is') {
      return param == val;
    }
    else if (cond == 'is not') {
      return param != val;
    }
    else if (cond == '>') {
      return param > val;
    }
    else if (cond == '<') {
      return param < val;
    }
    else {
      if (typeof param != 'string') {
        param = param.toString();
      }
      return param.indexOf(val) == 0;
    }
  }
  return false;
}

export function condition(user, attr, val, cond, CRMorders) {
  var param = user[attr];
  if (attr && attr.indexOf('Context.') == 0) {
    user.Context = user.Context || {};
    var arr = attr.split('.');
    if (arr.length >= 2) {
      param = user.Context[arr[1]];
    }
  }
  if (attr && attr.indexOf('CRM(') == 0) {
    if (attr.indexOf('CRM(Кол-во') == 0) {
      param = CRMorders.length;
    }
    else {
      const isCrmRecordDate = attr.indexOf('CRM(Дата') == 0;
      if (!isCrmRecordDate) {
        attr = attr.substr(4);
        attr = attr.substr(0, attr.length - 1);
      }
      for (var i = 0; i < CRMorders.length; i++) {
        var id = CRMorders[i].ChatUser;
        if (isCrmRecordDate) {
          param = new Date(CRMorders[i].created_at).toLocaleDateString('ru-RU');
        } else {
          param = CRMorders[i].Context[attr];
        }
        if (inner_cond(param, cond, val)) {
          return true;
        }
      }
      return false;
    }
  }
  return inner_cond(param, cond, val);
}

export function getModuleData(route, body) {
  return new Promise(function (resolve, reject) {
    const options = {
      method: 'POST',
      url: route,
      success: function (res) {
        if (res.Error === undefined) {
          resolve(res);
        } else {
          reject(res);
        }
      },
      error: function (err) {
        reject(err);
      },
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      data: JSON.stringify(body ? body : {}),
    };

    jQuery.ajax(options);
  })
}

export function labels(p_obj) {
  var labels = [];
  //var weekdays = ['Monday','Tuesday','Wednesday','Thursday','Friday','Saturday','Sunday'];
  if (p_obj.Period == 'Month') {
    var now = new Date().getMonth();
    //var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July','August',
    // 'September','October','November','December']
    var months = ['Январь', 'Февраль', 'Марь', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь',
      'Октябрь', 'Ноябрь', 'Декабрь']
    for (var i = now - p_obj.Times + 1; i < now + 1; i++) {
      var x = i > 11 ? i - 12 : i;
      x = x < 0 ? 12 + i : x;
      labels.push(months[x]);
    }
  }
  else if (p_obj.Period == 'Day') {
    var now = new Date().valueOf();
    for (var i = p_obj.Times - 1; i >= 0; i--) {
      var x = new Date(now - i * 1000 * 60 * 60 * 24).toLocaleDateString('ru-RU');
      labels.push(x);
    }
  }

  return labels;
}

export function prepareLinkAllBlocksOptions(msgs) {
  var options = [{ label: "-", value: "-" }];

  for (var i in msgs) {
    var ind = -1;
    for (var j in options) {
      if (
          options[j].label ==
          msgs[i].Group.Name + "(#" + msgs[i].Group._id + ")"
      ) {
        ind = j;
      }
    }
    if (ind == -1) {
      options.push({
        label: msgs[i].Group.Name + "(#" + msgs[i].Group._id + ")",
        options: [
          {
            label: msgs[i].Name + "(#" + msgs[i]._id + ")",
            value: msgs[i]._id,
          },
        ],
      });
    } else {
      options[ind].options.push({
        label: msgs[i].Name + "(#" + msgs[i]._id + ")",
        value: msgs[i]._id,
      });
    }
  }

  return options;
}

export function labels_time(p_obj) {
  var times = [];
  var now = new Date().valueOf();
  for (var i = p_obj.Times - 1; i >= 0; i--) {
    if (p_obj.Period == 'Month') {
      var x = now - i * 1000 * 60 * 60 * 24 * 31;
    }
    else if (p_obj.Period == 'Day') {
      var x = now - i * 1000 * 60 * 60 * 24;
    }
    times.push(x);
  }
  return times;
}

export function sortByField(property) {
  var sortOrder = 1;
  if (property[0] === '-') {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a, b) {
    if (a[property] === undefined) {
      a[property] = 0;
    }
    if (b[property] === undefined) {
      b[property] = 0;
    }
    var result = (a[property] < b[property]) ? -1
      : (a[property] > b[property]) ? 1 : 0;
    return result * sortOrder;
  }
}

export function sort_data(data, labels_times, field) {
  var res = [];
  for (var i in labels_times) {
    res.push(0);
  }
  for (var i in data) {
    if (typeof data[i] == 'number') {
      data[i] = {
        created_at: data[i]
      };
    }
    var nearIndex = 0;
    var min = 1000 * 60 * 60 * 24 * 900;
    for (var j in labels_times) {
      if (Math.abs(data[i].created_at - labels_times[j]) < min) {
        min = Math.abs(data[i].created_at - labels_times[j]);
        nearIndex = j;
      }
    }
    res[nearIndex] += data[i][field] || 1;
  }
  return res;
}

export function period_obj(period) {
  switch (period) {
    case 'Week':
      return {
        Period: 'Day',
        Times: 7
      };
    case '15Day':
      return {
        Period: 'Day',
        Times: 15
      };
    case 'Month':
      return {
        Period: 'Day',
        Times: 31
      };
    case '3Month':
      return {
        Period: 'Month',
        Times: 3
      };
    case 'Half Year':
      return {
        Period: 'Month',
        Times: 6
      };
    case 'Year':
      return {
        Period: 'Month',
        Times: 12
      };
  }
}

export function load_data(x, route, period, data) {
  var p = period_obj(period);
  for (var key in data) {
    p[key] = data[key];
  }
  var s = this.state;

  this.setState({
    Wait: ++this.state.Wait
  });
  getData(route, p).then(function (data) {
    this.setState({
      [x]: data,
      Wait: --this.state.Wait
    });
  }.bind(this), function (err) {
    this.setState({
      Wait: --this.state.Wait
    });
    //alert(JSON.stringify(err));
  }.bind(this))
}
