import React, {Component} from 'react';
import jQuery from 'jquery';
import Cookies from 'universal-cookie';
import { config } from '../../config.js';

const cookies = new Cookies();

class Vk extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  repair = (id) => {
    return new Promise(function (resolve, reject) {
      var url = config.Backend + '/bot/repairBot?';
      if ( !(cookies.get('Id') === undefined) ) {
        url += 'CurrentUserId=' + cookies.get('Id').toString();
      }
      url += '&Id=' + id;
      jQuery.getJSON(url, function (res) {
        if (!res.Error) {
          resolve(res);
        } else {
          reject((res));
        }
      }.bind(this));
    }.bind(this));
  };

  addOrCopy = (name, temp) => {
    return new Promise(function (resolve, reject) {
      var url = config.Backend + '/bot/add?';
      if (!(cookies.get('Id') === undefined)) {
        url += 'CurrentUserId=' + cookies.get('Id').toString();
      }
      url += '&Name=' + name;
      url += '&AiPos=50*50';
      url += '&BindTo=Vk';
      if ( temp ) {
        url = config.Backend + '/bot/copyBot?';
        if ( !(cookies.get('Id') === undefined) ) {
          url += 'CurrentUserId=' + cookies.get('Id').toString();
        }
        url += '&Id=' + temp;
        url += '&Name=' + name;
        url += '&BindTo=Vk';
      }
      jQuery.getJSON(url, function (res) {
        if ( !res.Error ) {
          if ( !temp ) {
            resolve(res);
          }
          else {
            this.repair(res.Bot._id).then(function (res) {
              resolve(res);
            }, function (err) {
              reject(err);
            })
          }
        }
        else {
          reject(res);
        }
      }.bind(this));
    }.bind(this));
  };

  componentDidMount () {
    const params = new URLSearchParams(this.props.location.search);
    const code = params.get('code');
    const state = params.get('state');
    if ( code && state ) {
      if ( state == 'log' ) {
        var data = {
          Code: code,
          Id: cookies.get('Id')
        };
        const key = params.get('key');
        if ( key ) {
          data.Key = key;
        }

        jQuery.ajax({
          method: 'POST',
          contentType: 'application/json',
          url: config.Backend + '/vkLog',
          success: function (res) {
            if ( !res.Error ) {
              cookies.set('Id', res.User._id, {
                path: '/'
              });
              if ( res.User.Type !== '' ) {
                if ( res.User.Type == 'Admin' ) {
                  window.location.replace(window.location.origin + '/dashboard?create=1&soc=Vk');
                } else if (res.User.Type == 'Super') {
                  window.location.replace(window.location.origin + '/admin/main');
                }
              }
            } else {
              alert(JSON.stringify(res));
            }
          }.bind(this),
          data: JSON.stringify(data),
        })
      }
      else {
        const name = params.get('state').split(':::')[0];
        const gid = params.get('state').split(':::')[1];
        const temp = params.get('state').split(':::')[2];
        jQuery.ajax({
          method: 'POST',
          contentType: 'application/json',
          url: config.Backend + '/vkGroupToken',
          success: function (res) {
            if ( !res.Error ) {
              var token = res.token;
              this.addOrCopy(name, temp).then(function (res) {
                var b = res.Bot;
                jQuery.ajax({
                  method: 'POST',
                  contentType: 'application/json',
                  url: config.Backend + '/vkBindTo',
                  success: function (res) {
                    if ( !res.Error ) {
                      window.location.href = window.location.origin + '/dashboard'
                    } else {
                      alert(JSON.stringify(res));
                    }
                  }.bind(this),
                  data: JSON.stringify({
                    Id: b._id,
                    Token: token,
                    gid: gid
                  }),
                })
              }, function (err) {
                alert(JSON.stringify(err));
              });
            } else {
              alert(JSON.stringify(res));
            }
          }.bind(this),
          data: JSON.stringify({
            Code: code
          }),
        });
      }
    }
    else {
      window.location.href = window.location.origin + '/login';
    }
  }

  render() {
    return (
      <div className="background_comein">
      </div>
    );
  }

}

export default Vk;
