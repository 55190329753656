import React from 'react';
import jQuery from 'jquery';
import Cookies from 'universal-cookie';
import LeftPanel from '../../LeftPanel';
import TopPanel from '../../TopPanel/TopPanel';
import Tags from './Tags';
import TimeTags from './TimeTags';
import {Scrollbars} from 'react-custom-scrollbars';
import PopupAll from '../../PopupAll';
import ReactTooltip from 'react-tooltip';
import Reply from './Reply.js';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import { config } from '../../../config.js';
import TransComponent from "../../../TransComponent";

const cookies = new Cookies();
var func = require('../../../func.js');


class GrowMain extends TransComponent {

  constructor(props) {
    super(props);
    this.state = {
      Bot: {},
      Tab: 'Words',
      Syno: false,
      AllMsgs: [],
      Wait: 0
    };

    this.onChangeHandler = config.change.bind(this);
  }

  onChangeReplyLinkHandler = (i, ind, val) => {
    var s = this.state;
    s.Bot.Replies[i].Objs[ind].Link = val ? val.value : '-';
    this.setState({
      Wait: ++this.state.Wait
    });
    func.getData('bot/edit_replies', {
      Bot: config.minimizeBot(s.Bot)
    }).then(b => {
      this.setState({
        Bot: b,
        Wait: --this.state.Wait
      });
    }, e => {
      alert(JSON.stringify(e));
      this.setState({
        Wait: --this.state.Wait
      });
    });
  };

  onDeleteReplyHandler = (i) => {
    var s = this.state;
    s.Bot.Replies.splice(i, 1);
    this.setState({
      Wait: ++this.state.Wait
    });

    func.getData('bot/edit_replies', {
      Bot: config.minimizeBot(s.Bot)
    }).then(b => {
      this.setState({
        Bot: b,
        Wait: --this.state.Wait
      });

    }, e => {
      alert(JSON.stringify(e));
      this.setState({
        Wait: --this.state.Wait
      });
    });
  };

  onDeleteBlockHandler = (i, ind) => {
    var s = this.state;
    s.Bot.Replies[i].Objs.splice(ind, 1);
    this.setState({
      Wait: ++this.state.Wait
    });

    func.getData('bot/edit_replies', {
      Bot: config.minimizeBot(s.Bot)
    }).then(b => {
      this.setState({
        Bot: b,
        Wait: --this.state.Wait
      });
    }, e => {
      this.setState({
        Wait: --this.state.Wait
      });

      alert(JSON.stringify(e));
    });
  };

  onSaveRepliesHandler = () => {
    this.setState({
      Wait: ++this.state.Wait
    });
    func.getData('bot/edit_replies', {
      Bot: config.minimizeBot(this.state.Bot)
    }).then(b => {
      this.setState({
        Wait: --this.state.Wait
      });
    }, e => {
      this.setState({
        Wait: --this.state.Wait
      });
      alert(JSON.stringify(e));
    });
  };

  onAddReplyHandler = () => {
    var s = this.state;
    s.Bot.Replies.push({
      Tag: '',
      Objs: []
    });
    this.setState({
      Wait: ++this.state.Wait
    });

    func.getData('bot/edit_replies', {
      Bot: config.minimizeBot(s.Bot)
    }).then(b => {
      this.setState({
        Bot: b,
        Wait: --this.state.Wait
      });
    }, e => {
      this.setState({
        Wait: --this.state.Wait
      });

      alert(JSON.stringify(e));
    });
  };

  onAddBlockHandler = (i) => {
    var s = this.state;
    s.Bot.Replies[i].Objs.push({
      Link: '',
      Value: '',
      Param: ''
    });
    this.setState({
      Wait: ++this.state.Wait
    });

    func.getData('bot/edit_replies', {
      Bot: config.minimizeBot(s.Bot)
    }).then(b => {
      this.setState({
        Bot: b,
        Wait: --this.state.Wait
      });
    }, e => {
      this.setState({
        Wait: --this.state.Wait
      });
      alert(JSON.stringify(e));
    });
  };

  onDeleteTagHandler = (i, ti) => {
    var url = config.Backend + '/bot/edit?';
    if ( !(cookies.get('Id') === undefined) ) {
      url += '&CurrentUserId=' + cookies.get('Id').toString();
    }
    url += '&Id=' + this.state.Bot._id;
    url += this.state.Tab == 'TimeWords' ? '&EditTimeWord=1' : '&EditWord=1';
    url += '&DeleteTag=1';
    url += '&Index=' + i;
    url += '&TagIndex=' + ti;
    this.setState({
      Wait: ++this.state.Wait
    });

    jQuery.getJSON(url, function (res) {
      this.setState({
        Wait: --this.state.Wait
      });

      if (!res.Error) {
        this.setState({
          Bot: res
        });
      } else {
        alert(JSON.stringify(res));
      }
    }.bind(this));
  };

  onAddTagHandler = (i, tag) => //не работает,рабочая версия в Tags
  {
    var url = config.Backend + '/bot/edit?';
    if (!(cookies.get('Id') === undefined)) {
      url += '&CurrentUserId=' + cookies.get('Id').toString();
    }
    url += '&Id=' + this.state.Bot._id;
    url += this.state.Tab == 'TimeWords' ? '&EditTimeWord=1' : '&EditWord=1';
    url += '&Tag=' + tag;
    url += '&Syno=true';
    url += '&Index=' + i;
    this.setState({
      Wait: ++this.state.Wait
    });

    jQuery.getJSON(url, function (res) {
      this.setState({
        Wait: --this.state.Wait
      });

      if (!res.Error) {
        this.setState({
          Bot: res
        });
      } else {
        alert(JSON.stringify(res));
      }
    }.bind(this));
  };

  onDeleteLinkHandler = (i) => {
    var url = config.Backend + '/bot/edit?';
    if ( !(cookies.get('Id') === undefined) ) {
      url += '&CurrentUserId=' + cookies.get('Id').toString();
    }
    url += '&Id=' + this.state.Bot._id;
    url += this.state.Tab == 'TimeWords' ? '&DeleteTimeWord=1' : '&DeleteWord=1';
    url += '&Index=' + i;
    this.setState({
      Wait: ++this.state.Wait
    });

    jQuery.getJSON(url, function (res) {
      this.setState({
        Wait: --this.state.Wait
      });

      if (!res.Error) {
        this.setState({
          Bot: res
        });
      } else {
        alert(JSON.stringify(res));
      }
    }.bind(this));
  };

  onFormatChangedHandler = (i, newFormat) => {
    var url = config.Backend + '/bot/edit?';
    if (!(cookies.get('Id') === undefined)) {
      url += '&CurrentUserId=' + cookies.get('Id').toString();
    }
    url += '&Id=' + this.state.Bot._id;
    url += this.state.Tab == 'TimeWords' ? '&EditTimeWord=1' : '&EditWord=1';
    url += '&Index=' + i;
    url += '&Format=' + newFormat;
    this.setState({
      Wait: ++this.state.Wait
    });

    jQuery.getJSON(url, function (res) {
      this.setState({
        Wait: --this.state.Wait
      });

      if (!res.Error) {
        this.setState({
          Bot: res
        });
      } else {
        alert(JSON.stringify(res));
      }
    }.bind(this));
  };

  onLinkChangedHandler = (i, newLink) => {
    var url = config.Backend + '/bot/edit?';
    if (!(cookies.get('Id') === undefined)) {
      url += '&CurrentUserId=' + cookies.get('Id').toString();
    }
    url += '&Id=' + this.state.Bot._id;
    url += this.state.Tab == 'TimeWords' ? '&EditTimeWord=1' : '&EditWord=1';
    url += '&Index=' + i;
    url += '&Link=' + newLink;
    jQuery.getJSON(url, function (res) {
      if (!res.Error) {
        this.setState({
          Bot: res
        });
      } else {
        alert(JSON.stringify(res));
      }
    }.bind(this));
  };

  onAddWordHandler = () => {
    var url = config.Backend + '/bot/edit?';
    if (!(cookies.get('Id') === undefined)) {
      url += '&CurrentUserId=' + cookies.get('Id').toString();
    }
    url += '&Id=' + this.state.Bot._id;
    url += this.state.Tab == 'TimeWords' ? '&AddTimeWord=1' : '&AddWord=1';
    this.setState({
      Wait: ++this.state.Wait
    });

    jQuery.getJSON(url, function (res) {
      this.setState({
        Wait: --this.state.Wait
      });

      if (!res.Error) {
        this.setState({
          Bot: res
        });
      } else {
        alert(JSON.stringify(res));
      }
    }.bind(this));
  };

  componentDidMount () {
    // this.t_load();

    var url = config.Backend + '/users/all_msgs?';
    url += '&BotId=' + cookies.get('BotId');
    this.setState({
      Wait: ++this.state.Wait
    });

    jQuery.getJSON(url, function (res) {
      this.setState({
        Wait: --this.state.Wait
      });

      this.loadBot();
      if ( !res.Error ) {
        this.setState({
          AllMsgs: res
        });
      } else {
        alert(JSON.stringify(res));
      }
    }.bind(this));
  }

  loadBot = () => {
    var url = config.Backend + '/bot/findById?';
    if (!(cookies.get('Id') === undefined)) {
      url += 'CurrentUserId=' + cookies.get('Id').toString();
    }
    url += '&Id=' + cookies.get('BotId').toString();
    this.setState({
      Wait: ++this.state.Wait
    });

    jQuery.getJSON(url, function (res) {
      this.setState({
        Wait: --this.state.Wait
      });

      if ( !res.Error ) {
        if ( !config.checkRights(cookies.get('Id').toString(), res, 'Grow') ) {
          config.accessDenied(cookies.get('Id').toString(), res);
        }

        this.setState({
          Bot: res
        });
      } else {
        alert(JSON.stringify(res));
      }
    }.bind(this));
  };

  TimeWords () {
    var words;
    if (this.state.Bot.TimeWords) {
      words = this.state.Bot.TimeWords.map((l, i) =>
        (
          <TimeTags
            onDeleteLinkHandler={this.onDeleteLinkHandler}
            Parent={this.state}
            Link={l.Link}
            onAddTagHandler={this.onAddTagHandler}
            onFormatChangedHandler={this.onFormatChangedHandler}
            onDeleteTagHandler={this.onDeleteTagHandler}
            Format={l.Format}
            t={this.t}
            Word={l}
            Index={i}
            onLinkChangedHandler={this.onLinkChangedHandler}/>)
      );
    } else {
      words = null;
    }
    return (
      <div>
        <div className="form_group_right_panel">
          <button
            className="btn__all--small bg_shadow mb10 mt_10" onClick={this.onAddWordHandler}>
            Добавить правило
          </button>
        </div>

        <div className="form_syno_content">
          <div className="wrap_filter_laber">
            <label for="SynoDate">Добавить синонимы из онлайн словаря
              <span data-tip data-for="i_Dictionary" className={"ml5"}>
                <i className="fz15 far fa-question-circle"></i>
              </span>
            </label>

            <ReactTooltip id="i_Dictionary" type="light">
            <span>При добавлении тэга для него будут добавлены его синонимы полученные из онлайн словаря."</span>
            </ReactTooltip>

            <input id="SynoDate" type={"checkbox"} name={"Syno"} value={this.state.Syno}
              onChange={this.onChangeHandler}/>
          </div>
        </div>
        {words}
      </div>
    );
  }

  Words () {
    return (
      <div>
        <div className="form_group_right_panel">
          <button className="btn__all--small bg_shadow mb10 mt_10" onClick={this.onAddWordHandler}>
            Добавить правило
          </button>
        </div>

        <div className="form_syno_content">
          <div className="wrap_filter_laber">
            <label for="Syno">Добавить синонимы из онлайн словаря
              <span data-tip data-for="i_Dictionary" className={"ml5"}>
                <i className="fz15 far fa-question-circle"></i>
              </span>
            </label>

            <ReactTooltip id="i_Dictionary" type="light">
            <span>При добавлении тэга для него будут добавлены его синонимы полученные из онлайн словаря</span>
            </ReactTooltip>

            <input type={"checkbox"} id={"Syno"} name={"Syno"} value={this.state.Syno}
              onChange={this.onChangeHandler}/>
          </div>
        </div>

        {this.state.Bot.Words ? this.state.Bot.Words.map((l, i) =>
            <div className="grow_tags">
              <Tags
                onDeleteLinkHandler={this.onDeleteLinkHandler}
                Parent={this.state}
                t={this.t}
                Link={l.Link}
                onAddTagHandler={this.onAddTagHandler}
                onDeleteTagHandler={this.onDeleteTagHandler}
                Word={l}
                Index={i}
                onLinkChangedHandler={this.onLinkChangedHandler}/>
            </div>) : null}
      </div>
    );
  }

  onChangeAndSaveHandler = e => {
    this.onChangeHandler(e);
    this.onSaveRepliesHandler();
  };

  Replies () {
    var arr = this.state.Bot.Replies || [];
    var replys = arr.map((l, i) => {
      return this.Reply(l, i);
    });

    return (
      <div>
        <div className="form_group_right_panel">
          <button className="btn__all--small bg_shadow mb10 mt_10" onClick={this.onAddReplyHandler}>
            Добавить правило
          </button>
      </div>

      {replys}
    </div>);
  }

  Reply (l, i) {
    var objs = l.Objs.map((elem, ind) =>
      <Reply
        elem={elem}
        ind={ind}
        i={i}
        AllMsgs={this.state.AllMsgs}
        onChangeAndSaveHandler={this.onChangeAndSaveHandler}
        onDeleteBlockHandler={this.onDeleteBlockHandler}
        onChangeReplyLinkHandler={this.onChangeReplyLinkHandler}/>);
    var onAddBlockHandler = () => this.onAddBlockHandler(i);
    var onDeleteReplyHandler  = () => this.onDeleteReplyHandler(i);
    return (
      <div className={"form-group"}>
        <div className="row__grid">
          <span>Ключевая фраза
            <span data-tip data-for="i_Keyword" className={"ml5"}>
              <i className="fz15 far fa-question-circle"></i>
            </span>
          </span>
        </div>

        <div className={"row__grid"}>
          <div className="col__10">
            <ReactTooltip id="i_Keyword" type="light">
              <span>
                Если исходящее сообщение содержит ключевую фразу (регистр не учитывается) оно будет обработано
              </span>
            </ReactTooltip>

            <input className={"chat_input mr5"} type={"text"} name={"Bot.Replies[" + i + "].Tag"}
              value={l.Tag} onChange={this.onChangeAndSaveHandler}/>
          </div>
        </div>

        <div className={"row__grid"}>
          <button className={"btn__all--small mr5"} onClick={onAddBlockHandler}>
            {this.t("Добавить блок обработки")}
          </button>

          <button className={"btn__all--small"} onClick={onDeleteReplyHandler}>
            <i className="fas fa-trash-alt"></i>
          </button>
        </div>

        {objs}
      </div>
    );
  }

  onTabWordsHandler = () => {
    this.setState({
      Tab: "Words"
    });
  };

  onTabTimeWordsHandler = () => {
    this.setState({
      Tab: "TimeWords"
    });
  };

  onTabRepliesHandler = () => {
    this.setState({
      Tab: "Replies"
    });
  };

  render () {
    var s = this.state;
    var bind = s.Bot.BindTo;
    return (
      <div>
        {this.state.Wait != 0 ? this.Load() : null}

        <TopPanel/>

        <PopupAll/>

        <LeftPanel User={this.props.user}/>

        <div className="grow_main">
          <Scrollbars style={{
              height: "100%"
            }}>
            <div className="overlay_chart">
              <div className="grow_main_post">
                <div className="grow_main_block">
                  <button onClick={this.onTabWordsHandler} className={this.state.Tab == "Words"
                    ? "btn_all mb_all mr5" : "btn_all mr5"}>Преобразование слов
                    <span data-tip data-for="i_Words" className={"ml5"}>
                      <i className="fz15 far fa-question-circle"></i>
                    </span>
                  </button>

                  <ReactTooltip data-offset="{top: 120, left: 410}" place="right" type="light"
                    effect="float" id="i_Words">
                    <div>
                      Бот преобразует набор фраз или слов к одной строке.
                      Это используется при распознавании(когда бот решает какую группу сообщений вызвать) и при сохранении ответов от пользователей.<br/>
                      Регистр не учитывается.<br/>

                      Пример: есть три тэга "Здравствуйте","Привет","Добрый день" их преобразуем к "Приветствие<br/>
                      и когда бот получит сообщение "Привет как дела? он будет действовать также как если бы в сообщение было слово "Приветствие".
                    </div>
                  </ReactTooltip>

                  <button
                    onClick={this.onTabTimeWordsHandler}
                    className={this.state.Tab == "TimeWords"
                      ? "btn_all mb_all mr5" : "btn_all mr5 "}>
                    Преобразование дат

                    <span data-tip data-for="i_TimeWords" className={"ml5"}>
                      <i className="fz15 far fa-question-circle"></i>
                    </span>
                  </button>

                  <ReactTooltip data-offset="{top: 120, left: 410}" place="right" type="light"
                    effect="float" id="i_TimeWords">
                    <div>
                      Бот преобразует набор фраз или слов к дате.
                      Это используется при распознавании(когда бот решает какую группу сообщений вызвать) и при сохранении ответов от пользователей.<br/>
                      Регистр не учитывается.Формат даты "dd.mm.yyyy"<br/>
                      Пример: есть тэг "Завтра" его преобразуем к "сегодня + 1 дней"')
                      <br/>
                      и когда бот получит сообщение "Хочу сделать заказ на завтра" он будет действовать также как если бы сообщение было
                      таким "Хочу сделать заказ на 29.03.2018"(если сегодня 28.03.2018)
                    </div>
                  </ReactTooltip>

                  {(bind === 'FB') || (bind === 'Vk') ?
                    <div style={{
                      display: "inline-block"
                    }}>
                      <button onClick={this.onTabRepliesHandler} className={
                        this.state.Tab === "Replies" ? "btn_all mb_all  " : "btn_all "}>
                        Исходящие сообщения
                        <span data-tip data-for="i_Replies" className={"ml5"}>
                          <i className="fz15 far fa-question-circle"></i>
                        </span>
                      </button>

                      <ReactTooltip data-offset="{top: 120, left: 410}" place="right"
                        type="light" effect="float" id="i_Replies">
                        <div>
                          Когда бот или кто-то от имени канала к котрому подключен бот например сообщества вконтакте<br/>
                          отправляет сообщение,оно может быть обработано в соответсвии с заданными правилами.')}<br/>
                          Вы можете сохранить значение которое содержится в сообщении а также послать дополнительное сообщение.
                        </div>
                      </ReactTooltip>
                    </div>
                    : null}

                  {this[this.state.Tab]()}
                </div>
              </div>
            </div>
          </Scrollbars>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
    bot: (state.bots.bots || []).find(bot => bot._id === cookies.get('BotId').toString()),
  };
};
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(GrowMain));
