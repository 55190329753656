import React from 'react';
import jQuery from 'jquery';
import Cookies from 'universal-cookie';
import LeftPanel from '../../LeftPanel';
import Pagination from '../../../components/Pagination/Pagination';
import TopPanel from '../../TopPanel/TopPanel';
import {Bar, Pie, Line} from 'react-chartjs-2';
import {Scrollbars} from 'react-custom-scrollbars';
import PopupAll from '../../PopupAll';
import TransComponent from '../../../TransComponent.js';
import UserTableRow from './UserTableRow.js';
import Switch from 'react-toggle-switch';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import ReactTooltip from 'react-tooltip';
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import { withAlert } from 'react-alert'
import { config } from '../../../config.js';
import Popup from "../../../components/Popup/Popup";
import axios from "axios";

const cookies = new Cookies();
var func = require('../../../func.js');

class AlalyzeMain extends TransComponent {

  constructor(props) {
    super(props);
    this.state = {
      BotUsers: [],
      MonthUnsubscribesData: [],
      MonthUsersData: [],
      MonthMsgsData: [],
      BlocksData: [],
      BtnsData: [],
      WordsData: [],
      TagsData: [],
      MonthCnt: 8,
      Tables: false,
      Table: 'MsgsTable',
      MsgsPeriod: 'Week',
      Wait: 0,
      Order: 'desc',
      Filter: '',
      UsersFilter: {
        byName: {
          enabled: false,
          value: ''
        },
        byFirstAction: {
          startEnabled: false,
          endEnabled: false,
          startDate: moment(),
          endDate: moment().add(7, 'days'),
        },
        byLastAction: {
          startEnabled: false,
          endEnabled: false,
          startDate: moment(),
          endDate: moment().add(7, 'days'),
        },
        byConversion: {
          startEnabled: false,
          endEnabled: false,
          start: 50,
          end: 100,
        }
      },
      SendedMsgs: [],
      showPopupResetStatistics: false,
      pagination: {
        BlocksData: {
          pointer: 1,
          count: 1,
          sortDirection: 'desc'
        },
        BtnsData: {
          pointer: 1,
          count: 1,
          sortDirection: 'desc'
        },
        WordsData: {
          pointer: 1,
          count: 1,
          sortDirection: 'desc'
        },
        TagsData: {
          pointer: 1,
          count: 1,
          sortDirection: 'desc'
        },
        SendedMsgs: {
          pointer: 1,
          count: 1,
          sortDirection: 'desc'
        },
        BotUsers: {
          pointer: 1,
          count: 1,
          sortDirection: 'desc'
        }
      }
    };

    this.onChangeHandler = config.change.bind(this);
  }

  onChangeCheckBoxHandler = (event, type, field) => {
    try {
      const pagination = this.state.pagination;
      pagination.BotUsers.pointer = 1;

      const usersFilter = this.state.UsersFilter;
      usersFilter[type][field] = event.target.checked;

      this.setState({
        UsersFilter: usersFilter,
        pagination: pagination
      }, async () => {
        await this.loadUsersData();
      });
    } catch (e) {
      ;
    }
  }

  onChangeDateHandler = async (date, parentField, field) => {
    const state = this.state;
    const pagination = state.pagination;
    pagination.BotUsers.pointer = 1;

    state.UsersFilter[parentField][field] = date;
    await this.setState({
      UsersFilter: state.UsersFilter,
      pagination: state.pagination
    });

    await this.loadUsersData();
  };

  onChangeFirstActionStartDateHandler = (date) => {
    this.onChangeDateHandler(date, 'byFirstAction', 'startDate');
  };

  onChangeFirstActionEndDateHandler = (date) => {
    this.onChangeDateHandler(date, 'byFirstAction', 'endDate');
  };

  onChangeLastActionStartDateHandler = (date) => {
    this.onChangeDateHandler(date, 'byLastAction', 'startDate');
  };

  onChangeLastActionEndDateHandler = (date) => {
    this.onChangeDateHandler(date, 'byLastAction', 'endDate');
  };


  reset = () => {
    const alert = this.props.alert;
    var url = config.Backend + '/bot/resetStat?';
    if (!(cookies.get('Id') === undefined )) {
      url += 'CurrentUserId=' + cookies.get('Id').toString();
    }
    url += '&Id=' + cookies.get('BotId');

    jQuery.getJSON(url, function (res) {
      if (!res.Error) {
        alert.success('Статистика сброшена');

        this.setState({
          showPopupResetStatistics: false
        });

        this.load();
      } else {
        alert.error(JSON.stringify(res));
      }
    }.bind(this));
  };

  getData = (attr) => {
    var labels = [];
    var data = [];
    var colors = [];

    for (var i = 0; i < this.state.BotUsers.length; i++) {
      var ind = labels.indexOf(this.state.BotUsers[i][attr]);
      if (ind == -1) {
        labels.push(this.state.BotUsers[i][attr]);
        data.push(1);
        var r = Math.floor(Math.random() * (256));
        var g = Math.floor(Math.random() * (256));
        var b = Math.floor(Math.random() * (256));
        var c = '#' + r.toString(16) + g.toString(16) + b.toString(16);
        colors.push(c);
      } else {
        data[ind]++;
      }
    }

    var datas = {
      labels: labels,
      datasets: [{
        data: data,
        backgroundColor: colors,
        hoverBackgroundColor: colors
      }]
    };
    return datas;
  };

  pieGraphic() {
    return (
      <div>
        <h2 className="">Floor</h2>
        <div className="listPie">
          {this.props.bot.BindTo == "Skype" ?
            <div className="pieWrap">
              <div className="pie">
                <Pie
                  data={this.getData("platform")}
                  height={100}
                  width={100}/>
              </div>
              <div className="pie">
                <Pie data={this.getData("locale")}
                     height={100}
                     width={100}/>
              </div>
              <div className="pie">
                <Pie data={this.getData("country")}
                     height={100}
                     width={100}/>
              </div>
            </div> : null}

          {this.props.bot.BindTo == "FB" ?
            <div className="pieWrap">
              <div className="pie">
                <Pie data={this.getData("gender")}
                     height={100}
                     width={100}/>
              </div>

              <div className="pie">
                <Pie data={this.getData("locale")}
                     height={100}
                     width={100}/>
              </div>
            </div> : null}
        </div>
      </div>
    );
  }

  popularWords(data) {
    var data = {
      labels: data.map((e) => e.Name),
      datasets: [
        {
          label: 'Популярные ответы',
          backgroundColor: '#2c2c8b',
          borderColor: '#2c2c8b',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(255,99,132,0.4)',
          hoverBorderColor: '#2c2c8b',
          data: data.map((e) => e.Cnt)
        }
      ]
    };

    return (
      <div>
        <span className={"fw_menu"} style={{
          textTransform: "uppercase"
        }}>
          Популярные ответы
        </span>
        <div className="row">
          <div className="col-xl-12">
            <div className="bd pd-t-30 pd-b-20 pd-x-20">
              <Bar
                data={data}
                width={100}
                height={200}
                options={{
                  maintainAspectRatio: false
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  line(data) {
    var data = {
      labels: data.map((e) => e.Name),
      datasets: [
        {
          label: 'Популярные блоки',
          backgroundColor: '#2c2c8b',
          borderColor: '#2c2c8b',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(255,99,132,0.4)',
          hoverBorderColor: 'rgba(255,99,132,1)',
          data: data.map((e) => e.Cnt)
        }
      ]
    };

    return (
      <div>
        <span className={"fw_menu"} style={{
          textTransform: "uppercase"
        }}>
          Популярные блоки
        </span>
        <div className="bd pd-t-30 pd-b-20 pd-x-20">
          <Bar
            data={data}
            width={100}
            height={200}
            options={{
              maintainAspectRatio: false
            }}/>
        </div>
      </div>
    );
  }

  UserActivity(labels, users, msgs) {

    var dataLine = {
      labels: labels,
      datasets: [
        {
          label: 'Уникальные пользователи',
          fill: true,
          lineTension: 0.5,
          backgroundColor: '#5f97dd',
          borderColor: '#5f97dd',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: '#5f97dd',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 0,
          pointHoverRadius: 3,
          pointHoverBackgroundColor: '#5f97dd',
          pointHoverBorderColor: '#5f97dd',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: users
        },
        {
          label: 'Отправленные сообщения',
          fill: true,
          lineTension: 0.5,
          backgroundColor: '#2c2c8b',
          borderColor: '#2c2c8b',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: '#2c2c8b',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 0,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: '#2c2c8b',
          pointHoverBorderColor: '#2c2c8b',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: msgs
        }
      ]
    };

    return (
      <div>
        <span className={"fw_menu"} style={{
          textTransform: "uppercase"
        }}>
          Активность пользователей
        </span>

        <div className="row">
          <div className="col-xl-12">
            <div className="bd pd-t-30 pd-b-20 pd-x-20">
              <Line
                data={dataLine}
                height={70}/>
            </div>
          </div>
        </div>
      </div>
    );
  }

  PopularBlockButton(data) {
    var data = {
      labels: data.map(e => e.Name),
      datasets: [
        {
          label: 'Популярные кнопки',
          backgroundColor: '#2c2c8b',
          borderColor: '#2c2c8b',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(255,99,132,0.4)',
          hoverBorderColor: 'rgba(255,99,132,1)',
          data: data.map(e => e.Cnt)
        }
      ]
    };

    return (
      <div className={"col-lx-4"}>
        <span className={"fw_menu"} style={{
          textTransform: "uppercase",
          fontWeight: ""
        }}>
          Популярные кнопки
        </span>
        <div className="bd pd-t-30 pd-b-20 pd-x-20">
          <Bar
            data={data}
            width={100}
            height={200}
            options={{
              maintainAspectRatio: false
            }}/>
        </div>
      </div>
    );
  }

  onChangePagination = (x, pointer) => {
    const state = this.state.pagination;
    state[x].pointer = pointer;
    this.setState({
      pagination: {...state}
    });

    this.reLoadSection(x);
  }

  async reLoadSection(section) {
    switch (section) {
      case 'BlocksData':
        await this.loadPopularBlocksData();
        break;
      case 'TagsData':
        await this.loadTagsData();
        break;
      case 'WordsData':
        await this.loadPopularAnswersData();
        break;
      case 'BtnsData':
        await this.loadPopularBtnsData();
        break;
      case 'BotUsers':
        await this.loadUsersData();
        break;
    }
  }

  async loadPopularBlocksData() {
    let url = config.Backend + '/bot/getBlocksData?Id=' + cookies.get('BotId') + '&page=' + this.state.pagination.BlocksData.pointer + '&sortDirection=' + this.state.pagination.BlocksData.sortDirection;

    if (!(cookies.get('Id') === undefined)) {
      url += '&CurrentUserId=' + cookies.get('Id').toString();
    }

    const { data } = await axios.get(url);
    const state = this.state.pagination;
    state.BlocksData.count = data.Count ?? 0;

    this.setState({
      BlocksData: data.Blocks ?? [],
      pagination: {...state}
    });
  }

  async loadPopularBtnsData() {
    let url = config.Backend + '/bot/getBtnsData?Id=' + cookies.get('BotId') + '&page=' + this.state.pagination.BtnsData.pointer + '&sortDirection=' + this.state.pagination.BtnsData.sortDirection;

    if (!(cookies.get('Id') === undefined)) {
      url += '&CurrentUserId=' + cookies.get('Id').toString();
    }

    const { data } = await axios.get(url);
    const state = this.state.pagination;
    state.BtnsData.count = data.Count ?? 0;

    this.setState({
      BtnsData: data.Blocks ?? [],
      pagination: {...state}
    });
  }

  async loadUsersData() {
    let route = 'fb';

    if (this.props.bot.BindTo === 'Skype' || this.props.bot.BindTo === 'Standalone') {
      route = 'skype';
    } else if (this.props.bot.BindTo === 'Telegram') {
      route = "telegram";
    } else if (this.props.bot.BindTo === 'Ok') {
      route = "ok";
    } else if (this.props.bot.BindTo === "Vk") {
      route = "vk";
    } else if (this.props.bot.BindTo === 'Viber') {
      route = "viber";
    } else if (this.props.bot.BindTo  === 'WhatsApp' ) {
      route = 'whatsapp';
    }

    let url = config.Backend + '/' + route + '/getUsers?BotId=' + cookies.get('BotId') + '&Page=' + this.state.pagination.BotUsers.pointer;

    if (!(cookies.get('Id') === undefined)) {
      url += '&CurrentUserId=' + cookies.get('Id').toString();
    }

    const filter = {};

    if (this.state.UsersFilter.byFirstAction.startEnabled) {
      filter.firstStart = this.state.UsersFilter.byFirstAction.startDate;
    }

    if (this.state.UsersFilter.byFirstAction.endEnabled) {
      filter.firstEnd = this.state.UsersFilter.byFirstAction.endDate;
    }

    if (this.state.UsersFilter.byLastAction.startEnabled) {
      filter.lastStart = this.state.UsersFilter.byLastAction.startDate;
    }

    if (this.state.UsersFilter.byLastAction.endEnabled) {
      filter.lastEnd = this.state.UsersFilter.byLastAction.endDate;
    }

    if (this.state.UsersFilter.byName.enabled) {
      filter.name = this.state.UsersFilter.byName.value;
    }

    const { data } = await axios.get(url, {
      params: filter
    });
    const state = this.state.pagination;
    state.BotUsers.count = data.Count;

    this.setState({
      BotUsers: data.Users ?? [],
      pagination: {...state}
    });
  }

  async loadPopularAnswersData() {
    let url = config.Backend + '/bot/getWordsData?Id=' + cookies.get('BotId') + '&page=' + this.state.pagination.WordsData.pointer + '&sortDirection=' + this.state.pagination.WordsData.sortDirection;

    if (!(cookies.get('Id') === undefined)) {
      url += '&CurrentUserId=' + cookies.get('Id').toString();
    }

    const { data } = await axios.get(url);
    const state = this.state.pagination;
    state.WordsData.count = data.Count ?? 0;

    this.setState({
      WordsData: data.Blocks ?? [],
      pagination: {...state}
    });
  }

  async loadTagsData() {
    let url = config.Backend + '/bot/getTagsData?Id=' + cookies.get('BotId')
        + '&page=' + this.state.pagination.TagsData.pointer
        + '&sortDirection=' + this.state.pagination.TagsData.sortDirection;

    if (!(cookies.get('Id') === undefined)) {
      url += '&CurrentUserId=' + cookies.get('Id').toString();
    }

    const { data } = await axios.get(url);
    const state = this.state.pagination;
    state.TagsData.count = data.Count ?? 0;

    this.setState({
      TagsData: data.Tags ?? [],
      pagination: {...state}
    });
  }

  load = () => {
    if (!config.checkRights(cookies.get('Id').toString(), this.props.bot, 'Analyze')) {
      config.accessDenied(cookies.get('Id').toString(), this.props.bot);
    }

    this.loadPopularBlocksData();
    this.loadPopularAnswersData();
    this.loadTagsData();
    this.loadPopularBtnsData();
    this.loadUsersData();

    var now = new Date();
    for (var i = 0; i < this.state.MonthCnt; i++) {
      this.state.MonthUnsubscribesData.push(-1);
      this.state.MonthUsersData.push(-1);
      this.state.MonthMsgsData.push(-1);
    }

    for (var i = now.getMonth() - this.state.MonthCnt + 1, k = 0;
         i < now.getMonth() + 1; i++, k++) {
      var month = i > 11 ? i - 12 : i;
      month = month < 0 ? 12 + i : month;
      const ind = k;
      var getData = config.Backend + '/bot/getData?Id=' + cookies.get('BotId');
      if (!(cookies.get('Id') === undefined)) {
        getData += '&CurrentUserId=' + cookies.get('Id').toString();
      }
      if (i >= 0 && i <= 11) {
        getData += '&Year=' + now.getFullYear();
      } else if (i > 11) {
        getData += '&Year=' + (now.getFullYear() + 1);
      }
      else {
        getData += '&Year=' + (now.getFullYear() - 1);
      }
      getData += '&Month=' + month;

      jQuery.getJSON(getData, function (res) {
        if (!res.Error) {
          this.state.MonthUnsubscribesData[ind] = (res.Unsubscribes);
          this.state.MonthUsersData[ind] = (res.Users);
          this.state.MonthMsgsData[ind] = (res.Msgs);
          this.setState({
            MonthUnsubscribesData: this.state.MonthUnsubscribesData,
            MonthUsersData: this.state.MonthUsersData,
            MonthMsgsData: this.state.MonthMsgsData
          });
        }
      }.bind(this));
    }

    // func.getData('bot/sended_messages', {
    //   BotId: cookies.get('BotId')
    // }).then((msgs) => {
    //   this.setState({
    //     Wait: --this.state.Wait,
    //     SendedMsgs: msgs
    //   });
    // }, (err) => {
    //   this.setState({
    //     Wait: --this.state.Wait
    //   });
    // });
  };

  componentDidUpdate (prevProps) {
    if (this.props.bot && !prevProps.bot) {
      this.load();
    }
  }

  async componentDidMount() {
    await this.setState({
      UsersTable: [],
      SendedMsgs: [],
      WordsData: [],
      TagsData: [],
      BtnsData: [],
      BlocksData: []
    });

    if (config.botIsFullyLoadOther(this.props.bot)) {
      this.load();
    }
  }

  PopularBlockPopup(x) {
    var blocks = this.state[x].map(function (e, i) {
      var onChangeHandler = (e) => this.onChangeHandler(e, i);

      return (
        <tr>
          <td className={"tl"}>{e.Name}</td>
          <td className={"tac"}>{e.Cnt}</td>
          <td>
            <input type={"checkbox"} name={x + ".Select"} checked={e.Select}
                   onChange={onChangeHandler}/>
          </td>
        </tr>
      );
    }.bind(this));

    return (
      <div className="preloader_bots">
        <div className="preloader_bots_2 popupW">
          <div className="analyse_content">
            <Scrollbars
              style={{
                height: "95%"
              }}>
              <div className="analyse_content_2">
                <table className={"table"}>
                  <thead>
                  <tr className={"table_f"}>
                    <th className={"tl"}>Название</th>
                    <th className={"tac"}>Кол-во</th>
                    <th className={"tac"}>Выбрать</th>
                  </tr>
                  </thead>
                  <tbody>
                  {blocks}
                  </tbody>
                </table>
                {/*<button onClick={this.hidePopups}>Close</button>*/}
              </div>
            </Scrollbars>
            <div className="close_popup_btn">
              <button
                onClick={this.hidePopups}
                type="button"
                className="close"
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  onFilterSomethingHandler = async (section, something) => {
    const s = this.state;
    const order = s.Order == 'desc' ? 'asc' : 'desc';

    if (s.pagination.hasOwnProperty(section)) {
      s.pagination[section].sortDirection = order;
      s.pagination[section].pointer = 1;
    }

    await this.setState({
      Order: order,
      Filter: something,
      pagination: s.pagination
    });

    await this.reLoadSection(section);
  };

  onFilterPopularTableHandler = async (section) => {
    await this.onFilterSomethingHandler(section, 'PopularTable');
  };

  PopularTable(x) {
    const s = this.state;
    const blocks = this.state[x].map((e, i) => {
      return (<tr key={i}>
            <td className={"tl"}>{e.Name}</td>
            <td className={"tac"}>{e.Cnt}</td>
          </tr>
      );
    });

    return (
      <div className="analyse_content">
        <div className="testinhs3">
          {blocks.length > 0 ?
            <div>
              <a className={"grid_underline"}
                 href={config.Backend + "/bot/export?Id=" + cookies.get('BotId') + "&What=" + x + "&Ext=xls"}>
                Скачать как xls
              </a>

              <a className={"grid_underline"} style={{marginLeft: "15px"}}
                 href={config.Backend + "/bot/export?Id=" + cookies.get('BotId') + "&What=" + x + "&Ext=csv"}>
                Скачать как csv
              </a>
            </div>
            : null}

          <table className={"table mt_20"}>
            <thead>
            <tr className={"table_f"}>
              <th className={"tl"}>
                Название
              </th>

              <th className={"tl"}>
                <span
                  style={{
                    cursor: "pointer"
                  }}
                  onClick={() => this.onFilterPopularTableHandler(x)}>
                  Кол-во
                  {s.Filter == "PopularTable" ? (s.Order == "desc" ?
                    <i className="fz12 fas fa-chevron-down"></i> :
                    <i className="fz12 fas fa-chevron-up"></i>) : ""}
                </span>
              </th>
            </tr>
            </thead>

            <tbody>
            {blocks}
            </tbody>
          </table>
        </div>
        <div>
          <Pagination key={x} section={x} onChange={this.onChangePagination} count={this.state.pagination[x].count} pointer={this.state.pagination[x].pointer}/>
        </div>
      </div>
    );
  }

  PopularBlocksTable() {
    return this.PopularTable('BlocksData');
  }

  PopularAnswersTable() {
    return this.PopularTable('WordsData');
  }

  TagsTable() {
    return this.PopularTable('TagsData');
  }

  PopularButtonsTable() {
    return this.PopularTable('BtnsData');
  }

  onFilterMessagesHandler = () => {
    this.onFilterSomethingHandler('Messages');
  };

  onFilterUsersHandler = () => {
    this.onFilterSomethingHandler('Users');
  };

  onFilterUnsubscribesHandler = () => {
    this.onFilterSomethingHandler('Unsubscribes');
  };

  MsgsTable() {
    var s = this.state;
    var labels = [];
    var now = new Date().getMonth();
    var months = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август',
      'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];

    for (var i = now - this.state.MonthCnt + 1; i < now + 1; i++) {
      var x = i > 11 ? i - 12 : i;
      x = x < 0 ? 12 + i : x;
      labels.push(this.t(months[x]));
    }
    var msgs = s.MonthMsgsData;
    var users = s.MonthUsersData;
    var unsubscribes = s.MonthUnsubscribesData;
    const showUnsubscribes = this.props.bot && this.props.bot.BindTo === 'Telegram';
    var arr = [];

    for (var i = labels.length - 1; i >= 0; i--) {
      arr.push({
        label: labels[i],
        unsubscribes: unsubscribes[i],
        msgs: msgs[i],
        users: users[i]
      });
    }

    if (s.Filter == 'Messages') {
      arr.sort(func.sortByField((s.Order == 'desc' ? '-' : '') + 'msgs'));
    } else if (s.Filter == 'Users') {
      arr.sort(func.sortByField((s.Order == 'desc' ? '-' : '') + 'users'));
    } else if (s.Filter == 'Unsubscribes') {
      arr.sort(func.sortByField((s.Order == 'desc' ? '-' : '') + 'unsubscribes'));
    }

    var rows = arr.map(obj => (
      <tr key={obj.label}>
        <td>{obj.label}</td>

        <td className={'tac'}>{obj.msgs}</td>

        <td className={'tac'}>{obj.users}</td>

        {showUnsubscribes && (<td className={'tac'}>{obj.unsubscribes}</td>)}
      </tr>
    ));


    return (<div>
      {rows.length > 0 ?
        <div className={"mt_15"}>
          <a className={"grid_underline"}
             href={config.Backend + "/bot/export?Id=" + cookies.get('BotId') + "&What=Activity&Ext=xls"}>
            Скачать как xls
          </a>

          <a className={"grid_underline"} style={{marginLeft: "15px"}}
             href={config.Backend + "/bot/export?Id=" + cookies.get('BotId') + "&What=Activity&Ext=csv"}>
            Скачать как csv
          </a>
        </div>
        : null}

      <table className={"table mt_20"}>
        <thead>
        <tr className={"table_f tl"}>
          <th className={"tl"}>Месяц</th>

          <th className={"tl"}>
             <span
               style={{
                 cursor: "pointer"
               }}
               onClick={this.onFilterMessagesHandler}>
               Сообщений
               {s.Filter == "Messages" ? (s.Order == "desc" ?
                 <i className="fz12 fas fa-chevron-down"/> :
                 <i className="fz12 fas fa-chevron-up"/>) : ""}
             </span>
          </th>

          <th className={"tl"}>
            <span
              style={{
                cursor: "pointer"
              }}
              onClick={this.onFilterUsersHandler}>
              Уникальных пользователей
              {s.Filter == "Users" ? (s.Order == "desc" ?
                <i className="fz12 fas fa-chevron-down"/> :
                <i className="fz12 fas fa-chevron-up"/>) : ""}
             </span>
          </th>

          {showUnsubscribes && (
              <th className={"tl"}>
            <span
                style={{
                  cursor: "pointer"
                }}
                onClick={this.onFilterUnsubscribesHandler}>
              Количество отписавшихся в месяце
              {s.Filter == "Unsubscribes" ? (s.Order == "desc" ?
                  <i className="fz12 fas fa-chevron-down"/> :
                  <i className="fz12 fas fa-chevron-up"/>) : ""}
             </span>
              </th>
          )}
        </tr>
        </thead>

        <tbody>
        {rows}
        </tbody>
      </table>
    </div>);
  }

  onMsgsTableHandler = () => {
    this.setState({
      Table: "MsgsTable"
    });
  };

  onPopularBlocksTableHandler = () => {
    this.setState({
      Table: "PopularBlocksTable"
    });
  };

  onPopularAnswersTableHandler = () => {
    this.setState({
      Table: "PopularAnswersTable"
    });
  };

  onPopularButtonsTableHandler = () => {
    this.setState({
      Table: "PopularButtonsTable"
    });
  };

  onFilterUserNameHandler = () => {
    this.onFilterSomethingHandler('UserName');
  };

  onFilterUserLastActionHandler = () => {
    this.onFilterSomethingHandler('UserLastAction');
  };

  onFilterUserFirstActionHandler = () => {
    this.onFilterSomethingHandler('UserFirstAction');
  };

  onFilterUserConversionHandler = () => {
    this.onFilterSomethingHandler('Conversion');
  };

  onUsersTableHandler = () => {
    this.setState({
      Table: "UsersTable"
    });
  };

  onTagsTableHandler = () => {
    this.setState({
      Table: "TagsTable"
    });
  };

  FilterForUsersTable() {
    var s = this.state;
    var showConversion = ['Vk'].indexOf(this.props.bot.BindTo) !== -1;

    return (
      <div>
        {this.UsersStringFilter("Имя", 'byName')}
        <div className="row__grid">
          {this.UsersDateFilter("Время последнего взаимодействия", 'byLastAction')}

          {this.UsersDateFilter("Время первого взаимодействия", 'byFirstAction')}
        </div>

        {showConversion ?
          this.UsersNumberFilter("Конверсия должна быть в диапазоне:", 'byConversion')
        : null}
      </div>
    );
  }

  UsersDateFilter(publicName, nameInUserFilter) {
    var s = this.state;
    var nameWithoutBy = nameInUserFilter.replace('by', '');

    return (
      <div className={'col__5'}>
        <div className="col__10">
          {/*{publicName + ' должно находится в промежутке между датами:'}*/}
        </div>

        {/*<div className="row__grid pl">*/}
        {/*  <div className="col__4 grid__align--center row__grid">*/}
        {/*    <div className="wrap_filter_laber">*/}
        {/*      <label>Учитывать стартовую дату</label>*/}
        {/*      <input*/}
        {/*        type={"checkbox"}*/}
        {/*        value={s.UsersFilter[nameInUserFilter].startEnabled}*/}
        {/*        onChange={(e) => this.onChangeCheckBoxHandler(e, nameInUserFilter, 'startEnabled')}/>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*  <div className="col__6">*/}
        {/*    <DatePicker*/}
        {/*      selected={s.UsersFilter[nameInUserFilter].startDate}*/}
        {/*      selectsStart*/}
        {/*      placeholderText={'Выберите стартовую дату'}*/}
        {/*      onChange={this['onChange' + nameWithoutBy + 'StartDateHandler']}/>*/}
        {/*  </div>*/}
        {/*</div>*/}

        {/*<div className="row__grid pl">*/}
        {/*  <div className="col__4 grid__align--center row__grid">*/}
        {/*    <div className="wrap_filter_laber">*/}
        {/*      <label>Учитывать конечную дату</label>*/}

        {/*      <input*/}
        {/*        type={"checkbox"}*/}
        {/*        value={s.UsersFilter[nameInUserFilter].endEnabled}*/}
        {/*        onChange={(e) => this.onChangeCheckBoxHandler(e, nameInUserFilter, 'endEnabled')}/>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*  <div className="col__6">*/}
        {/*    <DatePicker*/}
        {/*      selected={s.UsersFilter[nameInUserFilter].endDate}*/}
        {/*      selectsEnd*/}
        {/*      placeholderText={'Выберите конечную дату'}*/}
        {/*      onChange={this['onChange' + nameWithoutBy + 'EndDateHandler']}/>*/}
        {/*  </div>*/}
        {/*</div>*/}

      </div>
    );
  }

  UsersStringFilter(publicName, nameInUserFilter) {
    var s = this.state;

    return (
      <div>
        {/*{publicName + ' должно содержать строку:'}*/}

        {/*<input*/}
        {/*  className={'chat_input'}*/}
        {/*  type={"text"}*/}
        {/*  value={s.UsersFilter[nameInUserFilter].value}*/}
        {/*  onChange={(e) => this.onChangeCheckBoxHandler(e, nameInUserFilter, 'value')}/>*/}

        {/*<div className="form-group mb15">*/}
        {/*  <div className="wrap_filter_laber">*/}
        {/*    <label htmlFor={'UserCheck'}>Включен</label>*/}

        {/*    <input*/}
        {/*      id={'UserCheck'}*/}
        {/*      type={"checkbox"}*/}
        {/*      value={s.UsersFilter[nameInUserFilter].enabled}*/}
        {/*      name={'UsersFilter.' + nameInUserFilter + '.enabled'}*/}
        {/*      onChange={this.onChangeHandler}/>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
    );
  }

  UsersNumberFilter(publicName, nameInUserFilter) {
    var s = this.state;

    return (
      <div className={'col__5'}>
        <div className="col__10">
          {publicName}
        </div>

        <div className="row__grid pl">
          <div className="col__4 grid__align--center row__grid">
            <div className="wrap_filter_laber">
              <label htmlFor={'UserCheck'}>Учитывать нижнюю границу</label>

              <input
                id={'UserCheck'}
                type={"checkbox"}
                value={s.UsersFilter[nameInUserFilter].startEnabled}
                name={'UsersFilter.' + nameInUserFilter + '.startEnabled'}
                onChange={this.onChangeHandler}/>
            </div>
          </div>

          <div className="col__6">
            <input
              className={'chat_input'}
              type={"number"}
              value={s.UsersFilter[nameInUserFilter].start}
              name={'UsersFilter.' + nameInUserFilter + '.start'}
              onChange={this.onChangeHandler}/>
          </div>
        </div>

        <div className="row__grid pl">
          <div className="col__4 grid__align--center row__grid">
            <div className="wrap_filter_laber">
              <label htmlFor={'UserCheck'}>Учитывать верхнюю границу</label>

              <input
                id={'UserCheck'}
                type={"checkbox"}
                value={s.UsersFilter[nameInUserFilter].endEnabled}
                name={'UsersFilter.' + nameInUserFilter + '.endEnabled'}
                onChange={this.onChangeHandler}/>
            </div>
          </div>

          <div className="col__6">
            <input
              className={'chat_input'}
              type={"number"}
              value={s.UsersFilter[nameInUserFilter].end}
              name={'UsersFilter.' + nameInUserFilter + '.end'}
              onChange={this.onChangeHandler}/>
          </div>
        </div>
      </div>
    );
  }

  UsersTable() {
    var s = this.state;
    var readed_msgs = 0;
    s.SendedMsgs.forEach(msg => {
      if (msg.read_state == 1) {
        readed_msgs++;
      }
    });

    var conversion = ((readed_msgs * 100) / s.SendedMsgs.length).toFixed(0);

    var arr = [];
    s.BotUsers.forEach(u => {
      u.name = (u.first_name || u.last_name) ? (u.first_name + ' ' + u.last_name)
        : (u.name || u._id);
      u.Last = new Date(u.Last).valueOf();
      u.First = new Date(u.First).valueOf();

      var readed_msgs = 0;
      var all = 0;
      s.SendedMsgs.forEach(msg => {
        if (msg.User == u.Id) {
          all++;
          if (msg.read_state == 1) {
            readed_msgs++;
          }
        }
      });

      u.Conversion = (readed_msgs * 100)/all;
      arr.push(u);
    });


    var showConversion = ['Vk'].indexOf(this.props.bot.BindTo) !== -1;
    var users = arr.map((e, i) => <UserTableRow key={i} elem={e} ind={i} showConversion={showConversion}/>);

    return (
      <div className="analyse_content">

        {this.FilterForUsersTable()}

        <div className="testinhs3">
          <table className={"table mt_20"}>
            <thead>
            <tr className={"table_f"}>
              <th className={"tl"}>
                 <span
                   style={{
                     cursor: "pointer"
                   }}
                   onClick={this.onFilterUserNameHandler}>
                   {"Имя"}
                   {s.Filter == "UserName" ? (s.Order == "desc" ?
                     <i className="fz12 fas fa-chevron-down"></i> :
                     <i className="fz12 fas fa-chevron-up"></i>) : ""}
                </span>
              </th>

              <th className={"tl"}>
                <span
                  style={{
                    cursor: "pointer"
                  }}
                  onClick={this.onFilterUserLastActionHandler}>
                  Последнее действие
                  {s.Filter == "UserLastAction" ? (s.Order == "desc" ?
                    <i className="fz12 fas fa-chevron-down"></i> :
                    <i className="fz12 fas fa-chevron-up"></i>) : ""}
                </span>
              </th>

              <th className={"tl"}>
                <span
                  style={{
                    cursor: "pointer"
                  }}
                  onClick={this.onFilterUserFirstActionHandler}>
                  Первое действие
                  {s.Filter == "UserFirstAction" ? (s.Order == "desc" ?
                    <i className="fz12 fas fa-chevron-down"></i> :
                    <i className="fz12 fas fa-chevron-up"></i>) : ""}
                </span>
              </th>

              {showConversion ?
              <th className={"tl"}>
                <span
                  style={{
                    cursor: "pointer"
                  }}
                  onClick={this.onFilterUserConversionHandler}>
                  Конверсия Общая {conversion + '%'}

                  {s.Filter == "Conversion" ? (s.Order == "desc" ?
                    <i className="fz12 fas fa-chevron-down"></i> :
                    <i className="fz12 fas fa-chevron-up"></i>) : ""}
                </span>

                <label htmlFor="wait_answer_build2">
                  <Link data-tip data-for="i_DLcnt" className={"ml5"} to="">
                    <i className="fz15 far fa-question-circle"></i>
                  </Link>
                </label>

                <ReactTooltip id="i_DLcnt" type="light">
                  <span>
                    Показывает сколько процентов сообщений пользователь прочитал из всех им полученных
                  </span>
                </ReactTooltip>
              </th> : null}
            </tr>
            </thead>

            <tbody>
            {users}
            </tbody>
          </table>
        </div>
        <div>
          <Pagination key={'BotUsers'} section={'BotUsers'} onChange={this.onChangePagination} count={this.state.pagination.BotUsers.count} pointer={this.state.pagination.BotUsers.pointer}/>
        </div>
      </div>
    );
  }

  Tables() {
    var s = this.state;
    return (<div className={"mt_20"}>
      <button
        className={this.state.Table == "MsgsTable" ? "btn_all mb_all mr5" : "btn_all mr5"}
        onClick={this.onMsgsTableHandler}>
        Пользовательская активность
      </button>

      <button
        className={this.state.Table == "PopularBlocksTable" ? "btn_all mb_all mr5" : "btn_all mr5"}
        onClick={this.onPopularBlocksTableHandler}>
        Популярные блоки
      </button>

      <button
        className={this.state.Table == "PopularAnswersTable" ? "btn_all mb_all mr5" : "btn_all mr5"}
        onClick={this.onPopularAnswersTableHandler}>
       Популярные ответы
      </button>

      <button
        className={this.state.Table == "PopularButtonsTable" ? "btn_all mb_all mr5" : "btn_all mr5"}
        onClick={this.onPopularButtonsTableHandler}>
      Популярные кнопки
      </button>

      <button
        className={this.state.Table == "UsersTable" ? "btn_all mb_all mr5" : "btn_all mr5"}
        onClick={this.onUsersTableHandler}>
     Пользователи
      </button>

      <button
          className={this.state.Table == "TagsTable" ? "btn_all mb_all mr5" : "btn_all mr5"}
          onClick={this.onTagsTableHandler}>
        Метки
      </button>

      {this[s.Table]()}
    </div>);
  }

  onSwitchTableHandler = () => {
    var s = this.state;
    this.setState({
      Tables: !s.Tables
    });
  };

  openPopupResetStatistics = () => {
    this.setState({
      showPopupResetStatistics: true
    });
  }

  Content() {
    var s = this.state;
    var labels = [];
    var now = new Date().getMonth();
    var months = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август',
      'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];
    for (var i = now - this.state.MonthCnt + 1; i < now + 1; i++) {
      var x = i > 11 ? i - 12 : i;
      x = x < 0 ? 12 + i : x;
      labels.push(this.t(months[x]));
    }

    var ShowUserAct = this.props.bot && this.props.bot.BindTo &&
      this.state.MonthMsgsData.length == this.state.MonthCnt
      && this.state.MonthUsersData.length == this.state.MonthCnt;

    for (var i = 0; i < this.state.MonthCnt; i++) {
      if (this.state.MonthUsersData[i] == -1 || this.state.MonthMsgsData[i] == -1) {
        ShowUserAct = false;
      }
    }

    var ShowBlocks = this.state.BlocksData.length > 0;
    var ShowBtns = this.state.BtnsData.length > 0;
    var ShowWords = this.state.WordsData.length > 0;

    return (
      <div className="">
        {!ShowUserAct && !ShowBlocks && !ShowBtns ?
          <div className="no_data">
            <div className="content_none_data">

            </div>
          </div> : null}

        <div className="row">
          <div className="col-xl-3 row__grid align-items-center">
            <div className="form-check form-check-inline">
              <Switch
                onClick={this.onSwitchTableHandler}
                on={s.Tables}
                className={"form-check-input"}
                id={"Tables"}/>
            </div>

            <label
              className={"fw__panel"}
              onClick={this.onSwitchTableHandler}
              htmlFor="Tables">
              Показать  в виде таблиц
            </label>
          </div>

          <div className="col-xl-6">
            <div className="analyze_top_block">

              {!ShowUserAct && !ShowBlocks && !ShowBtns ? null :
                <button className="btn__all--small" onClick={this.openPopupResetStatistics}>
                  Сбросить статистику
                </button>
              }
            </div>
          </div>
        </div>

        {s.Tables ? this.Tables() : <div className="chart_full mt_20">
          <div>
            {ShowUserAct ?
              <div className="chart_one">
                {this.UserActivity(labels, this.state.MonthUsersData, this.state.MonthMsgsData)}
              </div> : null}

            {this.props.bot && (this.props.bot.BindTo == "Skype" || this.props.bot.BindTo == "FB") && false ?
              <div className="chart_right">
                {this.pieGraphic()}
              </div> : null
            }
          </div>

          <div className="row mt_25">
            {true ?
              <div className="col-lg-4">
                {this.line(this.state.BlocksData)}
              </div> : null}

            {true ?
              <div className="col-lg-4">
                {this.popularWords(this.state.WordsData)}
              </div> : null}

            {true ?
              <div className="col-lg-4">
                {this.PopularBlockButton(this.state.BtnsData)}
              </div> : null}
          </div>
        </div>
        }
      </div>
    );
  }

  render() {
    var s = this.state;
    return (
      <div>
        {s.ShowPopularBlockPopup ? this.PopularBlockPopup('BlocksData') : null}

        {s.ShowPopularWordsPopup ? this.PopularBlockPopup('WordsData') : null}

        {s.ShowPopularButtonsPopup ? this.PopularBlockPopup('BtnsData') : null}

        <TopPanel/>
        <div className="b-page">
          <div className="b-page__row">
            <LeftPanel User={this.props.user}/>
            <div className=" b-page__content-wrap" style={{height: "calc(100vh - 68px)"}}>
              <Scrollbars
                style={{
                  height: "100%"
                }}>
                <div className="dashboard-page">
                  <div className="dashboard-page__items">

                    {this.Content()}

                  </div>
                </div>
              </Scrollbars>
            </div>
          </div>
        </div>
        <PopupAll/>
        {this.state.showPopupResetStatistics ?
        <Popup
          Yes={this.reset}
          No={ () => { this.setState({showPopupResetStatistics: false }) }}
          isDashboard={true}
          text="Это действие удалит всех подписчиков и все данные в боте. Продолжить?"/>
          : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
    bot: (state.bots.bots || []).find(bot => bot._id === cookies.get('BotId').toString()),
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(withAlert()(withRouter(AlalyzeMain)));
