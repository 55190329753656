import React from 'react';
import jQuery from 'jquery';
import Cookies from 'universal-cookie';
import TopPanel from '../../TopPanel/TopPanel';
import {Scrollbars} from 'react-custom-scrollbars';
import PopupAll from '../../PopupAll';
import TransComponent from '../../../TransComponent.js';
import Tariff from './Tariff.js';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { config } from '../../../config.js';
import { successUser } from '../../../modules/User/actions';
import { withAlert } from 'react-alert'

const cookies = new Cookies();
var func = require('../../../func.js');


class Tariffs extends TransComponent {

  constructor(props) {
    super(props);
    this.state = {
      Tariffs: [],
      Wait: 0
    };
  }

  onBuyHandler = (id, i) => {
    var url = config.Backend + '/tariffs/bind2?Id=' + id + '&CurrentUserId=' + cookies.get('Id').toString();
    const alert = this.props.alert;

    jQuery.getJSON(url, function (res) {

      if (!res.Error) {
        const updatedUser = {
          ...this.props.user,
          Tariff: res.Tariff
        };

       this.props.editUserInStore(updatedUser);
        alert.success('Тариф успешно подключен');
      }
      else {
        alert.error(JSON.stringify(res));
      }
    }.bind(this));
  };

  load = () => {
    const alert = this.props.alert;
    var url = config.Backend + '/tariffs/all?';
    if ( !(cookies.get('Id') === undefined) ) {
      url += '&CurrentUserId=' + cookies.get('Id').toString();
    }
    this.setState({
      Wait: ++this.state.Wait
    });

    jQuery.getJSON(url, function (res) {
      this.setState({
        Wait: --this.state.Wait
      });

      if (!res.Error) {
        var arr = [];
        for (var i = 0; i < res.length; i++) {
          if (res[i]._id == '5a6a33058258e31f0a916ef9') {
            if (['3456789789@mail.ru'].indexOf(this.props.user.Email) != -1 ||
              ['5a7352415790c43d60ea513c', '5a7214de5790c43d60ea50e9', '5e69fbb4742e7d562dc73ca2',
                '5a7213c45790c43d60ea50e1', '5a77a0831b22160bf6bee45a', '5a720a625790c43d60ea50d6',
                '5a9be1de1ff8d9346f451682', '5d81e6b9c9c0bf7bf80df1ea', '613f9a0d0e35a76130f33616']
                .indexOf(this.props.user._id) != -1) {
              arr.push(res[i]);
            }
          }
          else {
            arr.push(res[i]);
          }
        }
        this.setState({
          Tariffs: arr
        })
      } else {
        alert.error(JSON.stringify(res));
      }
    }.bind(this));

    this.setState({
      Wait: ++this.state.Wait
    });

    func.getData('settings/all_public').then(function (ss) {
      for (var i in ss) {
        if (['OneBotPerDayTariffDiscount'].includes(ss[i].Key)) {
          this.setState({[ss[i].Key]: ss[i].Value});
        }
      }
      this.setState({
        Wait: --this.state.Wait,
        Settings: ss.map(e => e.Key)
      });
    }.bind(this), function (err) {
      this.setState({
        Wait: --this.state.Wait
      });
      alert.info(JSON.stringify(err));
    }.bind(this));
  };

  componentDidUpdate (prevProps) {
    if (this.props.user && !prevProps.user) {
      this.load();
    }
  }

  componentDidMount () {
    this.t_load();
    if (this.props.user) {
      this.load();
    }
  }

  TList () {
    var s = this.state;
    var tariffs;
    if (this.props.user && s.Tariffs) {
      tariffs = s.Tariffs.map((elem, ind) => <Tariff key={elem._id} elem={elem} ind={ind}
                                               User={this.props.user}
                                               onBuyHandler={this.onBuyHandler}/>);
    } else {
      tariffs = null;
    }
    return (<div className={"list_tariffs_plan"}>{tariffs}</div>);
  }


  render () {
    const u = this.props.user;
    var { OneBotPerDayTariffDiscount, Tariffs } = this.state;
    var oldT = u ? typeof u.Tariff === 'object' ? u.Tariff : null : null;

    Tariffs.forEach(tariff => {
      if (u && tariff._id === u.Tariff) {
        oldT = tariff;
      }
    });

    const tariffIsActive = u && oldT && u.TariffEnd && new Date(u.TariffEnd) > new Date();

    var discount = 0;

    if (OneBotPerDayTariffDiscount &&
      tariffIsActive && !oldT.Start && oldT.Price > 0) {
      // если старый тариф еще действует и он платный,
      // даем скидку = кол-во ботов * оставшиеся дни * X
      // X - настраивается в админке
      const daysLeft = u ? config.getDaysLeft(new Date(u.TariffEnd), new Date()) : 0;
      discount = oldT.Cnt * daysLeft * OneBotPerDayTariffDiscount;
    }

    return (
      <div>
        {this.state.Wait != 0 ? this.Load() : null}

        <TopPanel/>

        <PopupAll/>

        {/*<div className="list_tariffs_plan">*/}

        <div className="grow_main_tariffs">

          <Scrollbars style={{
            height: "100%"
          }}>
            <div className="overlay_chart">
              <div className="col-sm-12">
                <h1>Управление услугами</h1>

                <p>{this.t("Вы можете в любой момент перейти на другой тарифный план" +
                  " или добавить к текущему тарифу дополнительные услуги.")}</p>

                {/*{discount ? <p>*/}
                {/*  {this.t("Если вы переходите на другой тарифный план вы получите скидку:")*/}
                {/*  + " " + discount.toFixed(2) + " " + this.t("руб")}*/}
                {/*</p> : null}*/}
              </div>
              {this.TList()}
            </div>
          </Scrollbars>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
  };
};

const mapDispatchToProps = {
  editUserInStore: successUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(withAlert()(withRouter(Tariffs)))
