import React from 'react';
import TransComponent from '../../../TransComponent.js';

class User extends TransComponent {

  constructor(props) {
    super(props);
  }

  componentDidMount () {
    this.t_load();
  }

  render () {
    var p = this.props;
    var obj = p.elem;
    var ind = p.ind;
    var onShowHandler = () => p.onShowHandler(obj);

    var text = obj.TextAfterGrowUp || obj.Text;
    if ( text && text.length > 30 ) {
      text = text.substr(0, 30);
      text += '...';
    }
    return (
      <li key={obj._id} onClick={onShowHandler}
        className={"left__user__chat--block dashboard-page__items"}>
        <div className="row__grid">
          <div className="col__2">
            <div className="left__user__chat--avatar chat_icon--avatar user">
              <img src={obj.avatar}/>
            </div>
            {/*<div className="avatar_add_bot_ad mt_5">
                        <div className="row__grid">
                            <div className="col__2">
                                <img src="/img/avatar_chat/robot-face.svg" />
                            </div>
                            <div className="col__8">
                                {obj.TextAfterGrowUp || obj.Text}
                            </div>
                        </div>
                        <img src="/img/avatar_chat/admin.svg" />
                    </div>*/}
          </div>

          <div className="col__8">
            <div className="row__grid">
              <div className="left__user__chat--name   col__6">
                <span className={"ml10 build_span"}>{obj.Name}</span>
              </div>

              <div className="left__user__chat--answer  col__4 row__grid justify__content--end">
                {
                  new Date(obj.created_at).toLocaleDateString() + " " +
                  new Date(obj.created_at).toLocaleTimeString().slice(0, -3)
                }

                {obj.ChatWithAdmin && (
                  <div style={{
                    padding: '0 6px',
                    borderRadius: '18px',
                    background: '#688fe0',
                    color: '#688fe0',
                  }}>
                    1
                  </div>
                )}
              </div>
            </div>

            <div className="row__grid mt_5">
              {obj.Owner != "User" ?
                <div className="col__2 row__grid justify-content-center align-content-center">
                  <div className="avatar_add_bot_ad">
                    <img src={obj.Owner == "Bot"
                      ? "/img/avatar_chat/robot-face.svg" : "/img/avatar_chat/admin.svg"}/>
                  </div>
                </div> : null}

              <div className="col__8">
                {text}
              </div>
            </div>
          </div>
        </div>
      </li>);
  }

}

export default User;
