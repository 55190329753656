import {SIGN_IN_FAILURE, SIGN_IN_REQUEST, SIGN_IN_SUCCESS} from "./constains";

export const signInFailure = (errorMessage) => ({
  type: SIGN_IN_FAILURE,
  errorMessage,
});

export const signInSuccess = (ok) => ({
  type: SIGN_IN_SUCCESS,
  ok,
});

export const signInRequest = (email, password) => ({
  type: SIGN_IN_REQUEST,
  email,
  password,
});
