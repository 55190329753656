import React, { Fragment, Component } from "react";
import jQuery from "jquery";
import Cookies from "universal-cookie";
import LeftPanel from "../../LeftPanel";
import TopPanel from "../../TopPanel/TopPanel";
import Switch from "react-toggle-switch";
import Content from "../Build/Component/Content/Content";
import Popup from "../../../components/Popup/Popup";
import DatePicker from "react-date-picker";
import ReactTooltip from "react-tooltip";
import { Scrollbars } from "react-custom-scrollbars";
import TransComponent from "../../../TransComponent.js";
import Select from "react-select";
import "react-select/dist/react-select.css";
import PopupAll from "../../PopupAll";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { successUser } from "../../../modules/User/actions";
import { setGroups } from "../../../modules/Bots/actions";
import { CSVReader } from "react-papaparse";
import { CSVLink } from "react-csv";
import { withAlert } from "react-alert";
import { config } from "../../../config.js";
import { MESSAGE_TAGS } from "../../../types.js";
import { TooltipTitle } from "../../../components/TooltipTitle/TooltipTitle";
import {dict} from "../Build/Description/Description";
import axios from "axios";

const importButtonRef = React.createRef();

const cookies = new Cookies();
var func = require("../../../func.js");

// deliver your message now  center panel
class Deliver extends TransComponent {
  constructor(props) {
    super(props);
    this.state = {
      None: {},
      switched: false,
      Trigger: 0,
      TextContents: [],
      AudioContents: [],
      VideoContents: [],
      ImageContents: [],
      BtnContents: [],
      BotMsg: this.props.BotMsg,
      RepeatDays: [true, true, true, true, true, true, true],
      DefLink: [],
      evalDateResult: '',
      FilterUsersCount: 0,
      AllUsersCount: 0,
      FilterUsers: []
    };

    this.change = config.change_new.bind(this);
  }

  changeAnswerName = (e) => {
    this.state.BotMsg.AnswerName = e.target.value;
    this.setState({
      BotMsg: this.state.BotMsg,
    });
  };

  changeWaitAnswer = (e) => {
    this.state.BotMsg.WaitAnswer = e.target.checked;
    this.setState({
      BotMsg: this.state.BotMsg,
    });
  };

  selectChannel = (e, id) => {
    if (e.target.checked) {
      if (!this.state.BotMsg.ChannelsIds.includes(id)) {
        this.state.BotMsg.ChannelsIds.push(id);
      }
    } else {
      this.state.BotMsg.ChannelsIds = this.state.BotMsg.ChannelsIds
          .filter(channelId => channelId !== id);
    }

    this.setState({
      BotMsg: this.state.BotMsg,
    });
  };

  changeDate = (e) => {
    this.state.BotMsg.Date = new Date(e).toISOString();
    this.setState({
      BotMsg: this.state.BotMsg,
    });
    this.props.ChangeSave(false);
  };

  changeTimezone = (e) => {
    this.state.BotMsg.Timezone = e.target.value;
    this.setState({
      BotMsg: this.state.BotMsg,
    });
    this.props.ChangeSave(false);
  };

  changeRepeatDays = (i) => {
    this.state.RepeatDays[i] = !this.state.RepeatDays[i];
    this.setState({
      RepeatDays: this.state.RepeatDays,
    });
    this.props.ChangeSave(false);

    var str = "";
    for (var i = 0; i < this.state.RepeatDays.length; i++) {
      if (this.state.RepeatDays[i] == true) str += i + ";";
    }
    this.state.BotMsg.Days = str;
    this.setState({
      BotMsg: this.state.BotMsg,
    });
  };

  changeContextParamForEvalPeriod = (e) => {
    var val = e.target.value;
    this.state.BotMsg.ContextParamForEvalPeriod = val;
    this.setState({
      BotMsg: this.state.BotMsg,
    });
    this.props.ChangeSave(false);
  };

  changeContextParamForEvalPeriod = (e) => {
    var val = e.target.value;
    this.state.BotMsg.ContextParamForEvalPeriod = val;
    this.setState({
      BotMsg: this.state.BotMsg,
    });
    this.props.ChangeSave(false);
  };

  changeContextParamForEvalTimezone = (e) => {
    var val = e.target.value;
    this.state.BotMsg.ContextParamForEvalTimezone = val;
    this.setState({
      BotMsg: this.state.BotMsg,
    });
    this.props.ChangeSave(false);
  };

  changeContextParamForEvalTime = (e) => {
    var val = e.target.value;
    this.state.BotMsg.ContextParamForEvalTime = val;
    this.setState({
      BotMsg: this.state.BotMsg,
    });
    this.props.ChangeSave(false);
  };

  changeContextParamForEvalStart = (e) => {
    var val = e.target.value;
    this.state.BotMsg.ContextParamForEvalStart = val;
    this.setState({
      BotMsg: this.state.BotMsg,
    });
    this.props.ChangeSave(false);
  };

  changeRepeat = (e) => {
    var val = e.target.value;
    if (val != "Custom") {
      this.state.BotMsg.Days = val;
    } else {
      var str = "";
      for (var i = 0; i < this.state.RepeatDays.length; i++) {
        if (this.state.RepeatDays[i] == true) {
          str += i + ";";
        }
      }
      this.state.BotMsg.Days = str;
    }
    this.setState({
      BotMsg: this.state.BotMsg,
    });
    this.props.ChangeSave(false);
  };

  getRepeatType = () => {
    var val = this.state.BotMsg.Days;
    if (
      val == "None" ||
      val == "Workdays" ||
      val == "Every Day" ||
      val == "Every Month" ||
      val == "Weekends" ||
      val == "Workdays"
    ) {
      return val;
    } else {
      return "Custom";
    }
  };

  saveFilters = () => {
    const botMsg = this.props.BotMsg;

    var url = config.Backend + "/botMsg/cleanFilters?";
    if (!(cookies.get("Id") === undefined)) {
      url += "&CurrentUserId=" + cookies.get("Id").toString();
    }
    url += "&Id=" + botMsg._id;
    this.props.addWait();
    jQuery.getJSON(url, (res) => {
      this.props.delWait();

      if (!res.Error) {
        var url1 = config.Backend + "/botMsg/edit?";
        if (!(cookies.get("Id") === undefined)) {
          url1 += "&CurrentUserId=" + cookies.get("Id").toString();
        }
        url1 += "&Id=" + botMsg._id;
        url1 += "&Filter=" + botMsg.Filters[0].Attr;
        url1 += "&Edit=true";
        url1 += "&Index=0";
        url1 += "&Val=" + botMsg.Filters[0].Val;
        url1 += "&Cond=" + botMsg.Filters[0].Cond;
        url1 += "&FilterEnable=" + (botMsg.FilterEnable ? "True" : "False");
        this.props.addWait();

        jQuery.getJSON(
          url1,
          function (res) {
            this.props.delWait();

            if (!res.Error) {
              this.props.setupBotMsg(res.BotMsg);
            }
          }.bind(this)
        );

        for (var i = 1; i < botMsg.Filters.length; i++) {
          const filter = botMsg.Filters[i];

          if (filter.Val !== "" && filter.Attr !== "") {
            var url = config.Backend + "/botMsg/edit?";
            if (!(cookies.get("Id") === undefined)) {
              url += "&CurrentUserId=" + cookies.get("Id").toString();
            }
            url += "&Id=" + botMsg._id;
            url += "&Filter=" + filter.Attr;
            url += "&Add=true";
            url += "&Val=" + filter.Val;
            url += "&Cond=" + filter.Cond;
            this.props.addWait();

            jQuery.getJSON(
              url,
              function (res) {
                this.props.delWait();

                if (!res.Error) {
                  this.props.setupBotMsg(res.BotMsg);
                }
              }.bind(this)
            );
          }
        }
      }
    });
  };

  changeTimeType = (e) => {
    this.state.BotMsg.TimeType = e.target.value;
    this.setState({
      BotMsg: this.state.BotMsg,
    });
    this.props.ChangeSave(false);
  };

  changeTime = (e) => {
    this.state.BotMsg.Time = e.target.value;
    this.setState({
      BotMsg: this.state.BotMsg,
    });
    this.props.ChangeSave(false);
  };

  changeTrigger = (e) => {
    this.state.BotMsg.Trigger = e.target.value;
    this.setState({
      BotMsg: this.state.BotMsg,
    });
    this.props.ChangeSave(false);
  };

  changeTag = (e) => {
    this.state.BotMsg.Tag = e.target.value;
    this.setState({
      BotMsg: this.state.BotMsg,
    });
    this.props.ChangeSave(false);
  };

  changeTriggerCond = (e) => {
    this.state.BotMsg.TriggerCond = e.target.value;
    this.setState({
      BotMsg: this.state.BotMsg,
    });
    this.props.ChangeSave(false);
  };

  changeTriggerAttr = (e) => {
    if (e) {
      this.state.BotMsg.TriggerAttr = e.value;
    } else {
      this.state.BotMsg.TriggerAttr = "";
    }

    this.setState({
      BotMsg: this.state.BotMsg,
    });
    this.props.ChangeSave(false);
  };

  send = () => {
    this.props.setTutorialStep(3);
    var x = config.Backend;
    if (this.props.bot.BindTo == "FB") {
      x += "/sendMsg?";
      x +=
        "PageId=" +
        this.props.bot.Page +
        "&Id=" +
        this.state.BotMsg._id +
        "&PageToken=" +
        this.props.bot.PageToken;
      if (!(cookies.get("Id") === undefined)) {
        x += "&CurrentUserId=" + cookies.get("Id").toString();
      }
    } else if (this.props.bot.BindTo == "Vk") {
      x += "/vk/sendMsg?";
      x +=
        "VkPage=" +
        this.props.bot.VkPage +
        "&Id=" +
        this.state.BotMsg._id +
        "&VkPageToken=" +
        this.props.bot.VkPageToken;
      if (!(cookies.get("Id") === undefined)) {
        x += "&CurrentUserId=" + cookies.get("Id").toString();
      }
    } else if (this.props.bot.BindTo == "Viber") {
      x += "/viber/sendMsg?";
      x += "BotId=" + this.props.bot._id + "&Id=" + this.state.BotMsg._id;
      if (!(cookies.get("Id") === undefined)) {
        x += "&CurrentUserId=" + cookies.get("Id").toString();
      }
    } else if (this.props.bot.BindTo == "Telegram") {
      x += "/telegram/sendMsg?";
      x += "&Id=" + this.state.BotMsg._id + "&BotId=" + this.props.bot._id;
      if (!(cookies.get("Id") === undefined)) {
        x += "&CurrentUserId=" + cookies.get("Id").toString();
      }
    } else if (this.props.bot.BindTo == "Ok") {
      x += "/ok/sendMsg?";
      x += "&Id=" + this.state.BotMsg._id + "&BotId=" + this.props.bot._id;
      if (!(cookies.get("Id") === undefined)) {
        x += "&CurrentUserId=" + cookies.get("Id").toString();
      }
    } else if (this.props.bot.BindTo == "WhatsApp") {
      x += "/whatsapp/sendMsg?";
      x += "&Id=" + this.state.BotMsg._id + "&BotId=" + this.props.bot._id;
      if (!(cookies.get("Id") === undefined)) {
        x += "&CurrentUserId=" + cookies.get("Id").toString();
      }
    }
    this.props.addWait();

    jQuery.getJSON(
      x,
      function (res) {
        this.props.delWait();
        this.props.changeMain("Sent");
      }.bind(this)
    );
  };

  deleteMsg = (msg) => {
    this.setState({
      ShowDeletebotMsgPopup: false,
    });
    this.props.DeleteMsg(msg);
  };

  saveContents = () => {
    this.props.setTutorialStep(2);
    this.setState({
      disable_save: true,
    });
    setTimeout(
      () =>
        this.setState({
          disable_save: false,
        }),
      1000
    );

    var fd = {};
    fd.Id = this.state.TextContents[0]._id;
    fd.Text = this.state.TextContents[0].Text;
    fd.Type = "TextContent";
    fd.Time = this.state.TextContents[0].Time;
    fd.Link = this.state.TextContents[0].Link;
    this.props.addWait();

    jQuery.ajax({
      method: "POST",
      contentType: "application/json",
      url: config.Backend + "/content/edit",
      success: function (res) {
        this.props.delWait();
        this.props.addContentToAllContent(res.Content);
      }.bind(this),
      data: JSON.stringify(fd),
    });

    for (var ind = 0; ind < this.state.ImageContents.length; ind++) {
      const i = ind;
      if (this.state.ImageContents[i]._id == "-1") {
        this.state.ImageContents[i]._id = "1";
        var fd = new FormData();
        fd.append("File", this.state.ImageContents[i].File);
        this.props.addWait();

        jQuery.ajax({
          method: "POST",
          async: false,
          processData: false,
          contentType: false,
          url: config.Backend + "/content/file",
          success: function (msg) {
            this.props.delWait();

            this.state.ImageContents[i].Link = msg;
          }.bind(this),
          data: fd,
        });
        var url = config.Backend + "/content/add?";
        if (!(cookies.get("Id") === undefined)) {
          url += "&CurrentUserId=" + cookies.get("Id").toString();
        }
        url += "&BotMsg=" + this.state.BotMsg._id;
        url += "&Type=" + this.state.ImageContents[i].Type;
        url += "&Text=" + this.state.ImageContents[i].Text;
        url += "&Time=" + this.state.ImageContents[i].Time;
        url += "&Link=" + this.state.ImageContents[i].Link;
        this.props.addWait();

        jQuery.getJSON(
          url,
          function (res) {
            this.props.delWait();

            if (!res.Error) {
              this.props.addContentToAllContent(res.Content);
            }
          }.bind(this)
        );
      }
    }

    for (var ind = 0; ind < this.state.AudioContents.length; ind++) {
      const i = ind;
      if (this.state.AudioContents[i]._id == "-1") {
        this.state.AudioContents[i]._id = "1";
        var fd = new FormData();
        fd.append("File", this.state.AudioContents[i].File);
        this.props.addWait();

        jQuery.ajax({
          method: "POST",
          async: false,
          processData: false,
          contentType: false,
          url: config.Backend + "/content/file",
          success: function (msg) {
            this.props.delWait();

            this.state.AudioContents[i].Link = msg;
          }.bind(this),
          data: fd,
        });

        var url = config.Backend + "/content/add?";
        if (!(cookies.get("Id") === undefined)) {
          url += "&CurrentUserId=" + cookies.get("Id").toString();
        }
        url += "&BotMsg=" + this.state.BotMsg._id;
        url += "&Type=" + this.state.AudioContents[i].Type;
        url += "&Text=" + this.state.AudioContents[i].Text;
        url += "&Time=" + this.state.AudioContents[i].Time;
        url += "&Link=" + this.state.AudioContents[i].Link;
        this.props.addWait();

        jQuery.getJSON(
          url,
          function (res) {
            this.props.delWait();

            if (!res.Error) {
              this.props.addContentToAllContent(res.Content);
            }
          }.bind(this)
        );
      }
    }

    for (var ind = 0; ind < this.state.VideoContents.length; ind++) {
      const i = ind;
      if (this.state.VideoContents[i]._id == "-1") {
        this.state.VideoContents[i]._id = "1";
        var fd = new FormData();
        fd.append("File", this.state.VideoContents[i].File);
        this.props.addWait();

        jQuery.ajax({
          method: "POST",
          async: false,
          processData: false,
          contentType: false,
          url: config.Backend + "/content/file",
          success: function (msg) {
            this.props.delWait();

            this.state.VideoContents[i].Link = msg;
          }.bind(this),
          data: fd,
        });
        var url = config.Backend + "/content/add?";
        if (!(cookies.get("Id") === undefined)) {
          url += "&CurrentUserId=" + cookies.get("Id").toString();
        }
        url += "&BotMsg=" + this.state.BotMsg._id;
        url += "&Type=" + this.state.VideoContents[i].Type;
        url += "&Text=" + this.state.VideoContents[i].Text;
        url += "&Time=" + this.state.VideoContents[i].Time;
        url += "&Link=" + this.state.VideoContents[i].Link;
        this.props.addWait();

        jQuery.getJSON(
          url,
          function (res) {
            this.props.delWait();

            if (!res.Error) {
              this.props.addContentToAllContent(res.Content);
            }
          }.bind(this)
        );
      }
    }

    var url = config.Backend + "/botMsg/edit?";
    if (!(cookies.get("Id") === undefined)) {
      url += "&CurrentUserId=" + cookies.get("Id").toString();
    }
    url += "&Id=" + this.state.BotMsg._id;
    url += "&Name=" + this.state.BotMsg.Name;
    url += "&DefaultLink=" + this.state.BotMsg.DefaultLink;
    url += "&Status=" + this.state.BotMsg.Status;
    url += "&Time=" + this.state.BotMsg.Time;
    url += "&TimeType=" + this.state.BotMsg.TimeType;
    url += "&Trigger=" + this.state.BotMsg.Trigger;
    url += "&TriggerCond=" + this.state.BotMsg.TriggerCond;
    url += "&TriggerVal=" + this.state.BotMsg.TriggerVal;
    url += "&TriggerAttr=" + this.state.BotMsg.TriggerAttr;
    url += "&FilterType=" + this.state.BotMsg.FilterType;
    url += "&Date=" + this.state.BotMsg.Date;
    url += "&Days=" + this.state.BotMsg.Days;
    url += "&Timezone=" + this.state.BotMsg.Timezone;
    url += "&Tag=" + this.state.BotMsg.Tag;
    url += "&TimezoneOffset=" + new Date().getTimezoneOffset();

    if (this.state.BotMsg.WaitAnswer) {
      url += "&WaitAnswer=true";
    } else {
      url += "&WaitAnswer=false";
    }
    if (this.state.BotMsg.Trim) {
      url += "&Trim=true";
    } else {
      url += "&Trim=false";
    }
    if (this.state.BotMsg.TelegramMarkdown) {
      url += "&TelegramMarkdown=true";
    } else {
      url += "&TelegramMarkdown=false";
    }
    if (this.state.BotMsg.ImageAndTextInOneMessage) {
      url += "&ImageAndTextInOneMessage=true";
    } else {
      url += "&ImageAndTextInOneMessage=false";
    }
    if (this.state.BotMsg.SendToChannels) {
      url += "&SendToChannels=true";
    } else {
      url += "&SendToChannels=false";
    }
    url += "&AnswerName=" + this.state.BotMsg.AnswerName;
    this.props.addWait();

    jQuery.getJSON(
      url,
      function (res) {
        this.props.delWait();

        if (!res.Error) {
          this.props.setupBotMsg(res.BotMsg);

          fd = {};
          if (!(cookies.get("Id") === undefined)) {
            fd.CurrentUserId = cookies.get("Id").toString();
          }
          fd.Id = this.state.BotMsg._id;
          fd.Btns = this.state.BtnContents.map((content) => {
            const result = { ...content };

            if (result._id === "-1") {
              delete result._id;
            }

            return result;
          });
          fd.ChannelsIds = this.state.BotMsg.ChannelsIds;
          fd.EvalDate = this.state.BotMsg.EvalDate;
          fd.EvalDateValue = this.state.BotMsg.EvalDateValue;

          fd.EvalTimeFromContext = this.state.BotMsg.EvalTimeFromContext;
          if (fd.EvalTimeFromContext) {
            fd.ContextParamForEvalTime = this.state.BotMsg.ContextParamForEvalTime;
            fd.ContextParamForEvalTimezone = this.state.BotMsg.ContextParamForEvalTimezone;
            fd.ContextParamForEvalStart = this.state.BotMsg.ContextParamForEvalStart;
            fd.ContextParamForEvalPeriod = this.state.BotMsg.ContextParamForEvalPeriod;
          }

          jQuery.ajax({
            method: "POST",
            contentType: "application/json",
            url: config.Backend + "/botMsg/edit",
            success: function (res) {
              if (!res.Error) {
                this.props.setupBotMsg(res.BotMsg);
                this.setState({
                  BtnContents: res.BotMsg.Btns,
                });
              }
            }.bind(this),
            data: JSON.stringify(fd),
          });
        }
      }.bind(this)
    );

    this.props.ChangeSave(true);
    this.saveFilters();
  };

  clearInput = (e) => {
    e.target.value = null;
  };

  deleteContent = (i, type) => {
    this.setState({
      Saved: false,
    });
    if (type == "Image") {
      if (this.state.ImageContents[i]._id != "-1") {
        var url = config.Backend + "/content/delete?";
        if (!(cookies.get("Id") === undefined)) {
          url += "&CurrentUserId=" + cookies.get("Id").toString();
        }
        url += "&Id=" + this.state.ImageContents[i]._id;

        jQuery.getJSON(url);
      }
      this.props.deleteContentFromAllContent(this.state.ImageContents[i]._id);
      this.state.ImageContents.splice(i, 1);
      var old = this.state.ImageContents;
      this.setState(
        {
          ImageContents: [],
        },
        () =>
          this.setState({
            ImageContents: old,
          })
      );
    } else if (type == "Video") {
      if (this.state.VideoContents[i]._id != "-1") {
        var url = config.Backend + "/content/delete?";
        if (!(cookies.get("Id") === undefined)) {
          url += "&CurrentUserId=" + cookies.get("Id").toString();
        }

        url += "&Id=" + this.state.VideoContents[i]._id;

        jQuery.getJSON(url);
      }
      this.props.deleteContentFromAllContent(this.state.VideoContents[i]._id);
      this.state.VideoContents.splice(i, 1);
      var old = this.state.VideoContents;
      this.setState(
        {
          VideoContents: [],
        },
        () =>
          this.setState({
            VideoContents: old,
          })
      );
    } else if (type == "Audio") {
      if (this.state.AudioContents[i]._id != "-1") {
        var url = config.Backend + "/content/delete?";
        if (!(cookies.get("Id") === undefined)) {
          url += "&CurrentUserId=" + cookies.get("Id").toString();
        }
        url += "&Id=" + this.state.AudioContents[i]._id;

        jQuery.getJSON(url);
      }
      this.props.deleteContentFromAllContent(this.state.AudioContents[i]._id);
      this.state.AudioContents.splice(i, 1);
      var old = this.state.AudioContents;
      this.setState(
        {
          AudioContents: [],
        },
        () =>
          this.setState({
            AudioContents: old,
          })
      );
    } else if (type == "BtnToGroup") {
      if (this.state.BtnContents[i]._id != "-1") {
        var url = config.Backend + "/botMsg/edit?";
        if (!(cookies.get("Id") === undefined)) {
          url += "&CurrentUserId=" + cookies.get("Id").toString();
        }
        url += "&Id=" + this.state.BotMsg._id;
        url += "&Btn=" + this.state.BtnContents[i].Name;
        url += "&Redirect=" + this.state.BtnContents[i].Redirect;
        url += "&Position=" + this.state.BtnContents[i].Position;
        url += "&Index=" + i;
        url += "&Delete=true";
        this.props.addWait();

        jQuery.getJSON(
          url,
          function (res) {
            this.props.delWait();

            if (!res.Error) {
              this.loadContent(res.BotMsg);
              this.props.setupBotMsg(res.BotMsg);
            } else {
              alert(JSON.stringify(res));
            }
          }.bind(this)
        );
      } else {
        this.state.BtnContents.splice(i, 1);
        var old = this.state.BtnContents;
        this.setState(
          {
            BtnContents: [],
          },
          () =>
            this.setState({
              BtnContents: old,
            })
        );
      }
    }
  };

  addBtnContent = () => {
    this.props.ChangeSave(false);
    var btn = {
      Name: "Button",
      Redirect: "-",
      _id: "-1",
      key: this.state.BotMsg._id + this.state.BtnContents.length.toString(),
      Type: this.state.BotMsg.SendToChannels ? 'openUrl' : 'postBack',
      Url: "http://",
      Position: "Vertical",
    };

    this.state.BtnContents.push(btn);
    this.setState({
      BtnContents: this.state.BtnContents,
    });
  };

  addVideoContent = (e) => {
    this.props.ChangeSave(false);
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      var Content = {
        _id: "-1",
        Type: "Video",
        Link: "-1",
        File: file,
        Url: reader.result,
      };
      this.state.VideoContents.push(Content);
      this.setState({
        VideoContents: this.state.VideoContents,
      });
    };
    reader.readAsDataURL(file);
  };

  addImageContent = (e) => {
    this.props.ChangeSave(false);
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      var Content = {
        _id: "-1",
        Type: "Image",
        Link: "-1",
        File: file,
        Url: reader.result,
      };
      this.state.ImageContents.push(Content);
      this.setState({
        ImageContents: this.state.ImageContents,
      });
    };
    reader.readAsDataURL(file);
  };

  addAudioContent = (e) => {
    this.props.ChangeSave(false);
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      var Content = {
        _id: "-1",
        Type: "Audio",
        Link: "-1",
        File: file,
        Url: reader.result,
      };
      this.state.AudioContents.push(Content);
      this.setState({
        AudioContents: this.state.AudioContents,
      });
    };
    reader.readAsDataURL(file);
  };

  handleForContent = (
    index,
    Text,
    Time,
    Link,
    File,
    Type,
    Url,
    ReqType,
    Field,
    Refresh,
    Macros,
    BtnType,
    BtnUrl,
    Headers,
    Save,
    Cond,
    CondRedirect,
    SimpleMode,
    SimpleAttr,
    SimpleCond,
    SimpleValue,
    BtnPosition
  ) => {
    this.props.ChangeSave(false);
    if (Type == "Text") {
      this.state.TextContents[index].Text = Text;
      this.state.TextContents[index].Time = Time;
    } else if (Type == "Image") {
      this.state.ImageContents[index].Link = Link;
      this.state.ImageContents[index].File = File;
    } else if (Type == "Audio") {
      this.state.AudioContents[index].Link = Link;
      this.state.AudioContents[index].File = File;
    } else if (Type == "Video") {
      this.state.VideoContents[index].Link = Link;
      this.state.VideoContents[index].File = File;
    } else if (Type == "BtnToGroup") {
      this.state.BtnContents[index].Name = Text;
      this.state.BtnContents[index].Redirect = Url;
      this.state.BtnContents[index].Type = BtnType;
      this.state.BtnContents[index].Url = BtnUrl;
      this.state.BtnContents[index].Position = BtnPosition;
      this.state.BotMsg.Btns = this.state.BtnContents;
    } else if (Type == "DefaultLinkToGroup") {
      this.state.DefLink[0] = Url;
      this.state.BotMsg.DefaultLink = Url;
    }
  };

  loadContent = async (msg, filters = null) => {
    this.state.DefLink = [];
    this.state.BtnContents = [];
    this.state.TextContents = [];
    this.state.ImageContents = [];
    this.state.FileContents = [];
    this.state.VideoContents = [];
    this.state.AudioContents = [];

    (this.props.AllContent || [])
      .filter((content) => content.BotMsg === msg._id)
      .forEach((content) => {
        if (content.Type == "Text")
          this.state.TextContents.push({ ...content });
        if (content.Type == "Image")
          this.state.ImageContents.push({ ...content });
        if (content.Type == "File")
          this.state.FileContents.push({ ...content });
        if (content.Type == "Video")
          this.state.VideoContents.push({ ...content });
        if (content.Type == "Audio")
          this.state.AudioContents.push({ ...content });
      });

    this.state.DefLink.push(msg.DefaultLink);

    (msg.Btns || []).forEach(
      function (btn, i) {
        this.state.BtnContents.push(btn);
      }.bind(this)
    );

    const params = {
      params: {
        bot: this.props.BotMsg.Bot,
        msg: this.props.BotMsg._id,
      }
    };

    const { data } = await axios.get(config.Backend + '/bot/msg/usersCountByFilter', params);

    this.state.FilterUsersCount = data.count;
    this.state.AllUsersCount = data.all;
    this.state.FilterUsers = data.users;

    this.setState({
      TextContents: this.state.TextContents,
      ImageContents: this.state.ImageContents,
      FileContents: this.state.FileContents,
      VideoContents: this.state.VideoContents,
      AudioContents: this.state.AudioContents,
      DefLink: this.state.DefLink,
      BtnContents: this.state.BtnContents,
      FilterUsersCount: data.count,
      AllUsersCount: data.all
    });
  };

  async componentWillReceiveProps(nextProps) {
    if (nextProps.BotMsg._id !== this.props.BotMsg._id) {
      await this.setState(
        {
          BotMsg: nextProps.BotMsg,
        },
        () => {
          if (this.state.BotMsg.Days !== undefined) {
            var arr = this.state.BotMsg.Days.split(";");
            for (var i = 0; i < this.state.RepeatDays.length; i++) {
              this.state.RepeatDays[i] = false;
            }
            for (var i = 0; i < arr.length; i++) {
              if (arr[i] != "") {
                this.state.RepeatDays[+arr[i].replace(";", "")] = true;
              }
            }
            this.setEvalDateResult(this.state.BotMsg.EvalDateValue);
          }
        }
      );

      await this.loadContent(nextProps.BotMsg);
    }
  }

  async componentDidMount() {
    await this.setState(
      {
        BotMsg: this.props.BotMsg,
      },
      () => {
        var arr = (this.state.BotMsg.Days || "").split(";");
        for (var i = 0; i < this.state.RepeatDays.length; i++) {
          this.state.RepeatDays[i] = false;
        }
        for (var k = 0; k < arr.length; k++) {
          if (arr[k] != "") {
            this.state.RepeatDays[+arr[k].replace(";", "")] = true;
          }
        }
        this.setEvalDateResult(this.state.BotMsg.EvalDateValue);
      }
    );

    await this.loadContent(this.props.BotMsg);
  }

  toggleSwitch = () => {
    this.props.ChangeSave(false);
    this.state.BotMsg.Status = this.state.BotMsg.Status == "0" ? "1" : "0";
    this.setState({
      BotMsg: this.state.BotMsg,
    });
  };

  AddItems() {
    var b = this.props.bot ? this.props.bot.BindTo : "-";
    var showBtn = true;
    var showVideo = !(b == "Ok" || b == "WhatsApp" || b == "-");
    var showAudio = !(b == "Ok" || b == "WhatsApp" || b == "-");
    const showImage = b !== 'WhatsApp';

    return (
      <div>
        <TooltipTitle
          idTooltip="i_addItems"
          name="Добавить элементы"
          tooltipDescription="Дополнительные элементы которые
          будут отправлены вместе с сообщением."
        />

        <div className="list_button mt_5">
          {showVideo ? (
            <div>
              <label
                htmlFor={"video"}
                className="ant-upload ant-upload-select ant-upload-select-text mr2"
              >
                <span className="ant-upload">
                  <input
                    id={"video"}
                    style={{
                      display: "none",
                    }}
                    type="file"
                    accept="video/*"
                    onChange={this.addVideoContent}
                    onClick={this.clearInput}
                  />

                  <span className="ant-btn">
                    <i className="fas fa-video" />

                    <span>
                      <font style={{ verticalAlignment: "inherit" }}>
                        <font style={{ verticalAlignment: "inherit" }}>
                          Видео
                        </font>
                      </font>
                    </span>
                  </span>
                </span>
              </label>
            </div>
          ) : null}

          {showAudio ? (
            <div>
              <label
                htmlFor={"audio"}
                className="ant-upload ant-upload-select ant-upload-select-text mr2"
              >
                <span className="ant-upload">
                  <input
                    style={{ display: "none" }}
                    id={"audio"}
                    type="file"
                    accept="audio/*"
                    onChange={this.addAudioContent}
                    onClick={this.clearInput}
                  />

                  <span className="ant-btn">
                    <i className="fas fa-file-audio"></i>

                    <span>
                      <font style={{ verticalAlignment: "inherit" }}>
                        <font style={{ verticalAlignment: "inherit" }}>
                          Аудио
                        </font>
                      </font>
                    </span>
                  </span>
                </span>
              </label>
            </div>
          ) : null}

          {showImage && (
              <div>
                <label
                    htmlFor={"image"}
                    className="ant-upload ant-upload-select ant-upload-select-text mr2"
                >
              <span className="ant-upload">
                <input
                    style={{
                      display: "none",
                    }}
                    id={"image"}
                    type="file"
                    accept="image/*"
                    onChange={this.addImageContent}
                    onClick={this.clearInput}
                />

                <span className="ant-btn">
                  <i className="fas fa-image"></i>

                  <span>
                    <font style={{ verticalAlignment: "inherit" }}>
                      <font style={{ verticalAlignment: "inherit" }}>
                        Изображение
                      </font>
                    </font>
                  </span>
                </span>
              </span>
                </label>
              </div>
          )}

          {showBtn ? (
            <div>
              <label
                className="ant-upload ant-upload-select ant-upload-select-text mr2"
                onClick={this.addBtnContent}
              >
                <span className="ant-upload">
                  <span className="ant-btn">
                    <span>
                      <font style={{ verticalAlignment: "inherit" }}>
                        <font style={{ verticalAlignment: "inherit" }}>
                          Кнопка
                        </font>
                      </font>
                    </span>
                  </span>
                </span>
              </label>
            </div>
          ) : null}
        </div>

        <button
          onClick={this.saveContents}
          disabled={this.state.disable_save}
          className="btn__all--small bg_shadow mt_15 mr5"
        >
          Сохранить
        </button>

        <button
          data-tip
          data-for="btn_d"
          onClick={this.props.ShowDelPopup}
          className="btn__all--small mt_15"
        >
          <i className="fas fa-trash-alt" />
        </button>

        <ReactTooltip id="btn_d" type="light">
          <span>Удалить этот блок</span>
        </ReactTooltip>
      </div>
    );
  }

  EditPanel() {
    const txtContents = this.state.TextContents.map((content, i) => (
      <Content
        key={content._id}
        Index={i}
        handleForContent={this.handleForContent}
        Bot={this.props.bot}
        BotMsg={this.state.BotMsg}
        Broadcast={true}
        Text={content.Text}
        Type={content.Type}
        File={content.File}
        Url={content.Url}
        onDelete={this.deleteContent}
        Link={content.Link}
        typingTime={content.Time}
      />
    ));

    const imgContents = this.state.ImageContents.map((content, i) => (
      <Content
        key={content._id}
        Broadcast={true}
        Index={i}
        handleForContent={this.handleForContent}
        onDelete={this.deleteContent}
        File={content.File}
        Url={content.Url}
        Text={content.Text}
        Type={content.Type}
        Link={content.Link}
        typingTime={content.Time}
      />
    ));

    const videoContents = this.state.VideoContents.map((content, i) => (
      <Content
        key={content._id}
        Broadcast={true}
        Index={i}
        handleForContent={this.handleForContent}
        onDelete={this.deleteContent}
        Text={content.Text}
        Type={content.Type}
        File={content.File}
        Url={content.Url}
        Link={content.Link}
        typingTime={content.Time}
      />
    ));

    const audioContents = this.state.AudioContents.map((content, i) => (
      <Content
        key={content._id}
        Broadcast={true}
        Index={i}
        handleForContent={this.handleForContent}
        onDelete={this.deleteContent}
        Text={content.Text}
        Type={content.Type}
        File={content.File}
        Url={content.Url}
        Link={content.Link}
        typingTime={content.Time}
      />
    ));

    const btnContents = [];

    this.state.BtnContents.forEach((content, i) => {
      if (content.Type === 'openUrl' || (this.state.BotMsg && !this.state.BotMsg.SendToChannels)) {
        btnContents.push(
            <Content
                key={content._id + i}
                Broadcast={true}
                Index={i}
                handleForContent={this.handleForContent}
                BtnUrl={content.Url}
                BtnType={content.Type}
                BtnPosition={content.Position}
                Bot={this.props.bot}
                onDelete={this.deleteContent}
                Text={content.Name}
                Type={"BtnToGroup"}
                ReqType={content.ReqType}
                Url={content.Redirect}
                BotMsg={this.state.BotMsg}
                Groups={this.props.groups}
                typingTime={content.Time}
            />
        );
      }
    });

    var link = "";
    for (var i = 0; i < this.props.groups.length; i++) {
      if (this.props.groups[i]._id == this.state.BotMsg)
        link = this.state.BotMsgs[i].DefaultLink;
    }
    var defaultLink = this.state.DefLink.map((d) => (
      <Content
        key={"DefaultLink"}
        Broadcast={true}
        handleForContent={this.handleForContent}
        Type={"DefaultLinkToGroup"}
        Url={d}
        Groups={this.props.groups}
      />
    ));

    const showTelegramMarkdown =
        this.state.BotMsg && this.props.bot && this.props.bot.BindTo === 'Telegram';
    const showImageAndTextInOneMessage = this.state.BotMsg && this.props.bot
        && this.props.bot.BindTo === 'Telegram' && !!imgContents.length;
    const showSendToChannelsOption =
        this.state.BotMsg && ['Now', 'Sch'].includes(this.state.BotMsg.Type)
        && this.props.bot && this.props.bot.BindTo === 'Telegram';
    const showWaitAnswerOption = this.state.BotMsg && !this.state.BotMsg.SendToChannels;

    return (
      <div>
        {this.state.BotMsg ? (
          <TooltipTitle
            idTooltip="i_Name"
            name="Имя"
            tooltipDescription="Произвольное название блока на ваш выбор."
          />
        ) : null}

        {this.state.BotMsg ? (
          <input
            className={"chat_input"}
            id="enable_filter_2"
            type={"text"}
            value={this.state.BotMsg.Name}
            name={"BotMsg.Name"}
            onChange={this.change}
          />
        ) : null}

        {showSendToChannelsOption && (
            <div className="wrap_filter_laber">
              <TooltipTitle
                  name="Отправить в группы/каналы"
                  idTooltip="i_SendToChannels"
                  tooltipDescription={dict.send_to_groups_info}
              />

              <input
                  id="i_SendToChannels"
                  type={"checkbox"}
                  name={"BotMsg.SendToChannels"}
                  checked={this.state.BotMsg.SendToChannels}
                  onChange={this.change}
              />
            </div>
        )}

        {showSendToChannelsOption && this.state.BotMsg.SendToChannels && (
            <div style={{
              'border': '2px solid',
              'padding': '5px'
            }}>
              {!this.props.bot.Channels.length && (
                  <div>
                    Ни одной доступной группы/канала не было найдено
                  </div>
              )}
              {this.props.bot.Channels
                .map(({ id, title, type }) => (
                  <div key={id} className="wrap_filter_laber">
                    {title + ` (${type})`}&nbsp;

                    <input
                        id="enable_filter"
                        type="checkbox"
                        checked={
                          this.state.BotMsg.ChannelsIds
                              .some(channelId => channelId === id)
                        }
                        onChange={
                          e => this.selectChannel(e, id)
                        }
                    />
                  </div>
                ))}

                <br/>
                <p style={{ color: 'grey '}}>
                  {'Если вы не нашли нужной вам группы/канала в этом списке, ' +
                  'пожалуйста дайте '}<b>права администратора</b>{
                  ' (если права уже есть то отнимите их и дайте заного) ' +
                  'группы/канала боту и '}<b>перезагрузите</b>{' страницу.'}
                </p>
            </div>
        )}

        {showWaitAnswerOption && (
            <div className="wrap_filter_laber">
              <TooltipTitle
                  idTooltip="i_WaitAnswer"
                  name="Подождать ответа"
                  tooltipDescription={
                    "После того как бот напечатает это сообщение он будет ожидать ответа пользователя. " +
                    "После того как он получит ответ, " +
                    "он сохранит его в указанную " +
                    "(Context." +
                    this.state.BotMsg.AnswerName +
                    ")" +
                    "переменную " +
                    "и вызовет следующее сообщение."
                  }
              />

              <input
                  id={"WaintAnswer"}
                  type={"checkbox"}
                  checked={this.state.BotMsg.WaitAnswer}
                  onChange={this.changeWaitAnswer}
              />
            </div>
        )}

        {showTelegramMarkdown && (
            <div className="wrap_filter_laber">

              <TooltipTitle
                  name="Включить"
                  idTooltip="i_TelegramMarkdown"
                  isLink={
                    <a
                        href={"https://core.telegram.org/bots/api#markdown-style"}
                        target={"_blank"}>
                      markdown
                    </a>}

                  tooltipDescription={dict.telegram_markdown_info}

              />

              <input
                  id="i_TelegramMarkdown"
                  type={"checkbox"}
                  name={"BotMsg.TelegramMarkdown"}
                  checked={this.state.BotMsg.TelegramMarkdown}
                  onChange={this.change}
              />
            </div>
        )}

        {showImageAndTextInOneMessage && (
            <div className="wrap_filter_laber">
              <label className="styles_tooltip_name__2Cl">
                Картинка и текст в одном сообщении
              </label>

              <input
                  id="i_ImageAndTextInOneMessage"
                  type={"checkbox"}
                  name={"BotMsg.ImageAndTextInOneMessage"}
                  checked={!!this.state.BotMsg.ImageAndTextInOneMessage}
                  onChange={this.change}
              />
            </div>
        )}

        {showWaitAnswerOption && this.state.BotMsg.WaitAnswer ? (
          <div className={"broadcast_contest"}>
            <div className="form-group">
              <div className={"wrap_filter_laber"}>

                <TooltipTitle
                  idTooltip="i_WaitAnswer"
                  name="Обрезать пробелы по краям"
                  tooltipDescription="Когда бот получит ответ пользователя,перед сохранением он
                    обрежет пробелы по краям."
                />

                <input
                  id="wait_answer_build"
                  type={"checkbox"}
                  name={"BotMsg.Trim"}
                  checked={this.state.BotMsg.Trim}
                  onChange={this.change}
                />
              </div>
            </div>

            <span>Context</span>

            <input
              className="borderR chat_input mb6"
              id="param"
              type="text"
              onChange={this.changeAnswerName}
              value={this.state.BotMsg.AnswerName}
            />
          </div>
        ) : null}

        {txtContents}

        {this.state.BotMsg && !this.state.BotMsg.SendToChannels && (
            <div className="test">{defaultLink}</div>
        )}

        <div className="testAudio">{imgContents}</div>

        <div className="right_video mt_c15">{videoContents}</div>

        <div className="audio">{audioContents}</div>

        <div className="test">{btnContents}</div>

        {this.AddItems()}
      </div>
    );
  }

  changeEvalDate = () => {
    this.state.BotMsg.EvalDate = !this.state.BotMsg.EvalDate;
    this.state.BotMsg.EvalTimeFromContext = false;
    this.setState({
      BotMsg: this.state.BotMsg,
    });
    this.props.ChangeSave(false);
  }

  changeEvalTimeFromContext = () => {
    this.state.BotMsg.EvalTimeFromContext = !this.state.BotMsg.EvalTimeFromContext;
    this.state.BotMsg.EvalDate = false;
    this.setState({
      BotMsg: this.state.BotMsg,
    });
    this.props.ChangeSave(false);
  }

  setEvalDateResult = value => {
    let evalDateResult = '';

    if (value) {
      const Global = this.props.bot.Global; // used for eval

      try {
        evalDateResult = eval(value);
        if (evalDateResult instanceof Date) {
          evalDateResult = evalDateResult.toISOString().split('T')[0];
        } else {
          evalDateResult = 'Расчет должен возвращать Date объект';
        }
      } catch (err) {
        evalDateResult = err;
      }
    }

    this.setState({
      evalDateResult,
    });
  }

  changeEvalDateValue = ({ target: { value } }) => {
    this.state.BotMsg.EvalDateValue = value;
    this.setState({
      BotMsg: this.state.BotMsg,
    });
    this.setEvalDateResult(value);
    this.props.ChangeSave(false);
  }

  ScheduleForLater() {
    let attrs = [];

    if (this.props.BotUsers) {
      this.props.BotUsers.Users.forEach((u, i) => {
        if (u.Context) {
          for (var key in u.Context) {
            var option = "Context." + key;
            if (attrs.indexOf(option) === -1) {
              attrs.push(option);
            }
          }
        }
      });
    }

    if (this.state.BotMsg.ContextParamForEvalPeriod &&
        !attrs.includes(this.state.BotMsg.ContextParamForEvalPeriod)) {
      attrs.push(this.state.BotMsg.ContextParamForEvalPeriod);
    }

    attrs = attrs.map(function (attr) {
        return (
            <option key={attr} value={attr}>
              {attr}
            </option>
        );
    });

    const evalFromContextAvailable = this.props.bot && this.props.bot.BindTo === 'Telegram';

    return (
      <div className="list_schedule_later">
        <div className="shedule_overlay_settings">
          <div className="schedule_input_1">
            {evalFromContextAvailable && this.state.BotMsg.EvalTimeFromContext ?
                <select
                    className="borderR"
                    onChange={this.changeContextParamForEvalStart}
                    value={this.state.BotMsg.ContextParamForEvalStart}
                >
                  <option value="">Выберите Context переменную содержащую дату начала отсчета</option>
                  {attrs}
                </select>
                :
                (this.state.BotMsg.EvalDate ?
                <input
                    className="borderR"
                    disabled
                    value={this.state.evalDateResult}
                />
                :
                <DatePicker
                    className="bred"
                    onChange={this.changeDate}
                    value={new Date(this.state.BotMsg.Date)}
                />)
            }
          </div>

          <div className="schedule_input_1">
            {evalFromContextAvailable && this.state.BotMsg.EvalTimeFromContext ?
                <select
                    className="borderR"
                    onChange={this.changeContextParamForEvalTime}
                    value={this.state.BotMsg.ContextParamForEvalTime}
                >
                  <option value="">Выберите Context переменную содержащую время</option>
                  {attrs}
                </select>
                :
            <input
                className="borderR"
                type="text"
                placeholder="HH:mm"
                value={this.state.BotMsg.Time}
                onChange={this.changeTime}
            />
            }
          </div>

          <div className="schedule_input_1">
            {evalFromContextAvailable && this.state.BotMsg.EvalTimeFromContext ?
                <select
                    className="borderR"
                    onChange={this.changeContextParamForEvalTimezone}
                    value={this.state.BotMsg.ContextParamForEvalTimezone}
                >
                  <option value="">Выберите Context переменную содержащую часовой пояс</option>
                  {attrs}
                </select>
                :
            <select
                className="borderR"
                value={this.state.BotMsg.Timezone}
                onChange={this.changeTimezone}
            >
              <option value="user">Часовой пояс пользователя</option>
              <option value="-12">UTC -12:00</option>
              <option value="-11">UTC -11:00</option>
              <option value="-10">UTC -10:00</option>
              <option value="-9">UTC -9:00</option>
              <option value="-8">UTC -8:00</option>
              <option value="-7">UTC -7:00</option>
              <option value="-6">UTC -6:00</option>
              <option value="-5">UTC -5:00</option>
              <option value="-4">UTC -4:00</option>
              <option value="-3">UTC -3:00</option>
              <option value="-2">UTC -2:00</option>
              <option value="-1">UTC -1:00</option>
              <option value="0">UTC +0:00</option>
              <option value="1">UTC +1:00</option>
              <option value="2">UTC +2:00</option>
              <option value="3">UTC +3:00</option>
              <option value="4">UTC +4:00</option>
              <option value="5">UTC +5:00</option>
              <option value="6">UTC +6:00</option>
              <option value="7">UTC +7:00</option>
              <option value="8">UTC +8:00</option>
              <option value="9">UTC +9:00</option>
              <option value="10">UTC +10:00</option>
              <option value="12">UTC +11:00</option>
              <option value="12">UTC +12:00</option>
            </select>
            }
          </div>

          <div className="schedule_input_1">
            {evalFromContextAvailable && this.state.BotMsg.EvalTimeFromContext ?
            <select
                className="borderR"
                onChange={this.changeContextParamForEvalPeriod}
                value={this.state.BotMsg.ContextParamForEvalPeriod}
            >
              <option value="">Выберите Context переменную содержащую кол-во пропускаемых дней</option>
              {attrs}
            </select>
            :
            <select
                className="borderR"
                onChange={this.changeRepeat}
                value={this.getRepeatType()}
            >
              <option value="None">Повторить: Нет</option>
              <option value="Every Day">Повторить: Каждый день</option>
              <option value="Weekends">Повторить: Выходные дни</option>
              <option value="Every Month">Повторить: Каждый месяц</option>
              <option value="Workdays">Повторить: Рабочие дни</option>
              <option value="Custom">Повторить: Свой вариант</option>
            </select>}
          </div>
        </div>

        {evalFromContextAvailable && (
            <Fragment>
              <button onClick={this.changeEvalTimeFromContext} className="btn__all--small mt_5">
                {
                  this.state.BotMsg.EvalTimeFromContext ?
                      'Расчитать дату и время одинаковую для всех пользователей' :
                      'Расчитать дату и время с помощью context переменных индивидуально для каждого'
                }
              </button>

              <TooltipTitle
                  idTooltip="i_EvalTimeFromContext"
                  name=""
                  tooltipDescription={
                    "Вы можете расчитать дату и время рассылки индивидуально для каждого пользователя" +
                    " на основе его данных сохраненных в" +
                    " context переменных." +
                    " Вам нужно будет указать названия context переменных в которых сохранено" +
                    " время рассылки, часовой пояс пользователя, кол-во пропускаемых дней между повторами рассылки" +
                    " и дату начала отсчета рассылки. Формат времени - '18:30','1:05'." +
                    "Формат часового пояса - '+3','-2', '0'." +
                    "Формат кол-ва пропускаемых дней - '1', 2, '0'." +
                    "Формат даты начала - new Date(), '2022-05-21', '2022-05-21T22:12:12.633Z'."
                  }
              />
            </Fragment>
        )}

        <button onClick={this.changeEvalDate} className="btn__all--small mt_5">
          {
            this.state.BotMsg.EvalDate ?
                'Выбрать дату с помощью календаря' :
                'Расчитать дату с помощью javascript и глобальных переменных'
          }
        </button>

        <TooltipTitle
            idTooltip="i_EvalDate"
            name=""
            tooltipDescription={
              "Вы можете расчитать дату рассылки." +
              " Javascript выражение должно вернуть Date объект." +
              " В окне даты вы можете увидеть результат расчета выражения."
            }
        />

        {this.state.BotMsg.EvalDate && (
            <div>
                <input
                  style={{
                    padding: '10px 3px',
                    margin: '5px 0px',
                    width: '100%',
                  }}
                  className="borderR"
                  type="text"
                  placeholder="const date = new Date(Global.myDate);const days = 3;date.setDate(date.getDate() + days);date;"
                  value={this.state.BotMsg.EvalDateValue}
                  onChange={this.changeEvalDateValue}
              />
            </div>
        )}

        {this.getRepeatType() == "Custom" ? (
          <div className="block_list_schedule_custom">
            <ul className="list_shedule">
              <li onClick={() => this.changeRepeatDays(0)}>
                <span>Вс {this.state.RepeatDays[0] ? "+" : ""}</span>
              </li>

              <li onClick={() => this.changeRepeatDays(1)}>
                <span>Пн {this.state.RepeatDays[1] ? "+" : ""}</span>
              </li>

              <li onClick={() => this.changeRepeatDays(2)}>
                <span>
                  {this.t("Вт")}
                  {this.state.RepeatDays[2] ? "+" : ""}
                </span>
              </li>

              <li onClick={() => this.changeRepeatDays(3)}>
                <span>
                  {this.t("Ср")}
                  {this.state.RepeatDays[3] ? "+" : ""}
                </span>
              </li>

              <li onClick={() => this.changeRepeatDays(4)}>
                <span>
                  {this.t("Чт")}
                  {this.state.RepeatDays[4] ? "+" : ""}
                </span>
              </li>

              <li onClick={() => this.changeRepeatDays(5)}>
                <span>
                  {this.t("Пт")}
                  {this.state.RepeatDays[5] ? "+" : ""}
                </span>
              </li>

              <li onClick={() => this.changeRepeatDays(6)}>
                <span>
                  {this.t("Сб")}
                  {this.state.RepeatDays[6] ? "+" : ""}
                </span>
              </li>
            </ul>
          </div>
        ) : null}
      </div>
    );
  }

  render() {
    if (this.props.BotMsg === undefined) return null;

    var attrs = [];
    if (this.props.bot) {
      if (this.props.bot.BindTo == "FB") {
        //['first_name', 'last_name', 'gender', 'locale', 'timezone']
        attrs = ["first_name", "last_name", "gender"].map(function (attr) {
          return {
            value: attr,
            label: attr,
          };
        });
      } else if (this.props.bot.BindTo == "Vk") {
        attrs = [
          "first_name",
          "last_name",
          "gender",
          "city",
          "country",
          "has_photo",
        ].map(function (attr) {
          return {
            value: attr,
            label: attr,
          };
        });
      } else if (this.props.bot.BindTo == "Ok") {
        attrs = ["name"].map(function (attr) {
          return {
            value: attr,
            label: attr,
          };
        });
      } else if (this.props.bot.BindTo == "Telegram") {
        attrs = ["first_name", "last_name"].map(function (attr) {
          return { value: attr, label: attr };
        });
      }
    }

    return (
      <div className="overlay_main">
        {this.props.Saved() ? (
          <span className="oSave">Сохранено</span>
        ) : (
          <span className="nSave">Не сохранено</span>
        )}

        <div className="trigger form-group">
          {this.state.BotMsg.Type != "Now" ? (
            <div className="switch_select">
              <Switch
                onClick={this.toggleSwitch}
                on={this.state.BotMsg.Status == "1"}
              />
            </div>
          ) : (
            <button className="sendMessage orange-rounded" onClick={this.send}>
              Отправить
            </button>
          )}
          {/*     {this.state.BotMsg.Type=="Now" ? <span>Deliver Your Message Now</span> : null}*/}
          {/*{this.state.BotMsg.Type=="Trigger" ? <span>Добавить событие</span> : null}*/}
          {/* {this.state.BotMsg.Type=="Sch" ? <span>Schedule for later</span> : null}*/}

          {this.state.BotMsg && !this.state.BotMsg.SendToChannels && (
            <div className="user_filter_send">
              <button className="userFilter" onClick={() => this.props.ShowUserFilter(this.state.FilterUsersCount, this.state.AllUsersCount)}>
                Фильтр пользователей
              </button>
            </div>
          )}
        </div>

        <div className="deliver_txt">
          {this.state.BotMsg && this.state.BotMsg.SendToChannels ?
              (
                  <span>
                    {this.t(
                        "Это сообщение будет отправлено в выбранные группы/каналы"
                    )}
                  </span>
              )
              :
              (
                  <span>
                    <b>{this.state.FilterUsersCount + " "}</b>
                    {this.t("пользователей соответствуют фильтру")}.<br />
                    {this.t(
                        "Это сообщение будет отправлено тем пользователям которые " +
                        'вы указали в "Фильтре пользователей"'
                    )}
                  </span>
              )}
        </div>

        <div className="trigger_settings">
          {this.props.bot.BindTo === 'FB' && (
              <div className="settings_trigger">
                <div className="left">
                  <span>{this.t("Facebook Message Tag")}</span>
                </div>

                <div className="right">
                  <div className="right_overlay">
                    <select
                        className="chat_input borderR"
                        value={this.state.BotMsg.Tag}
                        onChange={this.changeTag}
                    >
                      <option value="" key="">
                        -- Выберите тэг для отправки сообщения вне 24-часового окна --
                      </option>

                      {MESSAGE_TAGS.map(tag => (
                          <option value={tag} key={tag}>
                            {tag}
                          </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
          )}
          {this.state.BotMsg.Type == "Trigger" ? (
            <div className="settings_trigger">
              <div className="left">
                <span>{this.t("Реакция на сообщение")}</span>
              </div>

              <div className="right">
                <div className="right_overlay">
                  <select
                    className="chat_input borderR"
                    value={this.state.BotMsg.Trigger}
                    onChange={this.changeTrigger}
                  >
                    <option value="After first interaction">
                      {this.t("После") + " " + this.t("первого взаимодействия")}
                    </option>

                    <option value="After last interaction">
                      {this.t("После") +
                        " " +
                        this.t("последнего взаимодействия")}
                    </option>
                  </select>
                </div>

                {this.state.BotMsg.Trigger == "After user attribute is set" ? (
                  <div className="profile_pic">
                    <Select
                      className="borderRs"
                      placeholder={"Type attribute name"}
                      name="form-field-name borderR"
                      value={this.state.BotMsg.TriggerAttr}
                      options={attrs}
                      onChange={this.changeTriggerAttr}
                    />

                    <select
                      value={this.state.BotMsg.TriggerCond}
                      onChange={this.changeTriggerCond}
                      className="chat_input borderR"
                    >
                      <option value="is">is</option>

                      <option value="is not">is not</option>

                      <option value="start with">start with</option>

                      <option value=">">{">"}</option>

                      <option value="<">{"<"}</option>
                    </select>

                    <input
                      value={this.state.BotMsg.TriggerVal}
                      onChange={this.changeTriggerVal}
                      placeholder="Choose value of the attribute"
                      className="chat_input borderR"
                      type="text"
                    />
                  </div>
                ) : null}

                {this.state.BotMsg.Trigger == "After first interaction" ||
                this.state.BotMsg.Trigger == "After last interaction" ? (
                  <span className={"note"}>
                    <span>{this.t("Примечание")}:</span>
                    {this.t(
                      "Каждый пользователь получит сообщение только один раз"
                    )}
                  </span>
                ) : null}
              </div>

              {this.state.BotMsg.Trigger != "After user join to channel" &&
              this.state.BotMsg.Trigger != "After user add bot to contacts" ? (
                <div className="left">
                  <span>{this.t("Время отправки")}</span>
                </div>
              ) : null}

              {this.state.BotMsg.Trigger != "After user join to channel" &&
              this.state.BotMsg.Trigger != "After user add bot to contacts" ? (
                <div className="right">
                  <div className="right_overlay">
                    <input
                      value={this.state.BotMsg.Time}
                      onChange={this.changeTime}
                      className="chat_input borderR"
                      type="number"
                    />

                    <select
                      className="chat_input borderR"
                      value={this.state.BotMsg.TimeType}
                      onChange={this.changeTimeType}
                    >
                      <option value="Minutes">{this.t("Минуты")}</option>

                      <option value="Hours">{this.t("Час")}</option>

                      <option value="Days">{this.t("День")}</option>
                    </select>
                  </div>
                </div>
              ) : null}
            </div>
          ) : null}
          {this.state.BotMsg.Type == "Sch" ? this.ScheduleForLater() : null}
        </div>
        <div className="addCard">{this.EditPanel()}</div>
      </div>
    );
  }
}

class BroadcastCentrPanel extends TransComponent {
  constructor(props) {
    super(props);
    this.state = {
      None: {},
    };
  }

  MesseageSend() {
    return (
      <div className="message_block_center">
        <div className="text_center_block_success">
          <h2>{this.t("Сообщение отправлено!")}</h2>
          <p>
            {this.t("Вы также можете использовать Расписание для следующих")}
            <br />
            {this.t("сообщений на левой панели для управления вашими")}
            <br />
            {this.t("сообщениями в чате Botkits")}.
          </p>
        </div>
      </div>
    );
  }

  Statistic() {
    return (
        <div>
          <table className={"grid_w-100"}>
            <thead>
              <tr className={"table_f"}>
                <th className={"table_left"}>Id</th>
                <th className={"table_left"}>Название рассылки</th>
                <th className={"table_left"}>Отправлено</th>
                <th className={"table_left"}>Запущена</th>
              </tr>
            </thead>

            <tbody>
              {(this.props.BroadcastStat || []).map(stat => {
                let startDateTime = new Date(stat.created_at).toLocaleString();

                // delete seconds
                startDateTime = startDateTime.substr(0, startDateTime.length - 3);

                const broadcast = this.props.BroadcastMsgs.find(msg => msg._id === stat.Broadcast);

                return (
                    <tr key={stat._id}>
                      <td>{broadcast ? broadcast._id : 'Удалена'}</td>
                      <td>{broadcast ?
                          (<span className="a"
                              onClick={() => this.props.changeMsg(broadcast)}>
                            {broadcast.Name}
                          </span>)
                          : stat.BroadcastName}
                      </td>
                      <td>{stat.Sent}</td>
                      <td>{startDateTime}</td>
                    </tr>
                );
              })}
            </tbody>
          </table>
        </div>
    );
  }

  CenterPanelText() {
    return (
      <div className="message_block_center">
        <div className="text_center_block">
          <h2>
            {this.t("Функция рассылки позволяет Вам активно")}
            <br />
            {this.t("взаимодействовать с вашей аудиторией")}.
          </h2>
          <p>
            {this.t("Отфильтруйте пользователей на основе их атрибутов и")}
            <br />
            {this.t("отправляйте персонализированные сообщения")}.<br />
            {this.t("Начните с Выбора Рассылки которую вы хотите")}
            <br />
            {this.t("использовать на левой панели")}.
          </p>
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.t_load();
  }

  render() {
    return (
      <div
        className="dashboard-page__col--2"
        style={{ height: "calc(100vh - 82px)" }}
      >
        <Scrollbars style={{ height: "100%" }}>
          <div className="broad_main_content_block">
            {this.props.Main != "Start" &&
            this.props.Main != "Sent" &&
            this.props.Main != "Statistic" &&
            !(this.props.BotMsg === undefined) ? (
              <Deliver
                ChangeSave={this.props.ChangeSave}
                changeMsg={this.props.changeMsg}
                FilterCount={this.props.FilterCount}
                Saved={this.props.Saved}
                ShowDelPopup={this.props.ShowDelPopup}
                ShowUserFilter={this.props.ShowUserFilter}
                changeMain={this.props.changeMain}
                loadMsgs={this.props.loadMsgs}
                setupBotMsg={this.props.setupBotMsg}
                BroadcastMsgs={this.props.BroadcastMsgs}
                OriginalBroadcastMsgs={this.props.OriginalBroadcastMsgs}
                AllContent={this.props.AllContent}
                BotUsers={this.props.BotUsers}
                deleteContentFromAllContent={
                  this.props.deleteContentFromAllContent
                }
                addContentToAllContent={this.props.addContentToAllContent}
                setTutorialStep={this.props.setTutorialStep}
                addWait={this.props.addWait}
                delWait={this.props.delWait}
                BotMsg={this.props.BotMsg}
                groups={this.props.groups}
                bot={this.props.bot}
                User={this.props.user}
                DeleteMsg={this.props.DeleteMsg}
              />
            ) : null}
            {this.props.Main == "Start" ? this.CenterPanelText() : null}
            {this.props.Main == "Sent" ? this.MesseageSend() : null}
            {this.props.Main == "Statistic" ? this.Statistic() : null}
          </div>
        </Scrollbars>
      </div>
    );
  }
}

class Broadcast extends TransComponent {
  constructor(props) {
    super(props);

    this.state = {
      Main: "Start",
      CurrentMsg: {},
      Show: false,
      AllContent: null,
      BroadcastMsgs: [],
      OriginalBroadcastMsgs: [],
      ShowDeletebotMsgPopup: false,
      ShowUserFilter: false,
      Saved: true,
      BotUsers: { Users: [] },
      FilterCount: 0,
      Tab: "Filter",
      CRMorders: [],
      close: true,
      stripBottom: "",
      stripBg: "",
      Wait: 0,
      FilterUsersCount: 0,
      AllUsersCount: 0,
      FilterUsersLoadMoreEnable: true,
      page: 1
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInputFocus = this.handleInputFocus.bind(this);
    this.changeMain = this.changeMain.bind(this);
    this.changeMsg = this.changeMsg.bind(this);
    this.addNowMsg = this.addNowMsg.bind(this);
    this.addTriMsg = this.addTriMsg.bind(this);
    this.addSchMsg = this.addSchMsg.bind(this);
    this.loadMsgs = this.loadMsgs.bind(this);
    this.deleteMsg = this.deleteMsg.bind(this);
    this.changeFilterCond = this.changeFilterCond.bind(this);
    this.changeFilterAttr = this.changeFilterAttr.bind(this);
    this.changeFilterVal = this.changeFilterVal.bind(this);
    this.changeFilterType = this.changeFilterType.bind(this);
    this.addFilter = this.addFilter.bind(this);
    this.deleteFilter = this.deleteFilter.bind(this);
    this.changeSave = this.changeSave.bind(this);
    this.changeFilterEnable = this.changeFilterEnable.bind(this);
    this.change = config.change_new.bind(this);
    this.refreshTutorial = func.refreshTutorial.bind(this);

    this.NewBtn = this.NewBtn.bind(this);
    this.Close = this.Close.bind(this);
    this.BottomStyle = this.BottomStyle.bind(this);
    this.addWait = this.addWait.bind(this);
    this.delWait = this.delWait.bind(this);
  }

  addWait() {
    this.setState({
      Wait: ++this.state.Wait,
    });
  }

  delWait() {
    this.setState({
      Wait: --this.state.Wait,
    });
  }

  setTutorialStep = (step) => {
    var s = this.state;
    var t = func.get_tutorial(
      this.props.user && this.props.user.Tutorial,
      "Broadcast"
    );
    if (t && t.Step == step - 1) {
      this.refreshTutorial("Broadcast", step);
    }
  };

  changeFilterEnable(e) {
    this.state.CurrentMsg.FilterEnable = e.target.checked;
    this.changeMsg(this.state.CurrentMsg);
    this.setState({
      Saved: false,
    });
  }

  changeSave(x) {
    this.setState({
      Saved: x,
    });
  }

  deleteFilter(i) {
    this.state.CurrentMsg.Filters.splice(i, 1);
    this.changeMsg(this.state.CurrentMsg);
    this.setState({
      Saved: false,
    });
  }

  addFilter(attr, cond, val) {
    this.state.CurrentMsg.Filters.push({
      Attr: attr || this.t("Введите имя атрибута"),
      Val: val || "",
      Cond: cond || "is",
    });
    this.changeMsg(this.state.CurrentMsg);
    this.setState({
      Saved: false,
    });
  }

  changeFilterType(e) {
    if (typeof e == "string") {
      this.state.CurrentMsg.FilterType = e;
    } else {
      this.state.CurrentMsg.FilterType = e.target.value;
    }
    this.changeMsg(this.state.CurrentMsg);
    this.setState({
      Saved: false,
    });
  }

  changeFilterCond(e, i) {
    this.state.CurrentMsg.Filters[i].Cond = e.target.value;
    this.changeMsg(this.state.CurrentMsg);
    this.setState({
      Saved: false,
    });
  }

  changeFilterAttr(e, i) {
    if (typeof e == "string") {
      this.state.CurrentMsg.Filters[i].Attr = e;
    } else {
      this.state.CurrentMsg.Filters[i].Attr = e.target.value;
    }

    this.changeMsg(this.state.CurrentMsg);
    this.setState({
      Saved: false,
    });
  }

  changeFilterVal(e, i) {
    this.state.CurrentMsg.Filters[i].Val = e.target.value;
    this.changeMsg(this.state.CurrentMsg);
    this.setState({
      Saved: false,
    });
  }

  BroadCastFilter() {
    var s = this.state;
    var attrs = [""];
    if (this.props.bot) {
      if (this.props.bot.BindTo == "FB") {
        attrs = attrs.concat(["first_name", "last_name", "gender"]);
      } else if (this.props.bot.BindTo == "Vk") {
        attrs = attrs.concat([
          "first_name",
          "last_name",
          "gender",
          "city",
          "country",
          "has_photo",
        ]);
      } else if (this.props.bot.BindTo == "Ok") {
        attrs = attrs.concat(["name"]);
      } else if (this.props.bot.BindTo == "Telegram") {
        attrs = attrs.concat(["first_name", "last_name"]);
      }
      s.BotUsers.Users.forEach((u, i) => {
        if (u.Context) {
          for (var key in u.Context) {
            var option = "Context." + key;
            if (attrs.indexOf(option) == -1) {
              attrs.push(option);
            }
          }
        }
      });
      attrs.push("_id");
      var crm_str = "CRM(Кол-во сделанных заказов в црм)";
      attrs.push({
        val: crm_str,
        key: this.t(crm_str),
      });
      attrs.push({
        val: "CRM(Дата создания записи)",
        key: this.t("CRM(Дата создания записи)"),
      });
      var crm_attrs = [];
      s.CRMtables.forEach((e) => {
        e.Fields.forEach((f) => {
          if (func.find(crm_attrs, { Name: f.Name, Key: f.Key }).length == 0) {
            crm_attrs.push({ Name: f.Name, Key: f.Key });
          }
        });
      });


      var attrs = attrs.map(function (attr) {
        if (typeof attr == "string") {
          return (
            <option key={attr} value={attr}>
              {attr}
            </option>
          );
        } else {
          return (
            <option key={JSON.stringify(attr.val)} value={attr.val}>
              {attr.key}
            </option>
          );
        }
      });

      crm_attrs.forEach((a) => {
        const value = "CRM(" + a.Key + ")";

        attrs.push(
          <option key={value} value={value}>
            {"CRM(" + this.t("Колонка") + ":" + a.Name + ")"}
          </option>
        );
      });
    }

    var users = s.FilterUsers.map((user, ind) => {
      const u = user;
      const i = ind;
      var name = "";
      if (u.name) name = u.name;
      else if (u.first_name) name = u.first_name + " " + u.last_name;
      else if (u._iud) name = u._id;

      return (
        <tr>
          <td>{name}</td>
          <td>
            <button
              className={"btn__all--small mt_5"}
              onClick={() => {
                this.setState({
                  Tab: "User",
                  BotUser: u,
                });
              }}
            >
              {this.t("Подробнее")}
            </button>
          </td>
          <td className={"tc"}>
            <button
              className={"btn__all--small"}
              onClick={() => {
                this.addFilter("_id", "is not", u._id);
                this.changeFilterType("AND");
              }}
            >
              <i className="fas fa-trash-alt"></i>
            </button>
          </td>
        </tr>
      );
    });

    if (s.Tab == "User" && s.BotUser) {
      var user_rows = [];
      s.BotUser.Context = s.BotUser.Context || {};
      for (var key in s.BotUser.Context) {
        user_rows.push(
          <tr key={key}>
            <td>{"Context." + key}</td>
            <td>{JSON.stringify(s.BotUser.Context[key])}</td>
          </tr>
        );
      }
    }

    var firstFilter = (
      <div className="filter_group">
        <div className="filter_width">
          <div className="filter_score">
            <span>{this.t("Если")}</span>
          </div>
          <div className="filter_score" style={{ border: "none" }}>
            <select
              style={{ border: "1px solid #dfdfdf" }}
              className="filter_is select_w"
              value={s.CurrentMsg.Filters[0].Attr}
              name={"CurrentMsg.Filters[0].Attr"}
              onChange={(e) => this.changeFilterAttr(e, 0)}
            >
              {attrs.includes(s.CurrentMsg.Filters[0].Attr)
                ? attrs
                : attrs.concat(s.CurrentMsg.Filters[0].Attr)}
            </select>
          </div>

          <div className="filter_score" style={{ border: "none" }}>
            <select
              style={{ border: "1px solid #dfdfdf" }}
              className="filter_is select_w"
              value={this.state.CurrentMsg.Filters[0].Cond}
              onChange={(e) => this.changeFilterCond(e, 0)}
            >
              <option value="is">{this.t("является")}</option>
              <option value="is not">{this.t("не является")}</option>
              <option value="starts with">{this.t("начинается с")}</option>
              <option value="<">{"<"}</option>
              <option value=">">{">"}</option>
            </select>
          </div>

          <div className="filter_f_width">
            <input
              className="attr_input"
              type="text"
              value={this.state.CurrentMsg.Filters[0].Val}
              onChange={(e) => this.changeFilterVal(e, 0)}
            />
          </div>
        </div>
      </div>
    );

    var otherFilters = this.state.CurrentMsg.Filters.slice(1).map(
      function (f, ind) {
        const i = ind;
        return (
          <div key={i} className="add_filter_group_list">
            <div className="filter_width">
              <div className="filter_width_2">
                <div className="filter_score">
                  <select
                    className="select_w"
                    value={this.state.CurrentMsg.FilterType}
                    onChange={this.changeFilterType}
                  >
                    <option value="AND">{this.t("И")}</option>
                    <option value="OR">{this.t("Или")}</option>
                  </select>
                </div>

                <div className="filter_score" style={{ border: "none" }}>
                  <select
                    style={{ border: "1px solid #dfdfdf" }}
                    className="filter_is select_w"
                    value={s.CurrentMsg.Filters[i + 1].Attr}
                    name={"CurrentMsg.Filters[" + (i + 1) + "].Attr"}
                    onChange={(e) => this.changeFilterAttr(e, i + 1)}
                  >
                    {attrs.includes(s.CurrentMsg.Filters[i + 1].Attr)
                      ? attrs
                      : attrs.concat(s.CurrentMsg.Filters[i + 1].Attr)}
                  </select>
                </div>

                <div className="filter_score">
                  <select
                    className="filter_is select_w"
                    value={this.state.CurrentMsg.Filters[i + 1].Cond}
                    onChange={(e) => this.changeFilterCond(e, i + 1)}
                  >
                    <option value="is">{this.t("является")}</option>
                    <option value="is not">{this.t("не является")}</option>
                    <option value="starts with">
                      {this.t("начинается с")}
                    </option>
                    <option value="<">{"<"}</option>
                    <option value=">">{">"}</option>
                  </select>
                </div>

                <div className="filter_f_width">
                  <input
                    className="attr_input"
                    type="text"
                    value={this.state.CurrentMsg.Filters[i + 1].Val}
                    onChange={(e) => this.changeFilterVal(e, i + 1)}
                  />
                </div>
              </div>
            </div>

            <div className="btn_filter_w">
              <button
                className="btn__all--small"
                onClick={() => this.deleteFilter(i + 1)}
              >
                <i className="fas fa-trash-alt"></i>
              </button>
            </div>
          </div>
        );
      }.bind(this)
    );

    return (
      <div className="filterPopup">
        <Scrollbars style={{ height: "90%" }}>
          <div className="popup_filter_content">
            <div className="filter_title">
              <p>{this.t("Фильтр пользователя")}</p>
            </div>
            <div className="popup_filter_text">
              <p>
                {this.t(
                  "Выберите, какие пользователи получат ваше сообщение." +
                    "Используйте один из предопределенных фильтров или собственные фильтры " +
                    "в раскрывающемся списке ниже,чтобы охватить определенную группу людей"
                )}
                .
              </p>
            </div>
            <div className="popup_filter_setting">
              <p>{this.t("Кто получит эту запись")}:</p>
              {s.Tab == "Filter" ? firstFilter : null}
              {s.Tab == "Filter" ? otherFilters : null}
              {s.Tab == "Users" ? (
                <div style={{maxHeight: '300px', overflowY: 'scroll'}} ref={this.filterUsersRef} onScroll={this.scrollFilterUsers}>
                  <table className={"grid_w-100"}>
                    <tr className={"table_f"}>
                      <th className={"table_left"}>{this.t("Имя")}</th>
                      <th className={"table_left"}>{this.t("Данные")}</th>
                      <th className={"tc"}>{this.t("Удалить из фильтра")}</th>
                    </tr>
                    {users}
                  </table>
                </div>
              ) : null}
              {s.Tab == "User" ? (
                <table className={"grid_w-100"}>
                  <tr className={"table_f"}>
                    <th className={"table_left"}>{this.t("Название")}</th>
                    <th className={"table_left"}>{this.t("Значение")}</th>
                  </tr>
                  {user_rows}
                </table>
              ) : null}
              {s.Tab == "Filter" ? (
                <button
                  onClick={() => this.addFilter()}
                  className="btn__all--small mt_15 mr5"
                >
                  Добавить фильтр
                </button>
              ) : null}
              {s.Tab == "Filter" ? (
                <button
                  onClick={() =>
                    this.setState({
                      Tab: "Users",
                      page: 1
                    })
                  }
                  className="btn__all--small mt_15"
                >
                  Получатели
                </button>
              ) : null}
              {s.Tab == "Users" ? (
                <button
                  onClick={() =>
                    this.setState({
                      Tab: "Filter",
                    })
                  }
                  className="btn_all mt_15"
                >
                  {this.t("Назад")}
                </button>
              ) : null}
              {s.Tab == "User" ? (
                <button
                  onClick={() =>
                    this.setState({
                      Tab: "Users",
                    })
                  }
                  className="btn_all mt_15"
                >
                  {this.t("Назад")}
                </button>
              ) : null}

              <div className="popup_filter_bottom mt_15">
                <div className="bottom_left">
                  <span>
                    Всего получателей: {this.state.FilterUsersCount}{" "}
                    из {this.state.AllUsersCount}
                  </span>
                  <br />
                  <div className="wrap_filter_laber">
                    <label htmlFor="enable_filter">
                      Включить фильтр
                    </label>
                    <input
                      id="enable_filter"
                      type="checkbox"
                      checked={this.state.CurrentMsg.FilterEnable}
                      onChange={this.changeFilterEnable}
                    />
                  </div>
                </div>

                <div className="bottom_right">
                  <button
                    onClick={() => {
                      this.setState({
                        ShowUserFilter: false,
                      });
                    }}
                    className="btn__all--small bg_shadow"
                  >
                    Готово
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Scrollbars>
      </div>
    );
  }

  filterUsersRef = (div) => {
    this.filterUsersContainer = div;
  };

  deleteMsg(msg) {
    this.changeMain("Start");
    this.setState({
      BroadcastMsgs: this.state.BroadcastMsgs.filter(
        (botMsg) => botMsg._id !== msg._id
      ),
      OriginalBroadcastMsgs: this.state.OriginalBroadcastMsgs.filter(
        (botMsg) => botMsg._id !== msg._id
      ),
      ShowDeletebotMsgPopup: false,
    });

    let url = config.Backend + "/botMsg/delete?";
    if (!(cookies.get("Id") === undefined)) {
      url += "&CurrentUserId=" + cookies.get("Id").toString();
    }
    url += "&Id=" + msg._id;
    jQuery.getJSON(url);
  }

  setupBotMsg = (botMsg) => {
    this.setState({
      BroadcastMsgs: [
        ...this.state.BroadcastMsgs.filter((msg) => msg._id !== botMsg._id),
        {
          ...botMsg,
          Group: this.props.groups.find((group) => group._id === botMsg.Group),
        },
      ],
      OriginalBroadcastMsgs: [
        ...this.state.OriginalBroadcastMsgs.filter(
          (msg) => msg._id !== botMsg._id
        ),
        JSON.parse(
          JSON.stringify({
            ...botMsg,
            Group: this.props.groups.find(
              (group) => group._id === botMsg.Group
            ),
          })
        ),
      ],
    });
  };

  loadAllContent = (botMsgs) => {
    if (botMsgs.length === 0) {
      this.setState({
        AllContent: [],
      });
    } else {
      func
        .getData("botMsg/showAllContent", {
          Ids: botMsgs.map((botMsg) => botMsg._id),
          CurrentUserId: cookies.get("Id").toString(),
        })
        .then(
          (allContent) => {
            this.setState({
              AllContent: allContent,
            });
          }
        );
    }
  };

  addContentToAllContent = (content) => {
    let newAllContent;

    if (Array.isArray(content)) {
      newAllContent = [
        ...this.state.AllContent.filter(
          (c) => content.map((cc) => cc._id).indexOf(c._id) === -1
        ),
        ...content,
      ];
    } else {
      newAllContent = [
        ...this.state.AllContent.filter((c) => c._id !== content._id),
        content,
      ];
    }

    this.setState({
      AllContent: newAllContent,
    });
  };

  deleteContentFromAllContent = (contentId) => {
    this.setState({
      AllContent: this.state.AllContent.filter(
        (content) => content._id !== contentId
      ),
    });
  };

  loadMsgs(bot) {
    var url = config.Backend + "/bot/broadcastMsgs?";
    if (!(cookies.get("Id") === undefined)) {
      url += "&CurrentUserId=" + cookies.get("Id").toString();
    }
    url += "&Id=" + bot._id;
    this.addWait();

    jQuery.getJSON(
      url,
      function (res) {
        this.delWait();

        if (!res.Error) {
          this.state.BroadcastMsgs = [];
          this.state.OriginalBroadcastMsgs = [];
          this.setState({
            BroadcastMsgs: this.state.BroadcastMsgs,
            OriginalBroadcastMsgs: this.state.OriginalBroadcastMsgs,
          });

          this.loadAllContent(res);

          this.setState({
            BroadcastMsgs: res,
            OriginalBroadcastMsgs: JSON.parse(JSON.stringify(res)),
          });
        }
      }.bind(this)
    );
  }

  addBroadcastMsg = (type) => {
    var url = config.Backend + "/botMsg/add?";
    if (!(cookies.get("Id") === undefined)) {
      url += "&CurrentUserId=" + cookies.get("Id").toString();
    }
    url += "&Bot=" + cookies.get("BotId").toString();
    url += "&Type=" + type;
    url += "&Name=Название";
    this.addWait();

    jQuery.getJSON(
      url,
      function (res) {
        this.delWait();

        if (!res.Error) {
          this.addContentToAllContent(res.Content);
          this.changeMsg(res.BotMsg);
          this.setupBotMsg(res.BotMsg);

          if (type === "Now") {
            this.setTutorialStep(1);
          }
        } else {
        }
      }.bind(this)
    );
  };

  addNowMsg() {
    this.addBroadcastMsg("Now");
  }

  addTriMsg() {
    this.addBroadcastMsg("Trigger");
  }

  addSchMsg() {
    this.addBroadcastMsg("Sch");
  }

  importBotUsers = (Ids, Data) => {
    if (!Ids.length) {
      return alert('Импорт завершен');
    }

    let batch = Ids.splice(0, 100);
    let dataBatch = Data.splice(0, 100);

    const json = {
      CurrentUserId: cookies.get("Id"),
      BotId: cookies.get("BotId"),
      Data: dataBatch,
      Ids: batch,
    };

    func.getData("bot/importBotUsers", json)
        .then(
            (ok) => {
              if (ok.Ok) {
                this.setState({
                  importedUsers: this.state.importedUsers + batch.length
                }, () => {
                  this.importBotUsers(Ids, Data);
                });
              }
            }
        ).catch(ok => {
            alert(ok.Error);
            this.importBotUsers(Ids, Data);
        });
  }

  onLoadCsvUsersHandler = (data) => {
    const { bot } = this.props;
    let Data = [];
    let Ids = [];
    let headers;

    try {
      if (data.length === 1) {
        const rowData = data[0].data.map((id) => (id || '').trim());
        Ids = rowData;
      } else {
        for (const row of data) {
          const rowData = row.data;
          if (rowData.length) {
            if (headers) {
              let idHeaderIndex = headers.findIndex(header =>
                  (header || '').trim().toLowerCase() === 'id');

              if (idHeaderIndex === -1) {
                return alert('Неверный формат. "id" заголовок не найден');
              }

              if (bot.BindTo === 'Ok') {
                let idChatIndex = headers.findIndex(header =>
                    (header || '').trim().toLowerCase() === 'chat');

                if (idChatIndex === -1) {
                  return alert('Неверный формат. "Chat" заголовок не найден');
                }

                let idNameIndex = headers.findIndex(header =>
                    (header || '').trim().toLowerCase() === 'name');

                if (idNameIndex === -1) {
                  return alert('Неверный формат. "name" заголовок не найден');
                }

                Data.push({
                  Id: (rowData[idHeaderIndex] || '').trim(),
                  Chat: (rowData[idChatIndex] || '').trim(),
                  name: (rowData[idNameIndex] || '').trim()
                });
                Ids.push((rowData[idHeaderIndex] || '').trim());
              } else if (bot.BindTo === 'Telegram' || bot.BindTo === 'Viber') {
                const userRecord = {};
                headers.forEach((header, index) => {
                  userRecord[header.trim()] = rowData[index];
                });
                if (userRecord.id) {
                  Data.push(userRecord);
                  Ids.push((rowData[idHeaderIndex] || '').trim());
                }
              } else {
                Ids.push((rowData[idHeaderIndex] || '').trim());
              }
            } else {
              headers = rowData.map(cellData => (cellData || '').trim());
            }
          }
        }
      }

      if (bot.BindTo === 'Vk') {
        // only numbers
        Ids = Ids.filter(id => id && !id.match(/\D/));
      } else {
        Ids = Ids.filter(id => id);
      }
    } catch (e) {
      console.log(e);
      return alert('Внутренняя ошибка сервера');
    }

    if (Ids.length) {
      this.setState({
        importedUsers: 0,
        allUsersForImport: Ids.length,
      }, () => {
        this.importBotUsers(Ids, Data);
      });
    } else {
      alert("Не было найдено ни одного Id в файле");
    }
  };

  onOpenFileDialogForCsvExport = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (importButtonRef.current) {
      importButtonRef.current.open(e);
    }
  };

  importExport() {
    const { bot } = this.props;
    const { BotUsers, importedUsers, allUsersForImport } = this.state;
    let headers;
    let data;

    switch (bot.BindTo) {
      case 'Ok':
        headers = ['id', 'Chat', 'name'];
        data = BotUsers.Users.map((user) => [user.Id, user.Chat, user.name]);
        break;
      case 'Viber':
        headers = ['id', 'name'];
        data = BotUsers.Users.map((user) => [user.Id, user.name]);
        break;
      case 'Telegram':
        headers = ['id', 'first_name', 'last_name'];
        data = BotUsers.Users.map((user) => [user.Id, user.first_name, user.last_name]);
        break;
      default:
        headers = ['id'];
        data = BotUsers.Users.map((user) => [user.Id]);
    }

    const csvData = [
      headers,
      ...data
    ];

    return (
      <div>
        <span className="post_title">
          {this.t("Выгрузка/Загрузка пользователей")}
        </span>
        <span data-tip data-for="i_exportImport" className={"ml5"}>
          <i className="fz15 far fa-question-circle"></i>
        </span>

        <ReactTooltip id="i_exportImport" type="light">
          <span>{this.t("Импорт/Экспорт происходит в формате csv")}</span>
        </ReactTooltip>

        <div>
          <CSVReader
            ref={importButtonRef}
            style={{
              display: "inline-block",
              border: "none",
            }}
            noDrag
            onFileLoad={this.onLoadCsvUsersHandler}
          >
            {({ file }) => (
              <aside>
                <button
                  className={"btn__all--small mr5"}
                  type="button"
                  onClick={this.onOpenFileDialogForCsvExport}
                >
                  {this.t("Импорт")}
                </button>

                <CSVLink
                  className={"btn__all--small mr5"}
                  data={csvData}
                  filename={"users.csv"}
                >
                  {this.t("Экспорт")}
                </CSVLink>
              </aside>
            )}
          </CSVReader>

          {allUsersForImport
          && `Обработано ${importedUsers} из ${allUsersForImport} строк`}
        </div>
      </div>
    );
  }

  onOpenStatistic = () => {
    func.getData("bot/broadcastStat", {
      Bot: (cookies.get("BotId") || '').toString()
    }).then(
        (ok) => {
          this.setState({ BroadcastStat: ok });
        }
    );
    this.changeMain('Statistic');
  }

  showStatisticButton() {
    return (
        <div>
          <button
              className={"btn__all--small mr5"}
              type="button"
              onClick={this.onOpenStatistic}
          >
            {this.t("Статистика")}
          </button>
        </div>
    );
  }

  BroadcastLeftPanel() {
    const { bot } = this.props;
    var nowMsgs = this.state.BroadcastMsgs.filter(
      (botMsg) => botMsg.Type === "Now"
    ).map((msg) => (
      <div
        key={msg._id}
        onClick={() => this.changeMsg(msg)}
        className="tags-cell"
      >
        {msg.Status == "1" ? <span className="tags_off"></span> : null}

        <span>{msg.Name}</span>
      </div>
    ));

    var triggerMsgs = this.state.BroadcastMsgs.filter(
      (botMsg) => botMsg.Type === "Trigger"
    ).map(
      function (msg) {
        /*
      var str = this.t("Спустя") + " " + msg.Time +" ";
      if( msg.TimeType == 'Seconds' )
      {
        var last = + msg.Time[msg.Time.length -1];
        var last2 = msg.Time.length > 1 ? + msg.Time[msg.Time.length - 2] : 2;

        if( last2 != 1 ) {
          if ( last == 1 ) {
            str += this.t("секунду")
          }
          else if ( last > 1 && last < 5 ) {
            str += this.t("секунды")
          }
          else {
            str+=this.t("секунд")
          }
        }
        else
        {
          str+=this.t("секунд")
        }
      }
      else if( msg.TimeType == 'Minutes' )
      {
        var last = + msg.Time[msg.Time.length -1];
        var last2 = msg.Time.length > 1 ? + msg.Time[msg.Time.length - 2] : 2;
        str += this.t("минут")
        if(last2 != 1 && str.indexOf('минут') !=-1) {//только для rus
          if ( last == 1 ) {
            str += "у"
          }
          else if ( last > 1 && last < 5 ) {
            str += "ы"
          }
        }
      }
      else if( msg.TimeType == "Hours" )
      {
        var last = + msg.Time[msg.Time.length -1];
        var last2 = msg.Time.length> 1 ? + msg.Time[msg.Time.length - 2] : 2;
        if( last2 != 1 ) {
          if ( last == 1 ) {
            str += this.t("час")
          }
          else if (last > 1 && last < 5) {
            str += this.t("часа")
          }
          else {
            str += this.t("часов")
          }
        }
        else
        {
          str += this.t("часов")
        }
      }
      else if( msg.TimeType == 'Days' )
      {
        var last = + msg.Time[msg.Time.length-1];
        var last2 = msg.Time.length > 1 ? + msg.Time[msg.Time.length - 2] : 2;
        if( last2 != 1 ) {
          if ( last == 1 ) {
            str += this.t("день")
          }
          else if (last > 1 && last < 5) {
            str += this.t("дня")
          }
          else {
            str += this.t("дней")
          }
        }
        else
        {
          str += this.t("дней")
        }
      }
      str += " " +this.t("после") + " ";
      if( msg.Trigger.indexOf("first") !=- 1)str += this.t('первого взаимодействия');
      else if(msg.Trigger.indexOf("last")!=-1)str += this.t('последнего взаимодействия');
      else str += "("+ msg.TriggerAttr + " " + msg.TriggerCond + " "+ msg.TriggerVal+")";
      if( msg.Trigger == "After user join to channel" )
      {
        str = "After user join to channel";
      }
      else if( msg.Trigger == "After user add bot to contacts" )
      {
        str = "After user add bot to contacts";
      }*/
        var onChangeMsg = () => this.changeMsg(msg);

        return (
          <div key={msg._id} onClick={onChangeMsg} className="tags-cell">
            {msg.Status == "1" ? <span className="tags_off"></span> : null}

            <span>{msg.Name}</span>
          </div>
        );
      }.bind(this)
    );

    var schMsgs = this.state.BroadcastMsgs.filter(
      (botMsg) => botMsg.Type === "Sch"
    ).map(
      function (msg) {
        /*
      var str = "";
      if( msg.Days == 'None' )
      {
        var correct = new Date(msg.Date);
        correct.setDate(correct.getDate()+1);
        var correctDate = JSON.stringify(correct);
        str += correctDate.substr(1,correctDate.indexOf("T")-1);
      }
      else if( msg.Days == 'Every Day')
      {
        str += this.t("Каждый день");
      }
      else if( msg.Days == 'Weekends' )
      {
        str+=this.t("Выходные дни");
      }
      else if(msg.Days=="Workdays")
      {
        str += this.t("Рабочие дни");
      }
      else if( msg.Days == "Every Month" )
      {
        str += this.t("Каждый") + " ";
        var x =+ msg.Date.substr(8,2)+1
        str += x;
        str += " " + this.t("день месяца");
      }
      else
      {
        var arr = msg.Days.split(";");
        for(var i=0;i<arr.length-1;i++)
        {
          var days = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];
          str += this.t(days[+arr[i].replace(";","")]) + ", ";
        }
        str = str.substr(0,str.length-2);
      }
      str+=" " + msg.Time;*/

        var onChangeMsg = () => this.changeMsg(msg);

        return (
          <div key={msg._id} onClick={onChangeMsg} className="tags-cell">
            {msg.Status == "1" ? <span className="tags_off"></span> : null}

            <span>{msg.Name}</span>
          </div>
        );
      }.bind(this)
    );

    return (
      <div className="broadcast_left_panel dashboard-page__col--1">
        <Scrollbars
          style={{
            height: "100%",
          }}
        >
          <div className="broadcast_left_panel_post">
            {bot && ['Telegram'].includes(bot.BindTo)
              ? this.showStatisticButton() : null}
            {bot && ['Vk','Ok','Telegram'].includes(bot.BindTo) ? this.importExport() : null}
            {bot && ['Viber'].includes(bot.BindTo)
            && [
              '5a77a02f1b22160bf6bee459',
              '623d7fa6f61f6d3c5b9948bc',
              '5a7213c45790c43d60ea50e1'
            ].includes((cookies.get('Id') || '').toString())
                ? this.importExport() : null}

            <div className="post_message_now">
              <span className="post_title">
                {this.t("Отправить сообщение сейчас")}
              </span>

              <div className="tags">
                {this.state.AllContent ? nowMsgs : null}
                {this.state.AllContent ? (
                  <div onClick={this.addNowMsg} className="tags-cell">
                    <span className="post_add_button"></span>
                  </div>
                ) : null}
              </div>
            </div>

            <div className="post_add_trigger">
              <span className="post_title">
                {this.t("Реакция на сообщение")}
              </span>

              <div className="tags">
                {this.state.AllContent ? triggerMsgs : null}
                {this.state.AllContent ? (
                  <div onClick={this.addTriMsg} className="tags-cell">
                    <span className="post_add_button"></span>
                  </div>
                ) : null}
              </div>
            </div>

            <div className="post_schedule_later">
              <span className="post_title">
                {this.t("Сообщения по расписанию")}
              </span>

              <div className="tags">
                {this.state.AllContent ? schMsgs : null}
                {this.state.AllContent ? (
                  <div onClick={this.addSchMsg} className="tags-cell">
                    <span className="post_add_button"></span>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </Scrollbars>
      </div>
    );
  }

  scrollFilterUsers = async () => {
    const container = this.filterUsersContainer.scrollTop * 2;
    const height = this.filterUsersContainer.scrollHeight;

    if ((height - container) <= 100 && this.state.FilterUsersLoadMoreEnable) {
      console.log(this.state.FilterUsersLoadMoreEnable)
      await this.setState({
        page: this.state.page + 1,
        FilterUsersLoadMoreEnable: false
      });
      await this.loadFilterUsers(this.state.page)
    }
  }

  async loadFilterUsers(page = 1) {
    const params = {
      params: {
        bot: this.state.CurrentMsg.Bot,
        msg: this.state.CurrentMsg._id,
        filters: this.state.CurrentMsg.Filters,
        page: page
      }
    };
    const { data } = await axios.get(config.Backend + '/bot/msg/usersCountByFilter', params);
    let users = this.state.FilterUsers;
    await this.setState({
      FilterUsersCount: data.count,
      AllUsersCount: data.all,
      FilterUsersLoadMoreEnable: data.users.length > 0
    });

    if (page > 1) {
      data.users.map((u) => users.push(u));
    } else {
      users = data.users;
    }

    await this.setState({
      FilterUsers: users
    });
  }

  changeMsg(msg) {
    this.setState(
      {
        CurrentMsg: {
          ...msg,
        },
      },
      async () => {
        await this.loadFilterUsers();
        await this.changeMain(msg ? msg.Type : undefined);
      }
    );
  }

  async changeMain(newMain) {
    await this.setState({
      Main: newMain,
    });
  }

  checkRightsAndLoadBotUsersAndMsgs = () => {
    if (
      !config.checkRights(
        cookies.get("Id").toString(),
        this.props.bot,
        "Broadcast"
      )
    ) {
      config.accessDenied(cookies.get("Id").toString(), this.props.bot);
    }

    var route = "fb";
    if (this.props.bot.BindTo == "Telegram") {
      route = "telegram";
    } else if (this.props.bot.BindTo == "Ok") {
      route = "ok";
    } else if (this.props.bot.BindTo == "Vk") {
      route = "vk";
    } else if (this.props.bot.BindTo == "Viber") {
      route = "viber";
    } else if (this.props.bot.BindTo  == 'WhatsApp' ) {
      route = 'whatsapp';
    }
    var url =
      config.Backend + "/" + route + "/getUsers?BotId=" + this.props.bot._id;
    if (!(cookies.get("Id") === undefined)) {
      url += "&CurrentUserId=" + cookies.get("Id").toString();
    }
    this.addWait();

    jQuery.getJSON(
      url,
      function (res) {
        this.delWait();

        if (!res.Error) {
          this.setState({ BotUsers: res });
        }
      }.bind(this)
    );
    this.setState({ Show: true });
    this.loadMsgs(this.props.bot);
  };

  componentDidUpdate(prevProps) {
    if (this.props.bot && !prevProps.bot) {
      this.checkRightsAndLoadBotUsersAndMsgs();
    }
  }

  componentDidMount() {
    this.t_load();

    if (!this.props.groups) {
      this.loadGroups();
    }

    func.getData("crm/all_rows").then(
      (ok) => {
        this.setState({ CRMorders: ok });
      }
    );

    func.getData("crm/tables").then(
      (ok) => {
        this.setState({
          CRMtables: ok,
        });
      }
    );

    if (this.props.bot) {
      this.checkRightsAndLoadBotUsersAndMsgs();
    }
  }

  handleInputChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleInputFocus(event) {
    this.setState({
      [event.target.name + "_valid"]: true,
    });
  }

  NewBtn() {
    this.setState((prevState) => ({
      show: !prevState.show,
    }));
  }

  Close() {
    this.setState((prevState) => ({
      show: !prevState.show,
      close: !prevState.close,
    }));
  }

  BottomStyle() {
    this.Close();
    this.setState({
      stripBottom: "50px",
    });
  }

  loadGroups = () => {
    var url = config.Backend + "/bot/groups?";
    if (!(cookies.get("Id") === undefined)) {
      url += "&CurrentUserId=" + cookies.get("Id").toString();
    }
    url += "&Id=" + cookies.get("BotId").toString();

    jQuery.getJSON(
      url,
      function (res) {
        if (!res.Error) {
          this.props.addGroupsToStore({
            groups: res,
            botId: cookies.get("BotId").toString(),
          });
        }
      }.bind(this)
    );
  };

  PopupManual() {
    var s = this.state;
    var t = func.get_tutorial(
      this.props.user && this.props.user.Tutorial,
      "Broadcast"
    );
    return (
      <div className={"block_manual_icon"}>
        <div
          className={
            this.state.show
              ? "manual__popup__broadcast popup_vs u-pad-y-xxl u-relative"
              : "manual__popup__broadcast  u-pad-y-xxl u-relative"
          }
        >
          <div className="close_popup_btn">
            <button type="button" className="close" onClick={this.Close}>
              <span aria-hidden="true">×</span>
            </button>
          </div>

          <div className="content_manual_popup">
            <button
              className={"btn__all--small mr5"}
              onClick={() => this.refreshTutorial("Broadcast", 0)}
            >
              Заново
            </button>

            <button
              className={"btn__all--small"}
              onClick={() => this.refreshTutorial("Broadcast", 99)}
            >
              Завершить обучение
            </button>
          </div>
          {t.Step == 0 ? (
            <div className="content_manual_popup">
              <p>
                {this.t("Нажмите на")}
                <span className={"fw_menu"}>плюс</span>, чтобы добавить
                рассылаемое сообщение.
              </p>
            </div>
          ) : null}

          {/* или сделать  по выполненным действиям, или как вариант просто добавить кнопку "Далее"*/}
          {/* <button className={'btn__all--small'}>Далее</button>*/}

          {t.Step == 1 ? (
            <div className="content_manual_popup">
              <p>
                Введите текст и нажмите на кнопку
                <span>Сохранить</span>
              </p>
            </div>
          ) : null}

          {t.Step == 2 ? (
            <div className="content_manual_popup">
              <p>
                Нажмите на кнопку
                <span className={"fw_menu"}>отправить</span>
              </p>
            </div>
          ) : null}

          {t.Step == 3 ? (
            <div className="content_manual_popup">
              <p>
                Проверьте аккаунты, которые писали боту, им должно было придти
                сообщение.
              </p>
            </div>
          ) : null}

          {t.Step == 3 ? (
            <div className="content_manual_popup">
              <p>
                Вы можете подготовить шаблоны рассылок и отправлять сообщения по
                необходимости. <br />С остальными возможностями рассылки вы
                можете ознакомится в технической документации.
              </p>
            </div>
          ) : null}
        </div>
      </div>
    );
  }

  render() {
    var s = this.state;
    var t = func.get_tutorial(
      this.props.user && this.props.user.Tutorial,
      "Broadcast"
    );

    if (
      this.props.user &&
      this.props.user.Tariff &&
      !this.props.user.Tariff.EnableBroadcast
    ) {
      window.location.replace(window.location.origin + "/dashboard");
    }

    return (
      <div>
        <TopPanel />
        {this.state.Wait != 0 ? this.Load() : null}
        {this.state.Show ? (
          <div className="b-page">
            <div className="b-page__row">
              {t && t.Step < 50 ? this.PopupManual() : null}
              {/* После первого клика на иконку появится попап, после иконка пропадает, остается только попап с инструкцией*/}
              {s.BroadcastMsgs.filter((botMsg) => botMsg.Type === "Now")
                .length == 0 &&
              t &&
              t.Step < 50 &&
              !s.show ? (
                <button
                  className={"manual__btn_broadcast"}
                  onClick={this.NewBtn}
                ></button>
              ) : null}

              {this.state.Show ? <LeftPanel User={this.props.user} /> : null}
              <div className="b-page__content-wrap">
                <div className="dashboard-page">
                  <div className="dashboard-page__items">
                    {this.BroadcastLeftPanel()}
                    <BroadcastCentrPanel
                      loadMsgs={this.loadMsgs}
                      setupBotMsg={this.setupBotMsg}
                      changeMsg={this.changeMsg}
                      changeMain={this.changeMain}
                      loadContent={this.loadContent}
                      DeleteMsg={this.deleteMsg}
                      Saved={() => {
                        return this.state.Saved;
                      }}
                      ChangeSave={this.changeSave}
                      setTutorialStep={this.setTutorialStep}
                      BroadcastMsgs={this.state.BroadcastMsgs}
                      OriginalBroadcastMsgs={this.state.OriginalBroadcastMsgs}
                      AllContent={this.state.AllContent}
                      BotUsers={this.state.BotUsers}
                      deleteContentFromAllContent={
                        this.deleteContentFromAllContent
                      }
                      addContentToAllContent={this.addContentToAllContent}
                      addWait={this.addWait}
                      delWait={this.delWait}
                      ShowDelPopup={() => {
                        this.setState({ ShowDeletebotMsgPopup: true });
                      }}
                      ShowUserFilter={(filterUsersCount, allUsersCount) => {
                        this.setState({
                          ShowUserFilter: true,
                          FilterUsersCount: filterUsersCount,
                          AllUsersCount: allUsersCount
                        });
                      }}
                      BotMsg={this.state.CurrentMsg}
                      groups={this.props.groups}
                      user={this.props.user}
                      bot={this.props.bot}
                      Main={this.state.Main}
                      BroadcastStat={this.state.BroadcastStat}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {this.state.ShowDeletebotMsgPopup ? (
          <Popup
            text={"Вы действительно хотите удалить?"}
            FromBroadcast={true}
            Yes={() => this.deleteMsg(this.state.CurrentMsg)}
            No={() => {
              this.setState({ ShowDeletebotMsgPopup: false });
            }}
          />
        ) : null}
        {this.state.ShowUserFilter ? this.BroadCastFilter() : null}
        <PopupAll />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
    groups: state.bots.groups[cookies.get("BotId").toString()],
    bot: (state.bots.bots || []).find(
      (bot) => bot._id === cookies.get("BotId").toString()
    ),
  };
};
const mapDispatchToProps = {
  addGroupsToStore: setGroups,
  editUserInStore: successUser,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAlert()(withRouter(Broadcast)));
