import {
  SIGN_IN_FAILURE,
  SIGN_IN_REQUEST,
  SIGN_IN_SUCCESS,
} from './constains';

const initialState = {
  loading: false,
  success: false,
  error: false,
  errorMessage: '',
  ok: '',

};

export const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGN_IN_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
        error: false,
        errorMessage: '',
        ok: '',
      };

    case SIGN_IN_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        ok: action.ok,
      };

    case SIGN_IN_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    default:
      return state;
  }

};
