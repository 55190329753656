import axios, { Method } from 'axios';
import { config } from '../config.js';

export const METHOD_GET = 'get';
export const METHOD_POST = 'post';
export const METHOD_PUT = 'put';
export const METHOD_DELETE = 'delete';


export const requestBuilder = (
  path,
  method,
  data,
) => axios.request({
  baseURL: config.Backend,
  method,
  [method === METHOD_GET ? 'params' : 'data']: data,
  url: path,
});
