import React, {Component} from 'react';
import jQuery from 'jquery';
import Cookies from 'universal-cookie';
import {slide as Menu} from 'react-burger-menu';
import Collapsible from 'react-collapsible';
import {Link, Route, useRouteMatch, Redirect, useHistory, withRouter } from "react-router-dom";
import {setUser} from '../../modules/User/actions';
import {addBotSuccess, editBotSuccess} from '../../modules/Bots/actions';
import {connect} from "react-redux";
import { config } from '../../config.js';
import { Logo } from "./components/Logo/Logo";
import './style.scss';
import { Button } from "./components/Button/Button";

const cookies = new Cookies();

class TopPanel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      Group: this.props.Group,
    };
  }

  onExitHandler = (event) => {
    cookies.set('Exit',"true", {
      path: '/'
    });
    window.location.replace(window.location.origin + '/login');
  };

  componentDidMount () {
    // this.t_load();

    if (!this.props.user) {
      this.props.setUser();
    }

    if (!config.botIsFullyLoad(this.props.bot)) {

      var url = config.Backend + '/bot/findById?';

      if (!(cookies.get('Id') === undefined)) {
        url += 'CurrentUserId=' + cookies.get('Id').toString();
      }
      if (!(cookies.get('BotId') === undefined)) {
        url += '&Id=' + cookies.get('BotId').toString();

        jQuery.getJSON(url, function (res) {
          if ( !res.Error ) {
            if (this.props.bot) {
              this.props.editBotInStore(res);
            } else {
              this.props.addBotToStore(res);
            }
          }
        }.bind(this));
      }
    }
  }

  TariffIsEnd () {
    var u = this.props.user;
    var work = u && u.Tariff && u.TariffEnd && new Date(u.TariffEnd) > new Date();

    if (work || !u) {
      return (
        <div className="topnav_center"></div>
      );
    }
    return (
      <div className="topnav_center col-lg-6">
        <h2>
          Работа Ваших ботов временно преостановлена.Для возобновления подключите
          <Link to="/tariffs">тариф</Link>
        </h2>
      </div>
    );
  }

  onChangeLangHandler = (val) => {
    cookies.set("Lang", val.value, {
      path: "/"
    });
    window.location.reload();
  };

  render () {
    var link = (window.location.href);
    var s = this.state;
    var showBot = true;

    var arr = ['tariffs', 'profile', 'media', 'partner', 'support', 'crm', 'payment'];
    if (config.indexOfArr(link, arr) !== -1) {
      showBot = false;
    }

    let linkTestingBot = window.location.origin + '/chat/configure';
    let bot = this.props.bot;
    let user = this.props.user;

    if (bot && user) {
      if (bot.BindTo === 'Vk') {
        linkTestingBot = 'https://vk.com/im?sel=-' + bot.VkPage;
      }
      if (bot.BindTo === 'Ok') {
        linkTestingBot = 'https://ok.ru/messages';
      }
      if (bot.BindTo === 'Telegram') {
        linkTestingBot = 'https://web.telegram.org/#/im?p=@' + bot.TelegramName;
      }
      if (bot.BindTo === 'FB') {
        linkTestingBot = 'https://www.messenger.com/t/' + bot.Page;
      }
      if (bot.BindTo === 'Viber') {
        linkTestingBot = 'viber://pa?chatURI=' + bot.ViberUri;
      }
      if (bot.BindTo === 'Alisa') {
        linkTestingBot = 'https://dialogs.yandex.ru/developer/skills/' + bot.AlisaSkillId + '/draft/test';
      }
      if (bot.BindTo === 'WhatsApp') {
        linkTestingBot = bot.WhatsAppTestUrl;
      }
    }

    let langs = ( s.TLangs || []).map(e => {
      return {
        value: e.Slug,
        label: e.Slug
      };
    });

    let text = this.props.Group !== undefined ?
      this.props.Group : bot && showBot ? bot.Name : '';

    return (
      <div className="topnav">
        <Menu>
          <ul className={"menu_left_chatbot"}>
            <Collapsible trigger="Боты">
              <li>
                <Link className={"menu-item_lvl fw_menu  icon_menu_panel create"}
                  to={"/dashboard?create=1"}>
                  Создать
                </Link>
                {/*<a className={"menu-item_lvl"}  href={"#"}
                onClick={()=>this.setState({Creating:true,TemplateBotIndex:-1},this.handleClick)
                }>Создать</a>*/}
              </li>

              <li>
                <Link className={"menu-item_lvl fw_menu control_icon "} to={"/dashboard"}>
                  Управлять
                </Link>
                {/*<a className={"menu-item_lvl"} href={"#"}
                onClick={()=>this.setState({Creating:false,TemplateBotIndex:-1},
                this.handleClick)}>Управлять</a>*/}
              </li>
            </Collapsible>

            {/*<Collapsible trigger={this.t("Партнерская программа")}*/}
              {/*classParentString={"partner_menu"}>*/}
              {/*<li>*/}
                {/*<a className={"menu-item_lvl fw_menu  icon_menu_panel  referal"}*/}
                   {/*href="/partner">*/}
                  {/*{this.t("Реферальная система")}*/}
                {/*</a>*/}
              {/*</li>*/}

            {/*<Collapsible trigger={this.t("Партнерская программа")}*/}
            {/*  classParentString={"partner_menu"}>*/}
            {/*  <li>*/}
            {/*    <Link className={"menu-item_lvl fw_menu  icon_menu_panel  referal"}*/}
            {/*       to="/partner">*/}
            {/*      {this.t("Реферальная система")}*/}
            {/*    </Link>*/}
            {/*  </li>*/}

            <li>
              <Link className="menu-item package_menu" to="/tariffs">Тарифы</Link>
            </li>

            <li>
              <Link id="contact" className="menu-item setting_menu" to="/profile/setting">
                Личный кабинет
              </Link>
            </li>

            {/*<li>*/}
              {/*<a target={"_blank"} className="menu-item help_menu"*/}
                 {/*href="https://botkits.ru/manual.html">Помощь</a>*/}
            {/*</li>*/}

            {user && user.Tariff && user.Tariff.CRM ?
              <li>
                <Link id="contact" className="menu-item crm_menu" to="/crm">CRM</Link>
              </li>
              : null}

            {/*<li>*/}
            {/*  <Link className="menu-item url_menu" to="/chat/url">{this.t("Система коротких url")}</Link>*/}
            {/*</li>*/}

            {/*<li>*/}
            {/*  <Link className="menu-item widget_menu" to="/widget">{this.t("Создать виджет")}</Link>*/}
            {/*</li>*/}

            <li>
              <Link className="menu-item exit_menu" to={"#"} onClick={this.onExitHandler}>
                Выход
              </Link>
            </li>
          </ul>
        </Menu>

        <div className="topnav_left">
          <Logo/>

          <div className="name_block">
            <div className="bot_top_preview">

              <span style={{
                width: "300px"
              }}>{text}</span>
            </div>
          </div>
        </div>

        {this.state.User ? this.TariffIsEnd() : null}

         <div className="topnav_right">
          <div className="row__grid w-100 justify__content--end">
            <div className="mr15 row__grid">

              <div className="col__7">
                {bot && showBot ?
                  <Button link={linkTestingBot} />
                  : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
    bot: (state.bots.bots || []).find(bot => bot._id === cookies.get('BotId')),
  };
};

const mapDispatchToProps = {
  setUser: setUser,
  addBotToStore: addBotSuccess,
  editBotInStore: editBotSuccess,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TopPanel));

