import React, { useEffect, useState } from "react";
import { Button } from "./Button/Button";

const config = {
  tariffs: [
    {
      name: 'Старт',
      amount: 39000
    },
    {
      name: 'Стандарт',
      amount: 79000
    },
    {
      name: 'Бизнес',
      amount: 139000
    },
  ],
  tap2payProductIds: {
    'Старт': 'Dkgxdf5L',
    'Стандарт': 'hohW4Foz',
    'Бизнес': 'KG7jZ5SG',
  }
};

const generateTap2PayCheckoutLink = (productId) => `https://secure.tap2pay.me/products/${productId}/checkout`;

const PaymentModal = ({ onClose }) => {
  const [chosenPrice, setChosenPrice] = useState(null);

  const isFirstStepDone = chosenPrice !== null;
  const isAllStepsDone = isFirstStepDone

  const getPrice = () => {
    const tariff = config.tariffs[chosenPrice];

    return isAllStepsDone ? Math.round(tariff.amount / 100) * 100 : 0;
  }

  const onEventPaymentRedirect = () => {
    const tariff = config.tariffs[chosenPrice];

    window.open(
      generateTap2PayCheckoutLink(config.tap2payProductIds[tariff.name]),
      '_blank'
    );
  }

  return (
    <div>
      <div className="preloader_bots">
        <div className="popupW payment-modal">
          <div className="">
            <div className="payment-modal-main">
              <h1 className="payment-modal-title">Подписаться</h1>
              <div className="buttons-list-container mb20">
                <img
                  src="/img/payment-modal.png"
                  alt=""
                  className="payment-logo"
                  width={205}
                  height={169}
                />
                <div className="text-container">
                  <span>Выберите тариф</span>
                  <img
                    src="/img/checkMark.svg"
                    width={27}
                    height={27}
                    alt=""
                    className={`check-mark ${isFirstStepDone ? "" : "check-mark-hidden"}`}
                  />
                </div>
                <div className="buttons-list">
                  {config.tariffs.map((tariff, i) =>
                    <button
                      key={i}
                      onClick={() => setChosenPrice(i)}
                      className={`tariff-button ${chosenPrice === i ? "tariff-button-active" : ""}`}
                    >
                      <span>{tariff.name}</span>
                      <div>
                        <span className="payment-number">{tariff.amount / 100}</span>
                        {' '}
                        <span className="payment-currency">руб</span>
                      </div>

                    </button>
                  )}
                </div>
                <div className="price-description">
                  <div>Цены на тарифы указаны за 1 месяц пользования.</div>

                  <span className="sub-description">
                    Оплата будет автоматически взиматься каждый месяц до тех пор, пока вы не отмените подписку.
                  </span>
                </div>
              </div>
            </div>
            <div className="payment-footer">
              <div className="payment-footer-content">
                <div className={`payment-amount-container ${isAllStepsDone ? 'payment-amount-container-active' : ''}`}>
                  <span className="payment-to-invoice">
                   К оплате:
                  </span>
                  {' '}
                  <span className="payment-to-invoice-amount">
                    {getPrice() / 100}
                  </span>
                  {' '}
                  <span className="payment-to-invoice-amount-currency">
                    руб
                  </span>
                </div>

                <div style={{display: 'flex'}}>
                  <div style={{marginRight: '20px'}}>

                    <Button
                      activeDisabled={!isAllStepsDone}
                      name="Оплатить через"
                      action={onEventPaymentRedirect}
                      className={`link ${isAllStepsDone ? 'payment-button-active' : null}`}
                      backgroundColor="blue"
                      icon={
                        <img
                          src="/img/payment_btn-icon.svg"
                          width={205}
                          height={169}
                        />
                      }/>
                  </div>

                  <Button
                    backgroundColor="white"
                    name='Запросить счет'
                    className={`${isAllStepsDone ? 'payment-button-active' : null}`}
                    action={() => window.open('https://b24-rp2o78.bitrix24site.by/crm_form5/') }
                    activeDisabled={!isAllStepsDone}/>

                </div>

              </div>
            </div>
            <div className="close_popup_btn">
              <button onClick={onClose} type="button" className="close close-payment-modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentModal;
