import React, {Component} from 'react';
import jQuery from 'jquery';
import {Bar, Line} from 'react-chartjs-2';
import AdminLeft from '../AdminLeft';
import {Scrollbars} from 'react-custom-scrollbars';
import Cookies from 'universal-cookie';
import {Link} from "react-router-dom";
import { config } from '../../config.js';

const cookies = new Cookies();

class MainAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      User: {},
      TemplateBotsData: [],
      DefaultBotsData: [],
      MonthBotsData: [],
      MonthCnt: 8,
      Visits: 0,
      UniqueVisits: 0,
      Users: 0
    }
  }

  componentDidMount() {
    var url = config.Backend + '/users/findById?';
    if (!(cookies.get('Id') === undefined)) {
      url += 'Id=' + cookies.get('Id').toString();
    }

    jQuery.getJSON(url, function (res) {
      if (!res.Error && res.Type == "Super") {
        this.setState({
          User: res
        }, () => {
          var soc = ['FB', 'Vk', 'Ok', 'Telegram', 'Viber'];

          for (var g = 0; g < soc.length; g++) {
            const name = soc[g];
            var url = config.Backend + '/bot/socBotStat?';
            url += '&Soc=' + name;
            if (!(cookies.get('Id') === undefined)) {
              url += '&CurrentUserId=' + cookies.get('Id').toString();
            }
            jQuery.getJSON(url, function (res) {
              if (!res.Error) {
                //alert(JSON.stringify(res));
                this.state.DefaultBotsData.push({
                  Name: name,
                  Cnt: res.Data
                });
                this.setState({
                  DefaultBotsData: this.state.DefaultBotsData
                })
              } else {
                alert(JSON.stringify(res));
              }
            }.bind(this));
          }

          url = config.Backend + '/bot/dftBotStat?';
          if (!(cookies.get('Id') === undefined)) {
            url += '&CurrentUserId=' + cookies.get('Id').toString();
          }
          jQuery.getJSON(url, function (res) {
            if (!res.Error) {
              //alert(JSON.stringify(res));
              this.setState({
                TemplateBotsData: res.Data
              })
            } else {
              alert(JSON.stringify(res));
            }
          }.bind(this));

          var now = new Date();
          for (var i = 0; i < this.state.MonthCnt; i++) {
            this.state.MonthBotsData.push(-1);
          }

          for (var i = now.getMonth() - this.state.MonthCnt + 1, k = 0;
               i < now.getMonth() + 1; i++, k++) {
            var month = i > 11 ? i - 12 : i;
            month = month < 0 ? 12 + i : month;
            const ind = k;
            var getData = config.Backend + '/bot/getBotsData?';
            if (!(cookies.get('Id') === undefined)) {
              getData += '&CurrentUserId=' + cookies.get('Id').toString();
            }
            if (i >= 0 && i <= 11) {
              getData += "&Year=" + now.getFullYear();
            } else if (i > 11) {
              getData += "&Year=" + (now.getFullYear() + 1);
            }
            else {
              getData += "&Year=" + (now.getFullYear() - 1);
            }
            getData += "&Month=" + month;
            jQuery.getJSON(getData, function (res) {
              if (!res.Error) {
                this.state.MonthBotsData[ind] = (res.Bots);
                this.setState({
                  MonthBotsData: this.state.MonthBotsData
                });
              }
              else alert(JSON.stringify(res));
            }.bind(this));
          }
        });
      } else {
        window.location.replace(window.location.origin + '/login');
      }
    }.bind(this));

    url = config.Backend + '/users/cnt';
    jQuery.getJSON(url, function (res) {
      this.setState({
        Users: res.Data
      });
    }.bind(this));
    url = config.Backend + '/users/visits';
    jQuery.getJSON(url, function (res) {
      this.setState({
        Visits: res.Cnt
      });
      this.setState({
        UniqueVisits: res.Unique
      })
    }.bind(this));
  }

  AllTemplateBots(data) {
    return (
        <div className="chart_block">
          Боты созданные на основе шаблонов


          {data.map(({ Name, Cnt }) => (<div>
            {Name}: {Cnt}
          </div>))}
        </div>
    );
  }

  AllDefaultBots(data) {
    var data = {
      labels: data.map(e => e.Name),
      datasets: [
        {
          label: 'Боты по умолчанию',
          backgroundColor: 'rgba(255,99,132,0.2)',
          borderColor: 'rgba(255,99,132,1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(255,99,132,0.4)',
          hoverBorderColor: 'rgba(255,99,132,1)',
          data: data.map(e => e.Cnt)
        }
      ]
    };
    return (
      <div className="chart_block">
        <Bar
          data={data}
          width={100}
          height={250}
          options={{
            maintainAspectRatio: false
          }}/>
      </div>
    );
  }

  AllBots(labels, data) {
    var dataLine = {
      labels: labels,
      datasets: [
        {
          label: 'Все боты',
          fill: false,
          lineTension: 0.1,
          backgroundColor: 'rgba(75,192,192,0.4)',
          borderColor: 'rgba(75,192,192,1)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(75,192,192,1)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(75,192,192,1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: data
        }
      ]
    };
    return (
      <div>
        <h3>Все боты</h3>

        <Line data={dataLine}/>
      </div>
    );
  }

  AllMoney() {
    const data = {
      labels: ['Январь', 'Фервраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль',
        ' Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
      datasets: [
        {
          label: 'Earned',
          backgroundColor: 'rgba(255,99,132,0.2)',
          borderColor: 'rgba(255,99,132,1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(255,99,132,0.4)',
          hoverBorderColor: 'rgba(255,99,132,1)',
          data: [65, 59, 80, 881, 56, 55, 140, 44, 12, 56, 120, 30]
        }
      ]
    };

    return (
      <div>
        <Bar
          data={data}
          width={100}
          height={250}
          options={{
            maintainAspectRatio: false
          }}/>
      </div>
    );
  }

  AdminTop() {
    return (
      <div className="admin_top">
        <div className="admin_logo_top">
          <Link to="/admin/main"></Link>
        </div>
      </div>
    );
  }

  render () {
    var labels = [];
    var now = new Date().getMonth();
    var months = ['Январь', 'Фервраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль',
      ' Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];
    for (var i = now - this.state.MonthCnt + 1; i < now + 1; i++) {
      var x = i > 11 ? i - 12 : i;
      x = x < 0 ? 12 + i : x;
      labels.push(months[x]);
    }

    var ShowBots = true;
    for (var i = 0; i < this.state.MonthCnt; i++) {
      if (this.state.MonthBotsData[i] == -1)
        ShowBots = false;
    }
    var ShowDefaultBots = this.state.DefaultBotsData.length > 0;
    var ShowTemplateBots = this.state.TemplateBotsData.length > 0;

    return (
      <div>
        <div className="b-page">
          <div className="b-page__row">
            <AdminLeft/>

            <div className="b-page__content-wrap pl" style={{
              height: "calc(100vh)"
            }}>
              <Scrollbars style={{
                height: "100%"
              }}>
                <div className="dashboard-page">
                  <div className="dashboard-page__items">
                    {ShowDefaultBots ?
                      <div className="chart_main bd pd-t-30 pd-b-20 pd-x-20 form-group">
                        {this.AllDefaultBots(this.state.DefaultBotsData)}
                      </div>
                      : null}

                    {ShowTemplateBots ?
                        <div className="chart_main bd pd-t-30 pd-b-20 pd-x-20 form-group">
                          {this.AllTemplateBots(this.state.TemplateBotsData,)}
                        </div>
                        : null}

                    <div className="row">
                      {ShowBots ?
                        <div className="col-sm-6">
                          <div className="bd pd-t-30 pd-b-20 pd-x-20 form-group">
                            {this.AllBots(labels, this.state.MonthBotsData)}
                          </div>
                        </div> : null}

                      <div className="col-sm-6">
                        <div className="bd pd-t-30 pd-b-20 pd-x-20 form-group">
                          <ul className="list-group">
                            <li className="list-group-item">
                              <span className="badge">
                                <span className={"fw__panel"}>{this.state.Visits}</span>
                              </span>

                              <span className={"ml5"}>Количество посетителей</span>
                            </li>

                            <li className="list-group-item">
                              <span className="badge">
                                <span className={"fw__panel"}>{this.state.UniqueVisits}</span>
                              </span>
                              <span className={"ml5"}>Количество уникальных посетителей</span>
                            </li>

                            <li className="list-group-item">
                              <span className="badge">
                                <span className={"fw__panel"}>{this.state.Users}</span>
                              </span>

                              <span className={"ml5"}>
                                Количество зарегистрированных посетителей
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Scrollbars>
            </div>
          </div>
        </div>
      </div>
    );
  }

}

export default MainAdmin;







