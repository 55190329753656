import React from "react";
import Cookies from "universal-cookie";
import { WithContext as ReactTags } from "react-tag-input";
import SelectPlus from "react-select-plus";
import { TooltipTitle } from "../../../components/TooltipTitle/TooltipTitle";
import { prepareLinkAllBlocksOptions } from "../../../func";

const cookies = new Cookies();

class Tags extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tags: [],
      Link: this.props.Link,
      Index: this.props.Index,
      AnswerAs: 'Text',
    };
    this.t =
      this.props.t ||
      ((x) => {
        return x;
      });

    this.handleDelete = this.handleDelete.bind(this);
    this.handleAddition = this.handleAddition.bind(this);
    this.handleDrag = this.handleDrag.bind(this);
    this.txtOrBlockChange = this.txtOrBlockChange.bind(this);
  }

  txtOrBlockChange({ target: { value } }) {
    this.setState({
      AnswerAs: value
    });

    if (value === 'Text') {
      this.setState({ Link: '' });
      this.props.linkChanged(+this.props.Index, "", 'Text');
    } else if (value === 'Group') {
      this.setState({ Link: this.props.Groups[0]._id });
      this.props.linkChanged(+this.props.Index, this.props.Groups[0]._id, 'Group');
    } else if (value === 'BotMsg') {
      this.setState({ Link: '' });
      this.props.linkChanged(+this.props.Index, '', 'BotMsg');
    }
  }

  onChangeLinkInput = ({ target: { value, name }}) => {
    this.setState({ Link: value });
    this.props.linkChanged(+this.props.Index, value, name);
  }

  componentDidMount() {
    const {
      Link,
      Type,
      Groups,
      AiLink
    } = this.props;

    for (var i = 0; i < AiLink.Tags.length; i++) {
      this.state.tags.push({
        id: i,
        text: AiLink.Tags[i]
      });
    }

    if (Type) {
      this.setState({ AnswerAs: Type });
    } else {
      if (Groups.some(({ _id }) => Link === _id)) {
        this.setState({ AnswerAs: 'Group' });
      } else {
        this.setState({ AnswerAs: 'Text' });
      }
    }
  }

  handleDelete(i) {
    let tags = this.state.tags;
    tags.splice(i, 1);
    this.setState({ tags: tags });
    this.props.onDeleteTag(this.state.Index, i);
  }

  handleAddition(tag) {
    let tags = this.state.tags;
    let ok = true;
    for (let i = 0; i < tags.length; i++) {
      if (tags[i].text == tag) ok = false;
    }
    if (ok) {
      tags.push({
        id: tags.length + 1,
        text: tag,
      });
      this.setState({ tags: tags });
      this.props.onAddTag(this.state.Index, tag);
    }
  }

  handleDrag(tag, currPos, newPos) {
    let tags = this.state.tags;

    // mutate array
    tags.splice(currPos, 1);
    tags.splice(newPos, 0, tag);

    // re-render
    this.setState({ tags: tags });
  }

  Condition() {
    const botMsgsOptions = prepareLinkAllBlocksOptions(
      JSON.parse(JSON.stringify(this.props.AllMsgs))
    );

    const options = this.props.Groups.map((g) => (
      <option key={g._id} value={g._id}>
        {g.Name + "(#" + g._id + ")"}
      </option>
    ));
    const { tags } = this.state;

    return (
      <div className="condition EditMessage">
        <span className="condition_over mt_15">
          <div className="condition_left">
            <TooltipTitle
              idTooltip="i_Tags"
              name="Тэги"
              tooltipDescription="Если в сообщении бот встретит один из тэгов(регистр не учитывается)
                то он ответит либо стартовым сообщением группы,либо текстом.
                 Для ввода тэга введите слово или фразу а затем нажмите Enter или Tab на клавиатуре."
            />
          </div>
        </span>

        <div className="mb10 manual__tags">
          <div className="manual_">
            <ReactTags
              type="text"
              className="tags_manual"
              maxLength="30"
              tags={tags}
              handleDelete={this.handleDelete}
              handleAddition={this.handleAddition}
              placeholder="Введите слово или фразу человека"
            />
          </div>
        </div>
        <span className="condition_over mt_15">
          <div className="condition_left">
            <TooltipTitle
              idTooltip="answer_bots"
              name="Ответное действие бота"
              tooltipDescription="Сообщение которое ответит бот, на введеные Вами теги."
            />
          </div>
          <select
            className="chat_input borderR"
            name="TextOrBlock"
            value={this.state.AnswerAs}
            onChange={this.txtOrBlockChange}
          >
            <option value="Text">Текстом</option>
            <option value="Group">Группой</option>
            <option value="BotMsg">Блоком</option>
          </select>
        </span>

        {this.state.AnswerAs === 'Text' && (
          <input
            className="borderR chat_input"
            type="text"
            placeholder="Введите ответ бота"
            name="Text"
            value={this.state.Link}
            onChange={this.onChangeLinkInput}
          />
        )}

        {this.state.AnswerAs === 'Group' && (
          <select
            className="chat_input borderR"
            name="Group"
            value={this.state.Link}
            onChange={this.onChangeLinkInput}
          >
            {options}
          </select>
        )}

        {this.state.AnswerAs === 'BotMsg' && (
          <SelectPlus
            name="Url"
            value={this.state.Link}
            onChange={option => this.onChangeLinkInput({
              target: {
                value: option ? option.value : '',
                name: 'BotMsg'
              }
            })}
            options={botMsgsOptions}
          />
        )}
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.Condition()}
        <div className="button_delete_block">
          <span
            className="right_delete_icon"
            onClick={() => this.props.onDeleteLink(this.state.Index)}
          ></span>
        </div>
      </div>
    );
  }
}

export default Tags;
