import React, { Component } from "react";
import jQuery from "jquery";
import Cookies from "universal-cookie";
import FacebookLogin from "react-facebook-login";
import TransComponent from "../../TransComponent.js";
import { Link } from "react-router-dom";
import { config } from "../../config.js";
import { message } from "antd";

const cookies = new Cookies();

class RegisterForm extends TransComponent {
  constructor(props) {
    super(props);
    this.state = {
      Name: "",
      Name_valid: true,
      Email: "",
      Email_valid: true,
      Password: "",
      Password_valid: true,
      repeat_Password: "",
      repeat_Password_valid: true,
      Response_txt: "",
      Error: false,
      Type: "",
    };

    this.onFocusHandler = config.onFocusHandler.bind(this);
    this.onChangeHandler = config.onChangeHandler.bind(this);
  }

  vkLongToken = () => {
    var url = config.Frontend + "/vk";
    const params = new URLSearchParams(this.props.location.search);
    const key = params.get("key");
    if (key) {
      url += "?key=" + key;
    }
    window.location.replace(
      "https://oauth.vk.com/authorize?client_id=" +
        config.VkAppId +
        "&redirect_uri=" +
        url +
        "&response_type=code&scope=photos,video,audio,offline,groups,email" +
        "&state=log"
    );
  };

  ok = () => {
    var url = config.Frontend + "/login";
    const params = new URLSearchParams(this.props.location.search);
    const key = params.get("key");
    if (key) {
      url += "?key=" + key;
    }
    window.location.replace(
      "https://connect.ok.ru/oauth/authorize?client_id=" +
        config.OkAppId +
        "&redirect_uri=" +
        url +
        "&response_type=token&scope=GET_EMAIL" +
        "&state=ok"
    );
  };

  facebook = (res) => {
    const Email_filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (res.id !== undefined) {
      var url =
        config.Backend +
        "/fbLogin?" +
        "&Name=" +
        res.name +
        "&accessToken=" +
        res.accessToken +
        "&Fb_id=" +
        res.id;
      if (res.email != undefined) {
        url += "&Email=" + res.email;
      } else if (Email_filter.test(this.state.Email)) {
        url += "&Email=" + this.state.Email;
      }
      const params = new URLSearchParams(this.props.location.search);
      const key = params.get("key");
      if (key) {
        url += "&Key=" + key;
      }
      jQuery.getJSON(
        url,
        function (res) {
          if (res.Error) {
            this.setState({
              Response_txt: res.Error,
              Error: true,
            });
          } else {
            this.setState({
              Error: false,
              Response_txt: res.Ok,
            });
            cookies.set("Id", res.User._id, {
              path: "/",
            });
            if (res.User.Type == "Admin") {
              window.location.replace(window.location.origin + "/dashboard");
            } else if (res.User.Type == "Super") {
              window.location.replace(window.location.origin + "/admin/main");
            }
          }
        }.bind(this)
      );
    }
  };

  componentDidMount() {
    this.t_load();
    var url = config.Backend + "/users/findById?";
    if (!(cookies.get("Id") === undefined)) {
      url += "Id=" + cookies.get("Id").toString();
    }

    jQuery.getJSON(
      url,
      function (res) {
        this.setState({
          Type: res.Type,
        });
        // if (res.Type !== 'Super') {
        //   window.location.replace(window.location.origin + '/login');
        // }
        if (this.state.Type !== "") {
          if (res.Type == "Admin") {
            window.location.replace(window.location.origin + "/dashboard");
          } else if (res.Type == "Super") {
            window.location.replace(window.location.origin + "/admin/main");
          }
        }
      }.bind(this)
    );
  }

  xor = (ai) => {
    var f = "";
    for (var c = 0; c < ai.length; c++) {
      f += String.fromCharCode(ai.charCodeAt(c) ^ (1 + ((ai.length - c) % 32)));
    }
    return f;
  };

  onSubmitFormHandler = (event) => {
    const Email_filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (this.state.Name === "") {
      this.setState({
        Name_valid: false,
      });
    } else if (this.state.Password === "") {
      this.setState({
        Password_valid: false,
      });
    } else if (this.state.Password !== this.state.repeat_Password) {
      this.setState({
        repeat_Password_valid: false,
      });
    } else {
      const btn_login = document.querySelector(".btn_login");
      btn_login.disabled = true;
      var url = config.Backend + "/register";
      //alert(url)
      var fd = {};
      const params = new URLSearchParams(this.props.location.search);
      const key = params.get("key");
      if (key) {
        fd.Key = key;
      }
      // if(this.state.Email.indexOf('@')!=-1) {
      fd.Email = this.state.Email;
      // } else {
      //    fd.Phone = this.state.Email;
      //}
      fd.Password = this.xor(this.state.Password);
      fd.Type = "Admin";
      fd.Name = this.state.Name;
      jQuery.ajax({
        method: "POST",
        url: url,
        contentType: "application/json",
        success: function (res) {
          if (res.Error) {
            this.setState({
              Response_txt: res.Error,
              Error: true,
            });
          } else {
            this.setState({
              Error: false,
              Response_txt: res.Ok,
            });
            const Type = res.User.Type;
            var exp = new Date();
            exp.setDate(exp.getDate() + 3);
            cookies.set("Id", res.Id, {
              path: "/",
              expires: exp,
            });
            if (res.User.Type == "Admin") {
              window.location.replace(window.location.origin + "/dashboard");
            } else if (res.User.Type == "Super") {
              window.location.replace(window.location.origin + "/admin/main");
            }
          }
        }.bind(this),
        data: JSON.stringify(fd),
      });
    }
    event.preventDefault();
  };

  componentDidUpdate() {
    if (this.state.Response_txt && !!this.state.Error) {
      message.success(this.t(this.state.Response_txt));
    }
  }

  Name() {
    const danger = this.state.Name_valid ? (
      ""
    ) : (
      <div className="ant-typography ant-typography-danger">
        {this.t("Введите свое имя")}
      </div>
    );
    return (
      <div className="form-group">
        <input
          type="text"
          placeholder={this.t("Имя")}
          className=""
          value={this.state.Name}
          name="Name"
          onChange={this.onChangeHandler}
          onFocus={this.onFocusHandler}
          required
        />
        {danger}
      </div>
    );
  }

  Email() {
    const danger = this.state.Email_valid ? (
      ""
    ) : (
      <div className="ant-typography ant-typography-danger">
        {this.t("Проверьте email")}
      </div>
    );
    return (
      <div className="form-group">
        <input
          type="text"
          placeholder={this.t("Email")}
          value={this.state.Email}
          name="Email"
          onChange={this.onChangeHandler}
          onFocus={this.onFocusHandler}
          required
        />
        {danger}
      </div>
    );
  }

  Password() {
    const danger = this.state.Password_valid ? (
      ""
    ) : (
      <div className="ant-typography ant-typography-danger">
        {"Проверьте пароль"}
      </div>
    );
    return (
      <div className="form-group">
        <input
          type="Password"
          placeholder={"Пароль"}
          value={this.state.Password}
          name="Password"
          onChange={this.onChangeHandler}
          onFocus={this.onFocusHandler}
          required
        />
        {danger}
      </div>
    );
  }

  repeat_Password() {
    const danger = this.state.repeat_Password_valid ? (
      ""
    ) : (
      <div className="ant-typography ant-typography-danger">
        {"Повторите пароль"}
      </div>
    );
    return (
      <div className="form-group">
        <input
          type="Password"
          placeholder={"Повторите пароль"}
          value={this.state.repeat_Password}
          name="repeat_Password"
          onChange={this.onChangeHandler}
          onFocus={this.onFocusHandler}
          required
        />
        {danger}
      </div>
    );
  }

  agree() {
    return (
      <div className="register_politic mt_25">
        {this.t(
          "Создавая аккаунт Вы даете своё персональное согласие на обработку персональной " +
            "информациии на условиях, определенных"
        )}

        <a target="_blank" href="https://botkits.ru/privacy-policy/">
          Политика конфиденциальности
        </a>
      </div>
    );
  }

  render() {
    return (
      <div className="background_comein">
        <div className="form_block_login">
          <form onSubmit={this.onSubmitFormHandler} className="login">
            <div className="login_block_form">
              <div className="block_img">
                <Link to="/">
                  <img src="/img/logo-botkits.svg" />
                </Link>
              </div>
              <p>Регистрация</p>
            </div>

            {this.Name()}

            {this.Email()}

            {this.Password()}

            {this.repeat_Password()}

            <button type="submit" className="btn_login">
              Зарегистрироваться
            </button>

            {this.agree()}
            {/*<div className="facebook">*/}
            {/*<FacebookLogin*/}
            {/*appId={config.FbAppId}*/}
            {/*autoLoad={false}*/}
            {/*fields="name,email,picture"*/}
            {/*cssClass = "btn_facebook"*/}
            {/*reAuthenticate={true}*/}
            {/*scope={"pages_show_list,manage_pages"}*/}
            {/*returnScopes={true}*/}
            {/*callback={this.facebook} />*/}
            {/*</div>*/}
          </form>

          <div className="login_social">
            <div className="social_block">
              <button
                className="social_item login_vk"
                onClick={this.vkLongToken}
              />

              <button
                className="social_item login_ok"
                onClick={this.ok}
              />

              <FacebookLogin
                appId={config.FbAppId}
                autoLoad={false}
                fields="name,email,picture"
                cssClass="social_item btn_facebook"
                icon="fa-facebook "
                reAuthenticate={true}
                textButton={""}
                scope={
                  "pages_show_list,pages_manage_metadata,pages_read_engagement,pages_read_user_content,email,pages_messaging," +
                  "pages_messaging_subscriptions,public_profile"
                }
                returnScopes={true}
                tag={"button"}
                callback={this.facebook}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RegisterForm;
