import React from 'react';
import jQuery from 'jquery';
import Cookies from 'universal-cookie';
import TopPanel from '../../TopPanel/TopPanel';
import {Scrollbars} from 'react-custom-scrollbars';
import PopupAll from '../../PopupAll';
import TransComponent from '../../../TransComponent.js';
import PaymentModal from './PaymentModal';
import { Modal, Input} from 'antd';
import { Link, withRouter } from "react-router-dom";
import {
  connect
} from 'react-redux';
import {setPayments, setUser, successUser} from '../../../modules/User/actions';
import {setYandexShopSettings} from '../../../modules/Settings/action';
import { config } from '../../../config.js';
import { Button } from "../../../components/Button/Button";
import 'emoji-mart/css/emoji-mart.css'
import { withAlert } from 'react-alert'
import { Tab } from "./Component/Tab/Tab";

const cookies = new Cookies();
var func = require('../../../func.js');


class SettingProfile extends TransComponent {
  constructor(props) {

    super(props);
    this.state = {
      User: null,
      Name: '',
      Name_valid: true,
      Email: '',
      Email_valid: true,
      Password: '',
      Password_valid: true,
      repeat_Password: '',
      repeat_Password_valid: true,
      OldPassword: '',
      OldPassword_valid: true,
      Phone: '',
      Response_txt: '',
      Error: false,
      Tab: 'Settings',
      isToggleOn: true,
      Wait: 0,
      visible: false,
      Promocode: '',
    };

    this.refreshTutorial = func.refreshTutorial.bind(this);
    this.onChangeHandler = config.change.bind(this);
    this.onFocusHandler = config.onFocusHandler.bind(this);
  }

  xor = (ai) => {
    var f = '';
    for (var c = 0; c < ai.length; c++) {
      f += String.fromCharCode(ai.charCodeAt(c) ^ (1 + (ai.length - c) % 32));
    }
    return f;
  };

  mapUserToState = (user) => {
    if (user.PaymentPassword) {
      user.PaymentPassword = this.xor(user.PaymentPassword);
    }

    this.setState({
      User: user,
      Name: user.Name,
      Email: user.Email,
      Phone: user.Phone,
    });
  };

  loadPayments = () => {
    func.getData('payments/get').then(payments => {
      this.props.addPaymentsToStore(payments);
    });
  };

  componentDidUpdate (prevProps) {
    if (!prevProps.user && this.props.user) {
      this.mapUserToState(this.props.user);
    }
  }

  componentDidMount () {
    this.t_load();

    if (this.props.user) {
      this.mapUserToState(this.props.user);
    } else {
      this.props.setUser();
    }

    if (!this.props.payments) {
      this.loadPayments();
    }

    if (!this.props.yandexShop) {
      func.getData('settings/yandex').then(settings => {
        this.props.addYandexShopSettingsToStore(settings);
      });
    }
  }

  hidePopups = () => {
    this.setState({
      ShowFormPayment2: false
    });
  };

  onSubmitFormHandler = (event) => {
    var s = this.state;
    const alert = this.props.alert;

    if ( this.state.Password !== this.state.repeat_Password ) {
      this.setState({
        repeat_Password_valid: false
      });
    } else {
      var url = config.Backend + '/users/edit?';

      if ( this.state.Email.trim() !== '' ) {
        url += '&Email=' + this.state.Email;
      }
      if ( this.state.Password.trim() !== '' ) {
        url += '&Password=' + this.state.Password;
      }
      if ( this.state.Name.trim() !== '' ) {
        url += '&Name=' + this.state.Name;
      }
      if ( this.state.OldPassword.trim() !== '' ) {
        url += '&OldPassword=' + this.state.OldPassword;
      }
      if ( !(cookies.get('Id') === undefined) ) {
        url += '&Id=' + cookies.get('Id').toString();
        url += '&CurrentUserId=' + cookies.get('Id').toString();
      }
      // alert(url);


      jQuery.getJSON(url, function (res) {

        if (res.Error) {
          this.setState({
            Error: true,
            Response_txt: res.Error
          });

        } else {
          this.setState({
            Response_txt: res.Ok,
            Error: false
          }, () => {

            func.getData('users/edit_new', {
              Phone: this.state.Phone,
              ExtendTariff: s.User.ExtendTariff,
              OwnPaymentSystem: s.User.OwnPaymentSystem,
              PaymentShopId: s.User.PaymentShopId,
              PaymentPassword: s.User.PaymentPassword,
              PaymentScid: s.User.PaymentScid
            }).then(res => {


              this.mapUserToState(res);
              this.props.editUserInStore(res);
              alert.success('Сохранено');

            }, e => {

              this.setState({
                Response_txt: e,
                Error: true
              });
            });
          });
        }
      }.bind(this));
    }
    event.preventDefault();
  };

  onOldPasswordChangeHandler = () => {
    this.setState({
      Change: !this.state.Change
    }, () => {
      this.setState(prevState => ({
        isToggleOn: !prevState.isToggleOn
      }));
    });
  };

  Email () {
    const danger = this.state.Email_valid ? '' : <div className="text-danger">
      Проверьте email</div>;
    return (
      <div className="form-group">
        <div className="row__grid">
          <div className="col__2">
            <div className="row__grid grid__align--center grid_h-100">
              <label htmlFor="optionsEmail">
                <i className="fas fa-envelope fz13 mr15"/>
                Почта
              </label>
            </div>
          </div>

          <div className="col__8">
            <input type="Email" className="chat_input" id="optionsEmail" value={this.state.Email}
              name="Email" onChange={this.onChangeHandler} onFocus={this.onFocusHandler}/>
          </div>
        </div>

        {danger}
      </div>
    );
  }

  Phone () {
    const danger = this.state.Email_valid ? '' : <div className="text-danger">
      Проверьте email</div>;
    return (
      <div className="form-group">
        <div className="row__grid">
          <div className="col__2">
            <div className="row__grid grid__align--center grid_h-100">
              <label htmlFor="optionsPhone">
                <i className="fas fa-phone fz13 mr15"/>
                Телефон
                </label>
            </div>
          </div>

          <div className="col__8">
            <input type="Phone" className="chat_input" id="optionsPhone" value={this.state.Phone}
              name="Phone" onChange={this.onChangeHandler} onFocus={this.onFocusHandler}/>
          </div>
        </div>

        {danger}
      </div>
    );
  }

  ExtendTariff () {
    var s = this.state;
    return (
      <div className="form-group">
        <div className="row__grid">
          <div className="col__2">
            <div className="row__grid grid__align--center grid_h-100">
            </div>
          </div>

          <div className="col__8 wrap_filter_laber">
            <label htmlFor="moneyTest">
              Автоматически продлевать текущий тариф (при наличии средств)
            </label>
            <input id={"moneyTest"} type={"checkbox"} checked={s.User.ExtendTariff}
              name={"User.ExtendTariff"} onChange={this.onChangeHandler}/>
          </div>
        </div>
      </div>
    );
  }

  Name () {
    return (
      <div className="form-group mt_30">
        <div className="row__grid">
          <div className="col__2">
            <label htmlFor="optionsName">
              <i className="fas fa-user fz13 mr15"/>Имя
            </label>
          </div>

          <div className="col__8">
            <input
              type="text"
              className="input_form"
              name="Name"
              value={this.state.Name}
              onChange={this.onChangeHandler}
              onFocus={this.onFocusHandler}
              id="optionsName"/>
          </div>
        </div>
      </div>
    );
  }

  OldPassword () {
    return (
      <div className="form-group">
        <div className="row__grid">
          <div className="col__2 ">
            <div className="row__grid grid__align--center grid_h-100">
              <label htmlFor="optionsPassword">
                <i className="fas fa-key fz13 mr15"></i>Сменить пароль
                </label>
            </div>
          </div>

          <div className="col__8">
            <span onClick={this.onOldPasswordChangeHandler}
              className={"grid_text--b grid_underline"}>
              {this.state.isToggleOn ? 'Редактировать' : 'Закрыть'}
            </span>

            <div style={{
              color: "#888",
              fontSize: "12px"
            }}
              className={"mt_5"}>
              Рекомендуем использовать надежный пароль, уникальный для этого сайта
              </div>
          </div>
        </div>
      </div>
    );
  }

  Password () {
    const danger = this.state.Password_valid ? "" :
      <div className="text-danger">Проверьте свой пароль</div>;

    return (
      <div>
        <div className="form-group grid__offset--2">
          <label htmlFor="optionsPassword">Текущий пароль</label>

          <input type="password" className="input_form" id="optionsPassword"
            value={this.state.OldPassword} name="OldPassword" onChange={this.onChangeHandler}
            onFocus={this.onFocusHandler}/>

          {danger}
        </div>

        <div className="form-group grid__offset--2">
          <label htmlFor="optionsPassword">Новый пароль</label>

          <input type="password" className="input_form" id="optionsPassword"
            value={this.state.Password} name="Password" onChange={this.onChangeHandler}
            onFocus={this.onFocusHandler}/>

          {danger}
        </div>
      </div>
    );
  }

  repeat_Password () {
    const danger = this.state.repeat_Password_valid ? '' :
      <div className="text-danger">Повторите свой пароль</div>;

    return (
      <div className="form-group grid__offset--2">
        <label htmlFor="optionsPassword">Подтвердите новый пароль</label>

        <input type="password" className="chat_input" id="optionsPassword"
          value={this.state.repeat_Password} name="repeat_Password" onChange={this.onChangeHandler}
          onFocus={this.onFocusHandler}/>

        {danger}
      </div>
    );
  }

  Response () {
    const danger = this.state.Error ? <div className="text-danger">
        {this.t(this.state.Response_txt)}</div>
      : <div>{this.t(this.state.Response_txt)}</div>;

    return (
      <div className="grid__offset--2">
        {danger}
      </div>
    );
  }

  onGenerateKeyHandler = () => {
    this.setState({
      Wait: ++this.state.Wait
    });

    func.getData('generate_key').then(u => {
      this.setState({
        Wait: --this.state.Wait,
      });
      this.mapUserToState(u);
      this.props.editUserInStore(u);
    }, e => {
      this.setState({
        Error: true,
        Response_txt: JSON.stringify(e),
        Wait: --this.state.Wait
      });
    });
  };

  onClearKeyHandler = () => {
    func.getData('clear_key').then(u => {
      this.mapUserToState(u);
      this.props.editUserInStore(u);
    }, e => {
      this.setState({
        Error: true,
        Response_txt: JSON.stringify(e)
      });
    });
  };

  cancelSubscription = () => {
    func.getData('tap2payHook/unsubscribe').then(res => {
      this.mapUserToState(res.User);
      this.props.editUserInStore(res.User);
      this.props.addPaymentsToStore([res.Payment, ...this.props.payments]);
    }, e => {
      alert(JSON.stringify(e));
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };


  onOkHandler = (e) => {
    var s = this.state;
    const alert = this.props.alert;

    func.getData('promocodes/use', {
      Promocode: s.Promocode

    }).then(user => {
      this.setState({
        visible: false
      });

      alert.error('Промокод подтвержден');
      this.mapUserToState(user);
      this.loadPayments();

    }, e => {
      alert.error('Промокод не подтвержден. Попробуйте другой!');
    });
  };

  onCancelHandler = (e) => {
    this.setState({
      visible: false
    });
  };

  onGoBack = () => {
    this.props.history.goBack()
  }

  onVkBindHandler = () => {
    window.location.replace("https://oauth.vk.com/authorize?client_id=" +
      config.VkAppId + "&redirect_uri="
      + config.Frontend + "/vk" +
      "&response_type=code&scope=photos,video,audio,offline,groups,email"
      + "&state=log");
  };

  Settings () {

    var onRefreshTutorialHandler = () => this.refreshTutorial();
    var s = this.state;
    return (<div className="">
      <form className={"col__10"} onSubmit={this.onSubmitFormHandler}>
        {this.Name()}

        {this.Email()}

        {this.Phone()}

        {this.OldPassword()}

        {s.Change ? this.Password() : null}

        {s.Change ? this.repeat_Password() : null}

        {s.User ? this.ExtendTariff() : null}

        <hr/>

        {this.Response()}

        <div className="grid__offset--2">

          <Button
            type="submit"
            color="dark-blue"
            className="mr5"
            name="Сохранить" />

          <Button
            name="Отмена"
            action={this.onGoBack}/>

        </div>
      </form>

      <hr/>

      <div className="form-group grid__offset--2 mt_20">
        <Button
          name="Промокод"
          className="mr5"
          action={this.showModal}/>

        <Button
          name="Пройти обучение заново"
          className="mr5"
          action={onRefreshTutorialHandler}/>

        <Button
          name="Пройти обучение заново"
          className="mr5"
          action={onRefreshTutorialHandler}/>


        <Button
          name="Привязка вконтакте"
          color="blue"
          className="mr15"
          icon={<i className="fab fa-vk mr15"/>}
          action={this.onVkBindHandler}/>
      </div>
    </div>);
  }

  onShowFormPayment2Handler = () => {
    this.setState({
      ShowFormPayment2: true
    });
  };

  onChooseTabSettingsHandler = () => {
    this.setState({
      Tab: "Settings"
    });
  };

  onChooseTabPaymentsHandler = () => {
    this.setState({
      Tab: "Payments"
    });
  };

  onChangeSumHandler = (e) => {
    if (e.target.value >= 0) {
      this.onChangeHandler(e);
    }
  };

  Payments () {
    var s = this.state;
    var rows = (this.props.payments || []).map((elem, ind) => {
      const e = elem;
      const i = ind;
      var status = 'Успех';
      if (e.Status === 'Check') {
        status = 'На проверке';
      } else if (e.Status === 'Reject') {
        status = 'Отклонен' + '(' + e.RejectReason + ')';
      }
      return (<tr key={e._id}>
        <td>{(e.Amount || 0).toFixed(2)} руб</td>

        <td>
          {
            e.Type === 'Unsubscribe' ? '' :
              (
                ["Recharge", "Referal"].indexOf(e.Type) !== -1 ? 'Доход' : 'Трата'
              )
          }
        </td>

        <td>{new Date(e.created_at).toLocaleDateString("ru-RU")}</td>

        <td>{e.Note}</td>

        <td className={"tac"}>{status}</td>
      </tr>);
    });

    return (
      <div>
        <div className="subscription__settings">
            <span className="subscription__settings--balance">
              <span className="subscription__settings--balance-name">
                Баланс:
              </span>
              <span
                className="subscription__settings--balance-number">
                {s.User.Money ? s.User.Money.toFixed(2) : 0}</span>
                 руб
            </span>

            <Button
              action={this.onShowFormPayment2Handler}
              name={s.User && s.User.CurrentTap2PaySubscriptionId ?
                "Сменить Тариф" : "Активировать подписку"}
              size="large"
              className="mr-4"
              color="green"/>

            {s.User && s.User.CurrentTap2PaySubscriptionId && (
              <Button
                action={this.cancelSubscription}
                name="отменить подписку"
                size="large"
                color="grey"/>
            )}
          </div>

        <table className={"table mt_30"}>
          <thead>
          <tr className={"table_f"}>
            <th className={"table_left"}>Сумма</th>

            <th>Действие</th>

            <th className={"table_left"}>Дата</th>

            <th className={"table_left"}>Примечание</th>

            <th className={"tac"}>Статус</th>
          </tr>
          </thead>

          <tbody>
          {rows}
          </tbody>
        </table>
      </div>);
  }

  MainContent () {
    var s = this.state;
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">

            {s.User ?
              <div className="mt_25">
                <Tab
                  currentTab={this.state.Tab}
                  activeAccout={this.onChooseTabSettingsHandler}
                  activePayment={this.onChooseTabPaymentsHandler}
                />

                {this[this.state.Tab]()}
              </div> : null}
          </div>
        </div>
      </div>
    );
  }

  render () {
    var s = this.state;

    return (
      <div>
        {this.state.Wait !== 0 ? this.Load() : null}

        {s.ShowFormPayment2 ? <PaymentModal onClose={this.hidePopups} /> : null}

        <Modal
          title='Введите промокод'
          visible={this.state.visible}
          onOk={this.onOkHandler}
          onCancel={this.onCancelHandler}
          zIndex={'10000'}
          okText={'Потвердить'}
          cancelText={'Отмена'}>
          <Input
            type={"text"}
            value={s.Promocode}
            onChange={this.onChangeHandler}
            name={"Promocode"}/>
        </Modal>

        <TopPanel/>

        <PopupAll/>

        <div className={'broad_main'}>
          <Scrollbars style={{
            height: "100%"
          }}>
            <div className="overlay_chart">
              {this.MainContent()}
            </div>
          </Scrollbars>
        </div>
      </div>);
  }

}
const mapStateToProps = (state) => {
  return {
    user: state.user.user,
    payments: state.user.payments,
    yandexShop: state.globalSettings.yandexShop,
  };
};

const mapDispatchToProps = {
  setUser: setUser,
  addPaymentsToStore: setPayments,
  addYandexShopSettingsToStore: setYandexShopSettings,
  editUserInStore: successUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(withAlert()(withRouter(SettingProfile)))

