import React from 'react';
import jQuery from 'jquery';
import AdminLeft from '../AdminLeft';
import Cookies from 'universal-cookie';
import {Scrollbars} from 'react-custom-scrollbars';
import {Link} from "react-router-dom";
import { config } from '../../config.js';

const cookies = new Cookies();
var func = require('../../func.js');

class Settings extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      User: null,
      Settings: [],
      Wait: 0
    };

    this.onChangeHandler = config.change.bind(this);
  }

  onSaveHandler = () => {
    var s = this.state;
    for (var i in s.Settings) {
      this.setState({
        Wait: ++this.state.Wait
      });
      func.getData('settings/edit', {
        Key: s.Settings[i],
        Value: s[s.Settings[i]]
      }).then(function (s) {
        this.setState({
          Wait: --this.state.Wait
        });
      }.bind(this), function (err) {
        this.setState({
          Wait: --this.state.Wait
        });
        alert(JSON.stringify(err));
      }.bind(this));
    }
  };

  onSetupHandler = () => {
    var s = this.state;
    this.setState({
      Wait: ++this.state.Wait
    });
    func.getData('settings/setup').then(function (s) {
      this.setState({
        Wait: --this.state.Wait
      });
      this.load();
    }.bind(this), function (err) {
      this.setState({
        Wait: --this.state.Wait
      });
      alert(JSON.stringify(err));
    }.bind(this));
  };

  load = () => {
    var s = this.state;
    var url = config.Backend + '/users/findById?';
    if (!(cookies.get('Id') === undefined)) {
      url += 'Id=' + cookies.get('Id').toString();
    }
    this.setState({
      Wait: ++this.state.Wait
    });
    jQuery.getJSON(url, function (res) {
      if (!res.Error && res.Type == 'Super') {
        this.setState({
          User: res
        }, () => {
          func.getData('settings/all').then(function (ss) {
            for (var i in ss) {
              this.setState({[ss[i].Key]: ss[i].Value});
            }
            this.setState({
              Wait: --this.state.Wait,
              Settings: ss.map(e => e.Key)
            });
          }.bind(this), function (err) {
            this.setState({
              Wait: --this.state.Wait
            });
            alert(JSON.stringify(err));
          }.bind(this));
        });
      } else {
        window.location.replace(window.location.origin + '/login');
      }
    }.bind(this));
  }

  componentDidMount () {
    this.load();
  }

  AdminTop () {
    return (
      <div className="admin_top">
        <div className="admin_logo_top">
          <Link to="/admin/main"></Link>
        </div>
      </div>
    );
  }

  ClickPrice () {
    var s = this.state;

    return (
      <div>
        <div className="form-group">
          <label>Цена за клик(в рублях)</label>

          <input
            className={"chat_input"}
            type={"number"}
            value={s.ClickPrice}
            onChange={this.onChangeHandler}
            name={"ClickPrice"}/>
        </div>

        <div className="form-group">
          <label>Цена за просмотр(в рублях)</label>

          <input
            className={"chat_input"}
            type={"number"}
            value={s.ViewPrice}
            onChange={this.onChangeHandler}
            name={"ViewPrice"}/>
        </div>

        <div className="form-group">
          <label>Процент с рефералов</label>

          <input
            className={"chat_input"}
            type={"number"}
            value={s.ReferalPercent}
            onChange={this.onChangeHandler}
            name={"ReferalPercent"}/>
        </div>

        <div className="form-group">
          <label>Кол-во дней после регистрации реферала в течении которых действует система</label>

          <input
            className={"chat_input"}
            type={"number"}
            value={s.ReferalDays}
            onChange={this.onChangeHandler}
            name={"ReferalDays"}/>
        </div>

        <div className="form-group">
          <label>Цена ключа white label s(за 1 месяц)</label>

          <input
            className={"chat_input"}
            type={"number"}
            value={s.KeyPrice}
            onChange={this.onChangeHandler}
            name={"KeyPrice"}/>
        </div>
      </div>
    );
  }

  Yandex () {

    var s = this.state;
    return (
      <div>
        <div className="form-group">
          <label>Имя пользователя — идентификатор вашего магазина в Яндекс.Кассе</label>

          <input
            className={"chat_input"}
            type={"text"}
            value={s.Yandex.ShopId}
            onChange={this.onChangeHandler}
            name={"Yandex.ShopId"}/>
        </div>

        <div className="form-group">
          <label>Cекретный ключ</label>

          <input
            className={"chat_input"}
            type={"text"}
            value={s.Yandex.Secret}
            onChange={this.onChangeHandler}
            name={"Yandex.Secret"}/>
        </div>

        <div className="form-group">
          <label>Scid</label>

          <input
            className={"chat_input"}
            type={"text"}
            value={s.Yandex.Scid}
            onChange={this.onChangeHandler}
            name={"Yandex.Scid"}/>
        </div>
      </div>
    );
  }

  render () {
    var s = this.state;

    return (
      <div>
        <div className="b-page">
          <div className="b-page__row">
            <AdminLeft/>

            <div className="b-page__content-wrap" style={{
              height: "calc(100vh)"
            }}>
              <Scrollbars style={{
                height: "100%"
              }}>
                <div className="dashboard-page">
                  <div className="dashboard-page__items">
                    {s.ClickPrice !== undefined ? this.ClickPrice() : null}

                    {s.Yandex !== undefined ? this.Yandex() : null}

                    {s.OneBotPerDayTariffDiscount !== undefined ?
                      <div className="form-group">
                        <label>Скидка для тарифов(кол-во ботов * день * X)</label>

                        <input
                          className={"chat_input"}
                          type={"text"}
                          placeholder={'X'}
                          value={s.OneBotPerDayTariffDiscount}
                          onChange={this.onChangeHandler}
                          name={"OneBotPerDayTariffDiscount"}/>
                      </div>
                      : null}

                    <button className={"btn_all bg_shadow  mr_10"} onClick={this.onSaveHandler}>
                      Сохранить
                    </button>

                    <button className={"btn_all"} onClick={this.onSetupHandler}>
                      Вернуть значения по умолчанию/Установить настройки
                    </button>
                  </div>
                </div>
              </Scrollbars>
            </div>
          </div>
        </div>
      </div>
    );
  }

}

export default Settings;

