import {
  USER_FAILURE,
  USER_REQUEST,
  USER_SUCCESS,
  SET_VK_GROUPS,
  SET_FB_GROUPS,
  EDIT_FB_GROUP, SET_PAYMENTS
} from "./constains";

const initialState = {
  loading: false,
  success: false,
  error: false,
  errorMessage: '',
  user: null,
  vkGroups: null,
  fbGroups: null,
  payments: null,
};

export const userReducer = (state = initialState, action) => {
  switch(action.type) {
    case USER_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
        error: false,
        errorMessage: ''
      };
    case USER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        user: action.user,
      };
    case USER_FAILURE:
      return  {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    case SET_VK_GROUPS:
      return  {
        ...state,
        vkGroups: action.vkGroups,
      };
    case SET_FB_GROUPS:
      return  {
        ...state,
        fbGroups: action.fbGroups,
      };
    case SET_PAYMENTS:
      return  {
        ...state,
        payments: action.payments,
      };
    case EDIT_FB_GROUP:
      return {
        ...state,
        fbGroups: state.fbGroups.map((fbGroup, index) => index !== action.index ? fbGroup: {
          ...action.fbGroup
        })
      };
    default:
      return state;
  }
};
