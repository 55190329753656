import { call, put } from 'redux-saga/effects';

import {
  METHOD_GET, requestBuilder,
} from "../../../helpers/requestBuilder";

import Cookies from "universal-cookie";
import {failureBots, successBots} from "../actions";
const cookies = new Cookies();

export default function* BotsRequestSaga () {
  const id = cookies.get('Id');

  try {
    const data = yield call(
      requestBuilder,
      '/users/showBots?',
      METHOD_GET,
      {
        Id: id,
        NotTemplates: 1
      });
    if (data.data.Error) {
      yield put (failureBots('Error'))
    } else {
      yield put(successBots(data.data))
    }
  }

  catch (error) {
    yield put(failureBots('Bots error'))
  }

}
