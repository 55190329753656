export const BOTS_REQUEST = 'BOTS/REQUEST';
export const BOTS_SUCCESS = 'BOTS/SUCCESS';
export const SET_TEMPLATES = 'BOTS/SET_TEMPLATES';
export const BOTS_FAILURE = 'BOTS/FAILURE';
export const BOTS_DELETE = 'BOTS/DELETE';
export const BOTS_EDIT = 'BOTS/EDIT';
export const BOTS_EDIT_SUCCESS = 'BOTS/EDIT/SUCCESS';
export const BOTS_DELETE_SUCCESS = 'BOTS/DELETE/SUCCESS';
export const BOTS_SHARE = 'BOTS/SHARE';
export const BOTS_ADD = 'BOTS/ADD';
export const BOTS_ADD_SUCCESS = 'BOTS/ADD/SUCCESS';
export const BOTS_COPY = 'BOTS/COPY';
export const BOTS_COPY_SUCCESS = 'BOTS/COPY/SUCCESS';
export const BOTS_BIND_TO = 'BOTS/BIND/TO';
export const BOTS_BIND_CLEAR = 'BOTS/BIND/CLEAR';
export const SET_GROUPS = 'BOTS/SET_GROUPS';
export const DELETE_GROUP = 'BOTS/DELETE_GROUP';
export const ADD_GROUP = 'BOTS/ADD_GROUP';
export const EDIT_GROUP = 'BOTS/EDIT_GROUP';