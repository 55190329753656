import React from 'react';
import ReactTooltip from "react-tooltip";
import styles from './styles.module.scss'
import { TooltipTitle } from "../../../../../../../components/TooltipTitle/TooltipTitle";

export const TypingTime = ( {handleInputChange, typingTime} ) => {
  return (
    <div className={styles.typing_time}>

      <TooltipTitle
        idTooltip="i_TypingTime"
        name={"Время ввода"}
        place="left"
        tooltipDescription="Кол-во секунд в течении которых бот будет печатать сообщение."
      />

      <input
        type="range"
        min="0"
        max="10"
        className={styles.typing_time_input_range}
        value={ typingTime }
        onChange={ handleInputChange }
        name="typingTime"/>

      <span>{ typingTime }c</span>
    </div>
  )
}