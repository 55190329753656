import React from 'react';


export const CreateBotHelp = (props) => {

  let { linkDocumentation, linkVideo } = props;

  return (
    <div
      className="create_bot_help">
      <span className={"tooltip_bot"}>Как это работает?</span>

      <a
        target={"_blank"}
        href={linkDocumentation}>
        Ссылка на документацию
      </a>

      <a
        className={"ml30"}
        target={"_blank"}
        href={linkVideo}>
        Видео
      </a>
    </div>
  )
}