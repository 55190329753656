import React from 'react';
import classNames from 'classnames/bind';
import styles from './styles.module.scss';

export const InputCheckCharacter = (props) => {

  const cx = classNames.bind(styles);

  const input = cx({
    'input_check_character_input': true,
    'error': props.isLengthMoreTextLength,
  })

  const inputCheck = cx({
    'input_check_character_symbol': true,
    'error': props.isLengthMoreTextLength,
  });

  return (
    <div className={styles.input_check_character}>

      <div className={styles.input_check_character_wrap_input}>
        <input
          className={input}
          name="Text"
          onChange={props.onChange}
          value={ props.value }
          type="text"/>

        <span className={inputCheck}>{props.textLength}</span>
      </div>

      {props.isLengthMoreTextLength ?
        <span className={styles.input_check_character_error}>Название кнопки слишком большое</span> : ''}

    </div>
  )
}