import React from 'react';
import styles from './styles.module.scss';
import { Button } from "../../../../../components/Button/Button";

export const CardTemplateForBot = ({imgSrc, title, description, onClick}) => {

  return (
    <div className={styles.template}>

        <div className={styles.template_content}>
          <div className={styles.template_image}>
            <img src={imgSrc} />
          </div>

          <div className={styles.template_content_wrap}>
            <div className={styles.template_content_title}>{title}</div>
            <div className={styles.template_content_description}>
              <p>{description}</p>
            </div>
          </div>

        </div>
      <Button color="green" action={onClick} name="+УСТАНОВИТЬ ШАБЛОН"/>
    </div>
  )
}