import React from 'react';
import TransComponent from '../../../TransComponent.js';

class ReferalPayment extends TransComponent {

  constructor(props) {
    super(props);
  }

  componentDidMount () {
    this.t_load();
  }

  render () {
    var p = this.props;
    var e = p.elem;
    var i = p.ind;

    return (<tr className={"table_f"}>
      <td className={"table_left"}>{new Date(p.Referal.dates[i]).toLocaleDateString("ru-RU")}</td>

      <td className={"table_center"}>{e ? e.toFixed(2) : 0} {this.t("руб")}</td>
    </tr>);
  }

}

export default ReferalPayment;
