import React from 'react';
import Cookies from 'universal-cookie';
import {Link} from "react-router-dom";

const cookies = new Cookies();

class AdminLeft extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  onExitHandler = (event) => {
    cookies.set('Exit', 'true', {
      path: '/'
    });
    window.location.replace(window.location.origin + '/login');
  }

  render () {
    return (
      <div className="admin_left_panel">
        <ul className="admin_top_navigator">
          <li style={{
            borderBottom: "1px solid #eee",
            marginLeft: "13px"
          }}>
            <div className="row">
              <Link to="/">
                <img src="/img/logo_login.png"/>
              </Link>
            </div>
          </li>

          <li>
            <Link to="/admin/main">
              {/*<div className="block_image analyze">*/}
              {/*</div>*/}
              <span className={"nav__admin--icon"}>
                <i className="fas fa-chart-pie"></i>
              </span>

              <span className={"nav__admin--text"}>
                Статистика
              </span>
            </Link>
          </li>

          <li>
            <Link to="/admin/settings">
              <span className={"nav__admin--icon"}>
                <i className="fas fa-cog"></i>
              </span>

              <span className={"nav__admin--text"}>
                Настройки
              </span>
            </Link>
          </li>

          <li>
            <Link to={"/admin/tariffs"}>
              <span className={"nav__admin--icon"}>
                <i className="fas fa-align-justify"></i>
              </span>

              <span className={"nav__admin--text"}>
                Тарифы
              </span>
            </Link>
          </li>

          <li>
            <Link to={"/admin/users"}>
              <span className={"nav__admin--icon"}>
                <i className="far fa-user"></i>
              </span>

              <span className={"nav__admin--text"}>
                Пользователь
              </span>
            </Link>
          </li>

          <li>
            <Link to={"/admin/email_templates"}>
              <span className={"nav__admin--icon"}>
                <i className="fas fa-envelope"></i>
              </span>

              <span className={"nav__admin--text"}>
                Шаблоны писем
              </span>
            </Link>
          </li>

          <li>
            <Link to={"/admin/create-bot"}>
              <span className={"nav__admin--icon"}>
                <i className="fas fa-align-right"></i>
              </span>

              <span className={"nav__admin--text"}>
                Шаблоны ботов
              </span>
            </Link>
          </li>

          <li>
            <Link to={"/admin/promocodes"}>
              <span className={"nav__admin--icon"}>
                <i className="fas fa-align-right"></i>
              </span>

              <span className={"nav__admin--text"}>
                Промокоды
              </span>
            </Link>
          </li>

          <li>
            <a style={{
              cursor: "pointer"
            }} className={"fw_menu"} onClick={this.onExitHandler}>
              <span className={"nav__admin--icon"}>
                <i className="fas fa-sign-out-alt"></i>
              </span>

              <span className={"nav__admin--text"}>
                Выход
              </span>
            </a>
          </li>
        </ul>
      </div>
    );
  }

}

export default AdminLeft;

