import React, {Component} from 'react';
import jQuery from 'jquery';
import Cookies from 'universal-cookie';
import LeftPanel from '../../LeftPanel';
import TopPanel from '../../TopPanel/TopPanel';
import PopupAll from '../../PopupAll';
import TransComponent from '../../../TransComponent.js';
import ShareTableRow from './ShareTableRow.js';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import { config } from '../../../config.js';

const cookies = new Cookies();

class Share extends TransComponent {

  constructor(props) {
    super(props);
    this.state = {
      Bot: null,
      Users: [],
      ShowSharePopup: false,
      ShareEmail: '',
      Wait: 0
    };
  }

  onChangeEmailHandler = (e) => {
    this.setState({
      ShareEmail: e.target.value
    });
  };

  onShareHandler = () => {
    this.setState({
      Wait: ++this.state.Wait
    });

    jQuery.ajax({
      method: 'POST',
      contentType: 'application/json',
      url: config.Backend + '/share/add',
      success: function (res) {
        this.setState({
          Wait: --this.state.Wait
        });

        if (!res.Error) {
          this.load();
          this.setState({
            ShowSharePopup: false
          });
        } else {
          alert(JSON.stringify(res));
        }
      }.bind(this),
      data: JSON.stringify({
        Email: this.state.ShareEmail,
        BotId: this.state.Bot._id,
        Rights: 'RTC'
      }),
    });
  };

  onSaveHandler = () => {
    var fd = this.state.Bot.Share;
    this.setState({
      Wait: ++this.state.Wait
    });

    jQuery.ajax({
      method: 'POST',
      contentType: 'application/json',
      url: config.Backend + '/share/editFull',
      success: function (res) {
        this.setState({
          Wait: --this.state.Wait
        });

        if (!res.Error) {
          alert(this.t('Изменения сохранены!'));

          //alert(JSON.stringify(res));
        } else {
          alert(JSON.stringify(res));
        }
      }.bind(this),
      data: JSON.stringify(fd),
    });
  };

  onDeleteHandler = (s, i) => {
    var fd = {};
    fd.ShareId = this.state.Bot.Share._id;
    fd.UserId = s.User;
    this.setState({
      Wait: ++this.state.Wait
    });

    jQuery.ajax({
      method: 'POST',
      contentType: 'application/json',
      url: config.Backend + '/share/delete',
      success: function (res) {
        this.setState({
          Wait: --this.state.Wait
        });

        if (!res.Error) {
          this.state.Bot.Share.UsersWithRights.splice(i, 1);
          this.setState({
            Bot: this.state.Bot
          })
          //alert(JSON.stringify(res));
        } else {
          alert(JSON.stringify(res));
        }
      }.bind(this),
      data: JSON.stringify(fd),
    });
  };

  onChangeHandler  = (event, i) => {
    this.state.Bot.Share.UsersWithRights[i].Rights = (event.target.checked ?
      (this.state.Bot.Share.UsersWithRights[i].Rights + event.target.name)
      : this.state.Bot.Share.UsersWithRights[i].Rights.replace(event.target.name, ''));
    if (event.target.name == 'RTC') {
      this.state.Bot.Share.UsersWithRights[i].Chat = undefined;
    }
    this.setState({
      Bot: this.state.Bot,
    });
  };

  load = () => {
    var url = config.Backend + '/bot/findById?';
    if (!(cookies.get('Id') === undefined)) {
      url += 'CurrentUserId=' + cookies.get('Id').toString();
    }
    url += '&Id=' + cookies.get('BotId').toString();
    this.setState({
      Wait: ++this.state.Wait
    });

    jQuery.getJSON(url, function (res) {
      this.setState({
        Wait: --this.state.Wait
      });

      if ( !res.Error ) {
        if (!config.checkRights(cookies.get('Id').toString(), res, 'Share')) {
          config.accessDenied(cookies.get('Id').toString(), res);
        }
        this.setState({
          Bot: res,
          Users: res.Users
        }, function () {
        }.bind(this));
      } else {
        alert(JSON.stringify(res));
      }
    }.bind(this));
  };

  componentDidMount () {
    this.t_load();
    this.load();
  }

  Users () {
    var s = this.state;
    var users = this.props.user ? this.state.Bot.Share.UsersWithRights
        .filter(elem => s.Users.find(user => user._id === elem.User))
        .map((elem, ind) => <ShareTableRow
      elem={elem} ind={ind} Bot={s.Bot} Users={s.Users} User={this.props.user}
      onChangeHandler={this.onChangeHandler} onDeleteHandler={this.onDeleteHandler}/>) : [];
    return (
      <table className={"table table-condensed"}>
        <tr className={"table_f"}>
          <th>{this.t("Имя")}</th>

          <th>{this.t("Email")}</th>

          <th>{this.t("Управление")}</th>

          <th>{this.t("Рассылка")}</th>

          <th>{this.t("Дополнительно")}</th>

          <th>{this.t("Статистика")}</th>

          <th>{this.t("Ручное управление")}</th>

          <th>{this.t("Общий доступ")}</th>

          <th>{this.t("Удалить")}</th>
        </tr>
        <tbody className={"table_center"}>
        {users}
        </tbody>
      </table>)
  }

  onHidePopupHandler = () => {
    this.setState({
      ShowSharePopup: false
    });
  };

  onShowPopupHandler = () =>  {
    this.setState({
      ShowSharePopup: true
    });
  };

  sharePopup () {
    return (
      <div>
        <div className="filterPopup">
          <div className="filter_delete">
            <h1>{this.t("Предоставить общий доступ к боту")}</h1>

            <input className="chat_input mb10" type="text" value={this.state.ShareEmail}
              name="ShareEmail"
              placeholder={this.t("Введите email пользователся которому хотите " +
              "предоставить доступ...")} onChange={this.onChangeEmailHandler}/>

            <button className={"bg_shadow btn_all"}
                  onClick={this.onShareHandler}>
              {this.t("Потвердить")}
            </button>

            <button className="grey button" onClick={this.onHidePopupHandler}> {this.t("Отмена")}
            </button>
          </div>
        </div>
      </div>
    );
  }

  render () {
    var s = this.state;
    var somebodyGetShare = s.Bot && s.Bot.Share && s.Bot.Share.UsersWithRights.length > 0
      && s.Users.length > 0;

    if (this.props.user && this.props.user.Tariff && !this.props.user.Tariff.EnableShare) {
      window.location.replace(window.location.origin + '/dashboard');
    }

    return (
      <div>
        {this.state.Wait != 0 ? this.Load() : null}

        <TopPanel/>

        <div className="b-page">
          <div className="b-page__row">
            <LeftPanel User={this.props.user}/>

            <div className="b-page__content-wrap">
              <div className="dashboard-page">
                <div className="dashboard-page__items">
                  <div className="mainAnalize grow_main">
                    <div>
                      {somebodyGetShare ? this.Users() : null}

                      {somebodyGetShare ?
                        <button className={"btn__all--small bg_shadow mr5"}
                          onClick={this.onSaveHandler}>
                          Сохранить изменения
                        </button>
                        : null}

                      <button className={"btn__all--small"} onClick={this.onShowPopupHandler}>
                        Предоставить доступ
                      </button>

                      <p>
                        {
                          somebodyGetShare ? null :
                            <span>Еще никто не получил доступа к вашему боту</span>
                        }
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.state.ShowSharePopup ? this.sharePopup() : null}

        <PopupAll/>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
    bot: (state.bots.bots || []).find(bot => bot._id === cookies.get('BotId').toString()),
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Share));
