import { call, put } from 'redux-saga/effects';

import {
  METHOD_GET, requestBuilder
} from "../../../helpers/requestBuilder";

import Cookies from 'universal-cookie';
import {deleteBotsSuccess, editBotSuccess, failureBots} from "../actions";

const cookies = new Cookies;

export default function* BotEditSaga (action) {
  const id = cookies.get('Id');

  try {
    const data = yield call(
      requestBuilder,
      'bot/edit?',
      METHOD_GET,
      {
        CurrentUserId: id,
        Id: action.id,
        Name: action.name
      }
    );
    if (data.data.Error){
      yield put (failureBots('Error'))
    }  else {
    yield put(editBotSuccess(data.data))
  }

  }

  catch (error) {
    yield (put(failureBots('Bots error')))
  }
}
