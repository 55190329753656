import { call, put } from 'redux-saga/effects';

import {
  METHOD_POST,
  METHOD_GET,
  requestBuilder, } from '../../../helpers/requestBuilder'

import Cookies from "universal-cookie";
import {failureUser, successUser} from "../actions";
const cookies = new Cookies();

export default function* UserRequestSaga () {

  const id = cookies.get('Id');

  try {
    const data = yield call(
      requestBuilder,
      '/users/findById?',
      METHOD_GET,
      {
        Id: id,
        PopulateTariff: 'true'
      }
      );
    if (data.data.Error) {
      yield put (failureUser('Sing In Request Error'))
    }

    else {
      yield put(successUser(data.data));
    }
  }

  catch (error) {
    yield put(failureUser('User error'));
  }
}
