import { call, put } from 'redux-saga/effects';

import Cookies from "universal-cookie";
import {addBotSuccess, failureBots, successBots} from "../actions";
const cookies = new Cookies();

export default function* BotsBindTo (action) {
  const id = cookies.get('Id');

  try {
    const data = yield call(() => {
        return fetch(action.url)
          .then(res => res.json())
      }
    );
  }

  catch (error) {
    yield put(failureBots('Bots error'))
  }
}
