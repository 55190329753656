import React, {Component} from 'react';
import jQuery from 'jquery';
import Cookies from 'universal-cookie';
import TopPanel from '../../TopPanel/TopPanel';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {Scrollbars} from 'react-custom-scrollbars';
import PopupAll from '../../PopupAll';
import TransComponent from '../../../TransComponent.js';
import Referal from './Referal.js';
import ReferalPayment from './ReferalPayment.js';
import { config } from '../../../config.js';

const cookies = new Cookies();
var func = require('../../../func.js');

class Partner extends TransComponent {

  constructor(props) {
    super(props);
    this.state = {
      User: {},
      Response_txt: '',
      Error: false,
      Settings2: {},
      Referals: [],
      copied: false,
      Popup: -1,
      Wait: 0
    };
    this.onChangeHandler = config.change.bind(this);
  }

  componentDidMount () {
    this.t_load();
    var url = config.Backend + '/users/findById?';
    if ( !(cookies.get('Id') === undefined) ) {
      url += 'Id=' + cookies.get('Id').toString();
    }
    this.setState({
      Wait: ++this.state.Wait
    });

    jQuery.getJSON(url, function (res) {
      this.setState({
        Wait: --this.state.Wait
      });

      if ( !res.Error ) {
        this.setState({
          User: res,
          Wait: ++this.state.Wait
        });

        func.getData('settings/all_public').then(settings => {
          var o = {};
          for (var x = 0; x < settings.length; x++) {
            o[settings[x].Key] = settings[x].Value;
          }
          this.setState({
            Settings2: o,
            Wait: --this.state.Wait
          });
        }, e => {
          this.setState({
            Wait: --this.state.Wait
          });

          alert(JSON.stringify(e));
        });
        this.setState({
          Wait: ++this.state.Wait
        });

        func.getData('payments/referals').then(ok => {
          this.setState({
            Referals: ok,
            Wait: --this.state.Wait
          });
        }, e => {
          this.setState({
            Wait: --this.state.Wait
          });

          alert(JSON.stringify(e));
        })
      } else {
        window.location.replace(window.location.origin + '/login');
      }
    }.bind(this));
  }

  Response () {
    const danger = this.state.Error ? <div className='text-danger'>
        {this.t(this.state.Response_txt)}</div> :
      <div>{this.t(this.state.Response_txt)}</div>;
    return (
      <div className="">
        {danger}
      </div>
    );
  }

  onGenerateKeyHandler = () => {
    func.getData('onGenerateKeyHandler').then(u => {
      this.setState({
        User: u
      });
    }, e => {
      this.setState({
        Error: true,
        Response_txt: JSON.stringify(e)
      });
    });
  };

  clear_key = () => {
    func.getData('clear_key').then(u => {
      this.setState({
        User: u
      });
    }, e => {
      this.setState({
        Error: true,
        Response_txt: JSON.stringify(e)
      });
    });
  };

  show = (e, i) => {
    this.setState({
      Popup: i
    });
  };

  MainContent () {
    var s = this.state;
    var rows = s.Referals.map((elem, ind) =>
      <Referal
        elem={elem}
        ind={ind}
        onShowDetailsHandler={this.show}
        Settings2={s.Settings2}/>);

    var onChangeKeyHandler = ({target: {value}}) => {
      this.setState({
        value,
        copied: false
      });
    };

    var onCopyHandler = () => {
      this.setState({
        copied: true
      });
    };

    return (
      <div>
        {s.User ?
          <div className="overlay_chart profile_settings mt_15">
            {this.t("Вы получите")} {s.Settings2["ReferalPercent"]}%
            {this.t("в том случае если ваш реферал пополнит счет в течении")}
            {s.Settings2["ReferalDays"]} {this.t("дней после его регистрации.")}
            <p>{this.t("Кол-во ваших рефералов")} :
              <span className={"fw_menu"}>{s.Referals.length}</span>
            </p>

            <hr/>
            {s.User.Key ?
              <div className="form-group">
                <div className="row__grid">
                  <div className="col__9">
                    <label htmlFor="key">{this.t("Реферальная ссылка")}</label>
                  </div>

                  <div className="col__1">
                    {this.state.copied ? <span style={{
                      color: "green"
                    }}>
                      {this.t("Скопировано")}
                      </span> : null}
                  </div>
                </div>

                {/*<div className="row__grid">*/}
                  {/*<div className="col__9">*/}
                    {/*<input onChange={onChangeKeyHandler}*/}
                      {/*disabled={0} type="text" className="chat_input" id="key"*/}
                      {/*value={window.location.origin + "/register?key=" + s.User.Key}/>*/}
                  {/*</div>*/}

                  {/*<div className="col__1">*/}
                    {/*<CopyToClipboard text={window.location.origin + "/register?key=" + s.User.Key}*/}
                      {/*onCopy={onCopyHandler}>*/}
                      {/*<button className={"btn__all--small m__c--5 bg_shadow"}>*/}
                        {/*{this.t("Скопировать")}*/}
                      {/*</button>*/}
                    {/*</CopyToClipboard>*/}
                  {/*</div>*/}
                {/*</div>*/}
              </div>
              :
              <div className="form-group">
                <button id="key" className="btn_all bg_shadow" onClick={this.onGenerateKeyHandler}>
                  {this.t("Сгенерировать ключ")}
                </button>
              </div>
            }

            {this.Response()}

            <table className={"table"}>
              <thead>
              <tr className={"table_f"}>
                <th className={"table_left"}>{this.t("Имя")}</th>

                <th className={"table_left"}>{this.t("Email")}</th>

                <th>{this.t("Дата регистрации")}</th>

                <th>{this.t("Действует до")}</th>

                <th>{this.t("Сумма отчислений")}</th>

                <th>{this.t("Управление")}</th>
              </tr>
              </thead>

              <tbody>
              {rows}
              </tbody>
            </table>

          </div> : null}
      </div>
    );
  }

  hidePopups = () => {
    this.setState({
      Popup: -1
    });
  };

  WhitePopup (i) {
    var s = this.state;
    var ref = s.Referals[i];
    var rows = (ref.sums || []).map((elem, ind) =>
      <ReferalPayment elem={elem} ind={ind} Referal={ref}/>);
    return (
      <div className="preloader_bots">
        <div className="preloader_bots_2 popupW">
          <div className="analyse_content">
            <Scrollbars
              style={{
                height: "90%"
              }}>
              <div className="analyse_content_2">
                <table className={"table"}>
                  <thead>
                  <tr className={"table_f"}>
                    <th className={"table_left"}>{this.t("Дата отчисления")}</th>

                    <th className={"tac"}>{this.t("Сумма")}</th>
                  </tr>
                  </thead>

                  <tbody>
                  {rows}
                  </tbody>
                </table>
                {/*<button onClick={this.hidePopups}>Close</button>*/}
              </div>
            </Scrollbars>

            <div className="close_popup_btn">
              <button onClick={this.hidePopups} type="button" className="close" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }


  render () {
    var s = this.state;
    return (
      <div>
        {this.state.Wait != 0 ? this.Load() : null}

        <TopPanel/>

        <PopupAll/>

        <div className="grow_main_tariffs">
          <Scrollbars style={{
            height: "100%"
          }}>
            <div className="col-sm-12">
              <h1>{this.t("Партнерская программа")}</h1>
            </div>

            {this.MainContent()}
          </Scrollbars>
        </div>

        {s.Popup != -1 ? this.WhitePopup(s.Popup) : null}
      </div>
    );
  }
}

export default Partner;