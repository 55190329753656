import React from 'react';
import { Link } from "react-router-dom";
import styles from './styles.module.scss';

export const Logo = () => {
  return (
    <div className={styles.logotype}>
      <Link to="/dashboard">
        <img src="/img/logo-botkits.svg"/>
      </Link>
    </div>
  )
};