import React from 'react';
import styles from './style.module.scss';
import { NavLink } from "react-router-dom";


export const Button = (props) => {

  const { name, action, activeDisabled, backgroundColor, className, icon, type } = props;

  let styleBtn = {
    backgroundColor: backgroundColor === 'blue' ? '#243CBB' : 'white',
    color: backgroundColor === 'blue' ? 'white' : '#131118',
    width: backgroundColor === 'blue' ? '300px' : '200px',
  }

  return (

    type === 'link' ?

      <NavLink
        style={styleBtn}
        disabled={activeDisabled}
        className={`${styles.button} ${activeDisabled ? styles.link : 'payment-button-active'}` }
        to={'/payment-page'}>
        {name} {icon}
      </NavLink> :

      <button
        style={styleBtn}
        disabled={activeDisabled}
        onClick={action}
        className={`${styles.button} ${ className}` }>
      {name} {icon}
    </button>
  )
};