import React from 'react';
import classNames from 'classnames/bind';
import styles from './styles.module.scss';

export const Button = (
  {
    type = 'button',
    icon,
    size = 'default',
    name,
    color,
    action,
    className,

  },
) => {
  const cx = classNames.bind(styles);

  const colorButton = cx({
    'dark-blue-btn': color === 'dark-blue',
    'blue-btn': color === 'blue',
    'red-btn': color === 'red',
    'grey-btn': color === 'grey',
    'gren-btn': color === 'green',
    'large-btn': size === 'large',
    'white-btn': true,
  });

  return (
    <button
      type={type}
      onClick={action}
      className={`${styles.button} ${colorButton} ${className}`}
    >
      {icon}
      {' '}
      {name}
    </button>
  );
};


