import { SET_YANDEX_SHOP_SETTINGS } from "./constains";

const initialState = {
  yandexShop: null
};

export const globalSettingsReducer = ( state = initialState, action ) => {
  switch (action.type) {
    case SET_YANDEX_SHOP_SETTINGS:
      return {
        ...state,
        yandexShop: action.settings,
      };

    default:
      return state;
  }
};

