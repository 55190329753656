import React from "react";
import ReactTooltip from "react-tooltip";
import { Button } from "../../../../../../../components/Button/Button";
import { TypingTime } from "../TypingTime/TypingTime";
import { TextAreaSmile } from "../../../../../../../components/TextAreaSmile/TextAreaSmile";
import styles from "./styles.module.scss";
import { TooltipTitle } from "../../../../../../../components/TooltipTitle/TooltipTitle";

export const MessageBlock = (props) => {
  const { state } = props;
  const bind = props.bot;
  let name = "{{first_name}} {{last_name}}";
  const context = "{{Context.имяПеременной}}";

  switch (bind) {
    case "Viber":
      name = "{{name}}";
      break;
    case "Ok":
      name = "{{name}}";
    case "WhatsApp":
      name = "{{name}}";
      break;
  }

  const addParamentName = (ev) => {
    ev.preventDefault();
    const e = {
      target: {
        value: `${state.Text} ${name}`,
        name: "Text",
      },
    };
    props.handleInputChange(e);
  };

  const addParamentVariable = (ev) => {
    ev.preventDefault();
    const e = {
      target: {
        value: `${state.Text} ${context}`,
        name: "Text",
      },
    };
    props.handleInputChange(e);
  };

  const canAddParamsToText = !(bind === "Vk" && props.broadcast)
      && (!props.BotMsg || !props.BotMsg.SendToChannels);

  const textTitle = canAddParamsToText
    ? `Можно использовать Context переменные (пример: Вы заказали {{Context.product}}?)`
    : "Нельзя использовать Context переменные";

  return (
    <div className={styles.message_block}>
      {/* <div className="EditMessage"> */}

      <TypingTime
        typingTime={props.state.typingTime}
        handleInputChange={props.handleInputChange}
      />

      <TooltipTitle
        idTooltip="i_msg_block"
        name="Текст сообщения"
        tooltipDescription={`Не должен быть пустым. ${textTitle} В telegram можно добавлять команды в сообщение, пример: Команды бота: \\start, \\info.`}
      />

      <TextAreaSmile
        rows={5}
        nameBot={bind}
        ImageAndTextInOneMessage={props.BotMsg && props.BotMsg.ImageAndTextInOneMessage}
        name="Text"
        value={props.state.Text}
        onChange={props.handleInputChange}
      />

      {canAddParamsToText && (
        <Button
          className="mr2 fz12 mt_2"
          action={addParamentName}
          name="Вставить имя"
        />
      )}

      {canAddParamsToText && (
        <Button
          name="Вставить Context переменную"
          action={addParamentVariable}
          className="mr2 fz12 mt_2"
        />
      )}
    </div>
  );
};

export default MessageBlock;
