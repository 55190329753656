import React from 'react';
import jQuery from 'jquery';
import Cookies from 'universal-cookie';
import {WithContext as ReactTags} from 'react-tag-input';
import ReactTooltip from 'react-tooltip'
import { config } from '../../../config.js';

const cookies = new Cookies();


class TimeTags extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      tags: [],
      Link: this.props.Link,
      Format: this.props.Format,
      Index: this.props.Index
    };

    this.t = this.props.t || ((x) => {
      return x;
    });

  }

  onFormatChangedHandler = (e) => {
    this.setState({
      Format: e.target.value
    });
    this.props.onFormatChangedHandler(+this.props.Index, e.target.value);
  };

  onLinkChangedHandler = (e) => {
    this.setState({
      Link: e.target.value
    });
    this.props.onLinkChangedHandler(+this.props.Index, e.target.value);
  };

  componentDidMount () {
    for (var i = 0; i < this.props.Word.Tags.length; i++) {
      this.state.tags.push({
        id: i,
        text: this.props.Word.Tags[i]
      });
    }
  }

  onDeleteHandler = (i) => {
    let tags = this.state.tags;
    tags.splice(i, 1);
    this.setState({
      tags: tags
    });
    this.props.onDeleteTagHandler(this.state.Index, i);
  };

  onAdditionHandler = (tag) => {
    var url = config.Backend + '/bot/edit?';
    if (!(cookies.get('Id') === undefined)) {
      url += '&CurrentUserId=' + cookies.get('Id').toString();
    }
    url += '&Id=' + cookies.get('BotId');
    url += '&EditTimeWord=1';
    url += '&Tag=' + tag;
    url += '&Syno=' + (this.props.Parent.Syno ? 'true' : 'false');
    url += '&Index=' + this.state.Index;
    jQuery.getJSON(url, function (res) {
      if (!res.Error) {
        this.setState({
          tags: res.TimeWords[this.state.Index].Tags.map((t, i) => {
            return {
              id: i,
              text: t
            };
          })
        });
      } else {
        alert(JSON.stringify(res));
      }
    }.bind(this));
  };

  onDragHandler = (tag, currPos, newPos) => {
    let tags = this.state.tags;

    // mutate array
    tags.splice(currPos, 1);
    tags.splice(newPos, 0, tag);

    // re-render
    this.setState({
      tags: tags
    });
  };

  Condition () {
    const {tags} = this.state;

    return (
      <div className="condition EditMessage">
        <span className="condition_over mt_15">
          <div className="condition_left">Тэги
            <span data-tip data-for="i_TimeTags" className={"ml5"}>
              <i className="fz15 far fa-question-circle"></i>
            </span>
          </div>
        </span>

        <ReactTooltip id="i_TimeTags" type="light">
          <span>
            {this.t("Все тэги бот преобразует к конкретной дате.")} <br/>
              {this.t("Для ввода тэга введите слово или фразу а затем нажмите Enter или " +
                "Tab на клавиатуре.")}
          </span>
        </ReactTooltip>

        <div className="mb10 ">
          <ReactTags
            type="text"
            className="hello"
            maxLength="30"
            tags={tags}
            handleDelete={this.onDeleteHandler}
            handleAddition={this.onAdditionHandler}
            placeholder={this.t("Введите слово или фразу(примеры:сегодня,завтра,вчера...)")}/>
        </div>

        <span className="condition_over mt_15">
          <div className="condition_left">{this.t("Бот преобразует к: сегодня + N дней")}
            <span data-tip data-for="i_NDays" className={"ml5"}>
              <i className="fz15 far fa-question-circle"></i>
            </span>
          </div>
        </span>

        <ReactTooltip id="i_NDays" type="light">
          <span>
            {this.t("Когда бот наткнется на один из тэгов он преобразует его к дате" +
              "(сегодня + N дней).")}
          </span>
        </ReactTooltip>

        <input className="borderR chat_input" type="number" value={this.state.Link}
          onChange={this.onLinkChangedHandler}/>
      </div>
    );
  }

  render () {
    var onDeleteLinkHandler = () => this.props.onDeleteLinkHandler(this.state.Index);
    return (
      <div>
        {this.Condition()}
        <div className="button_delete_block">
          <span className="right_delete_icon" onClick={onDeleteLinkHandler}>
          </span>
        </div>
      </div>
    );
  }
}

export default TimeTags;