import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

const profileLink = "/profile/setting";

const AfterPayment = () => {
  const history = useHistory();

  useEffect(() => {
    setTimeout(() => history.push(profileLink), 5000);
  }, []);

  return (
    <div>
      <div
        style={{
          backgroundColor: "#243cbb",
        }}
        className="payoment_page"
      >
        <div>
          <div className="row">
            <div
              style={{ backgroundColor: "#243cbb" }}
              className="row__grid payoment_page grid__align--center grid__justify--content"
            >
              <div className="payment-container">
                <div className="row__grid grid__align--center mb30">
                  <h1 className="payment-title">Спасибо за</h1>
                  <div className="payment-tag mr15">/оплату</div>
                  <h1 className="payment-title ml15">!</h1>
                </div>

                <span className="payment-description">
                  <span className="payment-description--bold">Bot Kits</span> —{" "}
                  <span className="payment-description">
                    помогаем упростить процесс общения с клиентами.
                  </span>
                </span>

                <a href={profileLink} className="payment-back-button mt_30">
                  Вернуться в личный кабинет
                </a>
              </div>
              <div>
                <img src="/img/payment.png" alt="" width={544} height={420} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AfterPayment;
