export const dict = {
  misvecvu: 'Нажмите и потяните чтобы создать прямую связь.',
  lqjjocai: 'Нажмите и потяните чтобы создать прямую связь',
  gxzrenzg: 'В этом блоке вы можете вызвать нужное сообщение в зависимости от выполнения различный условий.',
  tebbibbh: 'Условный блок',
  lvwdeauz: 'Этот блок посылает http запрос удаленному сервису и сохраняет полученные данные.',
  otyriacx: 'В этом блоке вы можете изменить значения Context переменных.',
  sjxnbmmx: 'Управление переменными',
  bijfidok: 'Этот блок сохраняет все Context в выбранные ЦРМ таблицы.',
  jlwtbsvl: 'Сохранение данных в ЦРМ',
  dznzigyv: 'Этот блок выбирает данные из ЦРМ и сохраняет их в Context переменную',
  eedgbgzb: 'Получение данных из ЦРМ',
  cfkydfnn: 'Этот блок формирует Deep link и сохраняет ее в Context переменную',
  itxxbjie: 'Deep Link',
  ahmgdjce: 'Этот блок переключает пользователя в диалог который находится в Ручном управлении.',
  vueqblta: 'Этот блок отправляет точку на карте google maps.',
  pay_block: 'Этот блок отправляет ссылку для оплаты(средства поступят вам на счет)',
  pay_block_telegram: 'Этот блок отправляет ссылку для оплаты',
  hlucdloa: 'Отправка координат',
  pay_block_name: 'Оплата',
  pay_block_name_telegram: 'Оплата в Telegram',
  yachlkvs: 'Это стандартный блок сообщения.',
  wwkwdwyy: 'Блок сообщений',
  xfrvlwzu: 'Редактировать',
  lbulgcef: 'Копировать',
  utmsdrrf: 'Удалить',
  mhfelrig: 'Сохранение данных',
  lblvtecg: 'Ручное управление',
  gkcskyto: 'Сообщение',
  raartyoz: 'Стартовое сообщение',
  lwrokiwj: 'Группа',
  odidtiyk: 'Вернутся к настройке AI',
  sxvtxixk: 'Добавить блок сообщений',
  bhlztzrv: 'Добавить',
  jbtfzziv: 'условный блок',
  dkaltvqq: 'Deep link',
  emgzzctw: 'Количество знаков',
  coqgmcoe: 'Количество случайных знаков используемых для генерации значения',
  idvqzbwm: 'Параметр',
  axsxmbua: 'Имя параметра который будет встроен в ссылку на вашего бота',
  vgtmzalt: 'Тип значения',
  hvowmmrt: 'Тип значения которое будет передано в параметр ссылки на вашего бота',
  yybcgmiy: 'Случайное',
  wmqamsqd: 'Статичное',
  xiywgstf: 'Context переменная',
  ussumgji: 'ЦРМ',
  mxhsgfeo: 'Значение',
  lewewfuo: 'Значение которое будет передано в параметр ссылки на вашего бота',
  fvbigjlh: 'Из',
  rtddwhrj: 'таблицы',
  lnykglhx: 'Из колонки',
  oizowkqc: 'Из какой колонки взять данные',
  slndukyv: 'Способ выбора',
  bzwkqwkq: 'Способ выбора данных из колонки в ЦРМ таблице',
  jeuzuqeq: 'Текущий пользователь',
  dvdnqrgn: 'Случайно',
  etjlueln: 'Сохранить значение параметра в',
  chexverm: 'Сохраняет значение параметра в Context переменную',
  iunmuyhr: 'Сохранить ссылку в',
  alfqctqt: 'Сохраняет готовую ссылку в Context переменную',
  vzuyuids: 'Из какой колонки сохранять данные',
  hwxmcikd: 'Если',
  ydmwsued: 'колонка',
  wteuczgu: 'Выбирает данные из той строки, в которой для выбранной колонки таблицы выполняется условие',
  xhiasoli: 'Равно',
  note_info: 'Текст оплаты,можно использовать Context переменные так: Покупка {{Context.productName}}',
  title_info: 'Заголовок товара,можно использовать Context переменные так: {{Context.productName}}',
  yufhccsg: 'Не равно',
  nkkischh: 'Больше',
  karsjxwh: 'Меньше',
  fwmjsbfx: 'Больше или равно',
  rdgijbkx: 'Меньше или равно',
  lribsmak: 'Равно(без учета регистра)',
  ogvkjmgl: 'Входит в строку(без учета регистра)',
  sbaornlq: 'Входит в строку',
  bkrbjssb: 'Не входит в строку(без учета регистра)',
  jmolrsjn: 'Не входит в строку',
  dqgtjfme: 'В расчете значения можно использовать Context переменные и javascript',
  edqnrffe: 'Выбрать значение из Context переменных',
  cxtlodkr: 'Показать список знакомых для бота Context переменных(в списке могут быть не все переменные)',
  igayehaa: 'Сохранить в',
  avhmohgs: 'Сохраняет результат в Context переменную',
  vjogekby: 'Вы не создали ни одной',
  hmbyokdu: 'Долгота',
  sum: "Сумма к оплате",
  title: "Заголовок",
  note: "Примечание",
  yisankju: 'Дробные значения писать через точку',
  wsthoqmf: 'пример',
  zxzubqfg: 'Также можно использовать значение из Context переменной',
  yghcorvo: 'Широта',
  nvwjbeaz: 'Переменные',
  ktyifegx: 'Context переменные которые будут изменены',
  fnsvbtnk: 'Слева имя Context переменной,справа ее новое значение',
  rijjftdk: 'примеры',
  lszebicv: 'а можно использовать обычные цифры или текст',
  oznbfumv: 'для сохранения текста нужно обернуть его либо в одинарные \' либо в двойные " кавычки',
  swdklufb: 'переменную',
  bvyrfovw: 'Имя',
  jtxnloae: 'Постоянная',
  dnjffczm: 'Путь к данным',
  euetlxna: 'Что делает блок',
  clunyxda: 'Произвольное название блока на ваш выбор.',
  izdflbct: 'Используется для идентификации блока в нашей системе.',
  awkyyvmy: 'После того как бот вызовет группу',
  vcxxlmod: 'он запустит сообщение которое является стартовым',
  hrmgynvf: 'Стартовое сообщение может быть только одно для каждой группы',
  yhfuxnsf: 'Дождатся ответа удаленного сервиса',
  exist_crm_order: "Дополнить запись о пользователе",
  exist_crm_order_d1: "Если в вашей ЦРМ уже есть запись о данном пользователе",
  exist_crm_order_d2: "она перезапишется новыми данными,если нет то будет создана новая запись",
  quuctsxo: 'Включите данную опцию если хотите дождаться конца работы данного блока',
  request_phone_info: 'Включите данную опцию если хотите чтобы' +
    ' пользователь смог отправить свой номер телефона',
  request_location_info: 'Включите данную опцию если хотите чтобы' +
    ' пользователь смог отправить свое местоположение',
  request_phone: 'Запросить телефон',
  request_location: 'Запросить местоположение',
  zkchxano: 'перед тем как отправить следующее сообщение',
  yokwtfih: 'Подождать ответа',
  telegram_markdown: 'Включить ',
  telegram_markdown_info: 'Включить это опцию для того чтобы задействовать разметку в этом сообщении',
  send_to_groups_info: 'Включить это опцию для того чтобы послать сообщение в выбранные telegram группы/каналы в которых бот является администратором',
  nwrcxdcj: 'После того как бот напечатает это сообщение он будет ожидать ответа пользователя',
  kdijadmk: 'После того как он получит ответ,он сохранит его в указанную',
  frfexhoc: 'и вызовет следующее сообщение',
  rglvmgbv: 'Cохранить данные в ЦРМ',
  lycsxkuq: 'Обрезать пробелы по краям',
  okarmkxq: 'Когда бот получит ответ пользователя,перед сохранением он обрежет пробелы по краям',
  smgbhjnw: 'Клавиатура',
  bqidmqin: 'В telegram несовместима с обычными кнопками',
  jdvrhlcm: 'У названий кнопок клавиатуры есть ограничение на длину символов(примерно 20 русских букв или 40 латинских букв,цифр)',
  drlometr: 'Ссылка',
  egtfhezt: 'Ссылка куда будет направлен http запрос',
  cvotwwbt: 'Тип запроса',
  ijgzhtus: 'Тип http запроса',
  amqgwtxd: 'Заголовки',
  vqvqhuru: 'Заголовки которые будут переданы в http запрос.Слева название заголовка,справа значение заголовка.',
  gssxwuii: 'Добавить переменную',
  tzhgiyco: 'Добавить постоянную',
  qgoqwoth: 'Параметры',
  zvbciozh: 'Параметры которые будут переданы в http запрос.Слева название параметра,справа значение параметра.',
  yjqawxex: 'Сохранение результатов',
  nnywixux: 'Данные которые вы получите от ответа на http запрос(должны быть в формате json)',
  kizvvzxc: 'вы можете сохранить в Context переменные.',
  fsjsuvrl: 'Слева имя Context переменной,справа путь к данным которые хотите сохранить.',
  rzwevviq: 'В описании пути можно использовать Context переменные',
  lfbiunqb: 'например так',
  ocdsvnyn: 'если',
  stjrdnzn: 'в Context.i сохранено значение 0 мы получим такой путь',
  bnxovaee: 'Добавить элементы',
  jundsaur: 'Дополнительные элементы которые будут отправлены вместе с сообщением',
  dawkvfla: 'Видео',
  ezuzsdrs: 'Аудио',
  cwxfkdos: 'Изображение',
  icklsgwu: 'Файл',
  arezkgyu: 'Кнопка',
  cbnmskkf: 'Условие',
  gufqkjeg: 'Сохранить',
  tkkxrjuh: 'Отмена',
  saved: "Изменения сохранены!",
  cond_block_info: "В этом блоке вы можете вызвать нужное сообщение в зависимости от выполнения различный условий.",
  loc_block_info: "Этот блок отправляет точку на карте google maps.",
  var_block_info: "В этом блоке вы можете изменить значения Context переменных.",
  rtc_block_info: "Этот блок переключает пользователя в диалог который находится в Ручном управлении  (после этого " +
    "бот перестает реагировать на пользователя,все его сообщения попадают напрямую в диалог)",
  DL_block_info: "Этот блок формирует Deep link и сохраняет ее в Context переменную",
  api_block_info: "Этот блок посылает http запрос удаленному сервису и сохраняет полученные данные",
  crmo_block_info: "Этот блок выбирает данные из ЦРМ и сохраняет их в Context переменную",
  crm_block_info: "Этот блок сохраняет все Context переменные для текущего пользователя " +
    "(данные полученые в процессе диалога) в выбранные ЦРМ таблицы",
  standart_block_info: "Это стандартный блок сообщения.Он может отсылать текст,медиа файлы,кнопки " +
    "и сохранять ответы пользователя",
  details: 'Подробнее',
  all_bot_users: 'Кол-во посетивших бота',
  all_block_users: 'Кол-во посетивших этот блок',
  block_converion: 'Конверсия блока',
  stop_in_block_users: 'Кол-во остановившихся на этом блоке',
  week: 'Неделя',
  month: 'Месяц',
  halfyear: 'Полгода',
  year: 'Год'
}