import React from 'react';
import jQuery from 'jquery';
import Cookies from 'universal-cookie';
import TopPanel from '../../TopPanel/TopPanel';
import { Scrollbars } from 'react-custom-scrollbars';
import Switch from 'react-toggle-switch';
import { CSVLink } from 'react-csv';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import PopupAll from '../../PopupAll';
import { Modal } from 'antd';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import TransComponent from '../../../TransComponent.js';
import { successUser } from '../../../modules/User/actions';
import { config } from '../../../config.js';
import Pagination from "../../../components/Pagination/Pagination";

const cookies = new Cookies();
var func = require('../../../func.js');


class CRM extends TransComponent {

  constructor(props) {

    super(props);
    this.state = {
      Table: -1,
      TableEdit: -1,
      Tables: [],
      Rows: [],
      Bots: [],
      ContextParams: [],
      Temp: null,
      switched: false,
      Wait: 0,
      visible: false,
      pagination: {
        count: 0,
        pointer: 1
      }
    };

    this.onChangeHandler = config.change.bind(this);
    this.onChangeHandler3 = config.change3.bind(this);
  }

  onChangePagination = (x, pointer) => {
    const state = this.state.pagination;
    state.pointer = pointer;
    this.setState({
      pagination: {...state}
    });

    this.onTableChooseHandler(this.state.CRMtable, this.state.Table);
  }

  onSwitchNotifyHandler = () => {
    if ( !this.props.user.CRMnotify && !this.props.user.Email ) {
      alert('К вашему аккаунту не привязан Email. Вы можете сделать привязку в личном кабинете');
    } else {
      this.setState({
        Wait: ++this.state.Wait
      });
      func.getData('users/edit_new', {
        CRMnotify: !this.props.user.CRMnotify
      }).then(u => {
        this.props.editUserInStore({
          ...u,
          Tariff: this.props.user.Tariff
        });

        this.setState({
          Wait: --this.state.Wait,
        });
      }, e => {
        this.setState({
          Wait: --this.state.Wait
        });
        alert(JSON.stringify(e));
      });
    }
  };

  componentDidUpdate(prevProps) {
    if ( this.props.user && !prevProps.user ) {
      if ( !this.props.user.Tariff.CRM ) {
        window.location.replace(window.location.origin + '/dashboard');
      }
    }
  }

  loadTelegramNotificationRoles = () => {
    this.setState({
      Wait: ++this.state.Wait
    });
    func.getData('notificationRole/get')
        .then(roles => {
          this.setState({
            Wait: --this.state.Wait,
            NotificationRoles: roles
          });
        }, err => {
          this.setState({
            Wait: --this.state.Wait
          });
        });
  }

  componentDidMount() {
    this.t_load();

    var url = config.Backend + '/users/showBots?';
    if ( !(cookies.get('Id') === undefined) ) {
      url += 'Id=' + cookies.get('Id').toString();
    }
    url += '&NotTemplates=1';

    if ( this.props.user && !this.props.user.Tariff.CRM ) {
      window.location.replace(window.location.origin + '/dashboard');
    }

    this.setState({
      Wait: ++this.state.Wait
    });

    jQuery.getJSON(url, function (res) {
      this.setState({
        Wait: --this.state.Wait
      });

      if ( !res.Error ) {
        this.setState({
          Bots: res
        });
      } else {
        alert(JSON.stringify(res));
      }
    }.bind(this));
    url = config.Backend + '/users/all_all_msgs?';
    url += '&Id=' + cookies.get('Id');
    this.setState({
      Wait: ++this.state.Wait
    });

    jQuery.getJSON(url, function (res) {
      this.setState({
        Wait: --this.state.Wait
      });

      if ( !res.Error ) {
        var arr = [];
        res.forEach(e => {
          if ( e.WaitAnswer && e.AnswerName && arr.indexOf(e.AnswerName) == -1 ) {
            arr.push(e.AnswerName);
          } else if ( e.Type == 'Api' && e.Api && e.Api.Save && e.Api.Save.length > 0 ) {
            var saves = e.Api.Save;
            for (var z = 0; z < saves.length; z++) {
              if ( arr.indexOf(saves[z].Key) == -1 ) {
                arr.push(saves[z].Key);
              }
            }
          } else if ( e.Type == 'Process' && Array.isArray(e.Process) && e.Process.length > 0 ) {
            var saves = e.Process;
            for (var z = 0; z < saves.length; z++) {
              if ( arr.indexOf(saves[z].Key) == -1 ) {
                arr.push(saves[z].Key);
              }
            }
          } else if ( e.Type == 'DeepLink' ) {
            var saves = [ e.AnswerName, e.AnswerName2 ];
            for (var z = 0; z < saves.length; z++) {
              if ( arr.indexOf(saves[z]) == -1 ) {
                arr.push(saves[z]);
              }
            }
          }
          arr.sort();
        });

        this.setState({
          ContextParams: arr
        });
      } else {
        alert(JSON.stringify(res));
      }
    }.bind(this));

    this.setState({
      Wait: ++this.state.Wait
    });

    func.getData('crm/tables').then(ok => {
      this.setState({
        Wait: --this.state.Wait,
        Tables: ok.filter(({ Module }) => !Module)
      });
    }, e => {
      this.setState({
        Wait: --this.state.Wait
      });

      alert(JSON.stringify(e));
    });

    this.loadTelegramNotificationRoles();
  }

  add = () => {
    var s = this.state;
    this.setState({
      Wait: ++this.state.Wait
    });

    func.getData('crm/table_add', {
      Name: 'Таблица'
    }).then((ok) => {
      s.Tables.push(ok);
      this.setState({
        Tables: s.Tables,
        Wait: --this.state.Wait
      });
    }, e => {
      this.setState({
        Wait: --this.state.Wait
      });

      alert(JSON.stringify(e));
    });
  };

  onTableDeleteHandler = () => {
    var s = this.state;
    this.setState({
      Wait: ++this.state.Wait
    });

    func.getData('crm/table_delete', {
        CRMtable: s.Tables[s.Table]
      }
    ).then((ok) => {
      s.Tables.splice(s.Table, 1);
      this.setState({
        Tables: s.Tables,
        Table: -1,
        Orders: [],
        TableEdit: -1,
        Temp: null,
        Wait: --this.state.Wait
      });
    }, e => {
      this.setState({
        Wait: --this.state.Wait
      });

      alert(JSON.stringify(e));
    });
  };

  onTableChooseHandler = async (table, i) => {
    await this.setState({
      Wait: ++this.state.Wait,
      CRMtable: table
    });

    func.getData('crm/table_rows2', {
      CRMtable: {
        _id: table._id,
      },
      page: this.state.pagination.pointer
    }).then((ok) => {
      const pagination = this.state.pagination;
      pagination.count = ok.Count;

      this.setState({
        Table: i,
        Rows: ok.Rows,
        TableEdit: -1,
        Temp: null,
        Wait: --this.state.Wait,
        pagination: pagination
      });
    }, e => {
      this.setState({
        Wait: --this.state.Wait
      });

      alert(JSON.stringify(e));
    });
  };

  onTableEditHandler = () => {
    var s = this.state;
    this.setState({
      TableEdit: s.Table,
      Temp: JSON.parse(JSON.stringify(s.Tables[s.Table]))
    });
  };

  onTableSaveHandler = () => {
    var s = this.state;
    this.setState({
      Wait: ++this.state.Wait
    });

    func.getData('crm/table_edit', {
      CRMtable: s.Temp
    }).then((ok) => {
      s.Tables[s.TableEdit] = ok;
      this.setState({
        Tables: s.Tables,
        Wait: --this.state.Wait
      }, () => {
        alert('Изменения сохранены!');
        this.onTableChooseHandler(ok, s.TableEdit);
      })
    }, e => {
      this.setState({
        Wait: --this.state.Wait
      });

      alert(JSON.stringify(e));
    });
  };

  onRowSaveHandler = (row, index) => {
    var s = this.state;
    this.setState({
      Wait: ++this.state.Wait
    });

    func.getData('crm/table_row_change', {
      CRMorder: row,
      CRMtable: s.Tables[s.Table]
    }).then((ok) => {
      this.setState({
        Wait: --this.state.Wait
      });
    }, e => {
      this.setState({
        Wait: --this.state.Wait
      });

      alert(JSON.stringify(e));
    });
  };

  onRowDeleteHandler = (row, index) => {
    var s = this.state;
    this.setState({
      Wait: ++this.state.Wait
    });

    func.getData('crm/table_row_delete2', {
      CRMorder: row,
      CRMtable: s.Tables[s.Table]
    }).then((ok) => {
      s.Rows.splice(index, 1);
      this.setState({
        Rows: s.Rows,
        Wait: --this.state.Wait
      });
    }, e => {
      this.setState({
        Wait: --this.state.Wait
      });

      alert(JSON.stringify(e));
    });
  };

  onFieldDeleteHandler = (e, i) => {
    var s = this.state;
    s.Temp.Fields.splice(i, 1);
    this.setState({
      Temp: s.Temp
    });
  };

  onFieldAddHandler = () => {
    var s = this.state;
    s.Temp.Fields.push({
      Name: '',
      Key: '',
      Type: 'String',
      Required: false
    });
    this.setState({
      Temp: s.Temp
    });
  };

  info = () => {
    Modal.info({
      title: 'Инструкция',
      zIndex: '10000',
      width: '810px',
      content: (
        <div>
          <ul>
            <li>
              <p>Создайте ЦРМ таблицу</p>
            </li>

            <li>
              <p>Добавьте в конструкторе блок сохранения данных в ЦРМ</p>
            </li>

            <li>
              <p>
                Когда этот блок будет вызван он добавит запись в выбранные вами ЦРМ таблицы") }
              </p>
            </li>

            <li>
              <p>
               Блок сохранит состояние всех Context переменных пользователя на момент его вызова
              </p>
            </li>

            <li>
              <p>
                Зайдите в настройки таблицы и добавьте нужные вам поля для отображения
              </p>
            </li>

            <li>
              <p>
                Здесь есть также зарезервированные таблицы для Модулей(туда записи"  добавляются автоматически)
              </p>
            </li>
          </ul>
        </div>
      ),
      onOk() {
      },
    });
  };

  Table() {
    var s = this.state;

    const thereIsAtLeastOneTelegramRecord = s.Rows
        .some(({ Soc }) => Soc === 'Telegram');

    var t = s.Tables[s.Table];
    var csvData = [];
    var headers = t.Module != 'reg' ? [ this.t('Дата'), this.t('Время'), this.t('Имя') ]
      : [ this.t('Имя') ];

    if (thereIsAtLeastOneTelegramRecord) {
      headers.push('Username');
    }

    var fields_th = t.Fields.map((elem, ind) => {
      const e = elem;
      const i = ind;
      headers.push(e.Name);
      return (
        <th key={ e._id } className={ "tc" }>{ t.Module ? this.t(e.Name) : e.Name }</th>
      );
    });

    csvData.push(headers);

    var rows = s.Rows.map((elem, ind) => {
      const e = elem;
      const row_i = ind;
      var soc = '';

      switch (e.Soc) {
        case 'FB':
          soc = 'Facebook';
          break;
        case 'Ok':
          soc = 'Одноклассники';
          break;
        case 'Vk':
          soc = 'ВКонтакте';
          break;
        case 'Telegram':
          soc = 'Telegram';
          break;
      }
      var arr = [];
      var fields_td = t.Fields.map((elem, ind) => {
        const f = elem;
        const i = ind;

        var data = (e.Context[f.Key] ? e.Context[f.Key].file : '') || e.Context[f.Key];
        if ( typeof data === 'object' ) {
          data = JSON.stringify(data);
        }

        var editable = typeof data === 'string' && f.Type == 'String';

        arr.push(data);

        if ( f.Type == 'File' ) {
          if ( !e.Context[f.Key] || !e.Context[f.Key].file ) {
            if ( e.Soc == 'Telegram' && data && data.length > 20 && e.Bot ) {
              func.getData(config.TelegramApi + 'bot' + e.Bot.TelegramToken + '/getFile?file_id='
                + data).then((ok) => {
                if ( ok.ok ) {
                  e.Context[f.Key] = {
                    file: config.TelegramApi
                      + 'file/bot' + e.Bot.TelegramToken + '/' + ok.result.file_path
                  };
                  this.setState({
                    Tables: s.Tables
                  });
                }
              })
            }
          }

          if ( typeof data == 'string' && data.indexOf('http') != -1 ) {
            data = <a href={ data } download>Скачать</a>;
          }
        }
        if ( f.Type == 'String' && data && data.length > 100 ) {
          return (<td key={ f._id + e._id } className={ 'tc' }>
            <textarea value={data} name={ "Rows[" + row_i + "].Context[\"" + f.Key + "\"]" }
                      onChange={ this.onChangeHandler3 }>
            </textarea>
          </td>);
        } else {
          if ( editable ) {
            return (<td key={ f._id + e._id } className={ 'tc' }>
              <input type={ "text" } name={ "Rows[" + row_i + "].Context[\"" + f.Key + "\"]" }
                     value={ data } onChange={ this.onChangeHandler3 }/>
            </td>);
          } else {
            return (<td key={ f._id + e._id } className={ 'tc' }>{ data }</td>);
          }
        }
      });

      var date = new Date(e.created_at).toLocaleDateString('ru-RU');
      var time = new Date(e.created_at).toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit'
      });
      var name = e.Name;
      var arr0 = t.Module != 'reg' ? [ date, time, name ] : [ name ];
      if (thereIsAtLeastOneTelegramRecord) {
        arr0.push(e.Username);
      }
      csvData.push(arr0.concat(arr));
      var onRowDeleteHandler = () => this.onRowDeleteHandler(e, row_i);
      var onRowSaveHandler = () => this.onRowSaveHandler(e, row_i);

      return (
        <tr key={ e._id }>
          { t.Module != 'reg' ? <td>{ date }</td> : null }

          { t.Module != 'reg' ? <td>{ time }</td> : null }

          {/* <td className={'tc'}>{e.Bot.Name}</td>*/ }

          {/*<td>{e.ChatUser}</td>*/ }

          <td className={ "tl" }>{ name }</td>

          {thereIsAtLeastOneTelegramRecord &&
            (
              <td className={ "tl" }>{ e.Username }</td>
            )
          }

          {/* <td className={"tc"}>{e.Soc}</td>*/ }

          { fields_td }

          <td className={ "tc" }>
            <button className={ "btn__all--small" } onClick={ onRowDeleteHandler }>
              <i className="fas fa-trash-alt"></i>
            </button>

            <button
              className={ "btn__all--small" }
              onClick={ onRowSaveHandler }>
              Сохранить
            </button>
          </td>
        </tr>);
    });

    return (
      <div>
        <table id={ "table-to-xls" } className={ "table mt_30" }>
          <thead>
          <tr className={ "table_f " }>
            { t.Module != "reg" ? <th className={ "table_left" }>Дата</th> : null }

            { t.Module != "reg" ? <th className={ "table_left" }>Время</th> : null }

            {/*<th>Бот</th>*/ }

            {/*<th>Id</th>*/ }

            <th className={ "tl" }>Имя</th>

            {thereIsAtLeastOneTelegramRecord &&
              (
                <th className={ "tl" }>Username</th>
              )
            }

            {/*<th>Соцсеть</th>*/ }

            { fields_th }

            <th>
              <button className={ "btn__all--small mr5" } onClick={ this.onTableEditHandler }>
                { this.t("Настройки") }
              </button>

              <a href={config.Backend + "/crm/export?Id=" + cookies.get('Id').toString() + "&table=" + this.state.CRMtable._id + "&format=csv"} className={ "btn__all--small mr5" }>CSV</a>
              <a href={config.Backend + "/crm/export?Id=" + cookies.get('Id').toString() + "&table=" + this.state.CRMtable._id + "&format=xls"} className="btn__all--small mr5">XLS</a>
              <button className={ "btn__all--small" } onClick={ this.onTableDeleteHandler }>
                Удалить таблицу
              </button>
            </th>
          </tr>
          </thead>

          <tbody>
          { rows }
          </tbody>
        </table>
        <Pagination section={'crm'} onChange={this.onChangePagination} count={this.state.pagination.count} pointer={this.state.pagination.pointer}/>
        <br/><br/>
      </div>
    );
  }

  onCheckBotHandler = (event, bot, i) => {
    var s = this.state;
    var t = s.Temp;
    if ( event.target.checked ) {
      if ( t.Bots.indexOf(bot._id) == -1 ) {
        t.Bots.push(bot._id);
        this.setState({
          Temp: t
        });
      }
    } else {
      if ( t.Bots.indexOf(bot._id) != -1 ) {
        t.Bots.splice(t.Bots.indexOf(bot._id), 1);
        this.setState({
          Temp: t
        });
      }
    }
  };

  change_key = (e, i) => {
    var s = this.state;
    if ( e ) {
      s.Temp.Fields[i].Key = e;
    } else {
      s.Temp.Fields[i].Key = '';
    }
    this.setState({
      Temp: s.Temp
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  onChangeNotifyBotRoleHandler = ({ target: { checked } }, role, field) => {
    field.NotifyBotRoles = field.NotifyBotRoles || [];

    if (checked && !field.NotifyBotRoles.includes(role._id)) {
      field.NotifyBotRoles.push(role._id);
    } else if (!checked && field.NotifyBotRoles.includes(role._id)) {
      field.NotifyBotRoles = field.NotifyBotRoles.filter(roleId => roleId !== role._id);
    }

    field.NotifyBotRoles = field.NotifyBotRoles
        .filter(roleId => this.state.NotificationRoles
            .some(role => role._id === roleId));

    this.setState({
      Temp: this.state.Temp
    });
  }

  onCancelHandler = () => this.setState({TableEdit: -1, Temp: null});

  TableEdit() {
    var s = this.state;
    var t = s.Temp;
    var bots = s.Bots.map((elem, ind) => {
      const e = elem;
      const i = ind;

      var onCheckBotHandler = (evt) => this.onCheckBotHandler(evt, e, i);
      return (
        <div className={ "form-group" }>
          <label>
            <span className="row__grid list_bot_crm">
              <span className={ "col__3" }>{ e.Name }</span>

              <input
                checked={ t.Bots.indexOf(e._id) != -1 }
                onChange={ onCheckBotHandler }
                type={ "checkbox" }/>
            </span>
          </label>
          <br/>
        </div>
      );
    });

    var fields = t.Fields.map((elem, ind) => {
      const e = elem;
      const i = ind;
      //alert(JSON.stringify(s.ContextParams));
      var params = s.ContextParams.map((elem, ind) => {
        const p = elem;
        const j = ind

        return (
          <option value={ p }>{ p }</option>
        );
      });
      // <input className={'chat_input'} value={e.Key} name={"Temp.Fields["+i+"].Key"}
      // onChange={this.onChangeHandler} type={"text"}/>
      //<option value={""}>{""}</option>

      var onFieldDeleteHandler = () => this.onFieldDeleteHandler(e, i);

      return (
        <tr>
          <td>
            <input
              className={ "chat_input" }
              value={ e.Name }
              name={ "Temp.Fields[" + i + "].Name" }
              onChange={ this.onChangeHandler } type={ "text" }/>
          </td>

          <td>
            <select
              className={ "chat_input" }
              value={ e.Key }
              name={ "Temp.Fields[" + i + "].Key" }
              onChange={ this.onChangeHandler }>
              <option value=""></option>

              <option value="MessageBlock">Блок сохранения</option>

              { params }
            </select>
          </td>

          <td>
            <select
              className={ "chat_input" }
              value={ e.Type }
              name={ "Temp.Fields[" + i + "].Type" }
              onChange={ this.onChangeHandler }>
              <option value={ "String" }>Строка</option>

              <option value={ "File" }>Файл</option>
            </select>
          </td>

          <td>
            <input
              className={ "chat_input" }
              checked={ e.Notify }
              name={ "Temp.Fields[" + i + "].Notify" }
              onChange={ this.onChangeHandler }
              type={ "checkbox" }/>
          </td>

          <td>
            <input
              className={ "chat_input" }
              checked={ e.NotifyBot }
              name={ "Temp.Fields[" + i + "].NotifyBot" }
              onChange={ this.onChangeHandler } type={ "checkbox" }/>
          </td>

          <td>
            {e.NotifyBot ? (
              s.NotificationRoles && !s.NotificationRoles.length && (
                  <span>Похоже вы еще не создали ни одной роли.</span>
              )
              ||
              (
                <table>
                  {(s.NotificationRoles || []).map(role => (
                    <tr>
                      <td>
                        {role.Name}
                      </td>
                      <td>
                        <input
                          checked={ e.NotifyBotRoles && e.NotifyBotRoles.includes(role._id) }
                          onChange={ event =>
                              this.onChangeNotifyBotRoleHandler(event, role, e) }
                          type={ "checkbox" }/>
                      </td>
                    </tr>
                  ))}
                </table>
              )
            )
            :
              (
                <span>Сначала включите поле в telegram уведомление.</span>
              )
            }
          </td>

          {/*<td>
                    <input checked={e.Required} name={"Temp.Fields["+i+"].Required"} onChange={this.onChangeHandler} type={"checkbox"}/>
                </td>*/ }

          <td className={ "tc" }>
            <button className={ "btn__all--small" } onClick={ onFieldDeleteHandler }>
              <i className="fas fa-trash-alt"></i>
            </button>
          </td>
        </tr>
      );
    });

    return (
      <div className={ "table" }>
        <hr/>

        <h3>Настройки таблицы</h3>

        <div className="form-group mt_15">
          <label>Название</label>

          <input
            className={ "chat_input" }
            value={ t.Name }
            name={ "Temp.Name" }
            onChange={ this.onChangeHandler }
            type={ "text" }/>
        </div>

        <hr/>
        {/*<div className={"fw_menu mb15"}>Боты:</div>
                {bots}*/ }
        Поля:
        <table className={ "table" }>
          <thead>
          <tr className={ "table_f" }>
            <th className={ "text_left" }>Отображаемое название</th>
            <th>Название переменной</th>
            <th>Формат</th>
            <th>Включить поле в email уведомление</th>
            <th>Включить поле в telegram уведомление</th>
            <th>Роли которые получат поле в telegram уведомлении</th>

            {/*<th>Не показывать строки без этого поля</th>*/ }

            <th>
              <button
                className={ "btn__all--small" }
                onClick={ this.onFieldAddHandler }>
                <i className="fas fa-plus"/>
              </button>
            </th>
          </tr>
          </thead>

          <tbody>
          { fields }
          </tbody>
        </table>

        <button
          className={ "btn__all--small bg_shadow mr5" }
          onClick={ this.onTableSaveHandler }>
          Сохранить
        </button>

        <button
          className={ "btn__all--small" }
          onClick={ this.onCancelHandler }>
          Отмена
        </button>
      </div>
    );
  }

  render() {
    var s = this.state;

    // таблицы для модулей скрыты так как модули отключены
    var tables = s.Tables.map((elem, ind) => {
      const e = elem;
      const i = ind;
      var onTableChooseHandler = () => {
        this.onTableChooseHandler(e, i)
      };
      return (
        <button
          key={ elem._id }
          onClick={ onTableChooseHandler }
          className={ s.Table == i
          ? "mt_5 btn_all mb_all mr5" : "mt_5 btn_all mr5" }>
          { e.Module ? this.t(e.Name) : e.Name }
        </button>
      );
    });

    return (
      <div>
        { this.state.Wait != 0 ? this.Load() : null }

        <TopPanel/>

        <PopupAll/>

        <div className="grow_main_tariffs">
          <button className={ "manual__btn_crm" } onClick={ this.info }></button>

          <Scrollbars style={ {
            height: "100%"
          } }>
            <div className="overlay_chart">
              <div className="col-sm-12">
                <div className="row__grid">
                  { this.props.user ?
                    <div className={ "overlay_chart grid_w-100" }>
                      <div className="b-layout-form2__group">
                        <div className="b-layout-form2__cell">
                          <label className={ " fw__panel" }>
                            <span>Отключить | Включить:</span>
                          </label>
                        </div>

                        <div className="b-layout-form2__cell">
                          <div className="row__grid grid__align--center">
                            <Switch onClick={ this.onSwitchNotifyHandler } on={ this.props.user.CRMnotify }/>
                          </div>
                        </div>
                      </div>

                      { tables }

                      <button onClick={ this.add } className={ "btn_all mt_5  " }>
                        <i className="fas fa-plus"></i>
                      </button>

                      { s.TableEdit != -1 && s.Temp ? this.TableEdit() :
                        (s.Table != -1 ? this.Table() : null) }
                    </div> : null }
                </div>
              </div>
            </div>
          </Scrollbars>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
  };
};

const mapDispatchToProps = {
  editUserInStore: successUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CRM))
