import React, { Component } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import jQuery from "jquery";
import Cookies from "universal-cookie";
import TransComponent from "../TransComponent.js";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { setUser, setVkGroups } from "../modules/User/actions";
import { config } from "../config.js";

class PopupAll extends TransComponent {
  constructor(props) {
    super(props);
    this.state = {
      ShowPopularButtonsPopup: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      !prevProps.user &&
      this.props.user &&
      this.props.user.VkToken &&
      !this.props.vkGroups &&
      !this.state.ShowPopularButtonsPopup
    ) {
      var url2 = config.Backend + "/vk/getVkGroups?Id=" + this.props.user._id;
      jQuery.getJSON(
        url2,
        function (res) {
          if (!res.Error) {
            this.props.addVkGroupsToStore(res);
          } else {
            this.setState({
              ShowPopularButtonsPopup: true,
            });
          }
        }.bind(this)
      );
    }
  }

  componentDidMount() {
    this.t_load();
  }

  onHidePopupsHandler = () => {
    this.setState({
      ShowPopularButtonsPopup: false,
    });
  };

  onVkRedirectHandler = () => {
    window.location.replace(
      "https://oauth.vk.com/authorize?client_id=" +
        config.VkAppId +
        "&redirect_uri=" +
        config.Frontend +
        "/vk" +
        "&response_type=code&scope=photos,video,audio,offline,groups,email" +
        "&state=log"
    );
  };

  PopularBlockPopup() {
    return (
      <div className="preloader_bots">
        <div className="preloader_bots_2 popupW">
          <div
            className="analyse_content"
            style={{
              height: "215px",
            }}
          >
            <Scrollbars
              style={{
                height: "100%",
              }}
            >
              <div className="analyse_content_2">
                <h1>
                  Что-то пошло не так,возможно ваш ключ доступа вконтакте
                  устарел!
                </h1>

                <button
                  className={"btn__all--small bg_shadow"}
                  onClick={this.onVkRedirectHandler}
                >
                  Обновить
                </button>
              </div>
            </Scrollbars>

            <div className="close_popup_btn">
              <button
                className={"btn_tchatbot close"}
                onClick={this.onHidePopupsHandler}
                type="button"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const s = this.state;

    return (
      <div>{s.ShowPopularButtonsPopup ? this.PopularBlockPopup() : null}</div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.user.user,
    vkGroups: state.user.vkGroups,
  };
};

const mapDispatchToProps = {
  setUser: setUser,
  addVkGroupsToStore: setVkGroups,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PopupAll));
