import React from 'react';
import jQuery from 'jquery';
import Cookies from 'universal-cookie';
import LeftPanel from '../../LeftPanel';
import TopPanel from '../../TopPanel/TopPanel';
import {Scrollbars} from 'react-custom-scrollbars';
import PopupAll from '../../PopupAll';
import TextareaAutosize from 'react-autosize-textarea';
import TransComponent from '../../../TransComponent';
import User from './User';
import Message from './Message';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import { config } from '../../../config.js';

const cookies = new Cookies();
var func = require('../../../func.js');

const intevals = [undefined, undefined];

class History extends TransComponent {

  constructor(props) {
    super(props);
    this.state = {
      Users: [],
      History: [],
      Wait: 0,
      Text: '',
      MsgsCount: 10,
      LoadMoreEnable: true,
      Filter: false,
      pointer: 1,
      UsersLoadMoreEnable: true
    };
    this.onChangeHandler = config.change.bind(this);
  }

  show = (u, check_new_msgs, load_more) => {
    var add = (load_more ? 10 : 0);
    var cnt = check_new_msgs || load_more ? (this.state.MsgsCount + add ) : 10;
    if ( !check_new_msgs && !load_more ) {
      this.setState({
        Wait: ++this.state.Wait
      });
    }
    this.setState({
      MsgsCount: cnt
    }, () => {

      func.getData('bot/history', {
        Period: 'All',
        Times: 1,
        Bot: this.props.bot._id,
        User: u.User,
        MsgsCount: this.state.MsgsCount
      }).then(function (h) {
        this.setState({
          History: h.reverse(),
          ChatUser: u
        }, () => {
          if ( !check_new_msgs && !load_more ) {
            this.setState({
              Wait: --this.state.Wait
            });
            setTimeout(() => {
              this.scrollToBottom();
              this.messageList.addEventListener('scroll', this.onScrollHandler);
            }, 0);
          }
        })
      }.bind(this), function (err) {
        this.setState({
          Wait: --this.state.Wait
        });
      }.bind(this));
    });
  };

  load = (add = false) => {
    const body = {
      Period: 'All',
      Times: 1,
      Page: this.state.pointer,
      Bot: cookies.get('BotId')
    };

    if (this.state.Filter) {
      body.ChatWithAdmin = true;
    }

    func.getData('bot/time_users', body).then((u) => {
      this.loadUsers(u, add);
    });
  };

  componentDidMount () {
    this.t_load();

    this.load();
    intevals[0] = setInterval(() => {//подгрузка сообщение
      if (this.state.ChatUser) {
        this.show(this.state.ChatUser, true);
      }
    }, 10000);
  }

  componentWillUnmount () {
    clearInterval(intevals[0]);
    clearInterval(intevals[1]);
  }

  sendAnswer = (text2) => {
    var s = this.state;
    var text = text2 || s.Text;
    var route = '';//facebook app.js
    if (this.props.bot.BindTo == 'Telegram') {
      route = 'telegram/';
    }
    else if (this.props.bot.BindTo == 'Ok') {
      route = 'ok/';
    }
    else if (this.props.bot.BindTo == 'Vk') {
      route = 'vk/';
    }
    else if (this.props.bot.BindTo == 'Viber') {
      route = 'viber/';
    } else if (this.props.bot.BindTo == 'WhatsApp') {
      route = 'whatsapp/';
    }

    s.History.push({
      Bot: this.props.bot._id,
      User: s.ChatUser.User,
      Text: text,
      Owner: 'Admin',
      created_at: new Date().valueOf()
    });

    this.setState({
      History: s.History,
      Text: text2 ? s.Text : ''
    });

    func.getData(route + 'sendMsgToAdminDialog', {
      BotId: this.props.bot._id,
      SocId: s.ChatUser.User,
      Text: text,
      CurrentUserId: cookies.get('Id')
    }).then((res) => {

    });
  };

  loadUsers = (users, add = false) => {
    if (this.props.bot) {
      var s = this.state;
      users = users || s.Users;
      var route = 'fb';
      if ( this.props.bot.BindTo == 'Telegram' ) {
        route = 'telegram';
      }
      else if ( this.props.bot.BindTo == 'Ok' ) {
        route = 'ok';
      }
      else if ( this.props.bot.BindTo == 'Vk' ) {
        route = 'vk';
      }
      else if ( this.props.bot.BindTo == 'Viber' ) {
        route = 'viber';
      } else if (this.props.bot.BindTo  == 'WhatsApp' ) {
        route = 'whatsapp';
      }
      var url = config.Backend + '/' + route + '/getUsers?BotId=' + this.props.bot._id;
      url += '&Page=' + this.state.pointer;
      if (this.state.Filter) {
        url += '&ChatWithAdmin=true';
      }
      if ( !(cookies.get('Id') === undefined) ) {
        url += '&CurrentUserId=' + cookies.get('Id').toString();
      }
      jQuery.getJSON(url, function (res) {
        if ( !res.Error ) {
          res.Users.forEach(u => {
            users.forEach(u2 => {
              if ( u.Id == u2.User || u.Psid == u2.User || u.recipientId == u2.User ) {
                u2.ChatWithAdmin = u.ChatWithAdmin;
                u2.user_id = u._id;
                u2.Name = u.first_name ? (u.first_name + ' ' + u.last_name) : (u.name || u2.User);
                u2.Context = u.Context;
                u2.avatar = u.avatar;
                if (s.ChatUser && s.ChatUser.user_id == u2.user_id) {
                  this.setState({
                    ChatUser: u2
                  });
                }
              }
            })
          });

          const _users = this.state.Users;

          if (add) {
            users.map((u) => _users.push(u))
          }

          if (users.length === 0) {
            this.state.UsersLoadMoreEnable = false;
          }

          this.setState({
            Users: add ? _users : users
          });
        }
      }.bind(this));
    }
  };

  unlockUser = (user) => {
    var route = 'fb';
    if ( this.props.bot.BindTo == 'Telegram' ) {
      route = 'telegram';
    }
    else if ( this.props.bot.BindTo == 'Ok' ) {
      route = 'ok';
    }
    else if (this.props.bot.BindTo == 'Vk') {
      route = 'vk';
    }
    else if (this.props.bot.BindTo == 'Viber') {
      route = 'viber';
    } else if (this.props.bot.BindTo == 'WhatsApp') {
      route = 'whatsapp';
    }
    var url = config.Backend + '/' + route + '/unlockUser?BotId=' + this.props.bot._id;
    url += '&UserId=' + user.user_id;
    if ( !(cookies.get('Id') === undefined) ) {
      url += '&CurrentUserId=' + cookies.get('Id').toString();
    }
    jQuery.getJSON(url, function (res) {
      if (!res.Error) {
        this.loadUsers();
      }
    }.bind(this))
  };

  lockUser = (user) => {
    var route = 'fb';
    if (this.props.bot.BindTo == 'Telegram') {
      route = 'telegram';
    }
    else if (this.props.bot.BindTo == 'Ok') {
      route = 'ok';
    }
    else if (this.props.bot.BindTo == 'Vk') {
      route = 'vk';
    }
    else if (this.props.bot.BindTo == 'Viber') {
      route = 'viber';
    } else if (this.props.bot.BindTo == 'WhatsApp') {
      route = 'whatsapp';
    }
    var url = config.Backend + '/' + route + '/unlockUser?BotId=' + this.props.bot._id;
    url += '&UserId=' + user.user_id;
    url += '&Lock=true';
    if ( !(cookies.get('Id') === undefined) ) {
      url += '&CurrentUserId=' + cookies.get('Id').toString();
    }
    jQuery.getJSON(url, function (res) {
      if (!res.Error) {
        this.loadUsers();
      }
    }.bind(this))
  };

  onProcessHandler = (i) => {
    var s = this.state;
    s.History[i].before_process = !s.History[i].before_process;
    this.setState({
      History: s.History
    });
  };

  History () {
    var s = this.state;

    var msgs = this.state.History.map((elem, ind) =>
      <Message
        elem={elem}
        ind={ind}
        onProcessHandler={this.onProcessHandler}
        Users={s.Users}/>);
    var users = this.state.Users
      .filter(user => user.ChatWithAdmin || !s.Filter)
      .map((s, i) => <User key={s._id} elem={s} ind={i} onShowHandler={this.show}/>);

    var onMakeRefHandler = (div) => {
      this.messageList = div;
    };

    var onMakeRefHandlerUsers = (div) => {
      this.usersList = div;
    };

    var onSendAnswerHandler = () => this.sendAnswer();
    var onUnlockHandler = () => this.unlockUser(s.ChatUser);
    var onLockHandler = () => this.lockUser(s.ChatUser);
    return (
      <div>
        <br/>
        <div className="row__grid">
          <div className="col__3" >
            <div>
              <div className="left__user__chat--panel" style={{height: "100vh", 'overflowY': 'scroll'}} onScroll={this.onUsersScrollHandler} ref={onMakeRefHandlerUsers}>
                <ul>
                  {users}
                </ul>
              </div>
            </div>
          </div>

          <div className="col__7 b-page__content-wrap ctn_wrap" style={{
            height: "calc(100vh - 68px)"}}>
            {!this.state.ChatUser ?
              <div style={{
                height: "70vh"
              }} className="row__grid align-content-center justify-content-center">
                <div className="dialog_message_block">
                  <img src="/img/dialog.png"/>

                  <p className={"tc mt_15"}>
                    {s.Users.length == 0 ? 'Вашему боту пока никто не писал'
                    : 'Выберите один из существующих диалогов'}
                  </p>
                </div>
              </div> :
              <div
                className="MessageList pl"
                style={{
                  height: "70vh",
                  position: "relative"
                }}
                ref={onMakeRefHandler}>
                {msgs}
              </div>}
            {this.state.ChatUser ?
              <div className="form-group bd pd-t-20 pd-b-20 pd-x-20 mt_5">
                <div className="row__grid">
                  <div className="col__7 row__grid justify-content-center align-content-center">
                    <TextareaAutosize
                      placeholder={'Текст сообщения...'}
                      rows={3}
                      onChange={this.onChangeHandler}
                      value={s.Text}
                      className={"button__message_textarea w-100"}
                      name="Text">
                    </TextareaAutosize>
                  </div>

                  <div className="col__2 row__grid justify-content-center align-content-center">
                    <button className={"button__message"} onClick={onSendAnswerHandler}>
                      {s.Load ? 'Подождите...' : 'Отправить сообщение'}
                      <i className="fz16 ml5 far fa-paper-plane"></i>
                    </button>
                  </div>

                  <div className="col__1 row__grid justify-content-center align-content-center">
                    <input type="file" onChange={this.onLoadFileHandler}
                      onClick={this.onClearInputHandler} className="file_message"
                      data-title="Товары"/>
                  </div>

                  <div className="col__3 row__grid">
                    {s.ChatUser && s.ChatUser.ChatWithAdmin ?
                      <button className="btn__all--small" onClick={onUnlockHandler}>
                        Включить
                      </button>
                      : <button className="btn__all--small" onClick={onLockHandler}>
                        Выключить
                      </button>}
                  </div>
                </div>
              </div> : null}
          </div>
        </div>
      </div>);
  }

  onClearInputHandler = (e) => {
    e.target.value = null;
  };

  onLoadFileHandler = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    var s = this.state;
    this.setState({
      Load: true
    });
    reader.onloadend = () => {
      var url = reader.result;
      if (file) {
        var fd = new FormData;
        fd.append('File', file);
        jQuery.ajax({
          method: 'POST',
          processData: false,
          contentType: false,
          url: config.Backend + '/content/file',
          success: function (msg) {
            var link = config.Backend + '/content/getFile?Link=' + msg;
            this.sendAnswer(link);
            this.setState({
              Load: false
            });
          }.bind(this),
          data: fd
        });
      }
    };
    reader.readAsDataURL(file);
  };

  onScrollHandler = () => {
    var s = this.state;
    const scrollContainer = this.messageList.scrollTop;
    if (scrollContainer < 50 && this.state.LoadMoreEnable) {
      const height = this.messageList.clientHeight;
      var msgs = s.History.length;
      this.messageList.scrollTop = height * (msgs / (msgs + 10));
      this.show(s.ChatUser, false, true);
      this.setState({
        LoadMoreEnable: false
      }, () => {
        setTimeout(() => {
          this.setState({
            LoadMoreEnable: true
          });
        }, 3000);
      });
    }
  };

  onUsersScrollHandler = async () => {
    const usersScrollContainer = this.usersList.scrollTop * 2;
    const usersScrollContainerHeight = this.usersList.scrollHeight;

    if ((usersScrollContainerHeight - usersScrollContainer) <= 100 && this.state.UsersLoadMoreEnable) {
      this.state.UsersLoadMoreEnable = false;
      await this.setState({
        pointer: this.state.pointer+1
      }, async () => {
        await this.load(true);
        setTimeout(async () => {
          this.state.UsersLoadMoreEnable = true;
        }, 3000);
      });
    }
  };

  scrollToBottom = () => {
    try {
      var scrollHeight = this.messageList.scrollHeight;
      const height = this.messageList.clientHeight;
      const maxScrollTop = scrollHeight - height;
      this.messageList.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    } catch (e) {
      ;
    }
  };

  filter = () => {
    this.setState({
      Filter: true,
      pointer: 1,
      Users: [],
      History: [],
      ChatUser: undefined
    }, () => {
      // reload dialogs with filter
      this.load();
    });
  }

  clearFilter = () => {
    this.setState({
      Filter: false,
      pointer: 1,
      Users: [],
      History: [],
      ChatUser: undefined
    },() => {
      // reload dialogs without filter
      this.load();
    });
  }

  render () {
    var s = this.state;
    return (
      <div>
        {this.state.Wait != 0 ? this.Load() : null}

        <TopPanel/>

        <div className="b-page">
          <div className="b-page__row">
            <PopupAll/>

            <LeftPanel User={this.props.user}/>

            <div className=" b-page__content-wrap" style={{
              height: "calc(100vh - 68px)"}}>
              <Scrollbars
                style={{
                  height: "100%"
                }}>
                <div className="dashboard-page">
                  <button
                    onClick={this.clearFilter}
                    className={'mr5 btn__all--small' + (!s.Filter ? ' mb_all' : '')}>
                    Все
                  </button>

                  <button
                    onClick={this.filter}
                    className={'mr5 btn__all--small' + (s.Filter ? ' mb_all' : '')}>
                    Неотвеченные
                  </button>

                  <div className="dashboard-page__items">
                    {this.History()}
                  </div>
                </div>
              </Scrollbars>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
    bot: (state.bots.bots || []).find(bot => bot._id === cookies.get('BotId').toString()),
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(History));
