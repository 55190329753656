import React, {Component} from 'react';
import jQuery from 'jquery';

import { config } from '../../config.js';

class VkRe extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount () {
    const params = new URLSearchParams(this.props.location.search);
    const code = params.get('code');
    const id = params.get('state').split(':::')[0];
    const gid = params.get('state').split(':::')[1];
    if ( code ) {
      jQuery.ajax({
        method: 'POST',
        contentType: 'application/json',
        url: config.Backend + '/vkGroupToken',
        success: function (res) {
          if ( !res.Error ) {
            var token = res.token;
            jQuery.ajax({
              method: 'POST',
              contentType: 'application/json',
              url: config.Backend + '/vkBindTo',
              success: function (res) {
                if ( !res.Error ) {
                  window.location.href = window.location.origin + '/dashboard'
                } else {
                  alert(JSON.stringify(res));
                }
              }.bind(this),
              data: JSON.stringify({
                Id: id,
                Token: token,
                gid: gid
              }),
            })
          } else {
            alert(JSON.stringify(res));
          }
        }.bind(this),
        data: JSON.stringify({
          Code: code
        }),
      });
    }
    else {
      window.location.href = window.location.origin + '/login';
    }
  }

  render() {
    return (
      <div className="background_comein">
      </div>
    );
  }

}

export default VkRe;
