import React from 'react';
import {Button} from 'antd';

class User extends React.Component {

  constructor(props) {
    super(props);
  }


  render() {
    var p = this.props;
    var x = p.elem;
    var i = p.ind;
    var end = x.TariffEnd ? new Date(x.TariffEnd) : '';
    if (end) {
      var year = end.getFullYear();
      var month = end.getMonth() + 1;
      var day = end.getDate();

      if (day < 10) {
        day = '0' + day;
      }
      if (month < 10) {
        month = '0' + month;
      }
      end = day + "." + month + "." + year;
    }

    var onUserChangeHandler = (e) => p.onUserChangeHandler(e, i);

    var onShowDetailsHandler = () => p.onShowDetailsHandler(i);

    var onSaveHandler = () => p.onSaveHandler(x, i);

    var onEnterHandler = () => p.onEnterHandler(x, i);

    var onDeleteHandler = () => p.onDeleteHandler(x, i);

    return (
      <tr>
        <td>
          <input type={"checkbox"} name={"Select"} checked={p.Users[i].Select}
                 onChange={onUserChangeHandler}/>
        </td>

        <td>
          {x._id}
        </td>

        <td>
          {x.Name}
        </td>

        <td>
          {x.Email}
        </td>

        <td>
          {x.Phone}
        </td>

        <td>
          {x.LastAction ? new Date(x.LastAction).toLocaleDateString("ru-Ru") : ""}
        </td>

        <td>
          {x.LastBotAction ? new Date(x.LastBotAction).toLocaleDateString("ru-Ru") : ""}
        </td>

        <td>
          {x.created_at ? new Date(x.created_at).toLocaleDateString("ru-Ru") : ""}
        </td>

        <td>
          {x.Bots ? x.Bots : 0}
        </td>

        <td>
          {x.TariffFull ? x.TariffFull.Name : ""}
        </td>

        <td>
          {x.TariffFull ? end : ""}
        </td>

        <td>{x.UTM ?
          <button onClick={onShowDetailsHandler} className={"btn__all--small"}>
            Метки
          </button>
          : null}
        </td>

        {/*<td>*/}
        {/*  <input type={"checkbox"} name={"Users[" + i + "].PaymentBlock"}*/}
        {/*         checked={p.Users[i].PaymentBlock}*/}
        {/*         onChange={p.onChangeHandler}/>*/}
        {/*</td>*/}

        <td>
          <div style={{
            display: "flex"
          }}>
            <Button className={"mr2"} type="default" onClick={onSaveHandler} icon="save"/>

              <Button type="default" onClick={onEnterHandler} icon="login"/>

              <Button type="default" onClick={onDeleteHandler} icon="delete"/>
          </div>
        </td>
      </tr>)
  }
}

export default User;
