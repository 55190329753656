import React, {Component} from 'react';
import jQuery from 'jquery';
import AdminLeft from '../AdminLeft';
import {Scrollbars} from 'react-custom-scrollbars';
import Cookies from 'universal-cookie';
import Popup from '../../components/Popup/Popup';
import Bot from './Bot.js';
import Gallery from 'react-grid-gallery';
import {Input, Button, Row} from 'antd';
import { config } from '../../config.js';
const cookies = new Cookies();

class CreateBot extends Component {

  constructor(props) {
    super(props);
    this.state = {
      Name: '',
      Bots: [],
      Url: '',
      File: undefined,
      BotId: '',
      ShowDeletePopup: false
    };

    this.onChangeHandler = config.change.bind(this);
  }

  onAddImageContentHandler = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        Url: reader.result,
        File: file
      });
    };
    reader.readAsDataURL(file);
  }

  onClearInputHandler = (e) => {
    e.target.value = null;
  }

  onDeleteBotHandler = () => {
    var url = config.Backend + '/bot/delete?';
    if (!(cookies.get('Id') === undefined)) {
      url += 'CurrentUserId=' + cookies.get('Id').toString();
    }
    url += '&Id=' + this.state.BotId;
    jQuery.getJSON(url, function (res) {
      if (!res.Error) {
        for (var i = 0; i < this.state.Bots.length; i++) {
          if (this.state.BotId == this.state.Bots[i]._id) {
            this.state.Bots.splice(i, 1);
          }
        }
        this.setState({
          Bots: this.state.Bots,
          ShowDeletePopup: false
        });
      } else {
        alert(JSON.stringify(res));
      }
    }.bind(this));
  }

  onWantDeleteBotHandler = (id) => {
    this.setState({
      BotId: id,
      ShowDeletePopup: true
    });
  }

  onCreateHandler = () => {
    var path = '';
    if (this.state.File) {
      var fd = new FormData;
      fd.append('File', this.state.File);
      jQuery.ajax({
        method: 'POST',
        async: false,
        processData: false,
        contentType: false,
        url: config.Backend + '/content/file',
        success: function (msg) {
          path = msg;
        }.bind(this),
        data: fd
      });
    }

    var url = config.Backend + '/bot/add?';
    if (!(cookies.get('Id') === undefined)) {
      url += 'CurrentUserId=' + cookies.get('Id').toString();
    }
    url += '&isTemplate=1';
    url += '&Name=' + (this.state.Name ? this.state.Name : 'Template Bot');
    url += '&AiPos=50*50';
    url += '&Icon=' + path;
    jQuery.getJSON(url, function (res) {
      if (!res.Error) {
        this.state.Bots.push(res.Bot);
        this.setState({
          Bots: this.state.Bots
        });
      } else {
        alert(JSON.stringify(res));
      }
    }.bind(this));
  }

  componentDidMount () {
    var url = config.Backend + '/users/findById?';
    if (!(cookies.get('Id') === undefined)) {
      url += 'Id=' + cookies.get('Id').toString();
    }
    jQuery.getJSON(url, function (res) {
      if (!res.Error && res.Type == 'Super') {
        this.setState(
          {
            User: res
          }, () => {
            var url = config.Backend + '/bot/dftBots?';
            if (!(cookies.get('Id') === undefined)) {
              url += 'CurrentUserId=' + cookies.get('Id').toString();
            }
            jQuery.getJSON(url, function (res) {
              if (!res.Error) {
                //alert(JSON.stringify(res));
                this.setState({
                  Bots: res.Data
                });
              } else {
                alert(JSON.stringify(res));
              }
            }.bind(this));
          });
      } else {
        window.location.replace(window.location.origin + '/login');
      }
    }.bind(this));
  }

  render () {
    var IMAGES = [{
      src: this.state.Url,
      thumbnail: this.state.Url,
      thumbnailWidth: 250,
      thumbnailHeight: 100,
      isSelected: false,
      caption: ''
    }];

    var bots = this.state.Bots.map((b) => {
      return (
        <Bot
          Bot={b}
          Id={b._id}
          Name={b.Name}
          Desc={b.Description}
          Icon={b.Icon}
          onDelete={this.onWantDeleteBotHandler}/>
      );
    });

    return (
      <div>
        {/*{this.AdminTop()}*/}

        <div className="b-page">
          <div className="b-page__row">
            <AdminLeft/>

            <div className="b-page__content-wrap" style={{
              height: "calc(100vh)"
            }}>
              <Scrollbars style={{
                height: "100%"
              }}>
                <div className="dashboard-page">
                  <div className="dashboard-page__items">
                    <div className="upload_text">
                      <Input
                        placeholder="Имя"
                        type={"text"}
                        value={this.state.Name}
                        onChange={this.onChangeHandler}
                        name="Name"/>
                    </div>

                    <div className="template__gallery__admin w-100 mt_5">
                      <div className="col-5">
                        <Gallery images={IMAGES}/>
                      </div>
                    </div>

                    <div className="row__grid w-100 mt_5">
                      <Button className={"mr_5"} onClick={this.onCreateHandler} type="primary">
                        Сохранить
                      </Button>

                      <div className={"ml5"}>
                        <label htmlFor={"image"}
                          className="ant-upload ant-upload-select ant-upload-select-text mr2">
                          <span className="ant-upload">
                            <input
                              style={{
                                display: "none"
                              }}
                              id={"image"}
                              type="file"
                              accept="image/*"
                              onChange={this.onAddImageContentHandler}
                              onClick={this.onClearInputHandler}/>
                            <span className="ant-btn">
                              <i className="fas fa-image"></i>
                              <span>
                                <font style={{
                                  verticalAlignment: "inherit"
                                }}>
                                  <font style={{
                                    verticalAlignment: "inherit"
                                  }}>Изображение</font>
                                </font>
                              </span>
                            </span>
                          </span>
                        </label>
                      </div>
                    </div>

                    <Row gutter={16}>
                      {bots}
                    </Row>
                  </div>
                </div>
              </Scrollbars>
            </div>
          </div>
        </div>

        {this.state.ShowDeletePopup ?
          <Popup
            text={"Are you sure?"}
            Yes={this.onDeleteBotHandler}
            isDashboard={true}
            No={() => {
              this.setState({
                ShowDeletePopup: false
              })
            }}/> : null}
      </div>
    );
  }

}

export default CreateBot;



