import React, { Component } from "react";
import jQuery from "jquery";
import Cookies from "universal-cookie";
import Popup from "../../../components/Popup/Popup";
import { Scrollbars } from "react-custom-scrollbars";
import FacebookLogin from "react-facebook-login";
import PopupAll from "../../PopupAll";
import Tooltip from "material-ui/Tooltip";
import { Button, Avatar, Card, Modal, Icon } from "antd";
import { CSSTransitionGroup } from "react-transition-group";
import { slide as Menu } from "react-burger-menu";
import Collapsible from "react-collapsible";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import {
  addBotSuccess,
  deleteBotsSuccess,
  editBotSuccess,
  setBots,
  setTemplates,
} from "../../../modules/Bots";

import { setUser, setFbGroups, setVkGroups } from "../../../modules/User";
import { config } from "../../../config.js";
import TransComponent from "../../../TransComponent";
import { CreateBotHelp } from "./components/CreateBotHelp";
import { BasedBotOnAnother } from "./components/BasedBotOnAnother";
import { Logo } from "../../TopPanel/components/Logo/Logo";
import { withAlert } from "react-alert";
import { CardTemplateForBot } from "./components/CardTemplateForBot/CardTemplateForBot";

const cookies = new Cookies();
var func = require("../../../func.js");

class Bots extends TransComponent {
  constructor(props) {
    super(props);
    this.state = {
      DftBots: [],
      ShowRenamePopup: false,
      ShowDeletePopup: false,
      ShowChoosePopup: false,
      ShowSharePopup: false,
      BotId: "",
      height: null,
      Creating: false,
      Soc: "FB",
      Name: "",
      Token: "",
      PageId: "",
      WhatsAppTestUrl: "",
      AdminTemplate: undefined,
      Template: undefined,
      Code: "",
      ShareEmail: "",
      condition: false,
      Wait: 0,
      showResults: false,
      copyBot: true,
    };
  }

  chooseFbPage = (e, i) => {
    var c = e.target.checked;

    const fbGroups = [...this.props.fbGroups];

    if (c) {
      for (var t = 0; t < fbGroups.length; t++) {
        fbGroups[t].bot = undefined;
      }
    }

    fbGroups[i].bot = c;

    this.props.addFbGroupsToStore(fbGroups);
  };

  facebookAcc = (res) => {
    if (res.id !== undefined) {
      var url =
        config.Backend +
        "/fbLogin?" +
        "&Name=" +
        res.name +
        "&accessToken=" +
        res.accessToken +
        "&Fb_id=" +
        res.id;
      if (res.email !== undefined) {
        url += "&Email=" + res.email;
      }
      if (!(cookies.get("Id") === undefined)) {
        url += "&Id=" + cookies.get("Id").toString();
      }
      this.setState({
        Wait: ++this.state.Wait,
      });

      jQuery.getJSON(
        url,
        function (res2) {
          this.setState({
            Wait: --this.state.Wait,
          });

          if (res2.Error) {
            alert(JSON.stringify(res2));
          } else {
            this.props.history.push("/dashboard?create=1&soc=FB");
          }
        }.bind(this)
      );
    }
  };

  chooseVkPage = (e, i) => {
    var c = e.target.checked;
    const vkGroups = [...this.props.vkGroups];

    if (c) {
      for (var t = 0; t < vkGroups.length; t++) {
        vkGroups[t].bot = undefined;
      }
    }

    vkGroups[i].bot = c;
    this.props.addVkGroupsToStore(vkGroups);
  };

  share = () => {
    const alert = this.props.alert;
    this.setState({
      Wait: ++this.state.Wait,
    });

    jQuery.ajax({
      method: "POST",
      contentType: "application/json",
      url: config.Backend + "/share/add",
      success: function (res) {
        this.setState({
          Wait: 0,
        });

        if (!res.Error) {
          this.props.history.push("/chat/share");
        } else {
          alert.info(JSON.stringify(res));
        }
      }.bind(this),
      data: JSON.stringify({
        Email: this.state.ShareEmail,
        BotId: this.state.BotId,
        Rights: "RTC",
      }),
    });
  };

  handleInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value.trim(),
    });
  };

  getBotName = () => {
    for (var i = 0; i < this.props.bots.length; i++) {
      if (this.props.bots[i]._id === this.state.BotId)
        return this.props.bots[i].Name;
    }
    return "-";
  };

  loadTemplates = () => {
    var url = config.Backend + "/bot/dftBots?";
    if (!(cookies.get("Id") === undefined)) {
      url += "CurrentUserId=" + cookies.get("Id").toString();
    }

    jQuery.getJSON(
      url,
      function (res) {
        if (!res.Error) {
          this.props.setTemplates(res.Data);
          this.setState({
            DftBots: res.Data,
          });
        }
      }.bind(this)
    );
  };

  addBot = (soc) => {
    const alert = this.props.alert;
    var url = config.Backend + "/bot/add?";
    if (!(cookies.get("Id") === undefined)) {
      url += "CurrentUserId=" + cookies.get("Id").toString();
    }
    url += "&Name=EmptyBot";
    url += "&AiPos=50*50";
    url += "&BindTo=" + soc;
    this.setState({
      Wait: ++this.state.Wait,
    });

    jQuery.getJSON(
      url,
      function (res) {
        this.setState({
          Wait: --this.state.Wait,
        });

        if (!res.Error) {
          this.props.addBotToStore(res.Bot);
          this.setState({
            Creating: false,
          });
        } else {
          alert.info(JSON.stringify(res));
        }
      }.bind(this)
    );
  };

  buyTemplate = () => {
    return new Promise(
      function (resolve, reject) {
        var s = this.state;
        if (!s.Template) {
          resolve();
        }
        var ind = -1;
        s.DftBots.forEach((b, i) => {
          if (b._id === s.Template._id) {
            ind = i;
          }
        });
        if (ind === -1) {
          resolve();
        }

        this.setState({
          Wait: ++this.state.Wait,
        });
        func.getData("bot/buy_template", { BotId: s.Template._id }).then(
          (ok) => {
            this.setState({
              Wait: --this.state.Wait,
            });
            resolve();
          },
          (e) => {
            this.setState({
              Wait: --this.state.Wait,
            });
            reject(JSON.stringify(e));
          }
        );
      }.bind(this)
    );
  };

  addSocBot = () => {
    const alert = this.props.alert;

    if (this.state.Name) {
      this.buyTemplate().then(
        (okk) => {
          var soc = this.state.Soc;
          if (soc === "Vk") {
            var gId = "";
            for (var t = 0; t < this.props.vkGroups.length; t++) {
              if (this.props.vkGroups[t].bot) {
                gId = this.props.vkGroups[t].id;
              }
            }
            if (!gId) {
              alert.info("Ни одна группа не выбрана для подключения");
            } else {
              var state = this.state.Name + ":::" + gId;
              if (this.state.Template) {
                state += ":::" + this.state.Template._id;
              }
              window.location.replace(
                "https://oauth.vk.com/authorize?client_id=" +
                config.VkAppId +
                "&redirect_uri=" +
                config.Frontend +
                "/vk&group_ids=" +
                gId +
                "&response_type=code&scope=photos,messages,docs,manage" +
                "&state=" +
                state +
                "&v=" +
                config.VkApiV
              );
            }
          } else if (soc === "FB") {
            var pageId = "";
            var token = "";
            for (var t = 0; t < this.props.fbGroups.length; t++) {
              if (this.props.fbGroups[t].bot) {
                pageId = this.props.fbGroups[t].id;
                token = this.props.fbGroups[t].access_token;
              }
            }
            if (!pageId || !token) {
              alert.info("Ни одна группа не выбрана для подключения");
            } else {
              var url = config.Backend + "/bot/add?";
              if (!(cookies.get("Id") === undefined)) {
                url += "CurrentUserId=" + cookies.get("Id").toString();
              }
              url += "&Name=" + this.state.Name;
              url += "&AiPos=50*50";
              url += "&BindTo=" + soc;
              if (this.state.Template) {
                url = config.Backend + "/bot/copyBot?";
                if (!(cookies.get("Id") === undefined)) {
                  url += "CurrentUserId=" + cookies.get("Id").toString();
                }
                url += "&Id=" + this.state.Template._id;
                url += "&Name=" + this.state.Name;
                url += "&BindTo=" + soc;
              }
              this.setState({
                Wait: ++this.state.Wait,
              });

              jQuery.getJSON(
                url,
                function (res) {
                  this.setState({
                    Wait: --this.state.Wait,
                  });

                  if (!res.Error) {
                    var x =
                      config.Backend +
                      "/fb/subscribe?" +
                      "PageId=" +
                      encodeURIComponent(pageId) +
                      "&BotId=" +
                      res.Bot._id +
                      "&accessToken=" +
                      encodeURIComponent(token);
                    this.setState({
                      Wait: ++this.state.Wait,
                    });

                    jQuery.getJSON(
                      x,
                      function (res2) {
                        this.setState({
                          Wait: --this.state.Wait,
                        });

                        var url = config.Backend + "/bot/edit?";
                        if (!(cookies.get("Id") === undefined)) {
                          url +=
                            "CurrentUserId=" + cookies.get("Id").toString();
                        }
                        url += "&Id=" + res.Bot._id;
                        url += "&Page=" + encodeURIComponent(pageId);
                        url += "&PageToken=" + encodeURIComponent(token);
                        jQuery.getJSON(
                          url,
                          function (res) {
                            if (!res.Error) {
                              if (!this.state.Template) {
                                this.props.addBotToStore(res);
                                this.setState({
                                  Creating: false,
                                });
                              } else {
                                setTimeout(() => this.repair(res._id), 1500);
                              }
                            } else {
                              alert.error(JSON.stringify(res));
                            }
                          }.bind(this)
                        );
                      }.bind(this)
                    );
                  } else {
                    alert.error(JSON.stringify(res));
                  }
                }.bind(this)
              );
            }
          } else {
            var url = config.Backend + "/bot/add?";
            if (!(cookies.get("Id") === undefined)) {
              url += "CurrentUserId=" + cookies.get("Id").toString();
            }
            url += "&Name=" + this.state.Name;
            url += "&AiPos=50*50";
            url += "&BindTo=" + soc;
            if (this.state.Template) {
              url = config.Backend + "/bot/copyBot?";
              if (!(cookies.get("Id") === undefined)) {
                url += "CurrentUserId=" + cookies.get("Id").toString();
              }
              url += "&Id=" + this.state.Template._id;
              url += "&Name=" + this.state.Name;
              url += "&BindTo=" + soc;
            }
            this.setState({
              Wait: ++this.state.Wait,
            });

            jQuery.getJSON(
              url,
              function (res) {
                if (!res.Error) {
                  var url = config.Backend + "/bot/bindTo?";
                  if (!(cookies.get("Id") === undefined)) {
                    url += "CurrentUserId=" + cookies.get("Id").toString();
                  }
                  url += "&Id=" + res.Bot._id;
                  if (soc === "Ok") {
                    url += "&OkToken=" + encodeURIComponent(this.state.Token);
                  } else if (soc === "Telegram") {
                    url +=
                      "&TelegramToken=" + encodeURIComponent(this.state.Token);
                  } else if (soc === "Vk") {
                    url += "&VkPage=" + encodeURIComponent(this.state.PageId);
                    url +=
                      "&VkConfirmation=" + encodeURIComponent(this.state.Code);
                    url +=
                      "&VkPageToken=" + encodeURIComponent(this.state.Token);
                  } else if (soc === "Viber") {
                    url +=
                      "&ViberToken=" + encodeURIComponent(this.state.Token);
                    url += "&ViberUri=" + encodeURIComponent(this.state.Code);
                  } else if (soc === "Alisa") {
                    url +=
                      "&AlisaSkillId=" + encodeURIComponent(this.state.Token);
                  } else if (soc === "WhatsApp") {
                    url += "&WhatsAppApiKey=" + encodeURIComponent(this.state.Token)
                    url += "&WhatsAppApiAppName=" + encodeURIComponent(this.state.Code)
                    url += "&WhatsAppNumber=" + encodeURIComponent(this.state.PageId);
                    url += "&WhatsAppTestUrl=" + encodeURIComponent(this.state.WhatsAppTestUrl);
                  }

                  jQuery.getJSON(
                    url,
                    function (res2) {
                      this.setState({
                        Wait: 0,
                      });

                      if (!res2.Error) {
                        if (!this.state.Template) {
                          if (soc === "Vk") {
                            cookies.set("BotId", res.Bot._id, {
                              path: "/",
                            });
                            window.location.replace(
                              "https://oauth.vk.com/authorize?client_id=" +
                              config.VkAppId +
                              "&redirect_uri=" +
                              config.Frontend +
                              "/dashboard" +
                              "&response_type=code&scope=photos,video,audio,offline"
                            );
                          } else {
                            this.props.addBotToStore(res.Bot);
                            this.setState({
                              Creating: false,
                            });
                          }
                        } else {
                          setTimeout(() => this.repair(res.Bot._id), 1500);
                        }
                      } else {
                        var url = config.Backend + "/bot/delete?";
                        if (!(cookies.get("Id") === undefined)) {
                          url +=
                            "CurrentUserId=" + cookies.get("Id").toString();
                        }
                        url += "&Id=" + res.Bot._id;
                        this.setState({
                          Wait: ++this.state.Wait,
                        });

                        jQuery.getJSON(
                          url,
                          function (res) {
                            this.setState({
                              Wait: 0,
                            });
                          }.bind(this)
                        );

                        alert.info(JSON.stringify(res2));
                      }
                    }.bind(this)
                  );
                } else {
                  this.setState({
                    Wait: 0,
                  });
                  alert.info(JSON.stringify(res));
                }
              }.bind(this)
            );
          }
        },
        (errr) => {
          alert.info(errr);
        }
      );
    } else {
      alert.info("Введите имя бота");
    }
  };

  editBot = (id) => {
    cookies.set("BotId", id, {
      path: "/",
    });
    this.props.history.push("/chat/build");
  };

  renameBot = (id, name) => {
    var url = config.Backend + "/bot/edit?";
    if (!(cookies.get("Id") === undefined)) {
      url += "CurrentUserId=" + cookies.get("Id").toString();
    }
    url += "&Id=" + this.state.BotId;
    url += "&Name=" + name;

    this.setState({
      Wait: ++this.state.Wait,
    });

    jQuery.getJSON(
      url,
      function (res) {
        this.setState({
          Wait: --this.state.Wait,
        });

        if (!res.Error) {
          for (var i = 0; i < this.props.bots.length; i++) {
            if (this.state.BotId === this.props.bots[i]._id && name !== "") {
              this.props.bots[i].Name = name;
              this.props.editBotInStore(this.props.bots[i]);
            }
          }
          this.setState({
            ShowRenamePopup: false,
          });
        }
      }.bind(this)
    );
  };

  deleteBot = () => {
    var url = config.Backend + "/bot/delete?";
    if (!(cookies.get("Id") === undefined)) {
      url += "CurrentUserId=" + cookies.get("Id").toString();
    }
    url += "&Id=" + this.state.BotId;
    this.setState({
      Wait: ++this.state.Wait,
    });

    jQuery.getJSON(
      url,
      function (res) {
        this.setState({
          Wait: --this.state.Wait,
        });

        if (!res.Error) {
          this.props.deleteBotFromStore(this.state.BotId);
          this.setState({
            ShowDeletePopup: false,
          });
        }
      }.bind(this)
    );
  };

  wantDeleteBot = (id) => {
    this.setState({
      BotId: id,
      ShowDeletePopup: true,
    });
  };

  loadPageOfFacebookPages = (url) => {
    const isFirstPage = !url;

    if (isFirstPage) {
      url =
          config.FbApi +
          "/" +
          this.props.user.Fb_id +
          "/accounts?access_token=" +
          this.props.user.accessToken;
    }

    jQuery
        .getJSON(
            url,
            function (pages) {
              var arr = [];
              for (var i = 0; i < pages.data.length; i++) {
                if (pages.data[i].tasks.indexOf("MANAGE") !== -1) {
                  arr.push(pages.data[i]);
                }
              }
              this.props.addFbGroupsToStore(
                  (isFirstPage ? [] : (this.props.fbGroups || [])).concat(arr)
              );

              if (pages.paging && pages.paging.next) {
                this.loadPageOfFacebookPages(pages.paging.next);
              }

              this.setState({
                needUpdateFbUserToken: false,
              });
            }.bind(this)
        )
        .fail((error) => {
          this.setState({
            needUpdateFbUserToken: true,
          });
        });
  };

  loadFacebookPages = () => this.loadPageOfFacebookPages();

  componentDidUpdate(prevProps) {
    if (this.props.bots !== prevProps.bots) {
      if (this.props.bots.length === 0) {
        // this.setState({
        //   Creating: true,
        // });
      }
    }

    if (
      this.props.user &&
      !prevProps.user &&
      this.props.user.VkToken &&
      !this.props.vkGroups
    ) {
      var url2 = config.Backend + "/vk/getVkGroups?Id=" + this.props.user._id;

      jQuery.getJSON(
        url2,
        function (res) {
          if (!res.Error) {
            this.props.addVkGroupsToStore(res);
          }
        }.bind(this)
      );
    }

    if (
      this.props.user &&
      !prevProps.user &&
      this.props.user.Fb_id &&
      this.props.user.accessToken &&
      !this.props.fbGroups
    ) {
      this.loadFacebookPages();
    }
  }

  componentDidMount() {
    if (!(cookies.get("Soc") === undefined)) {
      this.setState({
        Soc: cookies.get("Soc"),
      });
    }
    const params = new URLSearchParams(this.props.location.search);
    const create = params.get("create");
    if (create) {
      this.setState({
        Creating: true,
      });
      const soc = params.get("soc");
      if (soc) {
        this.setState({
          Soc: soc,
        });
      }
    }
    if (!this.props.botsWasLoad) {
      this.props.setBots();
    }

    if (!this.props.user) {
      this.props.setUser();
    }

    if (this.props.templatesWasLoad) {
      this.setState({
        DftBots: this.props.templates,
      });
    } else {
      this.loadTemplates();
    }
  }

  repair = (id) => {
    const alert = this.props.alert;
    var url = config.Backend + "/bot/repairBot?";
    if (!(cookies.get("Id") === undefined)) {
      url += "CurrentUserId=" + cookies.get("Id").toString();
    }
    url += "&Id=" + id;
    this.setState({
      Wait: ++this.state.Wait,
    });

    jQuery.getJSON(
      url,
      function (res) {
        this.setState({
          Wait: 0,
        });

        if (!res.Error) {
          if (this.state.Soc === "Vk") {
            cookies.set("BotId", res.Bot._id, {
              path: "/",
            });

            window.location.open(
              "https://oauth.vk.com/authorize?client_id=" +
              config.VkAppId +
              "&redirect_uri=" +
              config.Frontend +
              "/dashboard" +
              "&response_type=code&scope=photos,video,audio,offline"
            );
          } else {
            this.props.addBotToStore(res.Bot);
            this.setState({
              Creating: false,
            });
          }
        } else {
          alert.error(JSON.stringify(res));
        }
      }.bind(this)
    );
  };

  exit = (event) => {
    cookies.set("Exit", "true", {
      path: "/",
    });
    window.location.replace(window.location.origin + "/login");
  };

  handleClick = () => {
    this.setState({
      condition: !this.state.condition,
    });
  };

  t_end() {
    var s = this.state;
    var u = this.props.user;
    var work =
      u && u.Tariff && u.TariffEnd && new Date(u.TariffEnd) > new Date();

    if (work) {
      return <div className="topnav_center"></div>;
    } else {
      return (
        <div className="topnav_center">
          <h2>
            Работа Ваших ботов временно преостановлена. <br />
            Для возобновления подключите"
            <Link to="/tariffs">Тариф</Link>
          </h2>
        </div>
      );
    }
  }

  top() {
    var s = this.state;
    var u = this.props.user;

    return (
      <div className="chatbot">
        <div className="topnav">
          <Menu>
            <ul className={"menu_left_chatbot"}>
              <Collapsible trigger="Боты">
                <li>
                  <Link
                    className={"menu-item_lvl fw_menu icon_menu_panel create"}
                    to={"#"}
                    onClick={() =>
                      this.setState(
                        {
                          Creating: true,
                          Template: undefined,
                        },
                        this.handleClick
                      )
                    }
                  >
                    Создать
                  </Link>
                </li>

                <li>
                  <Link
                    className={"menu-item_lvl fw_menu control_icon"}
                    to={"#"}
                    onClick={() =>
                      this.setState(
                        {
                          Creating: false,
                          Template: undefined,
                        },
                        this.handleClick
                      )
                    }
                  >
                    Управлять
                  </Link>
                </li>
              </Collapsible>
              {/*<Collapsible*/}
              {/*  trigger={this.t("Партнерская программа")}*/}
              {/*  classParentString={'partner_menu'}>*/}
              {/*  <li>*/}
              {/*    <Link*/}
              {/*      className={"menu-item_lvl fw_menu icon_menu_panel  referal"}*/}
              {/*      to="/partner">*/}
              {/*      {this.t("Реферальная система")}*/}
              {/*    </Link>*/}
              {/*  </li>*/}

              {/*</Collapsible>*/}
              <li>
                <Link className="menu-item package_menu" to="/tariffs">
                  Тарифы
                </Link>
              </li>

              <li>
                <Link className="menu-item setting_menu" to="/profile/setting">
                  Личный кабинет
                </Link>
              </li>

              {/*<li>*/}
              {/*  <Link*/}
              {/*    target={"_blank"}*/}
              {/*    className="menu-item help_menu"*/}
              {/*    to="https://botkits.ru/manual.html">*/}
              {/*    {this.t("Помощь")}*/}
              {/*  </Link>*/}
              {/*</li>*/}

              {u && u.Tariff && u.Tariff.CRM ? (
                <li>
                  <Link id="contact" className="menu-item crm_menu" to="/crm">
                    {this.t("CRM")}
                  </Link>
                </li>
              ) : null}

              {/*<li>*/}
              {/*  <Link className="menu-item url_menu" to="/chat/url">*/}
              {/*    {this.t("Система коротких url")}*/}
              {/*  </Link>*/}
              {/*</li>*/}

              {/*<li>*/}
              {/*  <Link className="menu-item widget_menu" to="/widget">*/}
              {/*    {this.t("Создать виджет")}*/}
              {/*  </Link>*/}
              {/*</li>*/}

              <li>
                <Link
                  className="menu-item exit_menu"
                  to={"#"}
                  onClick={this.exit}
                >
                  {this.t("Выход")}
                </Link>
              </li>
            </ul>
          </Menu>
          <div className="topnav_left_create">
            <Logo />
            <div className="name_block">
              <div className="bot_top_preview"></div>
            </div>
          </div>
          {u && u.Type ? this.t_end() : null}
        </div>
      </div>
    );
  }

  ChooseSocPopup() {

    let s = this.state

    return (
      <div className="filterPopup">
        <div className="cansel_button_popup">
          {/*Кнопка закрыть тут*/}
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={() =>
              this.setState({
                ShowChoosePopup: false,
                Template: undefined,
              })
            }
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="filter_delete">
          <h1>Выберите социальную сеть</h1>
          <ul className={"bot_view_popup"}>
            <li>
              <a
                className="bot_view_popup popup_vk"
                onClick={ () => {
                  if (this.state.copyBot) {
                    this.setState({
                      ShowChoosePopup: false,
                      Creating: true,
                      Soc: "Vk",
                      Template: s.AdminTemplate,
                      AdminTemplate: undefined,
                      CopyBot: false,
                    });

                  } else {
                    this.setState({
                      ShowChoosePopup: false,
                      Creating: true,
                      Soc: "Vk",
                      CopyBot: false,
                    });
                  }
                }}
              >
                ВКонтакте
              </a>
            </li>

            <li>
              <a
                className="popup_od"
                onClick={ () => {
                  if (this.state.copyBot) {
                    this.setState({
                      ShowChoosePopup: false,
                      Creating: true,
                      Soc: "Ok",
                      Template: s.AdminTemplate,
                      AdminTemplate: undefined,
                      CopyBot: false,
                    });

                  } else {
                    this.setState({
                      ShowChoosePopup: false,
                      Creating: true,
                      Soc: "Ok",
                      CopyBot: false,
                    });
                  }
                }}
              >
                Одноклассники
              </a>
            </li>

            <li>
              <a
                className="popup_telegram"
                onClick={ () => {
                  if (this.state.copyBot) {
                    this.setState({
                      ShowChoosePopup: false,
                      Creating: true,
                      Soc: "Telegram",
                      Template: s.AdminTemplate,
                      AdminTemplate: undefined,
                      CopyBot: false,
                    });

                  } else {
                    this.setState({
                      ShowChoosePopup: false,
                      Creating: true,
                      Soc: "Telegram",
                      CopyBot: false,
                    });
                  }
                }}
              >
                Telegram
              </a>
            </li>

            <li>
              <a
                className="popup_facebook"
                onClick={ () => {
                  if (this.state.copyBot) {
                    this.setState({
                      ShowChoosePopup: false,
                      Creating: true,
                      Soc: "FB",
                      Template: s.AdminTemplate,
                      AdminTemplate: undefined,
                      CopyBot: false,
                    });

                  } else {
                    this.setState({
                      ShowChoosePopup: false,
                      Creating: true,
                      Soc: "FB",
                      CopyBot: false,
                    });
                  }
                }}
              >
                Facebook
              </a>
            </li>

            <li>
              <a
                className="popup_viber"
                onClick={ () => {
                  if (this.state.copyBot) {

                    this.setState({
                      ShowChoosePopup: false,
                      Creating: true,
                      Soc: "Viber",
                      Template: s.AdminTemplate,
                      AdminTemplate: undefined,
                      CopyBot: false,
                    });

                  } else {
                    this.setState({
                      ShowChoosePopup: false,
                      Creating: true,
                      Soc: "Viber",
                      CopyBot: false,
                    });
                  }
                }}
              >
                Viber
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  }

  SocBind() {
    var b = this.state.Soc;
    var t = this.state.Template;
    if (b === "FB") {
      if (
        this.props.user &&
        this.props.user.accessToken &&
        !this.state.needUpdateFbUserToken
      ) {
        var groups = (this.props.fbGroups || []).map(
          function (elem, ind) {
            const g = elem;
            const i = ind;

            const alreadyUse = (this.props.bots || []).find(
              (bot) => elem.id && bot.Page === elem.id.toString()
            );

            return (
              <tr>
                <td>{g.name}</td>
                <td>
                  {alreadyUse ? (
                    "Подключено"
                  ) : (
                    <input
                      type={"checkbox"}
                      checked={g.bot}
                      onChange={(e) => this.chooseFbPage(e, ind)}
                    />
                  )}
                </td>
              </tr>
            );
          }.bind(this)
        );
        return (
          <div>
            <h4>Facebook</h4>
            <table className={"table"}>
              <th>Название</th>
              <th>Подключить</th>
              <th>
                <button onClick={this.loadFacebookPages} className="btn__all--small bg_shadow">
                  Добавить новые группы
                </button>
              </th>
              {groups}
            </table>
            <input
              type={"text"}
              placeholder="Введите название бота..."
              name={"Name"}
              onChange={this.handleInputChange}
            />
            {t ? <BasedBotOnAnother template={t} /> : null}
          </div>
        );
      } else {
        return (
          <div>
            <h4>Facebook</h4>
            <FacebookLogin
              appId={config.FbAppId}
              autoLoad={false}
              fields="name,email,picture"
              cssClass="btn__all--small bg_shadow"
              // icon = "fa-facebook create_facebook "
              reAuthenticate={true}
              textButton={"Загрузить группы"}
              scope={
                "pages_show_list,pages_manage_metadata,pages_read_engagement,pages_read_user_content,email,pages_messaging,pages_messaging_subscriptions,public_profile"
              }
              returnScopes={true}
              tag={"button"}
              callback={this.facebookAcc}
            />
          </div>
        );
      }
    } else if (b === "Ok") {
      return (
        <div>
          <h4>{"Одноклассники"}</h4>

          <CreateBotHelp
            linkDocumentation={
              "https://botkits.ru/start/odnoklassniki/?utm_source=create_bot"
            }
            linkVideo={
              "https://www.youtube.com/channel/UCeeaBzXbE11cOTAwDVSJIww/videos"
            }
          />

          <input
            type={"text"}
            name={"Token"}
            placeholder={"Введите ключ доступа..."}
            onChange={this.handleInputChange}
          />

          <input
            type={"text"}
            placeholder={"Введите название бота..."}
            name={"Name"}
            onChange={this.handleInputChange}
          />

          {t ? <BasedBotOnAnother template={t} /> : null}
        </div>
      );
    } else if (b === "Vk") {
      var groups = (this.props.vkGroups || []).map(
        function (elem, ind) {
          const g = elem;
          const i = ind;

          const alreadyUse = (this.props.bots || []).find(
            (bot) => elem.id && bot.VkPage === elem.id.toString()
          );

          return (
            <tr>
              <td>
                <img src={g.photo_50} />
              </td>
              <td>{g.name}</td>
              <td>
                {alreadyUse ? (
                  "Подключено"
                ) : (
                  <input
                    type={"checkbox"}
                    checked={g.bot}
                    onChange={(e) => this.chooseVkPage(e, ind)}
                  />
                )}
              </td>
              <td></td>
            </tr>
          );
        }.bind(this)
      );
      if (this.props.user && this.props.user.VkToken) {
        return (
          <div>
            <h4>"Вконтакте</h4>

            <CreateBotHelp
              linkDocumentation={
                "https://botkits.ru/start/vkontakte/?utm_source=create_bot"
              }
              linkVideo={"https://www.youtube.com/watch?v=Btvtq6agIG0"}
            />

            <table className={"table create_group"}>
              <th>Иконка</th>
              <th>Название</th>
              <th className={"tc"}>"Подключить</th>
              {groups}
            </table>

            <input
              type={"text"}
              placeholder={"Введите название бота..."}
              name={"Name"}
              onChange={this.handleInputChange}
            />
            {t ? <BasedBotOnAnother template={t} /> : null}
          </div>
        );
      } else {
        return (
          <div>
            <h4>Вконтакте</h4>

            <CreateBotHelp
              linkDocumentation={
                "https://botkits.ru/start/vkontakte/?utm_source=create_bot"
              }
              linkVideo={"https://www.youtube.com/watch?v=Btvtq6agIG0"}
            />

            <button
              className="btn__all--small bg_shadow"
              onClick={() => {
                window.location.replace(
                  "https://oauth.vk.com/authorize?client_id=" +
                  config.VkAppId +
                  "&redirect_uri=" +
                  config.Frontend +
                  "/vk" +
                  "&response_type=code&scope=photos,video,audio,offline,groups,email" +
                  "&state=log"
                );
              }}
            >
              Загрузить группы
            </button>
          </div>
        );
      }
    } else if (b === "Telegram") {
      return (
        <div>
          <h4>Telegram</h4>

          <CreateBotHelp
            linkDocumentation={
              "https://botkits.ru/start/telegram/?utm_source=create_bot"
            }
            linkVideo={"https://www.youtube.com/watch?v=FKOn5DfpJDA"}
          />

          <input
            type={"text"}
            name={"Token"}
            placeholder={"Введите ключ доступа..."}
            onChange={this.handleInputChange}
          />

          <input
            type={"text"}
            placeholder={"Введите название бота..."}
            name={"Name"}
            onChange={this.handleInputChange}
          />
          {t ? <BasedBotOnAnother template={t} /> : null}
        </div>
      );
    } else if (b === "Viber") {
      return (
        <div>
          <h4>Viber</h4>

          <CreateBotHelp
            linkDocumentation={
              "https://botkits.ru/start/viber/?utm_source=create_bot"
            }
            linkVideo={"https://www.youtube.com/watch?v=R0qJ9c0SHQs"}
          />

          <input
            type={"text"}
            placeholder={"Введите ключ доступа вашего бота..."}
            name={"Token"}
            onChange={this.handleInputChange}
          />

          <input
            type={"text"}
            placeholder={"Введите uri вашего бота..."}
            name={"Code"}
            onChange={this.handleInputChange}
          />

          <input
            type={"text"}
            placeholder={"Введите название бота..."}
            name={"Name"}
            onChange={this.handleInputChange}
          />
          {t ? <BasedBotOnAnother template={t} /> : null}
        </div>
      );
    } else if (b === "Alisa") {
      return (
        <div>
          <h4>Alisa</h4>

          <CreateBotHelp
            linkDocumentation={
              "https://botkits.ru/start/alisa/?utm_source=create_bot"
            }
            linkVideo={
              "https://www.youtube.com/channel/UCeeaBzXbE11cOTAwDVSJIww/videos"
            }
          />

          <input
            type={"text"}
            placeholder={"Введите идентификатор навыка Алисы..."}
            name={"Token"}
            onChange={this.handleInputChange}
          />

          <input
            type={"text"}
            placeholder={"Введите название бота..."}
            name={"Name"}
            onChange={this.handleInputChange}
          />
          {t ? <BasedBotOnAnother template={t} /> : null}
        </div>
      );
    } else if (b === 'WhatsApp') {
      return (
          <div>
            <h4>WhatsApp</h4>

            <CreateBotHelp
                linkDocumentation={
                  "https://botkits.ru/start/whatsapp/?utm_source=create_bot"
                }
                linkVideo={
                  "https://www.youtube.com/channel/UCeeaBzXbE11cOTAwDVSJIww/videos"
                }
            />

            <input
                type={"text"}
                placeholder={"Введите ключ доступа вашего бота..."}
                name={"Token"}
                onChange={this.handleInputChange}
            />

            <input
                type={"text"}
                placeholder={"Введите whatsapp номер вашего бота..."}
                name={"PageId"}
                onChange={this.handleInputChange}
            />

            <input
                type={"text"}
                placeholder={"Введите название приложения вашего бота..."}
                name={"Code"}
                onChange={this.handleInputChange}
            />

            <input
                type={"text"}
                placeholder={"Введите ссылку на чат для тестирования вашего бота..."}
                name={"WhatsAppTestUrl"}
                onChange={this.handleInputChange}
            />

            <input
                type={"text"}
                placeholder={"Введите название бота..."}
                name={"Name"}
                onChange={this.handleInputChange}
            />
            {t ? <BasedBotOnAnother template={t} /> : null}
          </div>
      );
    }
  }

  chooseSoc = (soc) => {
    this.setState({
      Soc: soc,
    });
    cookies.set("Soc", soc, {
      path: "/",
    });
  };

  creatingBot() {
    return (
      <div className="dashboard_list_bot">
        <div className="main_bot_preview">
          <div className="main_bot_preview_left">
            <ul>
              <li>
                <a
                  className="list_bot facebook"
                  onClick={() => this.chooseSoc("FB")}
                >
                  Создать чат-бота для Facebook
                </a>
              </li>

              <li>
                <a
                  className="list_bot vk_bot"
                  onClick={() => this.chooseSoc("Vk")}
                >
                  Создать чат-бота для ВКонтакте
                </a>
              </li>

              <li>
                <a
                  className="list_bot odn"
                  onClick={() => this.chooseSoc("Ok")}
                >
                  Создать чат-бота для Одноклассники
                </a>
              </li>

              <li>
                <a
                  className="list_bot telegramm"
                  onClick={() => this.chooseSoc("Telegram")}
                >
                  Создать чат-бота для Telegram
                </a>
              </li>

              <li>
                <a
                  className="list_bot viber"
                  onClick={() => this.chooseSoc("Viber")}
                >
                  Создать чат-бота для Viber
                </a>
              </li>

              <li>
                <a
                  className="list_bot alisa"
                  onClick={() => this.chooseSoc("Alisa")}
                >
                  Создать чат-бота для Алисы
                </a>
              </li>

              {['5a77a02f1b22160bf6bee459', '5a7213c45790c43d60ea50e1', '628cdba90f0a667bbeeacec4'].includes(cookies.get('Id')) && (
                  <li>
                    <a
                        className="list_bot facebook"
                        onClick={() => this.chooseSoc("WhatsApp")}
                    >
                      Создать чат-бота для WhatsApp
                    </a>
                  </li>
              )}
            </ul>
          </div>

          <div className="main_bot_preview_right">
            <div className="main_bot_preview_right_content grow_main ">
              <Scrollbars style={{ height: "90%" }}>
                <div className="grow_main_post">
                  {this.SocBind()}
                  {((this.props.user && this.props.user.VkToken) ||
                    this.state.Soc !== "Vk") &&
                  !(
                    this.props.user &&
                    !this.props.user.accessToken &&
                    this.state.Soc === "FB"
                  ) ? (
                    <button
                      className={"btn__all--small bg_shadow"}
                      onClick={this.addSocBot}
                    >
                      Создать бота
                    </button>
                  ) : null}
                </div>
              </Scrollbars>
            </div>
          </div>
        </div>
      </div>
    );
  }

  onClickHandler = () => {
    this.setState((prev) => ({
      showResults: !prev.showResults,
    }));
  };



  templateblock() {
    var s = this.state;

    var templates = s.DftBots.map((elem, ind) => {
      const e = elem;
      const i = ind;
      var src = config.Backend + "/content/getFile?";
      if (!(cookies.get("Id") === undefined)) {
        src += "&CurrentUserId=" + cookies.get("Id").toString();
      }
      src += "&Link=" + e.Icon;

      const openPopup = () => {
        this.setState({
          AdminTemplate: e,
          ShowChoosePopup: true
        })
      }

      return (
        <CardTemplateForBot
          key={elem._id}
          onClick={openPopup}
          imgSrc={src}
          title={e.Name}
          description={e.Description}
        />
      );
    })

    return (
      <div className="template__main--list row__grid  mt_10">{templates}</div>
    );
  }

  render() {
    var s = this.state;
    var at = s.AdminTemplate;
    var at_src = config.Backend + "/content/getFile?";
    if (!(cookies.get("Id") === undefined)) {
      at_src += "&CurrentUserId=" + cookies.get("Id").toString();
    }
    at_src += "&Link=" + (at || {}).Icon;

    const bots = (this.props.bots || []).map(
      function (bot, i) {
        var b = bot.BindTo;
        var icon = "";

        if (b === "FB") {
          icon = "dashboard_facebook";
        } else if (b === "Vk") {
          icon = "dashboard_vk";
        } else if (b === "Ok") {
          icon = "dashboard_od";
        } else if (b === "Telegram") {
          icon = "dashboard_telegramm";
        } else if (b === "Viber") {
          icon = "dashboard_viber";
        } else if (b === "Alisa") {
          icon = "dashboard_alisa";
        } else if (b === "WhatsApp") {
          icon = "dashboard_facebook";
        }

        const showCopy = (!this.props.user ||
            !this.props.user.Tariff ||
            this.props.user.Tariff.Copy);

        var showShare =
          cookies.get("Id") &&
          config.checkRights(cookies.get("Id").toString(), bot, "Share") &&
          (!this.props.user ||
            !this.props.user.Tariff ||
            this.props.user.Tariff.EnableShare);


        const copyBot = () => {
          this.setState({
            ShowChoosePopup: true,
            Template: bot,
            copyBot: false
          });
        }
        return (
          <div key={bot._id} className="dashboard-bot">
            <div
              onClick={() => this.editBot(bot._id)}
              className="dashboard-bot_image"
            >
              <div className={"dashboard-bot_image_1" + " " + icon} />
            </div>

            <div className="dashboard-bot-settings">
              <a onClick={() => this.editBot(bot._id)}>
                <span className="dashboard_title">{bot.Name}</span>
              </a>

              <div className="dashboard_btn_group">
                {showCopy && (
                  <Tooltip
                    enterDelay={300}
                    id="tooltip-controlled"
                    leaveDelay={300}
                    onClose={this.handleTooltipClose}
                    onOpen={this.handleTooltipOpen}
                    open={this.state.open}
                    placement="bottom"
                    title={"Копировать"}
                  >

                    <button
                      className="copy"
                      onClick={copyBot}
                    />
                  </Tooltip>
                )}

                {showShare ? (
                  <Tooltip
                    enterDelay={300}
                    id="tooltip-controlled"
                    leaveDelay={300}
                    onClose={this.handleTooltipClose}
                    onOpen={this.handleTooltipOpen}
                    open={this.state.open}
                    placement="bottom"
                    title={"Общий доступ"}
                  >
                    <button
                      className="share"
                      onClick={() =>
                        this.setState({
                          ShowSharePopup: true,
                          BotId: bot._id,
                        })
                      }
                    />
                  </Tooltip>
                ) : null}

                {cookies.get("Id") &&
                bot.User === cookies.get("Id").toString() ? (
                  <Tooltip
                    enterDelay={300}
                    id="tooltip-controlled"
                    leaveDelay={300}
                    onClose={this.handleTooltipClose}
                    onOpen={this.handleTooltipOpen}
                    open={this.state.open}
                    placement="bottom"
                    title={"Редактировать"}
                  >
                    <button
                      className="edit"
                      onClick={() =>
                        this.setState({ BotId: bot._id, ShowRenamePopup: true })
                      }
                    />
                  </Tooltip>
                ) : null}
                {cookies.get("Id") &&
                bot.User === cookies.get("Id").toString() ? (
                  <Tooltip
                    enterDelay={300}
                    id="tooltip-controlled"
                    leaveDelay={300}
                    onClose={this.handleTooltipClose}
                    onOpen={this.handleTooltipOpen}
                    open={this.state.open}
                    placement="bottom"
                    title={"Удалить"}
                  >
                    <button
                      className="delete"
                      onClick={() => this.wantDeleteBot(bot._id)}
                    />
                  </Tooltip>
                ) : null}
              </div>
            </div>
          </div>
        );
      }.bind(this)
    );

    var templates = (
      <div className="mt_25">
        <Button onClick={this.onClickHandler} className={"ml10"} type="dashed">
          Шаблоны
        </Button>
        <div className="template__main">
          <CSSTransitionGroup
            transitionName="example"
            transitionEnterTimeout={500}
            transitionLeaveTimeout={300}
          >
            {this.state.showResults ? null : this.templateblock()}
          </CSSTransitionGroup>
        </div>
      </div>
    );

    return (
      <div>
        <CSSTransitionGroup
          transitionName="example"
          transitionEnterTimeout={500}
          transitionLeaveTimeout={300}
        >
          {this.state.Wait !== 0 ? this.Load() : null}
        </CSSTransitionGroup>
        {this.top()}
        <PopupAll />
        {this.state.Creating === false ? (
          <div className="grow_main_tariffs">
            <Scrollbars
              style={{
                height: "100%",
              }}
            >
              <div className="dashboard-bot_list_bot">
                <div className="dashboard_list_bot">
                  <div className="dashboard_add_button">
                    {templates}
                    <div className="dashboard-bot_list_bot">
                      {this.state.ShowChoosePopup ? this.ChooseSocPopup() : null}
                      {bots}
                    </div>
                  </div>
                </div>
              </div>
            </Scrollbars>
          </div>
        ) : (
          this.creatingBot()
        )}

        {this.state.ShowDeletePopup ? (
          <Popup
            text={"Вы действительно хотите удалить?"}
            Yes={this.deleteBot}
            isDashboard={true}
            No={() => {
              this.setState({ ShowDeletePopup: false });
            }}
          />
        ) : null}

        {this.state.ShowRenamePopup ? (
          <Popup
            text={"Введите новое имя"}
            Bot={"3534"}
            Group="-"
            RenameGroup={true}
            Rename={this.renameBot}
            isDashboard={true}
            Name={this.getBotName()}
            No={() => {
              this.setState({ ShowRenamePopup: false });
            }}
          />
        ) : null}

        {this.state.ShowSharePopup ? this.sharePopup() : null}

        {/*<LeftPanel isDashboard={true} User={this.props.user}/>*/}
      </div>
    );
  }

  sharePopup() {
    return (
      <div>
        <div className="filterPopup">
          <div className="filter_delete">
            <h1>Предоставить общий доступ к боту</h1>
            <input
              className="chat_input mb10"
              type="text"
              value={this.state.ShareEmail}
              name="ShareEmail"
              placeholder={
                "Введите email пользователя которому хотите предоставить доступ..."
              }
              onChange={this.handleInputChange}
            />

            <button className={"bg_shadow btn_all"} onClick={this.share}>
              Потвердить
            </button>

            <button
              className="grey button"
              onClick={() => {
                this.setState({ ShowSharePopup: false });
              }}
            >
              Отмена
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
    vkGroups: state.user.vkGroups,
    fbGroups: state.user.fbGroups,
    bots: state.bots.bots,
    templates: state.bots.templates,
    botsWasLoad: state.bots.botsWasLoad,
    templatesWasLoad: state.bots.templatesWasLoad,
  };
};

const actionBots = {
  setUser: setUser,
  setBots: setBots,
  setTemplates: setTemplates,
  addVkGroupsToStore: setVkGroups,
  addFbGroupsToStore: setFbGroups,
  addBotToStore: addBotSuccess,
  deleteBotFromStore: deleteBotsSuccess,
  editBotInStore: editBotSuccess,
};

export default connect(
  mapStateToProps,
  actionBots
)(withAlert()(withRouter(Bots)));
