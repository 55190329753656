import React, { Component } from "react";
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import "./style/css/main.min.css";
import "./style/sass/main.sass";
import LoginForm from "./public/login/login.js";
import RegisterForm from "./public/login/signup.js";
import Verify from "./public/login/Verify/Verify";
//import UsersForm from './public/users/all.js';
import { saveState, store } from "./modules";

import ResetPasswordForm from "./public/login/resetPassword.js";
import "antd/dist/antd.css";

// settingProfile
import ProfileSettings from "./public/chat/ProfileSettings/SettingProfile.js";

// Build
import Build from "./public/chat/Build/Build.js";

// Build
import Broadcast from "./public/chat/Broadcast/Broadcast.js";

// Configure
import Configure from "./public/chat/Configure/Configure.js";

// Grow
import Grow from "./public/chat/Grow/Grow.js";
import Modules from "./public/chat/Modules/Modules.js";

// Grow
import CRM from "./public/chat/CRM/CRM.js";

// Analyze
import Analize from "./public/chat/Analyze/Alalyze.js";

// Partner
import Partner from "./public/chat/Partner/Partner.js";

// Terms
import Terms from "./public/login/Terms.js";
import History from "./public/chat/History/History.js";

// Admin create bot
import AdminCreateBot from "./admin/createBot/List.js";

// Admin main
import AdminMain from "./admin/chart/MainAdmin.js";
import AdminUsers from "./admin/users/Users.js";
import AdminEmailTemplates from "./admin/text/email_templates.js";

// Admin settings
import AdminSetting from "./admin/settings/admin-settings";
import AdminPromo from "./admin/Promocodes/Promocodes";
import UserTariffs from "./public/chat/Tariffs/tariffs.js";
import PaymentSuccess from "./public/chat/Payments/AfterPayment.js";

// Manual Build Api site
import Share from "./public/chat/Share/Share.js";
import Vk from "./public/login/vk.js";
import VkRe from "./public/login/vkRebind.js";

//Groups
import Groups from "./public/chat/Build/Groups.js";
import Tariffs from "./admin/tariffs/Tariffs";
import Widget from "./public/chat/Widgets/Widget.js";

//Url
import Url from "./public/chat/Url/Url.js";
import { Provider } from "react-redux";
import "bootstrap/dist/js/bootstrap";
import { Switch, Route, } from "react-router-dom";
import jQuery from "jquery";
import Cookies from "universal-cookie";
import Bots from "./public/chat/Bots/Bots";
import { config } from "./config.js";
import { PaymentPage } from "./public/chat/ProfileSettings/PaymentPage/PaymentPage";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#22FFAA",
      dark: '#47FFB9',
    },
    secondary: {
      main: "#ffa500"
    }
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        color: 'rgba(255,255,255, 0.5)',
        background: '#060C23',
      },
      input: {
        padding: '14.5px 14px',
      },
      notchedOutline: {
        borderColor: '#060C23 !important',
        borderWidth: '1px',
      },
    },
    MuiSelect: {
      icon: {
        color: 'rgba(255,255,255, 0.5)',
      }
    },
    MuiInput: {
      root: {
        color: 'rgba(255,255,255, 0.5)',
      },
      underline: {
        '&:before': {
          borderBottom: '1px solid rgba(255,255,255, 0.5)',
        },
        '&:after': {
          borderBottom: '1px solid rgba(255,255,255, 0.5)',
        },
        '&:hover:not(.Mui-disabled):before': {
          borderBottom: '2px solid rgba(255,255,255, 0.5)',
        }
      },
    },
    MuiFormLabel: {
      root: {
        color: 'rgba(255,255,255, 0.5)',
        '&.Mui-focused': {
          color: 'rgba(255,255,255, 0.5)',
        }
      },
    },
    MuiMenu: {
      list: {
        width: '268px',
      },
    },
  },
});

const cookies = new Cookies();
var func = require("./func.js");

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.exit = this.exit.bind(this);
  }


  componentDidMount() {
    // test
    var o = func.searchToObj();
    for (var k in o) {
      if (k.search(/utm_/i) != -1) {
        cookies.set(k, o[k], {
          path: "/",
        });
      }
    }
    if (cookies.get("visit") === undefined) {
      var url = config.Backend + "/users/visit?";
      if (!(cookies.get("Id") === undefined)) {
        url += "Id=" + cookies.get("Id").toString();
      }
      jQuery.getJSON(
        url,
        function (res) {
          if (!res.Error) {
            var exp = new Date();
            exp.setMinutes(exp.getMinutes() + 30);
            cookies.set("visit", true, {
              path: "/",
              expires: exp,
            });
          }
        }.bind(this)
      );
    }
    if (cookies.get("Exit")) {
      cookies.remove("Id");
      cookies.remove("BotId");
      cookies.remove("Exit");
    }
  }

  exit(event) {
    cookies.remove("Id");
    window.location.replace(window.location.origin + "/login");
  }

  render() {
    store.subscribe(() => {
      saveState(store.getState());
    });

    return (
      <main>
        <ThemeProvider theme={theme}>
          <Provider store={store}>
            <Switch>
              <Route exact path="/" component={LoginForm} />
              <Route path="/login" component={LoginForm} />
              <Route path="/register" component={RegisterForm} />
              <Route path="/vk" component={Vk} />
              <Route path="/vkRebind" component={VkRe} />
              <Route exact path="/reset" component={ResetPasswordForm} />
              <Route exact path="/partner" component={Partner} />
              <Route exact path="/crm" component={CRM} />
              <Route exact path={"/payment/success"} component={PaymentSuccess} />
              <Route exact path="/verify" component={Verify} />
              <Route exact path="/profile/setting" component={ProfileSettings} />
              <Route exact path="/chat/build" component={Groups} />
              <Route exact path="/chat/broadcast" component={Broadcast} />
              <Route exact path="/chat/build/:id" component={Build} />
              <Route exact path="/dashboard" component={Bots} />
              <Route exact path="/tariffs" component={UserTariffs} />
              <Route exact path="/rules" component={Terms} />
              <Route exact path="/chat/configure" component={Configure} />
              <Route exact path="/chat/grow" component={Grow} />
              <Route exact path='/chat/modules' component={Modules} />
              <Route exact path="/widget" component={Widget} />
              <Route exact path="/chat/dialogs" component={History} />
              <Route exact path="/chat/url" component={Url} />
              <Route exact path="/chat/analyze" component={Analize} />
              <Route exact path="/chat/share" component={Share} />
              <Route exact path="/admin/main" component={AdminMain} />
              <Route exact path="/admin/settings" component={AdminSetting} />
              <Route exact path="/admin/create-bot" component={AdminCreateBot} />
              <Route exact path="/admin/tariffs" component={Tariffs} />
              <Route exact path="/admin/users" component={AdminUsers} />
              <Route exact path="/payment-page" component={PaymentPage} />

              <Route
                exact
                path="/admin/email_templates"
                component={AdminEmailTemplates}
              />
              <Route exact path="/admin/promocodes" component={AdminPromo} />
            </Switch>
          </Provider>
        </ThemeProvider>
      </main>
    );
  }
}

export default App;
