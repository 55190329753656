import React from 'react';
import {Link} from "react-router-dom";

class UrlTableRow extends React.Component {

  constructor(props) {
    super(props);
  }

  render () {
    var p = this.props;
    var elem = p.elem;
    var ind = p.ind;
    var onDeleteHandler = () => p.onDeleteHandler(elem, ind);

    var original = elem.Url;
    var redirectLink = p.domen + '?x=' + elem._id;


    return (
      <tr key={elem._id}>
        <td>
          <a href={original}>
            {original}
          </a>
        </td>

        <td>
          <a href={redirectLink}>
            {redirectLink}
          </a>
        </td>

        <td>{elem.clicks}</td>

        <td>
          <button className={"btn__all--small"} onClick={onDeleteHandler}>
            <i className="fas fa-trash-alt"></i>
          </button>
        </td>
      </tr>
    );
  }
}

export default UrlTableRow;
