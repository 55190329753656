import React, { useEffect, useState } from 'react';
import TextareaAutosize from "react-autosize-textarea";
import 'emoji-mart/css/emoji-mart.css'
import { Picker } from 'emoji-mart'
import classNames from 'classnames/bind';
import styles from './styles.module.scss';
import './style.css';


export const TextAreaSmile = (props )=> {

  const [visible, setVisible] = useState("off");
  const [text, setText] = useState('');

  const [botCountCharacters, setBotCountCharacters] = useState(600);

  let countCharacters = botCountCharacters - props.value.length;

  let lengthCharacters = props.value.length > botCountCharacters ? true : false


  useEffect(() => {

    switch (props.nameBot) {
      case 'FB':
        setBotCountCharacters(600);
        break;
      case 'Ok':
        setBotCountCharacters(4096);
        break;
      case 'Vk':
        setBotCountCharacters(4096);
        break;
      case 'Telegram':
        setBotCountCharacters(props.ImageAndTextInOneMessage ? 1024: 4096);
        break;
      case 'Viber':
        setBotCountCharacters(7000);
        break;
    }

  }, [props.nameBot, props.ImageAndTextInOneMessage])


  useEffect ( () => {
    if(props.value) {
      setText(props.value)
    }
  },[props.value]);

  const onChangeEdit = (event) => {

    let e = {
      target: {
        value: event.target.value,
        name: 'Text',
      },
    };

    props.onChange(e);
    setText(event.target.value)
  }


  const visibleEmoje = () => {
    setVisible(visible === "off" ? "on" : "off");
  }


  const  addEmoji = (emoji) => {

    function insertTextAtCursor(el, text) {
      const val = el.value;
      let endIndex;
      let range;
      const doc = el.ownerDocument;

      if (typeof el.selectionStart === 'number' && typeof el.selectionEnd === 'number') {
        el.focus();

        endIndex = el.selectionEnd;
        el.value = val.slice(0, endIndex) + text + val.slice(endIndex);
        el.selectionStart = el.selectionEnd = endIndex + text.length;
      } else if (doc.selection !== 'undefined' && doc.selection.createRange) {
        el.focus();
        range = doc.selection.createRange();
        range.collapse(false);
        range.text = text;
        range.select();
      }
    }
    const element = document.getElementById('message_text');
    insertTextAtCursor(element, ` ${emoji.native}`);
    if (element) {

      let e = {
        target: {
          value: element.value,
          name: 'Text',
        },
      };

      props.onChange(e);
      setText(element.value);

    }
  };

  const cx = classNames.bind(styles);

  const colorButton = cx({
    'visible__emoje': true,
    'visible': visible === 'on',
  });

  const countCharacter = cx({
    'count_characters': true,
    'error' : lengthCharacters
  });

  const textarea = cx({
    'textarea': true,
    'error': lengthCharacters
  })


  return (
    <div className={styles.element}>

      <span onClick={visibleEmoje} className={styles.circle_emoje}>
        <i className="far fa-smile"/>
      </span>

      <span className={countCharacter}>{countCharacters} / {botCountCharacters}</span>

      <div className={colorButton}>
        <Picker
          className={'fd'}
          showPreview={false}
          emojiSize={20}
          sheetSize={32}
          style={{width: '340px', height: '260px', overflow: 'hidden'}}
          onSelect={addEmoji}
        />
      </div>

      <TextareaAutosize
        className={textarea}
        rows={props.rows}
        id={'message_text'}
        text="Text"
        value={text}
        onChange={onChangeEdit}
        placeholder={props.placeholder} />

      <div>{lengthCharacters ?
      <span className={styles.input_check_texarea_error}>Нужно сократить текст</span> : ''}</div>

    </div>
  )
}