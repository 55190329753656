function firstRight (uId,bot) {
  if (bot.User.toString() != uId) {
    if (bot.Share) {
      var ind = bot.Share.Users.indexOf(uId);
      if (ind == -1) {
        return false;
      }

      var arr = ['Build', 'Broadcast', 'Configure', 'Grow', 'Analyze', 'RTC', 'Share'];
      for (var i = 0; i < arr.length; i++) {
        if (bot.Share.UsersWithRights[ind].Rights.indexOf(arr[i]) != -1) {
          return arr[i];
        }
      }
    } else {
      return false;
    }
  }
  return false;
}

export const config = {
  Backend: 'https://backend.botkits.ru',
  // Backend: 'http://localhost:5001',
  ShortUrlApi: 'https://url.botkits.ru',
  // Frontend: 'http://localhost:3000',
  //Frontend:'https://chatbot-front.i-solutions.org',
  //Frontend: 'https://dashboard.botkits.ru',
  Frontend: window.location.origin,
  FrontHtml: 'https://botkits.ru',
  ModuleServer: 'http://80.93.191.82/',
  ModuleServerTest: 'http://80.93.191.82/',
  Yandex: {
    Payment: {
      Api: 'https://payment.yandex.net/api/v3/payments',
      Test: false
    }
  },
  FbAppId: '1747105742265754',
  FbApi: 'https://graph.facebook.com/v2.11',
  TelegramApi: 'https://api.telegram.org/',
  VkAppId: '6297971',
  VkApiV: '5.131',
  OkAppId: '1260337408',
  OkAppKey: 'CBAKDQBMEBABABABA',
  OkAppSecret: 'FC703BA8547E935A74C41809',
  OkApi: 'https://api.ok.ru/fb.do',
  YandexAppId: '60059389f87e4c4b87eefd71a82e88d6',
  YandexOAuthApi: 'https://oauth.yandex.ru',
  YandexLoginApi: 'https://login.yandex.ru/info',
  MailruAppId: 'da04350c74d242aea75f14e0f601b57d',
  MailruOAuthApi: 'https://oauth.mail.ru',
  GoogleClientId: '174569734413-fke18360ve87ugguu2bmv8js7d0bha0c.apps.googleusercontent.com',
  getDaysLeft: function (oDeadLineDate, oToday) {
    return oDeadLineDate > oToday ? Math.ceil((oDeadLineDate - oToday) / (1000 * 60 * 60 * 24))
      : null;
  },
  botIsFullyLoad: (bot) => {
    return bot && !!bot.Users;
  },

  botIsFullyLoadOther: (bot) => {
    return bot && !!bot.User;
  },

  trans: function (str) {
    //return str;
    if ( str ) {
      var s = this.state;
      try {
        var slug = str.toLowerCase();
        return s.Translates[slug].Names[s.Settings['Lang']] || str;
      } catch (e) {
      }
    }
    return str;
  },
  onFocusHandler: function (event)  {
    this.setState({
      [event.target.name+'_valid']: true
    });
  },
  onChangeHandler: function (event)  {
    this.setState({
      [event.target.name]: event.target.value
    });
  },
  beep: (function () {
    var ctxClass = window.audioContext ||window.AudioContext || window.AudioContext
      || window.webkitAudioContext;
    var ctx = new ctxClass();
    return function (duration, type, finishedCallback) {

      duration = +duration;

      // Only 0-4 are valid types.
      type = (type % 5) || 0;

      if ( typeof finishedCallback != 'function' ) {
        finishedCallback = function () {};
      }

      var osc = ctx.createOscillator();

      osc.type = type;
      //osc.type = 'sine';

      osc.connect(ctx.destination);
      if ( osc.noteOn ) {
        osc.noteOn(0); // old browsers
      }
      if ( osc.start ) {
        osc.start(); // new browsers
      }

      setTimeout(function () {
        if ( osc.noteOff ) {
          osc.noteOff(0); // old browsers
        }
        if ( osc.stop ) {
          osc.stop(); // new browsers
        }
        finishedCallback();
      }, duration);

    };
  })(),
  accessDenied: function(uId,bot) {
    var r = firstRight(uId,bot);
    if ( r )
    {
      window.location.href = window.location.origin + '/chat/' + r.toLowerCase();
    }
    else
    {
      window.location.href = window.location.origin + '/profile/setting';
    }
  },
  img: function(src_from_backend) {
    var src = '';
    if( src_from_backend && src_from_backend.indexOf('uploads') != -1 ) {
      src = config.Backend + '/content/getFile?';
      src += '&Link=' + src_from_backend;
      return src;
    }
    else {
      return src_from_backend || src;
    }
  },
  firstRight,
  indexOfArr: function (str,arr) {
    for ( var x = 0; x < arr.length; x++)
    {
      var ind = str.indexOf(arr[x]);
      if ( ind != -1 )
      {
        return ind;
      }
    }
    return -1;
  },
  checkRights: function(uId,bot,right)
  {
    if( bot.User.toString() != uId )
    {
      if( bot.Share )
      {
        var i = bot.Share.Users.indexOf(uId);
        if( i == -1 ) {
          return false;
        }

        if(bot.Share.UsersWithRights[i].Rights.indexOf(right) == -1) {
          return false;
        }
      }
      else
      {
        return false;
      }
    }
    return true;
  },
  checkDialog: function (uId,bot) {
    if( bot.User.toString() != uId )
    {
      if(bot.Share)
      {
        var i = bot.Share.Users.indexOf(uId);
        if( i == -1 ) {
          return false;
        }

        return bot.Share.UsersWithRights[i].Chat;
      }
      else
      {
        return false;
      }
    }
    return false;
  },
  minimizeBot: (b) => {
    return {
      _id: b._id,
      Replies: b.Replies,
      RTC: b.RTC,
      RTCtext: b.RTCtext,
      Modules: b.Modules,
      HelloText: b.HelloText
    }
  },
  change_hours: (evt) => {
    if(evt.target.value >= 24) {
      evt.target.value = 0;
    }
    if( evt.target.value < 0 ) {
      evt.target.value = 23;
    }

    return evt;
  },
  change_minutes: (evt) => {
    if( evt.target.value >= 60 ) {
      evt.target.value = 0;
    }
    if( evt.target.value < 0 ) {
      evt.target.value = 59;
    }

    return evt;
  },
  change_new: function (event) {
    var arr = event.target.name.split('.');
    if ( arr.length == 1 ) {
      this.setState({
        [event.target.name]: event.target.type != 'checkbox' ? event.target.value
          : (event.target.checked ? true : false)
      });
    }
    else
    {
      var val = event.target.type != 'checkbox' ? event.target.value
        : (event.target.checked ? true : false);
      function edit(end,num,max,val) {
        var name = arr[num];
        var inds = [];
        do {
          var ind = -1;
          if ( name.search(/\[[0-9*]+\]/) != -1 )
          {
            ind = +name.match(/\[([0-9*]+)\]/)[1];
          }
          if ( ind != -1 )
          {
            name = name.replace(/\[[0-9*]+\]/,'');
            inds.push(ind);
          }
        } while (name.search(/\[[0-9*]+\]/) != -1)
        var temp = end[name];
        //alert(name);
        //alert(JSON.stringify(inds));
        for ( var i = 0; i < inds.length; i++)
        {
          temp = temp[inds[i]];
        }
        //alert(JSON.stringify(temp));
        if( num == max )
        {
          end[arr[max]] = val;
        }
        else
        {
          edit(temp, ++num, max, val);
        }
      }
      edit(this.state,0,arr.length-1,val);
      this.setState({
        [arr[0]]: this.state[arr[0].replace(/\[[0-9*]+\]/,'')]
      });
    }
  },
  change3: function (event) {
    try {
      var arr = event.target.name.split('.');
      eval('this.state.' + event.target.name + ' = event.target.type != \'checkbox\' ? event.target.value' +
        ' : (event.target.checked ? true : false)');
      this.setState({
        [arr[0]]: this.state[arr[0].replace(/\[[0-9*]+\]/,'')]
      });
    } catch (e) {
      alert('change error: ' + event.target.name);
    }
  },
  change: function (event) {
    var arr = event.target.name.split('.');
    if ( arr.length == 1 ) {
      this.setState({
        [event.target.name]: event.target.type != 'checkbox' ? event.target.value :
          (event.target.checked ? true : false)
      });
    }
    else {
      var val = event.target.type != 'checkbox' ? event.target.value
        : (event.target.checked ? true : false);

      function edit(end, num, max, val) {
        var ind = -1;
        if (arr[num].search(/\[[0-9*]+\]/) != -1) {
          ind = +arr[num].match(/\[([0-9*]+)\]/)[1];
        }
        if (num == max) {
          if (ind != -1) {
            end[arr[num].replace(/\[[0-9*]+\]/, '')][ind] = val;
          }
          else {
            end[arr[max]] = val;
          }
        }
        else {
          if (ind != -1) {
            edit(end[arr[num].replace(/\[[0-9*]+\]/, '')][ind], ++num, max, val);
          }
          else {
            edit(end[arr[num]], ++num, max, val);
          }
        }
      }

      edit(this.state, 0, arr.length - 1, val);
      this.setState({
        [arr[0]]: this.state[arr[0].replace(/\[[0-9*]+\]/, '')]
      })
    }
  },
};
