import { TARIFF_ADD, TARIFF_SET } from "./constains";

const initialState = {
  tariff: []
};

export const tariffReducer = ( state = initialState, action ) => {
  switch (action.type) {
    case TARIFF_SET:
      return {
        ...state,
        tariffs: action.tariff,
      };

    case TARIFF_ADD:
      return {
        ...state,
        tariffs: [ ...state.tariff, { ...action.tariff } ]
      };

    default:
      return state;
  }
};

