import React from 'react';

class DefaultQuestion extends React.Component {

  constructor(props) {
    super(props);
  }

  render () {
    var p = this.props;
    var e = p.elem;
    var i = p.ind;

    var onChangeQuestionHandler = (event) => p.onChangeQuestionHandler(event, i);
    return (<div className={"form-group"}>
      <input disabled={1} className={"grid__input"} type={"text"} value={e}
        onChange={onChangeQuestionHandler}/>
    </div>);
  }

}

export default DefaultQuestion;
