import React, {Component} from 'react';
import jQuery from 'jquery';
import Cookies from 'universal-cookie';
import TransComponent from '../TransComponent.js';
import {Link, withRouter } from "react-router-dom";
import {setUser} from '../modules/User/actions';
import {addBotSuccess} from '../modules/Bots/actions';
import {connect} from "react-redux";
import { config } from '../config.js';

const cookies = new Cookies();
var interval;

class LeftPanel extends TransComponent {

  constructor(props) {
    super(props);
    this.state = {
      OpenChats: 0
    };
  }

  refresh = (first) => {
    var s = this.state;
    var b = this.props.bot;
    if (b && b.BindTo != 'Alisa') {
      var route = 'fb';
      if ( b.BindTo == 'Telegram' ) {
        route = 'telegram';
      }
      else if ( b.BindTo == 'Ok' ) {
        route = 'ok';
      }
      else if ( b.BindTo == 'Vk' ) {
        route = 'vk';
      }
      else if ( b.BindTo == 'Viber' ) {
        route = 'viber';
      } else if (b.BindTo  == 'WhatsApp' ) {
        route = 'whatsapp';
      }
      var url = config.Backend + '/' + route + '/getUsers?BotId=' + b._id;
      url += '&ChatWithAdmin=1';
      url += '&CountOnly=1';
      if (!(cookies.get('Id') === undefined)) {
        url += '&CurrentUserId=' + cookies.get('Id').toString();
      }
      jQuery.getJSON(url, function (res) {
        if ( !res.Error ) {
          const openChatsRefreshedData = Array.isArray(res) ?
              res.length :
              (typeof res.Count === 'number' ? res.Count
                  : (res.Users && res.Users.length));
          if (openChatsRefreshedData > s.OpenChats && first !== true) {
            /*
            const text = 'Появился пользователь с неотвеченным диалогом!';

            alert(text);
            window.document.title = text;
            config.beep(250);*/
          }
          this.setState({
            OpenChats: openChatsRefreshedData
          });
        }
      }.bind(this));
    }
  };

  componentDidMount () {
    this.t_load();

    this.refresh(true);
    //interval = setInterval(this.refresh, 10 * 1000);
  }

  render () {
    var uId = cookies.get('Id').toString();
    var b = this.props.bot;
    var u = this.props.user;
    var showBuild = b && config.checkRights(uId, b, 'Build');
    var showBroadcast = (u && u.Tariff && u.Tariff.EnableBroadcast && b && config.checkRights(uId, b, 'Broadcast')
      && b.BindTo !== 'Alisa') || (b && b.Basic);
    var showConfigure = (u && u.Type != 'Super' && b && config.checkRights(uId, b, 'Configure'))
      || (b && b.Basic);
    var showGrow = (u && u.Type != 'Super' && b && config.checkRights(uId, b, 'Grow')) || (b);
    var showAnalyze = (u && u.Type != 'Super' && b && config.checkRights(uId, b, 'Analyze'))
      || (b && b.Basic);
    var showRtc = (u && u.Type != 'Super' && b && config.checkRights(uId, b, 'RTC')
      && b.BindTo != 'Alisa') || (b && b.Basic);
    var showShare = (u && u.Type != 'Super' && b && u.Tariff && u.Tariff.EnableShare && config.checkRights(uId, b, 'Share'))
      || (b && b.Basic);
    var showModules = (u && ['5a77a02f1b22160bf6bee459', '5a7213c45790c43d60ea50e1'].includes(u._id) && u.Type != 'Super' && b && u.Tariff && u.Tariff.Module)
      || (b && b.Basic);

    return (
      <div className="bot_left_panel">
        {this.props.isDashboard ? null :
          <ul className="top_navigator">
          {showBuild ?
            <li className={window.location.href.indexOf("/chat/build") != -1
            && window.location.href.indexOf("/chat/build_simple") == -1 ? "active" : ""}>
              <Link to="/chat/build">
                <div className="block_image build">
                </div>

                <span>Блок схемы</span>
              </Link>
            </li> : null}

          {showBroadcast ?
            <li className={window.location.href.indexOf("/chat/broadcast") != -1 ? "active" : ""}>
              <Link to="/chat/broadcast">
                <div className="block_image broadcast">
                </div>

                <span>Рассылка</span>
              </Link>
            </li> : null}

          {showConfigure ?
            <li className={window.location.href.indexOf("/chat/configure") != -1 ? "active" : ""}>
              <Link to="/chat/configure">
                <div className="block_image configurate">
                </div>

                <span>Конфигурация</span>
              </Link>
            </li> : null}

          {showGrow ?
            <li className={window.location.href.indexOf("/chat/grow") != -1 ? "active" : ""}>
              <Link to="/chat/grow">
                <div className="block_image  growUp">
                </div>

                <span>Дополнительно</span>
              </Link>
            </li> : null}

          {showAnalyze ?
            <li className={window.location.href.indexOf("/chat/analyze") != -1 ? "active" : ""}>
              <Link to="/chat/analyze">
                <div className="block_image analyze">
                </div>

                <span>Статистика</span>
              </Link>
            </li> : null}

          {/*{showRtc ?*/}
          {/*  <li className={window.location.href.indexOf("/chat/rtc") != -1 ? "active" : ""}>*/}
          {/*    <Link to="/chat/rtc">*/}
          {/*      <div className="block_image arm">*/}
          {/*      </div>*/}

          {/*      <span>Ручное управление ({this.state.OpenChats})</span>*/}
          {/*    </Link>*/}
          {/*  </li> : null}*/}

          {showRtc ?
            <li className={window.location.href.indexOf("/chat/dialogs") != -1 ? "active" : ""}>
              <Link to="/chat/dialogs">
                <div className="block_image dialog">
                </div>

                <span>Диалоги ({this.state.OpenChats})</span>
              </Link>
            </li> : null}
          {showShare ?
            <li className={window.location.href.indexOf("/chat/share") != -1 ? "active" : ""}>
              <Link to="/chat/share">
                <div className="block_image share">
                </div>

                <span>Общий доступ</span>
              </Link>
            </li> : null}

          {showModules ?
            <li className={window.location.href.indexOf("/chat/modules") != -1 ? "active" : ""}>
              <Link to="/chat/modules">
                <div className="block_image  module">
                </div>

                <span>{this.t("Модули")}</span>
              </Link>
            </li> : null}
        </ul>}
      </div>
    );
  }

}

const mapStateToProps = (state) => {
    return {
        user: state.user.user,
        bot: (state.bots.bots || []).find(bot => bot._id === cookies.get('BotId').toString()),
    };
};
const mapDispatchToProps = {
    setUser: setUser,
    addBotToStore: addBotSuccess,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LeftPanel));
