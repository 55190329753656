import React from 'react';
import './Pagination.scss';

export default class Pagination extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            count: this.props.count ?? 15201,
            limit: this.props.limit ?? 10,
            pointer: this.props.pointer ?? 1,
            range: this.props.range ?? 5,
            pagesInRange: [],
            batches: 0,
            firstPageInRange: 1,
            lastPageInRange: 15
        }
    }

    async componentDidMount() {
        await this.calculate();
    }

    async componentWillReceiveProps(nextProps) {
        if (nextProps.pointer !== this.state.pointer) {
            await this.setState({
                pointer: nextProps.pointer
            });
            await this.calculate();
        }
    }

    async calculate() {
        let range = this.state.range;
        const pagination = {
            batches: Math.ceil(this.state.count / this.state.limit),
        };

        if (this.state.pointer <= 1) {
            await this.setState(() => ({
                pointer: 1
            }));
        }

        if (this.state.pointer > pagination.batches || pagination.batches < this.state.pointer) {
            await this.setState(() => ({
                pointer: pagination.batches
            }));
        }

        if (range > pagination.batches) {
            range = pagination.batches;
        }

        let delta = Math.ceil(range / 2);
        let items;

        if (this.state.pointer - delta > pagination.batches - range) {
            items = this.range(
                pagination.batches - range + 1,
                pagination.batches,
            );
        } else {
            if (this.state.pointer - delta < 0) {
                delta = this.state.pointer;
            }

            const offset = this.state.pointer - delta;
            items = this.range(offset + 1, offset + range);
        }

        const proximity = Math.floor(range / 2);
        let start = this.state.pointer - proximity;
        let end = this.state.pointer + proximity;

        if (start < 1) {
            end = Math.min(end + (1 - start), pagination.batches);
            start = 1;
        }

        if (end > pagination.batches) {
            start = Math.max(start - (end - pagination.batches), 1);
            end = pagination.batches;
        }

        pagination.numItemsPerPage = this.state.limit;
        pagination.first = 1;
        pagination.pageCount = pagination.batches;
        pagination.totalCount = this.state.count;
        pagination.pageRange = range;
        pagination.startPage = start;
        pagination.endPage = end;

        if (this.state.pointer > 1) {
            pagination.previous = this.state.pointer - 1;
        }

        if (this.state.pointer < pagination.batches) {
            pagination.next = this.state.pointer + 1;
        }

        pagination.pagesInRange = items;
        pagination.firstPageInRange = items[0];
        pagination.lastPageInRange = items[items.length - 1] ?? items.length;

        await this.setState(() => ({
            pagesInRange: items,
            batches: pagination.batches,
            firstPageInRange: pagination.firstPageInRange,
            lastPageInRange: pagination.lastPageInRange
        }));

        if (this.state.count > 0) {
            pagination.currentItemCount = this.state.limit;
            pagination.firstItemNumber = 0;
            pagination.lastItemNumber = 0;

            if (pagination.totalCount > 0) {
                pagination.firstItemNumber = (this.state.pointer - 1) * this.state.limit + 1;
                pagination.lastItemNumber =
                    pagination.firstItemNumber + pagination.currentItemCount - 1;
            }
        }

        return pagination;
    }

    async change(value) {
        await this.setState(() => ({
            pointer: Number(value)
        }));

        if (this.state.pointer <= 1) {
            await this.setState(() => ({
                pointer: 1
            }));
        }

        if (this.state.pointer > this.state.batches) {
            await this.setState(() => ({
                pointer: this.state.batches
            }));
        }

        await this.calculate();

        this.props.onChange(this.props.section, this.state.pointer);
    }

    range(low, high, step) {
        const matrix = [];
        let initValue;
        let endValue;
        let plus;
        const walker = step || 1;
        let chars = false;

        // eslint-disable-next-line no-restricted-globals
        if (!isNaN(low) && !isNaN(high)) {
            initValue = low;
            endValue = high;
            // eslint-disable-next-line no-restricted-globals
        } else if (isNaN(low) && isNaN(high)) {
            chars = true;
            initValue = low.charCodeAt(0);
            endValue = high.charCodeAt(0);
        } else {
            // eslint-disable-next-line no-restricted-globals
            initValue = isNaN(low) ? 0 : low;
            // eslint-disable-next-line no-restricted-globals
            endValue = isNaN(high) ? 0 : high;
        }

        // eslint-disable-next-line prefer-const
        plus = initValue <= endValue;

        if (plus) {
            while (initValue <= endValue) {
                matrix.push(chars ? String.fromCharCode(initValue) : initValue);
                initValue += walker;
            }
        } else {
            while (initValue >= endValue) {
                matrix.push(chars ? String.fromCharCode(initValue) : initValue);
                initValue -= walker;
            }
        }

        return matrix;
    }

    render() {
        if (this.state.batches === 0) {
            return null;
        }

        return <div className={'footer-pagination'}>
            <div className={'pagination'}>
                <a href={'#'} className={'pagination-first-page'} onClick={() => this.change(1)}>
                    {'<<'}
                </a>
                <a href={'#'} onClick={() => this.change(this.state.pointer - 1)}>
                    {'<'}
                </a>
                {(() => {
                    if (this.state.firstPageInRange > 1) {
                        return <a href={'#'} onClick={() => this.change(1)}>1</a>;
                    }

                    return null;
                })()}
                {this.state.pagesInRange.map((page) =>
                    <a key={page} href={'#'} className={(this.state.pointer === page ? 'active' : '')} onClick={() => this.change(page)}>
                        {page}
                    </a>
                )}
                {(() => {
                    if (this.state.lastPageInRange < this.state.batches) {
                        return <a href={'#'} onClick={() => this.change(this.state.batches)}>{this.state.batches}</a>;
                    }

                    return null;
                })()}
                <a href={'#'} onClick={() => this.change(this.state.pointer + 1)}>
                    {'>'}
                </a>
                <a href={'#'} className={'pagination-last-page'} onClick={() => this.change((this.state.batches))}>
                    {'>>'}
                </a>
            </div>
        </div>;
    }
}
