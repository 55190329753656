import React, {Component} from 'react';
import jQuery from 'jquery';
import Cookies from 'universal-cookie';
import {Input, Button, Card, Col, Checkbox} from 'antd';
import { config } from '../../config.js';

const cookies = new Cookies();

var func = require('../../func.js');

class Bot extends Component {

  constructor(props) {
    super(props);
    this.state = {
      Bot: props.Bot
    };

    this.onChangeHandler = config.change.bind(this);
  }

  onEditHandler = () => {
    var s = this.state;
    var b = s.Bot;
    cookies.set('BotId', b._id, {
      path: '/'
    });
    window.location.replace(window.location.origin + '/chat/build');
  }

  onSaveHandler = () => {
    var s = this.state;
    var b = s.Bot;
    func.getData('bot/edit_new',
      {
        BotId: b._id,
        Name: b.Name,
        Status: b.Status,
        Description: b.Description,
        Price: b.Price
      }).then(ok => {
      alert('Успех');
    }, e => {
      alert(JSON.stringify(e));
    });
  }

  onEditIconHandler = (e) => {
    var s = this.state;
    var b = s.Bot;
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      var url = reader.result;
      if (file) {
        var fd = new FormData;
        fd.append('File', file);
        //p.addWait();
        jQuery.ajax({
          method: 'POST',
          processData: false,
          contentType: false,
          url: config.Backend + '/content/file',
          success: function (msg) {
            func.getData('bot/setup_icon', {
              Icon: msg,
              Bot: b._id
            }).then(function (ok) {
              window.location.reload();
              //p.delWait();
            }.bind(this), function (err) {
              //p.delWait();
              alert(JSON.stringify(err));
            }.bind(this));
          }.bind(this),
          data: fd
        });
      }
    };
    reader.readAsDataURL(file);
  };

  onClearInputHandler = (e) => {
    e.target.value = null;
  };

  render() {
    const {TextArea} = Input;
    var s = this.state;
    var p = this.props;
    var src = config.Backend + '/content/getFile?';
    if (!(cookies.get('Id') === undefined)) {
      src += '&CurrentUserId=' + cookies.get('Id').toString();
    }
    src += '&Link=' + p.Icon;

    var onWantDeleteBotHandler = () => {
      p.onWantDeleteBotHandler(p.Bot._id);
    };

    return (
      <Col span={8}>
        <Card bordered={false}>
          <div className="row__grid  pl">
            <div className="col__3">
              <div className="admin_bot_images">
                <img src={src} alt=""/>
              </div>
            </div>
            
            {s.Bot ?
              <div className="col__7">
                <Input
                  type={"text"}
                  value={s.Bot.Name}
                  onChange={this.onChangeHandler}
                  name={"Bot.Name"}
                  className={"mb10"}/>

                <div className="form-group">
                  <TextArea
                    placeholder={"описание"}
                    onChange={this.onChangeHandler}
                    name={"Bot.Description"}
                    value={s.Bot.Description}
                    rows={4}/>
                </div>

                <div className="form-group">
                  <label htmlFor={"price"}>Цена(в рублях)</label>
                  <Input
                    type={"number"}
                    value={s.Bot.Price}
                    onChange={this.onChangeHandler}
                    name={"Bot.Price"}/>
                </div>

                <div className="form-group">
                  <Checkbox
                    name={"Bot.Status"}
                    checked={s.Bot.Status}
                    onChange={this.onChangeHandler}>
                    Опубликован
                  </Checkbox>
                </div>

                {/*<input className={"ml__5"} type={"checkbox"} checked={s.Bot.Status}
                 name={"Bot.Status"} onChange={this.onChangeHandler}/>*/}

                <div className="form-group mb10">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={this.onEditIconHandler}
                    onClick={this.onClearInputHandler}/>
                </div>

                <div className="form-group mt_10">
                  <Button className={"mr2"} onClick={this.onSaveHandler} type="default">
                    Сохранить
                  </Button>

                  <Button className={"mr2"} onClick={this.onEditHandler} type="default"
                    icon="edit"/>

                  <Button className={"mr2"} onClick={onWantDeleteBotHandler} type="default"
                    icon="delete"/>
                </div>
              </div> : null}
          </div>
        </Card>
      </Col>
    );
  }
}

export default Bot;
