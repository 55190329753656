import React, { Component}  from 'react';
import Cookies from 'universal-cookie';

class Terms extends Component {

  constructor(props) {
    super(props);

    this.state = {};
  }

  render () {
    return <div>Политика конфиденциальности
      <br/>г. Гомель				25 ноября 2017 года
      <br/>Настоящая Политика конфиденциальности персональных данных (далее – Политика конфиденциальности) действует в отношении всей информации, которую система построения ботов “Chatter”, расположенный на доменных именах chat-front.i-solutions.org и chat-back.i-solutions.org, может получить о Пользователе во время использования сайта, программ и продуктов сайта.
      <br/>
      <br/> 1. ОПРЕДЕЛЕНИЕ ТЕРМИНОВ
      <br/> 1.1 В настоящей Политике конфиденциальности используются следующие термины:
      <br/>1.1.1. «Администрация сайта» – уполномоченные сотрудники на управления сайтом, действующие от имени  Chatter, которые организуют и (или) осуществляет обработку персональных данных, а также определяет цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.
      <br/>1.1.2. «Персональные данные» - любая информация, относящаяся прямо или косвенно к определенному или определяемому физическому лицу (субъекту персональных данных).
      <br/>1.1.3. «Обработка персональных данных» - любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.
      <br/>1.1.4. «Конфиденциальность персональных данных» - обязательное для соблюдения Оператором или иным получившим доступ к персональным данным лицом требование не допускать их распространения без согласия субъекта персональных данных или наличия иного законного основания.
      <br/>1.1.5. «Пользователь сайта (далее – Пользователь)» – лицо, имеющее доступ к Сайту, посредством сети Интернет и использующее Сайт.
      <br/>1.1.6. «Cookies» — небольшой фрагмент данных, отправленный веб-сервером и хранимый на компьютере пользователя, который веб-клиент или веб-браузер каждый раз пересылает веб-серверу в HTTP-запросе при попытке открыть страницу соответствующего сайта.
      <br/>1.1.7. «IP-адрес» — уникальный сетевой адрес узла в компьютерной сети, построенной по протоколу IP.
      <br/>2. ОБЩИЕ ПОЛОЖЕНИЯ
      <br/>2.1. Использование Пользователем Сайта означает согласие с настоящей Политикой конфиденциальности и условиями обработки персональных данных Пользователя.
      <br/>2.2. В случае несогласия с условиями Политики конфиденциальности Пользователь должен прекратить использование Сайта.
      <br/>2.3.Настоящая Политика конфиденциальности применяется только к сайту Chatter. Сайт не контролирует и не несет ответственность за сайты третьих лиц, на которые Пользователь может перейти по ссылкам, доступным на сайте
      <br/>2.4. Администрация сайта не проверяет достоверность персональных данных, предоставляемых Пользователем.
      <br/>
      <br/>3. ПРЕДМЕТ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ
      <br/>3.1. Настоящая Политика конфиденциальности устанавливает обязательства Администрации сайта по неразглашению и обеспечению режима защиты конфиденциальности персональных данных, которые Пользователь предоставляет по запросу Администрации сайта при регистрации на сайте, оформлении заказа, а также при создании и использовании ботов.
      <br/>3.2. Персональные данные, разрешённые к обработке в рамках настоящей Политики конфиденциальности, предоставляются Пользователем путём заполнения регистрационной формы на Сайте Chatter, а также при привязывании аккаунтов социальных сетей к сайту.
      <br/>3.3. Отключение cookies может повлечь невозможность доступа к частям сайта, требующим авторизации.
      <br/>3.4. Любая иная персональная информация неоговоренная выше (история покупок, используемые браузеры и операционные системы и т.д.) подлежит надежному хранению и нераспространению, за исключением случаев, предусмотренных в п.п. 5.2. и 5.3. настоящей Политики конфиденциальности.
      <br/>4. ЦЕЛИ СБОРА ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЯ
      <br/>4.1. Персональные данные Пользователя Администрация сайта интернет-магазина может использовать в целях:
      <br/>4.1.1. Идентификации Пользователя, зарегистрированного на Сайте, для оформления заказа и (или) заключения Договора купли-продажи товара дистанционным способом с Chatter.
      <br/>4.1.2. Предоставления Пользователю доступа к персонализированным ресурсам Сайта.
      <br/>4.1.3. Установления с Пользователем обратной связи, включая направление уведомлений, запросов, касающихся использования Сайта, оказания услуг, обработка запросов и заявок от Пользователя.
      <br/>4.1.4. Определения места нахождения Пользователя для обеспечения безопасности, предотвращения мошенничества.
      <br/>4.1.5. Подтверждения достоверности и полноты персональных данных, предоставленных Пользователем.
      <br/>4.1.6. Создания учетной записи для совершения покупок и проведения операций, если Пользователь дал согласие на создание учетной записи.
      <br/>4.1.7. Уведомления Пользователя Сайта о состоянии предоставляемых ему услуг.
      <br/>4.1.8. Обработки и получения платежей, подтверждения налога или налоговых льгот, оспаривания платежа, определения права на получение кредитной линии Пользователем.
      <br/>4.1.9. Предоставления Пользователю эффективной клиентской и технической поддержки при возникновении проблем связанных с использованием Сайта.
      <br/>4.1.10. Предоставления Пользователю с его согласия, обновлений продукции, специальных предложений, информации о ценах, новостной рассылки и иных сведений от имени Chatter.
      <br/>4.1.11. Осуществления рекламной деятельности с согласия Пользователя.
      <br/>4.1.12. Предоставления доступа Пользователю на сайты или сервисы партнеров Chatter с целью получения продуктов, обновлений и услуг.
      <br/>5. СПОСОБЫ И СРОКИ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ
      <br/>5.1. Обработка персональных данных Пользователя осуществляется без ограничения срока, любым законным способом, в том числе в информационных системах персональных данных с использованием средств автоматизации или без использования таких средств.
      <br/>5.2. Персональные данные Пользователя могут быть переданы уполномоченным органам государственной власти Республики Беларусь только по основаниям и в порядке, установленным законодательством Республики Беларусь.
      <br/>5.3. При утрате или разглашении персональных данных Администрация сайта информирует Пользователя об утрате или разглашении персональных данных.
      <br/>5.4. Администрация сайта принимает необходимые организационные и технические меры для защиты персональной информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий третьих лиц.
      <br/>5.5. Администрация сайта совместно с Пользователем принимает все необходимые меры по предотвращению убытков или иных отрицательных последствий, вызванных утратой или разглашением персональных данных Пользователя.
      <br/>6. ОБЯЗАТЕЛЬСТВА СТОРОН
      <br/>6.1. Пользователь обязан:
      <br/>6.1.1. Предоставить информацию о персональных данных, необходимую для пользования Chatter.
      <br/>6.1.2. Обновить, дополнить предоставленную информацию о персональных данных в случае изменения данной информации.
      <br/>6.2. Администрация сайта обязана:
      <br/>6.2.1. Использовать полученную информацию исключительно для целей, указанных в п. 4 настоящей Политики конфиденциальности.
      <br/>6.2.2. Обеспечить хранение конфиденциальной информации в тайне, не разглашать без предварительного письменного разрешения Пользователя, а также не осуществлять продажу, обмен, опубликование, либо разглашение иными возможными способами переданных персональных данных Пользователя, за исключением п.п. 5.2. настоящей Политики Конфиденциальности.
      <br/>6.2.3. Принимать меры предосторожности для защиты конфиденциальности персональных данных Пользователя согласно порядку, обычно используемого для защиты такого рода информации в существующем деловом обороте.
      <br/>6.2.4. Осуществить блокирование персональных данных, относящихся к соответствующему Пользователю, с момента обращения или запроса Пользователя или его законного представителя либо уполномоченного органа по защите прав субъектов персональных данных на период проверки, в случае выявления недостоверных персональных данных или неправомерных действий.
      <br/>7. ОТВЕТСТВЕННОСТЬ СТОРОН
      <br/>7.1. Администрация сайта, не исполнившая свои обязательства, несёт ответственность за убытки, понесённые Пользователем в связи с неправомерным использованием персональных данных, в соответствии с законодательством Республики Беларусь, за исключением случаев, предусмотренных п.п. 5.2. и 7.2. настоящей Политики Конфиденциальности.
      <br/>7.2. В случае утраты или разглашения Конфиденциальной информации Администрация сайта не несёт ответственность, если данная конфиденциальная информация:
      <br/>7.2.1. Стала публичным достоянием до её утраты или разглашения.
      <br/>7.2.2. Была получена от третьей стороны до момента её получения Администрацией сайта.
      <br/>7.2.3. Была разглашена с согласия Пользователя.
      <br/>8. РАЗРЕШЕНИЕ СПОРОВ
      <br/>8.1. До обращения в суд с иском по спорам, возникающим из отношений между Пользователем сайта и Администрацией сайта, обязательным является предъявление претензии (письменного предложения о добровольном урегулировании спора).
      <br/>8.2 .Получатель претензии в течение 30 календарных дней со дня получения претензии, письменно уведомляет заявителя претензии о результатах рассмотрения претензии.
      <br/>8.3. При не достижении соглашения спор будет передан на рассмотрение в судебный орган в соответствии с действующим законодательством Республики Беларусь.
      <br/>8.4. К настоящей Политике конфиденциальности и отношениям между Пользователем и Администрацией сайта применяется действующее законодательство Республики Беларусь.
      <br/>9. ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ
      <br/>9.1. Администрация сайта вправе вносить изменения в настоящую Политику конфиденциальности без согласия Пользователя.
      <br/>9.2. Новая Политика конфиденциальности вступает в силу с момента ее размещения на Сайте Chatter, если иное не предусмотрено новой редакцией Политики конфиденциальности.
      <br/>9.3. Все предложения или вопросы по настоящей Политике конфиденциальности следует сообщать используя форму обратной связи
      <br/>9.4. Действующая Политика конфиденциальности размещена на странице по адресу тут указать ссылку на страницу
    </div>
  }

}

export default  Terms;