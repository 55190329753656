import React from 'react';
import TransComponent from '../../../TransComponent.js';

class Referal extends TransComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount () {
    this.t_load();
  }

  render () {
    var p = this.props;
    var e = p.elem;
    var i = p.ind;
    var onShowDetailsHandler = () => p.onShowDetailsHandler(e, i);

    return (<tr key={e._id}>
      <td>{e.Name}</td>

      <td>{e.Email || ""}</td>

      <td className={"table_center"}>{new Date(e.created_at).toLocaleDateString("ru-RU")}</td>

      <td className={"table_center"}>
        {new Date(e.created_at + 1000 * 60 * 60 * 24 *
          (p.Settings2["ReferalDays"] || 0)).toLocaleDateString("ru-RU")}
      </td>

      <td className={"table_center"}>{e.total ? e.total.toFixed(2) : 0} {this.t("руб")}</td>

      <td className={"table_center"}>
        <button className={"btn__all--small"} onClick={onShowDetailsHandler}>
          {this.t("Подробнее")}
        </button>
      </td>
    </tr>);
  }
}

export default Referal;
