import React from 'react';
import { config } from '../../../config.js';

class Interval extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    var p = this.props;
    var s = p.state;
    var e = p.elem;
    var i = p.ind;

    var onChangeHoursHandler = (evt) => p.onChange2Handler(config.change_hours(evt));
    var onChangeMinutesHandler = (evt) => p.onChange2Handler(config.change_minutes(evt));
    var onDeleteIntervalHandler = () => p.onDeleteIntervalHandler(e, i);

    return (<tr className={"table_f"}>
      <td>
        <input className={"chat_input borderR"} type={"number"} value={e.Start.H}
          onChange={onChangeHoursHandler}
          name={"Modules." + s.Tab + ".Days[" + s.Day + "][" + i + "].Start.H"}/>
      </td>

      <td>
        <input className={"chat_input borderR"} type={"number"}
          value={e.Start.M} onChange={onChangeMinutesHandler}
          name={"Modules." + s.Tab + ".Days[" + s.Day + "][" + i + "].Start.M"}/>
      </td>

      <td>
        <input className={"chat_input borderR"} type={"number"}
          value={e.End.H} onChange={onChangeHoursHandler}
          name={"Modules." + s.Tab + ".Days[" + s.Day + "][" + i + "].End.H"}/>
      </td>

      <td>
        <input className={"chat_input borderR"} type={"number"}
          value={e.End.M} onChange={onChangeMinutesHandler}
          name={"Modules." + s.Tab + ".Days[" + s.Day + "][" + i + "].End.M"}/>
      </td>

      <td className={"tc"}>
        <button className={"btn__all--small mt_5"} onClick={onDeleteIntervalHandler}>
          <i className="fas fa-trash-alt"></i>
        </button>
      </td>
    </tr>);
  }

}

export default Interval;
