import {
  USER_FAILURE,
  USER_REQUEST,
  USER_SUCCESS,
  SET_VK_GROUPS,
  SET_FB_GROUPS,
  SET_PAYMENTS,
  EDIT_FB_GROUP
} from "./constains";

export const setUser = () => {
  return {
    type: USER_REQUEST,
  };
};

export const successUser = user => {
  return {
    type: USER_SUCCESS,
    user: user
  };
};

export const failureUser = errorMessage => {
  return {
    type: USER_FAILURE,
    errorMessage,
  }
};

export const setVkGroups = vkGroups => {
  return {
    type: SET_VK_GROUPS,
    vkGroups,
  }
};

export const setFbGroups = fbGroups => {
  return {
    type: SET_FB_GROUPS,
    fbGroups,
  }
};

export const setPayments = payments => {
  return {
    type: SET_PAYMENTS,
    payments,
  }
};

export const editFbGroup = (fbGroup, index) => {
  return {
    type: EDIT_FB_GROUP,
    fbGroup,
    index,
  }
};
