import React, { Component}  from 'react';
import jQuery from 'jquery';
import TransComponent from '../../TransComponent.js';
import {Link} from "react-router-dom";

import { config } from '../../config.js';

class ResetPasswordForm extends TransComponent {

  constructor(props) {
    super(props);

    this.state = {
      Email: '',
      Email_valid: true,
      Response_txt: '',
      Error: false
    };

    this.onFocusHandler = config.onFocusHandler.bind(this);
    this.onChangeHandler = config.onChangeHandler.bind(this);
  }

  componentDidMount () {
    this.t_load();
  }

  onSubmitFormHandler = (event) => {
    const Email_filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (!Email_filter.test(this.state.Email)) {
      this.setState({
        Email_valid: false
      });
    } else {
      var url = config.Backend + '/users/resetPassword?Email=' + this.state.Email;

      jQuery.getJSON(url, function (res){
        if (res.Error) {
          this.setState({
            Response_txt : res.Error,
            Error : true
          });
        } else {
          this.setState({
            Error: false,
            Response_txt : res.Ok
          });
        }
      }.bind(this));
    }
    event.preventDefault();
  };

  Response () {
    const danger = this.state.Error ? <div className="text-danger">
        {this.t(this.state.Response_txt)}</div>
      : <div>{this.t(this.state.Response_txt)}</div>;
    return (
      <div className="form-group">
        {danger}
      </div>
    );
  }

  Email () {
    const danger = this.state.Email_valid ? '' : <div className="text-danger">
      {this.t("Проверьте email")}</div>;
    return (
      <div className="form-group">
        <input type="Email" className="" placeholder={this.t("Email")} value={this.state.Email}
          name="Email" onChange={this.onChangeHandler} onFocus={this.onFocusHandler} required/>
        {danger}
      </div>
    );
  }

  render () {
    return (
      <div className="background_comein">
        <div className="form_block_login">
          <div className="block_img">
            <Link to="/">
              <img src="/img/logo-botkits.svg" />
            </Link>
          </div>

          <p className={"mt_15"} style={{
            marginBottom: "10px"
          }}>
            {this.t("Восстановить пароль или")} <Link to="/login">{this.t("Войти")}</Link>
          </p>

          <p>{this.t("Введите адрес электронной почты для получения пароля")}</p>

          <form onSubmit={this.onSubmitFormHandler} className="login">
            {this.Response()}

            {this.Email()}

            <button type="submit" className="btn_facebook ">{this.t("Сбросить пароль")}</button>
          </form>
        </div>
      </div>
    );
  }

}

export default ResetPasswordForm;
