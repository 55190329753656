import React from 'react';

class AdditionalRegQuestion extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    var p = this.props;
    var e = p.elem;
    var i = p.ind;

    var onChangeQuestionHandler = (event) => p.onChangeQuestionHandler(event, i);
    var onDeleteQuestionHandler = () => p.onDeleteQuestionHandler(e, i);

    return (<div className={"row__grid form-group"}>
      <div className="col__9">
        <input className={"grid__input"} type={"text"} value={e}
          onChange={onChangeQuestionHandler}/>
      </div>

      <div className="col__1 justify__content--end">
        <button className={"btn__all--small gray_m"} onClick={onDeleteQuestionHandler}>
          <i className="fas fa-trash-alt"></i>
        </button>
      </div>
      <hr/>
    </div>);
  }

}

export default AdditionalRegQuestion;
