import React from 'react';
import jQuery from 'jquery';
import AdminLeft from '../AdminLeft';
import Cookies from 'universal-cookie';
import TextareaAutosize from 'react-autosize-textarea';
import {Scrollbars} from 'react-custom-scrollbars';
import RichTextEditor from 'react-rte';
import {Link} from "react-router-dom";
import { config } from '../../config.js';

const cookies = new Cookies();

class Text extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      User: {},
      Templates: [],
      Index: 0,
      Saved: true,
      TariffSoonEnd: '5a4ee603148e5b220436fffe',
      RTC: '5aa9a105437e6b0a57cf50f7',
      CRM: '5aa9a0ad437e6b0a57cf4caf',
      TariffEnd: '5af5db6f11ab795e9fdd7389'
    };

  }

  onChangeTextHandler = (value) => {
    this.state.Templates[this.state.Index].Text = value.toString('html');
    this.state.Templates[this.state.Index].Editor = value;
    this.setState({
      Templates: this.state.Templates,
      Saved: false
    });
  };

  choose = (i) => {
    var temp = this.state.Templates;
    this.setState({
      Index: i
    });
  };

  onAddHandler = () => {
    jQuery.ajax({
      method: 'POST',
      contentType: 'application/json',
      url: config.Backend + '/admintext/add',
      success: function (res) {
        if (!res.Error) {
          this.state.Templates.push(res);
          this.setState({
            Templates: this.state.Templates
          });
        } else {
          alert(JSON.stringify(res));
        }
      }.bind(this),
      data: JSON.stringify({
        Id: cookies.get('Id')
      }),
    });
  };

  onSaveHandler = () => {
    jQuery.ajax({
      method: 'POST',
      contentType: 'application/json',
      url: config.Backend + '/admintext/editTemplate',
      success: function (res) {
        if (!res.Error) {
          this.setState({
            Saved: true
          });
        } else {
          alert(JSON.stringify(res));
        }
      }.bind(this),
      data: JSON.stringify({
        Id: cookies.get('Id'),
        Template: this.state.Templates[this.state.Index]
      }),
    })
  };

  onDeleteHandler = () => {
    jQuery.ajax({
      method: 'POST',
      contentType: 'application/json',
      url: config.Backend + '/admintext/delete',
      success: function (res) {
        if (!res.Error) {
          this.state.Templates.splice(this.state.Index, 1);
          this.setState({
            Templates: this.state.Templates
          });
        } else {
          alert(JSON.stringify(res));
        }
      }.bind(this),
      data: JSON.stringify({
        Id: cookies.get('Id'),
        Template: this.state.Templates[this.state.Index]
      }),
    });
  };

  onChangeHandler = (event) => {
    this.state.Templates[this.state.Index][event.target.name] = event.target.type != 'checkbox' ?
      event.target.value : (event.target.checked ? 1 : 0);
    this.setState({
      Templates: this.state.Templates,
      Saved: false,
    });
  };

  load = () => {
    var url = config.Backend + '/users/findById?';
    if (!(cookies.get('Id') === undefined)) {
      url += 'Id=' + cookies.get('Id').toString();
    }
    jQuery.getJSON(url, function (res) {
      if (!res.Error) {
        if (res.Type == 'Admin') {
          window.location.replace(window.location.origin + '/login');
        }
        this.setState({
          User: res
        }, () => {
          var url = config.Backend + '/admintext/templates?Id=' + cookies.get('Id');
          jQuery.getJSON(url, function (res) {
            if (!res.Error) {
              this.setState({
                Templates: res
              })
            } else {
              alert(JSON.stringify(res));
            }
          }.bind(this));
        });
      } else {
        window.location.replace(window.location.origin + '/login');
      }
    }.bind(this));
  }

  componentDidMount () {
    this.load();
  }

  AdminTop () {
    return (
      <div className="admin_top">
        <div className="admin_logo_top">
          <Link to="/admin/main"></Link>
        </div>
      </div>
    );
  }

  ManageText () {
    var s = this.state;
    var bind = [s.TariffSoonEnd, s.RTC, s.CRM, s.TariffEnd];//массив привязанных шаблонов

    return (
      <div>
        {bind.indexOf(this.state.Templates[this.state.Index]._id) != -1 ? null :
          <label>Название шаблона
            <input
              className={"chat_input"}
              placeholder={"Название шаблона"}
              value={this.state.Templates[this.state.Index].Name}
              name="Name"
              onChange={this.onChangeHandler}/>
          </label>}

        <div className="form-group">
          <label>Тема письма</label>

          <input
            className={"chat_input"}
            placeholder={"Тема письма"}
            value={this.state.Templates[this.state.Index].Subject}
            name="Subject"
            onChange={this.onChangeHandler}/>
        </div>

        <RichTextEditor
          value={this.state.Templates[this.state.Index].Editor ?
            this.state.Templates[this.state.Index].Editor :
            ( this.state.Templates[this.state.Index].Text ?
              RichTextEditor.createValueFromString(this.state.Templates[this.state.Index].Text,
                "html")
              : RichTextEditor.createEmptyValue())}
          onChange={this.onChangeTextHandler}/>

        <TextareaAutosize
          className="api_textarea border_textarea_ mt_5 pl"
          rows={3}
          maxRows={99}
          value={this.state.Templates[this.state.Index].Text}
          name="Text"
          onChange={this.onChangeHandler}>
        </TextareaAutosize>

        <button className={"btn__all--small bg_shadow mt_10"} onClick={this.onSaveHandler}>
          Сохранить изменения
        </button>

        {bind.indexOf(this.state.Templates[this.state.Index]._id) != -1 ? null :
          <button className={"btn__all--small bg_shadow mt_10"} onClick={this.onDeleteHandler}>
            Удалить шаблон
          </button>}
      </div>);
  }

  render () {
    var templates = this.state.Templates.map(function (elem, ind) {
      const i = ind;
      const t = elem;

      var onChooseHandler = () => this.choose(i);

      return (
        <button className={"btn__all--small mr2 mb30"} onClick={onChooseHandler}>
          {t.Name || "Безымянный"}}
        </button>);
    }.bind(this));

    return (
      <div>
        <div className="b-page">
          <div className="b-page__row">
            <AdminLeft/>

            <div className="b-page__content-wrap" style={{
              height: "calc(100vh)"
            }}>
              <Scrollbars style={{
                height: "100%"
              }}>
                <div className="dashboard-page">
                  <div className="dashboard-page__items">
                    <div className="form-group">
                      <button className={"btn__all--small bg_shadow mt_10 mr15"}
                        onClick={this.onAddHandler}>
                        Добавить шаблон
                      </button>

                      <span className={"oSave"}>
                        {this.state.Saved ? "Изменения сохранены" : "Изменения не сохранены"}
                      </span>
                    </div>

                    {templates}

                    {this.state.Templates[this.state.Index] ? this.ManageText() : null}
                  </div>
                </div>
              </Scrollbars>
            </div>
          </div>
        </div>
      </div>
    );
  }

}

export default Text;
