import { call, put } from 'redux-saga/effects';

import {
  METHOD_GET, requestBuilder,
} from "../../../helpers/requestBuilder";

import Cookies from "universal-cookie";
import {deleteBotsSuccess, failureBots, successBots} from "../actions";
const cookies = new Cookies();

export default function* BotDeleteSaga (action) {
  const id = cookies.get('Id');

  try {
    const data = yield call(
      requestBuilder,
      '/bot/delete?',
      METHOD_GET,
      {
        CurrentUserId: id,
        Id: action.id,
      });
    if (data.data.Error) {
      yield put() (failureBots('Error'))
    } else {
      yield put(deleteBotsSuccess(data.data))
    }
  }

  catch (error) {
    yield put(failureBots('Bots error'))
  }
}
