import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'

const options = {
  position: positions.TOP_CENTER,
  timeout: 5000,
  offset: '10px',
  transition: transitions.SCALE
}

ReactDOM.render((
  <BrowserRouter>
    <AlertProvider template={ AlertTemplate } { ...options }>
      <App/>
    </AlertProvider>
  </BrowserRouter>

), document.getElementById('root'));

