import React from 'react';
import TransComponent from '../../../TransComponent.js';

class Message extends TransComponent {

  constructor(props) {
    super(props);
  }

  componentDidMount () {
    this.t_load();
  }

  render () {
    var p = this.props;
    var obj = p.elem;
    var i = p.ind;
    var onProcessHandler = () => p.onProcessHandler(i);
    var owner = 'Администратор';
    var ava = '/img/avatar_chat/admin.svg';
    switch (obj.Owner) {
      case 'Bot':
        owner = 'Бот';
        ava = '/img/avatar_chat/robot-face.svg';
        break;
      case 'User':
        owner = obj.Name || 'Пользователь';
        p.Users.forEach(u => {
          if ( u.User == obj.User ) {
            ava = u.avatar || '/img/avatar_chat/user.svg';
          }
        });
        break;
    }
    return (
      <div key={obj._id} className={"row__grid w-100"}>
        <div className={"chat_message col__10"}>
          <div className="row__grid">
            <div className="col__1">
              <div className="chat_message_avatar row__grid justify-content-center">
                <img src={ava}/>
              </div>
            </div>

            <div className="col__9">
              <div className="row__grid">
                <div className="col__4">
                  <div className={"chat_name"}>{owner}</div>
                </div>

                <div className="col__6 justify__content--end row__grid">
                  <div className={"chat_date"}>{new Date(obj.created_at).toLocaleString()}</div>
                </div>
              </div>

              <button className={"message_buttom mt_5 mb5"} onClick={onProcessHandler}>
                {!obj.before_process ? 'До обработки' : 'После обработки'}
              </button>

              {obj.before_process ?
                <div className={"chat_text"}>{obj.Text}</div> :
                <div className={"chat_text_ins"}>{obj.TextAfterGrowUp || obj.Text}</div>}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Message;
