import React from 'react';
import TransComponent from '../../../TransComponent.js';

class Tariff extends TransComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.t_load();
  }

  render() {
    var p = this.props;
    var x = p.elem;
    var i = p.ind;
    var show = true;
    if ( x._id == '5a6a33058258e31f0a916ef9' ) {
      show = false;
      if ( [ '3456789789@mail.ru' ].indexOf(p.User.Email) != -1 ||
        [ '5a7352415790c43d60ea513c', '5a7214de5790c43d60ea50e9', '5e69fbb4742e7d562dc73ca2', '5a720a625790c43d60ea50d6',
          '5a7213c45790c43d60ea50e1', '5a77a0831b22160bf6bee45a', '5a9be1de1ff8d9346f451682', '5d81e6b9c9c0bf7bf80df1ea',
        '613f9a0d0e35a76130f33616']
          .indexOf(p.User._id) != -1 ) {
        show = true;
      }
    }
    var days = '';
    var what = '';
    if ( x.Days.indexOf('M') != -1 ) {
      days = x.Days.replace('M', '');
      what = '(в месяцах)';
    } else {
      days = x.Days.replace('D', '');
      what = '(в днях)';
    }
    var end = p.User.TariffEnd.toString();
    var onBuyHandler = () => p.onBuyHandler(x._id, i);
    return (
      <div className={ "tariffs_items" }>
        <ul
          className={ "tariffs_list_item" }>

          <li><span className={ "fw_h2" }>{ this.t(x.Name) }</span></li>

          <li>Цена: <span className={ "fz16 fw_menu" }>{ x.Price } "руб".</span></li>

          <li>Максимальное кол-во ботов: <span className={ "fw__panel" }> { x.Cnt }</span></li>

          <li>Длительность <span className={ "fw__panel" }>{ what }: { days }</span></li>

          { x.CRM ? <li>Включено ЦРМ</li> : null }

          { x.Module ? <li>Включены Модули</li> : null }

          { x.DayLimit != -1 ? <li>Дневной максимум исходящих сообщений для ваших ботов:{ x.DayLimit }</li> : null }

          { x.Start ? <li>Этот тариф является стартовым(он активируется сразу после регистрации)</li> : null }

          { x.Copy ? <li>Копирование ботов и групп</li> : null }

          { x._id === p.User.Tariff || (p.User.Tariff && x._id === p.User.Tariff._id) ?
            <li>Вы подключены к этому тарифу
              <br/>
              <span className={ "fw_menu" }>
                истекает: { end.substr(0, end.indexOf("T")) })
              </span>
            </li>
            : null
          }

          { x.Deleted || x.Start || !show ? null :
            <button
              className={ "btn__all--small bg_shadow mt_15" }
              onClick={ onBuyHandler }>
              { x._id == p.User.Tariff || (p.User.Tariff && x._id === p.User.Tariff._id) ? 'Продлить' : 'Подключить' }
            </button>
          }

        </ul>

      </div>);
  }
}

export default Tariff;
