import React from "react";
import {Link, withRouter} from "react-router-dom";
import jQuery from "jquery";
import Cookies from "universal-cookie";
import { config } from "../../../config";
import TransComponent from "../../../TransComponent";
const cookies = new Cookies();

class Verify extends TransComponent {
  constructor(props) {
    super(props);
    this.state = {
      Wait: 0,
      Response_txt: "",
      Error: false,
    };
  }

  showError = (error) => {
    this.setState({
      Error: true,
      Response_txt: error,
    });
  }

  componentDidMount() {
    const params = (new URL(document.location)).searchParams;
    const id = params.get('Id');
    const code = params.get('Code');

    if (!id) {
      this.showError('Пользователь не найден!');
      return;
    }

    if (!code) {
      this.showError('Code не найден!');
      return;
    }

    const url = config.Backend +
      "/users/verify?" +
      "&Id=" + id +
      "&Code=" + code;

    this.setState({Wait: ++this.state.Wait});
    jQuery.getJSON(
      url,
      (res) => {
        this.setState({Wait: --this.state.Wait});

        if (res.Error) {
          this.showError(res.Error);
        } else {
          cookies.set("Id", res.User._id, {
            path: "/",
          });

          const st = setTimeout(() => {
            this.props.history.push("/dashboard?create=1");
          }, 3000);

          return () => clearTimeout(st);
        }
      }
    );
  }

  ErrorResponse () {
    return (
      <div className='text-danger'>
        {this.state.Response_txt}
      </div>
    );
  }

  SuccessResponse() {
    return (
      <div>
        <span className="payment-tag">/email</span>
        <div className="payment-title">подтвержден!</div>
        <div className="verifyDescription">
            <span className="payment-description">
              <span className="payment-description--bold">Bot Kits</span> —{" "}
              <span className="payment-description">
                помогаем упростить процесс общения с клиентами.
              </span>
            </span>
        </div>
        <div>
          <Link to="/dashboard?create=1" className="ant-btn ant-btn-lg">
            Далее
          </Link>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="background_comein verifyWrap">
        {this.state.Wait != 0 ? this.Load() : null}

        {this.state.Error ? this.ErrorResponse() : this.SuccessResponse()}
      </div>
    );
  }
}

export default withRouter(Verify);
