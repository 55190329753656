import React, { Fragment } from 'react';
import jQuery from 'jquery';
import Cookies from 'universal-cookie';
import LeftPanel from '../../LeftPanel';
import TopPanel from '../../TopPanel/TopPanel';
import {Scrollbars} from 'react-custom-scrollbars';
import Switch from 'react-toggle-switch';
import PopupAll from '../../PopupAll';
import ReactTooltip from 'react-tooltip'
import TextareaAutosize from 'react-autosize-textarea';
import {Link, withRouter} from "react-router-dom";
import {
  connect
} from 'react-redux';
import TransComponent from '../../../TransComponent.js';
import {setFbGroups, setUser} from '../../../modules/User/actions';
import { config } from '../../../config.js';
import { withAlert } from 'react-alert';
import Popup from "../../../components/Popup/Popup";
import { TooltipTitle } from "../../../components/TooltipTitle/TooltipTitle";


const cookies = new Cookies();
var func = require('../../../func.js');


class Configure extends TransComponent {

  constructor(props) {
    super(props);
    this.state = {
      Bot: null,
      switched: false,
      Tab: 'Main',
      Chanell: '',
      NewRole: '',
      NewGlobal: {
        Key: '',
        Value: '',
      },
      Wait: 0,
      showPopupDeleteDataUser: false,
      NotificationRoles: []
    };

    this.onChangeHandler = config.change.bind(this);
  }

  componentDidUpdate (prevProps) {
    if (this.props.user && !prevProps.user &&
      this.props.user.Fb_id && this.props.user.accessToken && !this.props.fbGroups) {
      var url = config.FbApi + '/' + this.props.user.Fb_id
        + '/accounts?access_token=' + this.props.user.accessToken;

      const loadPageOfFacebookPages = (url) => {
        jQuery.getJSON(url, function (pages) {
          var arr = [];
          for (var i = 0; i < pages.data.length; i++) {
            if (pages.data[i].tasks.indexOf('MANAGE') !== -1) {
              arr.push(pages.data[i]);
            }
          }
          this.props.addFbGroupsToStore((this.props.fbGroups || []).concat(arr));

          if(pages.paging && pages.paging.next) {
            loadPageOfFacebookPages(pages.paging.next);
          }
        }.bind(this));
      };

      loadPageOfFacebookPages(url);
    }
  }

  componentDidMount () {
    this.t_load();

    var url = config.Backend + '/bot/findById?';
    if (!(cookies.get('Id') === undefined)) {
      url += 'CurrentUserId=' + cookies.get('Id').toString();
    }
    url += '&Id=' + cookies.get('BotId').toString();
    this.setState({
      Wait: ++this.state.Wait
    });

    jQuery.getJSON(url, function (res) {
      this.setState({
        Wait: --this.state.Wait
      });

      if (!res.Error) {
        if (!config.checkRights(cookies.get('Id').toString(), res, 'RTC')) {
          config.accessDenied(cookies.get('Id').toString(), res);
        }

        this.setState({
          Bot: res
        }, function () {
          if (res.BindTo == 'Telegram') {
            this.setState({
              Wait: ++this.state.Wait
            });

            func.getData(config.TelegramApi + 'bot' + res.TelegramToken + '/getMe')
              .then(ok => {
                this.setState({
                  Wait: --this.state.Wait,
                  Chanell: ok.result.first_name
                }, this.loadTelegramNotificationRoles);
              }, err => {
                this.setState({
                  Wait: --this.state.Wait
                }, this.loadTelegramNotificationRoles);
              });
          }
        }.bind(this));
      }
    }.bind(this));
  }

  loadTelegramNotificationRoles = () => {
    this.setState({
      Wait: ++this.state.Wait
    });
    func.getData('notificationRole/get')
        .then(roles => {
          this.setState({
            Wait: --this.state.Wait,
            NotificationRoles: roles
          });
        }, err => {
          this.setState({
            Wait: --this.state.Wait
          });
        });
  }

  onChangeRoleHandler = (value, index) => {
    this.state.NotificationRoles[index].Name = value;
    this.setState({
      NotificationRoles: this.state.NotificationRoles
    });
  }

  editRole = (index) => {
    this.state.NotificationRoles[index].EditMode = true;
    this.setState({
      NotificationRoles: this.state.NotificationRoles
    });
  }

  saveRole = (index) => {
    const { NotificationRoles } = this.state;
    const role = NotificationRoles[index];

    if (role.Name) {
      role.EditMode = false;
      this.setState({
        NotificationRoles
      });

      func.getData('notificationRole/edit', {
        Name: role.Name,
        RoleId: role._id,
      }).then(ok => {

      }, e => {
        this.props.alert.error(JSON.stringify(e));
      });
    }
  }

  onEnableRTCHandler = () => {
    this.state.Bot.RTC = !this.state.Bot.RTC;
    this.setState({
      Bot: this.state.Bot
    });
  };

  onEnableRTCNotifyHandler = () => {
    var s = this.state;
    const alert = this.props.alert;

    if (!s.Bot.RTCnotify && !this.props.user.Email) {
      alert.info('К вашему аккаунту не привязан Email.' + 'Вы можете сделать привязку в личном кабинете');
    }
    else {

      func.getData('bot/edit_new', {
        BotId: s.Bot._id,
        RTCnotify: !s.Bot.RTCnotify
      }).then(b => {

        s.Bot.RTCnotify = !s.Bot.RTCnotify;
        this.setState({
          Bot: s.Bot
        });

      }, e => {
        alert.error(JSON.stringify(e));
      });
    }
  };

  onEnableTelegramProtectContentHandler = () => {
    var s = this.state;
    const alert = this.props.alert;

    func.getData('bot/edit_new', {
      BotId: s.Bot._id,
      TelegramProtectContent: !s.Bot.TelegramProtectContent
    }).then(b => {

      s.Bot.TelegramProtectContent = !s.Bot.TelegramProtectContent;
      this.setState({
        Bot: s.Bot
      });

    }, e => {
      alert.error(JSON.stringify(e));
    });
  };

  onSaveRTCChangesHandler = (e) => {
    e.preventDefault();

    const alert = this.props.alert;

    func.getData('bot/edit_replies', {
      Bot: config.minimizeBot(this.state.Bot)
    }).then(ok => {
      this.setState({
        Bot: ok
      });

      alert.success('Изменения сохранены!');
    }, e => {

      alert.error(JSON.stringify(e));
    });
  };

  RTC () {
    return (
      <form className={"form-horizontal mt_15"}>
        <div className="b-layout-form2__group">
          <div className="b-layout-form2__cell">

            <TooltipTitle
              idTooltip="i_RTC"
              name="Панель управления:"
              tooltipDescription="Когда включено: если пользователь пишет что-то чего бот не в состоянии понять
              бот переключает его в диалог с администратором(помечен точкой) который находится в Диалогах.
              После этого бот перестает реагировать на сообщения пользователя и они попадают напрямую в диалог,
              до тех пор пока администратор бота не включит бота для пользователя."
            />

          </div>

          <div className="b-layout-form2__cell">
            <div className="row__grid grid__align--center">
              <Switch onClick={this.onEnableRTCHandler} on={this.state.Bot.RTC}/>
            </div>
          </div>
        </div>


        <div className="b-layout-form2__group">
          <div className="b-layout-form2__cell">
            <label className={" fw__panel"}>
              <span>Уведомления по Email:
                <span data-tip data-for="i_Notify" className={"ml5"}>
                  <i className="fz15 far fa-question-circle"></i>
                </span>
              </span>
            </label>
          </div>

          <div className="b-layout-form2__cell">
            <div className="row__grid grid__align--center">
              <Switch onClick={this.onEnableRTCNotifyHandler} on={this.state.Bot.RTCnotify}/>
            </div>
          </div>
        </div>

        <ReactTooltip id="i_Notify" type="light">
          <span>
            Включите данную опцию и каждый раз когда пользователь будет направлен в "Ручное управление"
            <br/>
            вы будете получать уведомление на Email который привязан к вашему аккаунту.
          </span>
        </ReactTooltip>

        <hr/>

        <label>{this.t("Сообщение")}
          <span data-tip data-for="i_Text" className={"ml5"}>
            <i className="fz15 far fa-question-circle"></i>
          </span>
        </label>

        <ReactTooltip id="i_Text" type="light">
          <span>Сообщение которое отправит бот в случае если не поймет пользователя.</span>
        </ReactTooltip>

        <TextareaAutosize
          placeholder={"Текст сообщения..."}
          rows={3}
          onChange={this.onChangeHandler}
          value={this.state.Bot.RTCtext}
          className={"chat_input w-100"}
          name="Bot.RTCtext">
        </TextareaAutosize>

        <button className={"btn__all--small bg_shadow mr5 mt_10"}
          onClick={this.onSaveRTCChangesHandler}>
          Сохранить
        </button>
      </form>
    );
  }

  onFacebookReconnectHandler = () => {
    var s = this.state;
    var page;

    for (var i = 0; i < this.props.fbGroups.length; i++) {
      if (this.props.fbGroups[i].id == s.Bot.Page) {
        page = this.props.fbGroups[i];
      }
    }

    if (page) {
      var url = config.Backend + "/fb/subscribe?"
        + "PageId=" + s.Bot.Page
        + "&BotId=" + s.Bot._id
        + "&accessToken=" + page.access_token;

      this.setState({
        Wait: ++this.state.Wait
      });

      jQuery.getJSON(url, function (res) {
        this.setState({
          Wait: --this.state.Wait
        });

        if ( !res.Error ) {
          alert(this.t("Успех"));
        } else {
          alert(JSON.stringify(res));
        }
      }.bind(this));
    } else {
      alert('Facebook page not found');
    }


  };

  Main () {
    var s = this.state;
    var b = s.Bot;
    var u = this.props.user;
    switch (b.BindTo) {
      case 'FB':
        return (<div>
          <p>Id страницы:{b.Page}</p>

          <p>
            <button onClick={this.onFacebookReconnectHandler}>Переподключить бота</button>
          </p>
        </div>);
        break;
      case 'Vk':
        var page = 'https://vk.com/club' + b.VkPage;
        return (<div className={"form-horizontal mt_15"}>
          <div className="form-group mt_15">
            <span className={"fw__panel"}>Сообщество: </span>

            <div className="row__grid grid__align--center">
              <a target={'_blank'} href={page}>{page}</a>
            </div>
          </div>

          <div className="form-group">
            <span className={"fw__panel"}>Ключ доступа сообщества: </span>

            <div className="row__grid grid__align--center">
              <span>{b.VkPageToken}</span>
            </div>
          </div>
          <div className="form-group">
            <span className={"fw__panel"}>
              Ключ доступа владельца сообщества:
            </span>

            <div className="row__grid grid__align--center">
              <span className={"fw__panel"}></span>

              <span>{u.VkToken}</span>
            </div>

          </div>
        </div>);
        break;
      case 'Ok':
        return (<div>
          <p>Ключ доступа сообщества:{b.OkToken}</p>
        </div>);
        break;
      case 'Telegram':
        return (<div>
          <p>Ключ доступа бота:{b.TelegramToken}</p>

          <p>Название канала:{s.Chanell}</p>

          <p>Подписаться на уведомления: /notify {b._id}</p>

          <p>Отписаться от уведомлений: /disable_notify {b._id}</p>

          <div>
            <form onSubmit={this.addNewRole}>
              <input
                  type="text"
                  style={{
                    width: '30%',
                    margin: '10px',
                  }}
                  onChange={this.onChangeHandler}
                  value={this.state.NewRole}
                  className={"chat_input"}
                  name="NewRole"
                  placeholder="Название роли"
              />

              <button className="btn_all mr_2" onClick={this.addNewRole}>
                Добавить роль
              </button>
            </form>

            <table className={"grid_w-100"}>
              <tbody>
              <tr className={"table_f"}>
                <th className={"table_left"}>{this.t("Роль")}</th>
                <th className={"table_left"}>{this.t("Команда для подписки")}</th>
              </tr>
                {s.NotificationRoles.map((role, index) => (
                    <tr key={role._id}>
                      <td>{role.EditMode ?
                          <input
                              type="text"
                              style={{
                                width: '90%',
                                margin: '10px',
                              }}
                              onChange={e => this.onChangeRoleHandler(e.target.value, index)}
                              value={role.Name}
                              className={"chat_input"}
                              name="NewRole"
                              placeholder="Название роли"
                          />
                          :
                          role.Name}</td>
                      <td>
                        /notify {b._id} {role._id}
                      </td>
                      <td>
                        {role.EditMode ?
                            (
                                <button
                                    onClick={() => this.saveRole(index)}
                                    className="btn_all mr_2">
                                  Сохранить
                                </button>
                            )
                            :
                            (
                                <button
                                    onClick={() => this.editRole(index)}
                                    className="btn_all mr_2">
                                  Редактировать
                                </button>
                            )
                        }

                        <button
                            onClick={() => this.deleteRole(role._id)}
                            className="btn_all mr_2">
                          Удалить
                        </button>
                      </td>
                    </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>);
        break;
      case 'Alisa':
        return (<div>
          <p>Идентификатор навыка :{b.AlisaSkillId}</p>
        </div>);
        break;
      case 'WhatsApp':
        return (<div>
          <p>Ключ доступа бота:{b.WhatsAppApiKey}</p>

          <p>Название приложения бота:{b.WhatsAppApiAppName}</p>

          <p>Номер бота:{b.WhatsAppNumber}</p>

          <p>Чат для тестирования бота:{b.WhatsAppTestUrl}</p>
        </div>);
        break;
    }
  }

  onDeleteUsersHandler = () => {
    var s = this.state;
    const alert = this.props.alert;

    func.getData('bot/delUsers', {
      BotId: s.Bot._id

    }).then(ok => {
      alert.success('Готово');

      this.setState({
        showPopupDeleteDataUser: false
      })

    }, e => {

      alert.error(JSON.stringify(e));
    });
  };


  openPopupDeleteUserData = () => {
    this.setState({
      showPopupDeleteDataUser: true
    })
  }

  Settings () {
    var s = this.state;
    var b = s.Bot;

    return (
      <div className={"form-horizontal mt_15"}>
          {b.BindTo == 'Telegram' && <Fragment>
            <div className="b-layout-form2__group">
              <div className="b-layout-form2__cell">
                <label className={" fw__panel"}>
                  <span>Защитить контент от копирования:
                    <span data-tip data-for="i_Notify" className={"ml5"}>
                      <i className="fz15 far fa-question-circle"></i>
                    </span>
                  </span>
                </label>
              </div>

              <div className="b-layout-form2__cell">
                <div className="row__grid grid__align--center">
                  <Switch onClick={this.onEnableTelegramProtectContentHandler} on={this.state.Bot.TelegramProtectContent}/>
                </div>
              </div>
            </div>

            <ReactTooltip id="i_Notify" type="light">
              <span>
                Включите данную опцию и чтобы сообщения отправленные ботом нельзя было копировать и пересылать
              </span>
            </ReactTooltip>
          </Fragment>}

          <div className="form-group mt_15">

          <button className="btn_all mr_2" onClick={this.openPopupDeleteUserData}>
            Удалить данные о пользователях написавших боту
          </button>
        </div>
      </div>);
  }

  addNewRole = (e) => {
    e.preventDefault();
    let { NewRole } = this.state;

    if (NewRole) {
      this.setState({
        NewRole: '',
      });

      func.getData('notificationRole/add', {
        Name: NewRole
      }).then(b => {
        this.loadTelegramNotificationRoles();
      }, e => {
        this.props.alert.error(JSON.stringify(e));
      });
    }
  }

  addNewGlobal = (e) => {
    e.preventDefault();
    let { NewGlobal: { Key, Value }, Bot } = this.state;

    if (Key && Value) {
      try {
        Value = eval(`const x=${Value};x;`);
      } catch (e) {
        // leave value as string
      }

      Bot.Global[Key] = Value;
      this.setState({
        NewGlobal: {
          Key: '',
          Value: ''
        },
        Bot
      });

      func.getData('bot/edit_new', {
        BotId: Bot._id,
        Global: Bot.Global
      }).then(b => {
        Bot.Global = b.Global;
        this.setState({
          Bot
        });
      }, e => {
        this.props.alert.error(JSON.stringify(e));
      });
    }
  }

  deleteRole = Id => {
    this.setState({
      NotificationRoles: this.state.NotificationRoles.filter(role => role._id !== Id)
    });

    func.getData('notificationRole/delete', {
      RoleId: Id,
    }).then(ok => {

    }, e => {
      this.props.alert.error(JSON.stringify(e));
    });
  }

  deleteGlobal = (key) => {
    const { Bot } = this.state;

    delete Bot.Global[key];
    this.setState({
      Bot
    });

    func.getData('bot/edit_new', {
      BotId: Bot._id,
      Global: Bot.Global
    }).then(b => {
      Bot.Global = b.Global;
      this.setState({
        Bot
      });
    }, e => {
      this.props.alert.error(JSON.stringify(e));
    });
  }

  Global () {
    const s = this.state;
    const b = s.Bot;

    const global_params = [];
    b.Global = b.Global || {};
    for (const key in b.Global) {
      global_params.push(
          <tr key={key}>
            <td>{"Global." + key}</td>
            <td>
              {JSON.stringify(b.Global[key])}
            </td>
            <td>
              <button
                  onClick={() => this.deleteGlobal(key)}
                  className="btn_all mr_2">
                Удалить
              </button>
            </td>
          </tr>
      );
    }

    return (
        <div className={"form-horizontal mt_15"}>
          <div className="form-group mt_15">
            <p>
              Вы можете использовать глобальные переменные в расчете даты
              и времени рассылок.
            </p>
            <form onSubmit={this.addNewGlobal}>
              <input
                  type="text"
                  style={{
                    width: '30%',
                    margin: '10px',
                  }}
                  onChange={this.onChangeHandler}
                  value={this.state.NewGlobal.Key}
                  className={"chat_input"}
                  name="NewGlobal.Key"
                  placeholder="Название"
              />

              <input
                  type="text"
                  style={{
                    width: '30%',
                    margin: '10px',
                  }}
                  onChange={this.onChangeHandler}
                  value={this.state.NewGlobal.Value}
                  className={"chat_input"}
                  name="NewGlobal.Value"
                  placeholder="Значение: 2022-02-05"
              />

              <button className="btn_all mr_2" onClick={this.addNewGlobal}>
                Добавить переменную
              </button>
            </form>

            <table className={"grid_w-100"}>
              <tbody>
                <tr className={"table_f"}>
                  <th className={"table_left"}>{this.t("Название")}</th>
                  <th className={"table_left"}>{this.t("Значение")}</th>
                </tr>
                {global_params}
              </tbody>
            </table>
          </div>
        </div>);
  }

  onTabMainHandler = () =>  {
    this.setState({
      Tab: "Main"
    });
  };

  onTabRTCHandler = () =>  {
    this.setState({
      Tab: "RTC"
    });
  };

  onTabSettingsHandler = () =>  {
    this.setState({
      Tab: "Settings"
    });
  };

  onTabGlobalContextHandler = () =>  {
    this.setState({
      Tab: "Global"
    });
  };

  render () {
    var s = this.state;
    return (
      <div>
        {this.state.Wait != 0 ? this.Load() : null}
        <TopPanel/>

        <PopupAll/>

        <div className="b-page">
          <div className="b-page__row">
            <LeftPanel User={this.props.user}/>

            <div className="b-page__content-wrap">
              <div className="dashboard-page"></div>

              <Scrollbars
                style={{
                  height: "100%"
                }}>
                <div className="overlay_chart">
                  <div className="col-sm-12">
                    {s.Bot && this.props.user ?
                      <div>
                        <button className="btn_all mr5" onClick={this.onTabMainHandler}>
                          Информация о подключении
                        </button>

                        {s.Bot.BindTo != "Alisa" ?
                          <button className="btn_all mr5" onClick={this.onTabRTCHandler}>
                            Ручное управление
                          </button>
                          : null}

                        <button className="btn_all mr5" onClick={this.onTabSettingsHandler}>
                          Настройки
                        </button>

                        <button className="btn_all mr5" onClick={this.onTabGlobalContextHandler}>
                          Глобальные переменные
                        </button>

                        {this[s.Tab]()}
                        </div> : null}
                  </div>
                </div>
              </Scrollbars>
            </div>
          </div>
        </div>

        { this.state.showPopupDeleteDataUser ?
          <Popup
            Yes={ this.onDeleteUsersHandler }
            No={ () => this.setState({showPopupDeleteDataUser: false}) }
            isDashboard={ true }
            text="Это действие удалит всех подписчиков и все данные в боте. Продолжить?"
          />
          : null
        }

      </div>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    user: state.user.user,
    fbGroups: state.user.fbGroups,
  };
};

const mapDispatchToProps = {
  setUser: setUser,
  addFbGroupsToStore: setFbGroups,
};

export default connect(mapStateToProps, mapDispatchToProps)(withAlert()(withRouter(Configure)));
