import React from 'react';
import jQuery from 'jquery';
import Cookies from 'universal-cookie';
import {Scrollbars} from 'react-custom-scrollbars';
import AdminLeft from '../AdminLeft';
import Promocode from "./Promocode";
import {Link} from "react-router-dom";
import { config } from '../../config.js';

const cookies = new Cookies();
var func = require('../../func.js');


class Promocodes extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      User: {},
      Promocode: [],
      Settings: {}
    };

    this.onChangeHandler = config.change.bind(this);
  }

  AdminTop() {
    return (
      <div className="admin_top">
        <div className="admin_logo_top">
          <Link to="/admin/main"></Link>
        </div>
      </div>
    );
  }

  load = () => {
    var s = this.state;
    var url = config.Backend + '/users/findById?';
    if (!(cookies.get('Id') === undefined)) {
      url += 'Id=' + cookies.get('Id').toString();
    }
    jQuery.getJSON(url, function (res) {
      if (!res.Error) {
        this.setState({
          User: res
        }, () => {
          func.getData('settings/all_public').then(settings => {
            func.getData('promocodes/all').then(ok1 => {
              var o = {};
              for (var x = 0; x < settings.length; x++) {
                o[settings[x].Key] = settings[x].Value;
              }
              this.setState({
                Settings: o,
                Promocode: ok1
              });
            }, e => {
              alert(JSON.stringify(e));
            });
          }, e => {
            alert(JSON.stringify(e));
          });
        });
      } else {
        window.location.replace(window.location.origin + '/login');
      }
    }.bind(this));
  }

  componentDidMount () {
    this.load();
  }

  add = () => {
    func.getData('promocodes/add').then(ok => {
      this.state.Promocode.push(ok);
      this.setState({
        Promocode: this.state.Promocode
      });
    }, e => {
      alert(JSON.stringify(e));
    });
  };

  save = (a, i) => {
    func.getData('promocodes/edit', {
      Promocode: a
    }).then(ok => {
      alert('Успех');
    }, e => {
      alert(JSON.stringify(e));
    });
  };

  del = (a, i) => {
    func.getData('promocodes/delete', {
      Promocode: a
    }).then(ok => {
      this.state.Promocode.splice(i, 1);
      this.setState({
        Promocode: this.state.Promocode
      });
    }, e => {
      alert(JSON.stringify(e));
    });
  };

  Table () {
    var s = this.state;
    var advs = this.state.Promocode.map((elem, ind) =>
      <Promocode elem={elem} ind={ind} onSaveHandler={this.save} onDeleteHandler={this.del}
        onChangeHandler={this.onChangeHandler}/>);

    return (
      <div>
        <table className={"table table-bordered mt_30"}>
          <thead>
          <tr className={"table_f"}>
            <th>Код</th>

            <th>Сумма(руб)</th>

            <th>
              <button className={"btn__all--small bg_shadow"} onClick={this.add}>
                Добавить
              </button>
            </th>
          </tr>
          </thead>

          <tbody className={"table_f"}>
          {advs}
          </tbody>
        </table>
      </div>
    );
  }

  render () {
    var s = this.state;
    return (
      <div>
        {/*{this.AdminTop()}*/}
        <div className="b-page">
          <div className="b-page__row">
            <AdminLeft/>

            <div className="b-page__content-wrap" style={{
              height: "calc(100vh)"
            }}>
              <Scrollbars style={{
                height: "100%"
              }}>
                <div className="dashboard-page">
                  <div className="dashboard-page__items">
                    {this.Table()}
                  </div>
                </div>
              </Scrollbars>
            </div>
          </div>
        </div>
      </div>
    );
  }

}

export default Promocodes;

