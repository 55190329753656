import React, { Component } from "react";
import jQuery from "jquery";
import Cookies from "universal-cookie";
import Audio from "./AudioPlayerClass.js";
import { Scrollbars } from "react-custom-scrollbars";
import SvgComponent from "./SvgComponent";
import LeftPanel from "../../LeftPanel";
import TopPanel from "../../TopPanel/TopPanel";
import Popup from "../../../components/Popup/Popup";
import Content from "./Component/Content/Content";
import PopupAll from "../../PopupAll";
import TransComponent from "../../../TransComponent.js";
import ReactTooltip from "react-tooltip";
import { Line } from "react-chartjs-2";
import { Popover, Spin } from "antd";
import { CSSTransitionGroup } from "react-transition-group"; // ES6
import "video-react/dist/video-react.css";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setGroups } from "../../../modules/Bots/actions";
import { config } from "../../../config.js";
import { dict } from "./Description/Description";
import { TooltipTitle } from "../../../components/TooltipTitle/TooltipTitle";
import Tooltip from "material-ui/Tooltip";

const cookies = new Cookies();
var func = require("../../../func.js");

class BotMsg extends Component {
  constructor(props) {
    super(props);
    this.state = {
      x: +this.props.x,
      y: +this.props.y,
      Name: this.props.Name,
      _id: this.props._id,
      BotMsg: this.props.BotMsg,

      //hideMsgName:false,
    };
    this.t =
      this.props.t ||
      ((x) => {
        return x;
      });
  }

  componentWillReceiveProps(props) {
    this.setState({ Name: this.props.Name });
  }

  handleDrag = (e, { deltaX, deltaY }) => {
    this.setState({
      x: this.state.x + deltaX,
      y: this.state.y + deltaY,
    });
  };

  render() {
    var className = this.props.BotMsg.Condition
      ? "canva-block conditions"
      : "canva-block";
    if (this.props.BotMsg.Start) className += " first";

    var parentProps = this.props.parentProps;

    var icon = "btn_icon";
    var title = dict.misvecvu;
    var nameBlock = dict.lqjjocai;
    if (this.props.BotMsg.Condition) {
      //условный
      icon = "btn_condition";
      title = dict.gxzrenzg;
      nameBlock = dict.tebbibbh;
    } else if (this.props.BotMsg.Type == "Api") {
      //апи
      icon = "btn_api btn_icon";
      title = dict.lvwdeauz;
      nameBlock = dict.misvecvu;
    } else if (this.props.BotMsg.Type == "Process") {
      //
      icon = "btn_process btn_icon";
      title = dict.otyriacx;
      // nameBlock = dict.sjxnbmmx
      nameBlock = dict.misvecvu;
    } else if (this.props.BotMsg.Type == "CRM") {
      icon = "btn_crm btn_icon";
      title = dict.bijfidok;
      // nameBlock = dict.jlwtbsvl
      nameBlock = dict.misvecvu;
    } else if (this.props.BotMsg.Type == "CRMoutput") {
      icon = "btn_crm btn_icon";
      title = dict.dznzigyv;
      // nameBlock = dict.eedgbgzb
      nameBlock = dict.misvecvu;
    } else if (this.props.BotMsg.Type == "DeepLink") {
      icon = "btn_deep_link btn_icon";
      title = dict.cfkydfnn;
      // nameBlock = dict.itxxbjie
      nameBlock = dict.misvecvu;
    } else if (this.props.BotMsg.Type == "RTC") {
      icon = "btn_rtc btn_icon";
      title = dict.ahmgdjce;
      nameBlock = dict.misvecvu;
    } else if (this.props.BotMsg.Type == "Location") {
      icon = "btn_coordinate btn_icon";
      title = dict.vueqblta;
      // nameBlock = dict.hlucdloa
      nameBlock = dict.misvecvu;
    } else if (this.props.BotMsg.Type == "Telegram Payment") {
      icon = "btn_payment btn_icon";
      title = dict.pay_block_telegram;
      nameBlock = dict.misvecvu;
    } else {
      //обычный
      icon = "btn_message btn_icon";
      title = dict.yachlkvs;
      // nameBlock = dict.wwkwdwyy
      nameBlock = dict.misvecvu;
    }

    return (
      <foreignObject className={className} width={220} height={120}>
        <div xmlns="http://www.w3.org/1999/xhtml">
          <div>
            <h2 className="title">
              <a>{this.props.Name}</a>
            </h2>
            <div className="canva_btn grid__justify--content">
              <div>
                <Popover
                  content={title}
                  title={nameBlock}
                  overlayStyle={{ maxWidth: "600px" }}
                >
                  {<span className={icon}></span>}
                </Popover>

                <button
                  onClick={this.props.loading ? null : this.props.onEdit}
                  className="Canva_btn_edit"
                >
                  {this.props.loading ? (
                    <Spin size={"small"}></Spin>
                  ) : (
                    dict.xfrvlwzu
                  )}
                </button>


                  <Tooltip
                    enterDelay={300}
                    id="tooltip-controlled"
                    leaveDelay={300}
                    onClose={this.handleTooltipClose}
                    onOpen={this.handleTooltipOpen}
                    open={this.state.open}
                    placement="bottom"
                    title={dict.lbulgcef}
                  >
                    <button
                      onClick={this.props.onCopy}
                      className="btn__all--small"
                    >
                      <i className="far fa-clone" />
                    </button>
                  </Tooltip>



                  <Tooltip
                    enterDelay={300}
                    id="tooltip-controlled"
                    leaveDelay={300}
                    onClose={this.handleTooltipClose}
                    onOpen={this.handleTooltipOpen}
                    open={this.state.open}
                    placement="bottom"
                    title={dict.utmsdrrf}
                  >
                    <button
                      onClick={this.props.onDelete}
                      className="btn__all--small"
                    >
                      <i className="fas fa-trash-alt" />
                    </button>
                  </Tooltip>

              </div>
            </div>
          </div>
        </div>
      </foreignObject>
    );
  }
}

class chatBuild extends TransComponent {
  constructor(props) {
    super(props);
    this.state = {
      typingTime: 3,
      Bot: {},
      Group: "",
      BotMsgs: [],
      AllMsgs: [],
      BotMsg: "-1",
      BotMsgName: "",
      AllContent: null,
      TextContents: [],
      AudioContents: [],
      VideoContents: [],
      ImageContents: [],
      FileContents: [],
      BtnContents: [],
      CondContents: [],
      ShowDeletebotMsgPopup: false,
      hideDefaultLink: false,
      hideMsgName: false,
      condition: false,
      Wait: 0,
      SendedMsgs: [],
      BotUsers: [],
      Period: "Week",
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInputFileChange = this.handleInputFileChange.bind(this);
    this.addBotMsg = this.addBotMsg.bind(this);
    this.wantDeleteGroup = this.wantDeleteGroup.bind(this);
    this.wantRenameGroup = this.wantRenameGroup.bind(this);
    this.chooseBlock = this.chooseBlock.bind(this);
    this.deleteBotMsg = this.deleteBotMsg.bind(this);
    this.saveBotMsg = this.saveBotMsg.bind(this);
    this.wantDeleteBotMsg = this.wantDeleteBotMsg.bind(this);
    this.hideAllPopups = this.hideAllPopups.bind(this);
    this.addAudioContent = this.addAudioContent.bind(this);
    this.addVideoContent = this.addVideoContent.bind(this);
    this.addImageContent = this.addImageContent.bind(this);
    this.addFileContent = this.addFileContent.bind(this);
    this.addBtnContent = this.addBtnContent.bind(this);
    this.saveContents = this.saveContents.bind(this);
    this.handleForContent = this.handleForContent.bind(this);
    this.deleteContent = this.deleteContent.bind(this);
    this.refreshBotMsgs = this.refreshBotMsgs.bind(this);
    this.chooseGroup = this.chooseGroup.bind(this);
    this.changeWaitAnswer = this.changeWaitAnswer.bind(this);
    this.changeStart = this.changeStart.bind(this);
    this.changeAnswerName = this.changeAnswerName.bind(this);
    this.dragLink = this.dragLink.bind(this);
    this.change = config.change.bind(this);
    this.change2 = config.change_new.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.getBotUsers = func.getBotUsers.bind(this);
    this.load_data = func.load_data.bind(this);
  }

  dragLink(from, to) {
    for (var i = 0; i < this.state.BotMsgs.length; i++) {
      if (from == this.state.BotMsgs[i]._id) {
        this.state.BotMsgs[i].DefaultLink = to;
        this.setState({ BotMsgs: this.state.BotMsgs });
        var url = config.Backend + "/botMsg/edit?";
        if (!(cookies.get("Id") === undefined)) {
          url += "&CurrentUserId=" + cookies.get("Id").toString();
        }
        url += "&Id=" + this.state.BotMsgs[i]._id;
        url += "&Name=" + this.state.BotMsgs[i].Name;
        url += "&DefaultLink=" + this.state.BotMsgs[i].DefaultLink;
        jQuery.getJSON(url);
      }
    }
  }

  changeAnswerName(e) {
    for (var i = 0; i < this.state.BotMsgs.length; i++) {
      if (this.state.BotMsg == this.state.BotMsgs[i]._id) {
        this.state.BotMsgs[i].AnswerName = e.target.value;
        this.setState({ BotMsgs: this.state.BotMsgs });
      }
    }
  }

  changeStart(e) {
    for (var i = 0; i < this.state.BotMsgs.length; i++) {
      if (this.state.BotMsg == this.state.BotMsgs[i]._id) {
        this.state.BotMsgs[i].Start = e.target.checked;
        this.setState({ BotMsgs: this.state.BotMsgs });
      }
    }
  }

  changeWaitAnswer(e) {
    for (var i = 0; i < this.state.BotMsgs.length; i++) {
      if (this.state.BotMsg == this.state.BotMsgs[i]._id) {
        this.state.BotMsgs[i].WaitAnswer = e.target.checked;
        this.setState({ BotMsgs: this.state.BotMsgs });
      }
    }
  }

  changeTelegramMarkdown = (e) => {
    for (var i = 0; i < this.state.BotMsgs.length; i++) {
      if (this.state.BotMsg == this.state.BotMsgs[i]._id) {
        this.state.BotMsgs[i].TelegramMarkdown = e.target.checked;
        this.setState({ BotMsgs: this.state.BotMsgs });
      }
    }
  };

  changeImageAndTextInOneMessage = (e) => {
    for (var i = 0; i < this.state.BotMsgs.length; i++) {
      if (this.state.BotMsg == this.state.BotMsgs[i]._id) {
        this.state.BotMsgs[i].ImageAndTextInOneMessage = e.target.checked;
        this.setState({ BotMsgs: this.state.BotMsgs });
      }
    }
  };

  refreshBotMsgs(id, newPos) {
    for (var i = 0; i < this.state.BotMsgs.length; i++) {
      if (id == this.state.BotMsgs[i]._id) {
        this.state.BotMsgs[i].Pos = newPos;
      }
    }
  }

  clearInput(e) {
    e.target.value = null;
  }

  deleteContent(i, type) {
    if (type === "Image") {
      if (this.state.ImageContents[i]._id !== "-1") {
        var url = config.Backend + "/content/delete?";
        if (!(cookies.get("Id") === undefined)) {
          url += "&CurrentUserId=" + cookies.get("Id").toString();
        }

        url += "&Id=" + this.state.ImageContents[i]._id;
        jQuery.getJSON(url);
      }

      this.setState({
        AllContent: this.state.AllContent.filter(
          (content) => content._id !== this.state.ImageContents[i]._id
        ),
      });
      this.state.ImageContents.splice(i, 1);
      this.setState({ ImageContents: this.state.ImageContents });
    } else if (type === "Video") {
      if (this.state.VideoContents[i]._id !== "-1") {
        var url = config.Backend + "/content/delete?";
        if (!(cookies.get("Id") === undefined)) {
          url += "&CurrentUserId=" + cookies.get("Id").toString();
        }

        url += "&Id=" + this.state.VideoContents[i]._id;

        jQuery.getJSON(url);
      }
      this.setState({
        AllContent: this.state.AllContent.filter(
          (content) => content._id !== this.state.VideoContents[i]._id
        ),
      });
      this.state.VideoContents.splice(i, 1);
      this.setState({ VideoContents: this.state.VideoContents });
    } else if (type === "File") {
      if (this.state.FileContents[i]._id !== "-1") {
        var url = config.Backend + "/content/delete?";
        if (!(cookies.get("Id") === undefined)) {
          url += "&CurrentUserId=" + cookies.get("Id").toString();
        }

        url += "&Id=" + this.state.FileContents[i]._id;
        jQuery.getJSON(url);
      }
      this.setState({
        AllContent: this.state.AllContent.filter(
          (content) => content._id !== this.state.FileContents[i]._id
        ),
      });
      this.state.FileContents.splice(i, 1);
      this.setState({ FileContents: this.state.FileContents });
    } else if (type === "Audio") {
      if (this.state.AudioContents[i]._id !== "-1") {
        var url = config.Backend + "/content/delete?";
        if (!(cookies.get("Id") === undefined)) {
          url += "&CurrentUserId=" + cookies.get("Id").toString();
        }

        url += "&Id=" + this.state.AudioContents[i]._id;

        jQuery.getJSON(url);
      }
      this.setState({
        AllContent: this.state.AllContent.filter(
          (content) => content._id !== this.state.AudioContents[i]._id
        ),
      });
      this.state.AudioContents.splice(i, 1);
      this.setState({ AudioContents: this.state.AudioContents });
    } else if (type === "Btn") {
      if (this.state.BtnContents[i]._id !== "-1") {
        var url = config.Backend + "/botMsg/edit?";
        if (!(cookies.get("Id") === undefined)) {
          url += "&CurrentUserId=" + cookies.get("Id").toString();
        }
        url += "&Id=" + this.state.BotMsg;
        url += "&Btn=" + this.state.BtnContents[i].Name;
        url += "&Redirect=" + this.state.BtnContents[i].Redirect;
        url += "&Position=" + this.state.BtnContents[i].Position;
        url += "&Index=" + i;
        url += "&Delete=true";

        jQuery.getJSON(
          url,
          function (res) {
            this.setupBotMsg(res.BotMsg);
          }.bind(this)
        );
      }
      this.state.BtnContents.splice(i, 1);
      this.setState({ BtnContents: this.state.BtnContents });
    } else if (type === "Cond") {
      if (this.state.CondContents[i]._id !== "-1") {
        var url = config.Backend + "/botMsg/edit?";
        if (!(cookies.get("Id") === undefined)) {
          url += "&CurrentUserId=" + cookies.get("Id").toString();
        }
        url += "&Id=" + this.state.BotMsg;
        url += "&Condition=" + this.state.CondContents[i].Condition;
        url += "&Redirect=" + this.state.CondContents[i].Redirect;
        url += "&Index=" + i;
        url += "&Delete=true";

        jQuery.getJSON(
          url,
          function (res) {
            this.setupBotMsg(res.BotMsg);
          }.bind(this)
        );
      }
      this.state.CondContents.splice(i, 1);
      this.setState({ CondContents: this.state.CondContents });
    }
  }

  addBtnContent() {
    if (this.state.BtnContents.length === 10 && this.state.Bot.BindTo === "WhatsApp") {
      alert("Ошибка(WhatsApp): Максимальное число кнопок - 10");
    } else if (this.state.BtnContents.length === 3 && this.state.Bot.BindTo === "FB") {
      alert("Ошибка(Facebook): Максимальное число кнопок - 3");
    } else {
      var btn = {
        Name: "Button",
        Redirect: "-",
        _id: "-1",
        key: this.state.BtnContents.length.toString(),
        Type: "postBack",
        Url: "http://",
        Position: "Vertical",
      };
      this.state.BtnContents.push(btn);
      this.setState({
        BtnContents: this.state.BtnContents,
      });
    }
  }

  addCondContent = () => {
    const { CondContents } = this.state;

    const btn = {
      Condition: "",
      Redirect: "-",
      _id: "-1",
    };

    CondContents.push(btn);

    this.setState({
      CondContents,
    });
  };

  addKeyboard = () => {
    var s = this.state;
    var ind = 0;
    for (var i = 0; i < s.BotMsgs.length; i++) {
      if (s.BotMsgs[i]._id == s.BotMsg) {
        ind = i;
      }
    }
    s.BotMsgs[ind].Keyboard = [[{ text: "" }]];
    this.setState({ BotMsgs: s.BotMsgs });
  };

  addVideoContent(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      var Content = {
        _id: "-1",
        Type: "Video",
        Link: "-1",
        File: file,
        Url: reader.result,
      };
      this.state.VideoContents.push(Content);
      this.setState({ VideoContents: this.state.VideoContents });
    };
    reader.readAsDataURL(file);
  }

  addFileContent(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      const extension = file.name.split(".").pop().toLowerCase();

      if (extension === "html") {
        alert("Недопустимый формат файла");
        return 0;
      }

      var fd = new FormData();
      fd.append("File", file);
      this.setState({ Wait: ++this.state.Wait });

      jQuery.ajax({
        method: "POST",
        async: false,
        processData: false,
        contentType: false,
        url: config.Backend + "/content/file",
        success: function (msg) {
          this.setState({ Wait: --this.state.Wait });

          var url = config.Backend + "/content/add?";
          if (!(cookies.get("Id") === undefined)) {
            url += "&CurrentUserId=" + cookies.get("Id").toString();
          }
          url += "&BotMsg=" + this.state.BotMsg;
          url += "&Type=File";
          url += "&Text=";
          url += "&Time=";
          url += "&FileName=" + file.name;
          url += "&Link=" + msg;
          jQuery.getJSON(
            url,
            function (res) {
              if (!res.Error) {
                this.state.FileContents.push(res.Content);
                this.setState({ FileContents: this.state.FileContents });
              } else {
                alert(JSON.stringify(res));
              }
            }.bind(this)
          );
        }.bind(this),
        data: fd,
      });
    };
    reader.readAsDataURL(file);
  }

  addImageContent(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      var Content = {
        _id: "-1",
        Type: "Image",
        Link: "-1",
        File: file,
        Url: reader.result,
      };
      this.state.ImageContents.push(Content);
      this.setState({ ImageContents: this.state.ImageContents });
    };
    reader.readAsDataURL(file);
  }

  loadProductImage = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      var ProductImage = {
        _id: "-1",
        Type: "Product Image",
        Link: "-1",
        File: file,
        Url: reader.result,
      };
      this.setState({ ProductImage });
    };
    reader.readAsDataURL(file);
  };

  addAudioContent(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      var Content = {
        _id: "-1",
        Type: "Audio",
        Link: "-1",
        File: file,
        Url: reader.result,
      };
      this.state.AudioContents.push(Content);
      this.setState({ AudioContents: this.state.AudioContents });
    };
    reader.readAsDataURL(file);
  }

  handleForContent(
    index,
    Text,
    Time,
    Link,
    File,
    Type,
    Url,
    ReqType,
    Field,
    Refresh,
    Macros,
    BtnType,
    BtnUrl,
    Headers,
    Save,
    Cond,
    CondRedirect,
    SimpleMode,
    SimpleAttr,
    SimpleCond,
    SimpleValue,
    BtnPosition
  ) {
    if (Type == "Text") {
      this.state.TextContents[index].Text = Text;
      this.state.TextContents[index].Time = Time;
    } else if (Type == "Image") {
      this.state.ImageContents[index].Link = Link;
      this.state.ImageContents[index].File = File;
    } else if (Type == "File") {
      this.state.FileContents[index].Link = Link;
      this.state.FileContents[index].File = File;
    } else if (Type == "Audio") {
      this.state.AudioContents[index].Link = Link;
      this.state.AudioContents[index].File = File;
    } else if (Type == "Video") {
      this.state.VideoContents[index].Link = Link;
      this.state.VideoContents[index].File = File;
    } else if (Type == "Btn") {
      this.state.BtnContents[index].Name = Text;
      if (this.state.BtnContents[index].Redirect != Url) {
        this.state.BtnContents[index].Redirect = Url;
        for (var i = 0; i < this.state.BotMsgs.length; i++) {
          if (this.state.BotMsgs[i]._id == this.state.BotMsg) {
            this.state.BotMsgs[i].Btns = this.state.BtnContents;
          }
        }
      }
      this.state.BtnContents[index].Type = BtnType;
      this.state.BtnContents[index].Url = BtnUrl;
      this.state.BtnContents[index].Position = BtnPosition;
    } else if (Type == "Cond") {
      this.state.CondContents[index].Condition = Cond;
      this.state.CondContents[index].SimpleMode = SimpleMode;
      this.state.CondContents[index].SimpleAttr = SimpleAttr;
      this.state.CondContents[index].SimpleCond = SimpleCond;
      this.state.CondContents[index].SimpleValue = SimpleValue;
      if (this.state.CondContents[index].Redirect != CondRedirect) {
        this.state.CondContents[index].Redirect = CondRedirect;

        for (var i = 0; i < this.state.BotMsgs.length; i++) {
          if (this.state.BotMsgs[i]._id == this.state.BotMsg) {
            this.state.BotMsgs[i].Links = this.state.CondContents;
          }
        }
      }
    } else if (
      ["DefaultLink", "LinkAfterPaymentSuccess"].indexOf(Type) !== -1
    ) {
      for (var i = 0; i < this.state.BotMsgs.length; i++) {
        if (this.state.BotMsgs[i]._id == this.state.BotMsg) {
          this.state.BotMsgs[i][Type] = Url;
        }
      }
    }
    if (Refresh == 1) {
      var arr = this.state.BotMsgs;
      this.setState({ BotMsgs: arr });
    }
  }

  handleInputFileChange(event) {
    this.setState({ Image: event.target.files[0] });
  }

  saveContents(botMsgId) {
    var fd = {};
    fd.Id = this.state.TextContents[0]._id;
    fd.Text = this.state.TextContents[0].Text;
    fd.Type = "TextContent";
    fd.Time = this.state.TextContents[0].Time;
    fd.Link = this.state.TextContents[0].Link;

    jQuery.ajax({
      method: "POST",
      contentType: "application/json",
      url: config.Backend + "/content/edit",
      success: function (res) {
        if (!res.Error) {
          this.addContentToAllContent(res.Content);
        }
      }.bind(this),
      data: JSON.stringify(fd),
    });

    for (var i = 0; i < this.state.ImageContents.length; i++) {
      if (this.state.ImageContents[i]._id == "-1") {
        var fd = new FormData();
        fd.append("File", this.state.ImageContents[i].File);
        /*if (this.state.ImageContents[i].Link!="-1") {
            jQuery.ajax({
                async: false,
                dataType: "json",
                url: "http://localhost:4800/content/deleteFile?Link="+this.state.ImageContents[i].Link,
            })
        }*/
        this.setState({ Wait: ++this.state.Wait });

        jQuery.ajax({
          method: "POST",
          async: false,
          processData: false,
          contentType: false,
          url: config.Backend + "/content/file",
          success: function (msg) {
            this.setState({ Wait: --this.state.Wait });

            this.state.ImageContents[i].Link = msg;
          }.bind(this),
          data: fd,
        });
        var url = config.Backend + "/content/add?";
        if (!(cookies.get("Id") === undefined)) {
          url += "&CurrentUserId=" + cookies.get("Id").toString();
        }
        url += "&BotMsg=" + botMsgId;
        url += "&Type=" + this.state.ImageContents[i].Type;
        url += "&Text=" + this.state.ImageContents[i].Text;
        url += "&Time=" + this.state.ImageContents[i].Time;
        url += "&Link=" + this.state.ImageContents[i].Link;
        this.setState({ Wait: ++this.state.Wait });

        jQuery.getJSON(
          url,
          function (res) {
            this.setState({ Wait: --this.state.Wait });

            if (!res.Error) {
              this.addContentToAllContent(res.Content);
            }
          }.bind(this)
        );
      }
    }

    for (var i = 0; i < this.state.AudioContents.length; i++) {
      if (this.state.AudioContents[i]._id == "-1") {
        var fd = new FormData();
        fd.append("File", this.state.AudioContents[i].File);
        this.setState({ Wait: ++this.state.Wait });

        jQuery.ajax({
          method: "POST",
          async: false,
          processData: false,
          contentType: false,
          url: config.Backend + "/content/file",
          success: function (msg) {
            this.setState({ Wait: --this.state.Wait });

            this.state.AudioContents[i].Link = msg;
          }.bind(this),
          data: fd,
        });
        var url = config.Backend + "/content/add?";
        if (!(cookies.get("Id") === undefined)) {
          url += "&CurrentUserId=" + cookies.get("Id").toString();
        }
        url += "&BotMsg=" + botMsgId;
        url += "&Type=" + this.state.AudioContents[i].Type;
        url += "&Text=" + this.state.AudioContents[i].Text;
        url += "&Time=" + this.state.AudioContents[i].Time;
        url += "&Link=" + this.state.AudioContents[i].Link;
        this.setState({ Wait: ++this.state.Wait });

        jQuery.getJSON(
          url,
          function (res) {
            this.setState({ Wait: --this.state.Wait });

            if (!res.Error) {
              this.addContentToAllContent(res.Content);
            }
          }.bind(this)
        );
      }
    }

    for (var i = 0; i < this.state.VideoContents.length; i++) {
      if (this.state.VideoContents[i]._id == "-1") {
        var fd = new FormData();
        fd.append("File", this.state.VideoContents[i].File);
        this.setState({ Wait: ++this.state.Wait });

        jQuery.ajax({
          method: "POST",
          async: false,
          processData: false,
          contentType: false,
          url: config.Backend + "/content/file",
          success: function (msg) {
            this.setState({ Wait: --this.state.Wait });

            this.state.VideoContents[i].Link = msg;
          }.bind(this),
          data: fd,
        });
        var url = config.Backend + "/content/add?";
        if (!(cookies.get("Id") === undefined)) {
          url += "&CurrentUserId=" + cookies.get("Id").toString();
        }
        url += "&BotMsg=" + botMsgId;
        url += "&Type=" + this.state.VideoContents[i].Type;
        url += "&Text=" + this.state.VideoContents[i].Text;
        url += "&Time=" + this.state.VideoContents[i].Time;
        url += "&Link=" + this.state.VideoContents[i].Link;
        this.setState({ Wait: ++this.state.Wait });

        jQuery.getJSON(
          url,
          function (res) {
            this.setState({ Wait: --this.state.Wait });

            if (!res.Error) {
              this.addContentToAllContent(res.Content);
            }
          }.bind(this)
        );
      }
    }

    fd = {};
    if (!(cookies.get("Id") === undefined)) {
      fd.CurrentUserId = cookies.get("Id").toString();
    }
    fd.Id = botMsgId;
    fd.Btns = this.state.BtnContents.map((content) => {
      const result = { ...content };

      if (result._id === "-1") {
        delete result._id;
      }

      return result;
    });

    fd.Links = this.state.CondContents.map((content) => {
      const result = { ...content };

      if (result._id === "-1") {
        delete result._id;
      }

      return result;
    });

    jQuery.ajax({
      method: "POST",
      contentType: "application/json",
      url: config.Backend + "/botMsg/edit",
      success: function (res) {
        this.setupBotMsg(res.BotMsg);
      }.bind(this),
      data: JSON.stringify(fd),
    });
  }

  hideAllPopups() {
    this.setState({ ShowDeletebotMsgPopup: false });
  }

  wantDeleteBotMsg(id) {
    this.hideAllPopups();
    this.setState({ ShowDeletebotMsgPopup: true });
    this.setState({ BotMsg: id });
  }

  saveBotMsg() {
    const botMsgId = this.state.BotMsg;
    var s = this.state;
    var url = config.Backend + "/botMsg/edit?";
    if (!(cookies.get("Id") === undefined)) {
      url += "&CurrentUserId=" + cookies.get("Id").toString();
    }
    url += "&Id=" + s.BotMsg;
    url += "&Name=" + s.BotMsgName;
    var api = false;
    var optionals = [];

    const botMsg = s.BotMsgs.find(({ _id }) => _id === s.BotMsg);

    url += "&DefaultLink=" + botMsg.DefaultLink;
    if (botMsg.WaitAnswer) {
      url += "&WaitAnswer=true";
    } else {
      url += "&WaitAnswer=false";
    }
    if (botMsg.Trim) {
      url += "&Trim=true";
    } else {
      url += "&Trim=false";
    }
    if (botMsg.OneTimeKeyboard) {
      url += "&OneTimeKeyboard=true";
    } else {
      url += "&OneTimeKeyboard=false";
    }
    if (botMsg.WaitApi) {
      url += "&WaitApi=true";
    } else {
      url += "&WaitApi=false";
    }
    if (botMsg.TelegramMarkdown) {
      url += "&TelegramMarkdown=true";
    } else {
      url += "&TelegramMarkdown=false";
    }
    if (botMsg.ImageAndTextInOneMessage) {
      url += "&ImageAndTextInOneMessage=true";
    } else {
      url += "&ImageAndTextInOneMessage=false";
    }
    if (botMsg.CRM) {
      url += "&CRM=true";
    } else {
      url += "&CRM=false";
    }
    if (botMsg.Start) {
      url += "&Start=1";
    } else {
      url += "&Start=0";
    }
    url += "&AnswerName=" + botMsg.AnswerName;
    if (botMsg.Type == "Api") {
      api = botMsg.Api;
    } else if (botMsg.Type == "CRM" || botMsg.Type == "CRMoutput") {
      optionals.push({
        Key: "CRMaddToExistRow",
        Value: botMsg.CRMaddToExistRow,
      });
      optionals.push({ Key: "CRMtables", Value: botMsg.CRMtables });
      if (botMsg.Type == "CRMoutput") {
        optionals.push({ Key: "CRMcolumn", Value: botMsg.CRMcolumn });
        optionals.push({ Key: "CRMIfColumn", Value: botMsg.CRMIfColumn });
        optionals.push({ Key: "CRMIf", Value: botMsg.CRMIf });
        optionals.push({ Key: "CRMIfValue", Value: botMsg.CRMIfValue });
      }
    } else if (botMsg.Type == "Process") {
      optionals.push({ Key: "Process", Value: botMsg.Process });
    } else if (botMsg.Keyboard !== undefined) {
      optionals.push({ Key: "Keyboard", Value: botMsg.Keyboard });
    } else if (botMsg.Type == "Location") {
      optionals.push({ Key: "Location", Value: botMsg.Location });
    } else if (botMsg.Type == "Telegram Payment") {
      if (this.state.ProductImage && this.state.ProductImage.File) {
        var fd = new FormData();
        fd.append("File", this.state.ProductImage.File);
        this.setState({ Wait: ++this.state.Wait });

        jQuery.ajax({
          method: "POST",
          async: false,
          processData: false,
          contentType: false,
          url: config.Backend + "/content/file",
          success: function (msg) {
            this.setState({ Wait: --this.state.Wait });

            optionals.push({ Key: "FileLink", Value: msg });
          }.bind(this),
          data: fd,
        });
      } else {
        optionals.push({ Key: "FileLink", Value: "" });
      }

      optionals.push({ Key: "Sum", Value: botMsg.Sum });
      optionals.push({ Key: "Title", Value: botMsg.Title });
      optionals.push({ Key: "Note", Value: botMsg.Note });
      optionals.push({ Key: "VatCode", Value: botMsg.VatCode });
      optionals.push({
        Key: "LinkAfterPaymentSuccess",
        Value: botMsg.LinkAfterPaymentSuccess,
      });
      optionals.push({
        Key: "TelegramPaymentProviderToken",
        Value: botMsg.TelegramPaymentProviderToken,
      });
    } else if (botMsg.Type == "DeepLink") {
      optionals.push({ Key: "CRMtables", Value: botMsg.CRMtables });
      optionals.push({ Key: "CRMcolumn", Value: botMsg.CRMcolumn });
      optionals.push({ Key: "DeepLinkParam", Value: botMsg.DeepLinkParam });
      optionals.push({ Key: "DeepLinkValue", Value: botMsg.DeepLinkValue });
      optionals.push({ Key: "DeepLinkNumber", Value: botMsg.DeepLinkNumber });
      optionals.push({ Key: "DeepLinkCRMtype", Value: botMsg.DeepLinkCRMtype });
      optionals.push({ Key: "ValueType", Value: botMsg.ValueType });
      optionals.push({ Key: "AnswerName2", Value: botMsg.AnswerName2 });
    }

    if (optionals.length > 0) {
      func
        .getData("botMsg/edit_opt", { Optionals: optionals, Msg: s.BotMsg })
        .then(
          (ok) => {
            this.setupBotMsg(ok);
          }
        );
    }
    if (api) {
      func.getData("botMsg/edit_api", { Api: api, Msg: s.BotMsg }).then(
        (ok) => {
          this.setupBotMsg(ok);
        }
      );
    }
    this.setState({ Wait: ++this.state.Wait });

    jQuery.getJSON(
      url,
      function (res) {
        this.setState({ Wait: --this.state.Wait });

        if (!res.Error) {
          this.setupBotMsg(res.BotMsg);
          this.setState({ BotMsg: "-1" });

          if (func.have_content(botMsg)) {
            this.saveContents(botMsgId);
          }
        }
      }.bind(this)
    );
  }

  deleteBotMsg() {
    var url = config.Backend + "/botMsg/delete?";
    if (!(cookies.get("Id") === undefined)) {
      url += "&CurrentUserId=" + cookies.get("Id").toString();
    }
    url += "&Id=" + this.state.BotMsg;

    this.setState({
      ShowDeletebotMsgPopup: false,
      BotMsg: "-1",
    });

    this.setupAllMsgs(
      this.state.AllMsgs.filter((botMsg) => botMsg._id !== this.state.BotMsg)
    );

    jQuery.getJSON(url);
  }

  addBotMsg(logical, type) {
    var url = config.Backend + "/botMsg/add?";
    if (!(cookies.get("Id") === undefined)) {
      url += "&CurrentUserId=" + cookies.get("Id").toString();
    }
    url +=
      "&Pos=" +
      Math.floor(Math.random() * 200) +
      "*" +
      Math.floor(Math.random() * 200);
    url += "&Group=" + this.state.Group;
    if (logical === true) {
      url += "&Condition=" + "true";
    }
    if (logical === true) {
      url += "&Name=" + dict.tebbibbh;
    } else if (type == "Api") {
      url += "&Name=API";
    } else if (type == "CRM") {
      url += "&Name=" + dict.mhfelrig;
    } else if (type == "CRMoutput") {
      url += "&Name=" + dict.eedgbgzb;
    } else if (type == "Deep Link") {
      url += "&Name=" + dict.itxxbjie;
    } else if (type == "Process") {
      url += "&Name=" + dict.sjxnbmmx;
    } else if (type == "RTC") {
      url += "&Name=" + dict.lblvtecg;
    } else if (type == "Location") {
      url += "&Name=" + dict.hlucdloa;
    } else if (type == "Telegram Payment") {
      url += "&Name=" + dict.pay_block_name_telegram;
    } else {
      url +=
        "&Name=" +
        (this.state.BotMsgs.length > 0 ? dict.gkcskyto : dict.raartyoz);
    }
    if (type) {
      url += "&Type=" + type;
    }
    url += "&Start=" + (this.state.BotMsgs.length > 0 ? "0" : "1");
    this.setState({ Wait: ++this.state.Wait });

    jQuery.getJSON(
      url,
      function (res) {
        this.setState({ Wait: --this.state.Wait });

        if (!res.Error) {
          this.setupBotMsg(res.BotMsg);
          this.addContentToAllContent(res.Content);
        }
      }.bind(this)
    );
  }

  chooseBlock(botMsgId) {
    const botMsg = this.state.BotMsgs.find((botMsg) => botMsg._id === botMsgId);

    this.setState(
      { BotMsg: botMsgId },
      function () {
        if (func.have_content(botMsg)) {
          this.setupBotMsgContent(botMsgId);
        }

        if (botMsg.FileLink) {
          this.setState({
            ProductImage: {
              Link: botMsg.FileLink,
              Type: "Product Image",
            },
          });
        } else {
          this.setState({ ProductImage: undefined });
        }

        this.setState(
          {
            BotMsgName: botMsg.Name,
          },
          () => {
            if (botMsg.Condition) {
              this.state.CondContents = [];
              this.setState({ CondContents: this.state.CondContents }, () => {
                this.setState({ CondContents: botMsg.Links }, () => {});
              });
            } else {
              this.state.CondContents = [];
              this.setState({ BotMsgs: this.state.BotMsgs }, () => {
                this.state.BtnContents = [];
                this.setState(
                  {
                    BtnContents: this.state.BtnContents,
                    CondContents: this.state.CondContents,
                  },
                  () => {
                    this.setState({ BtnContents: botMsg.Btns }, () => {});
                  }
                );
              });
            }
          }
        );
      }.bind(this)
    );
  }

  addContentToAllContent = (content) => {
    let newAllContent;

    if (Array.isArray(content)) {
      newAllContent = [
        ...this.state.AllContent.filter(
          (c) => content.map((cc) => cc._id).indexOf(c._id) === -1
        ),
        ...content,
      ];
    } else {
      newAllContent = [
        ...this.state.AllContent.filter((c) => c._id !== content._id),
        content,
      ];
    }

    this.setState({
      AllContent: newAllContent,
    });
  };

  setupBotMsgContent = (botMsgId) => {
    this.state.TextContents = [];
    this.state.ImageContents = [];
    this.state.FileContents = [];
    this.state.VideoContents = [];
    this.state.AudioContents = [];
    this.setState({
      TextContents: this.state.TextContents,
      ImageContents: this.state.ImageContents,
      FileContents: this.state.FileContents,
      VideoContents: this.state.VideoContents,
      AudioContents: this.state.AudioContents,
    });

    (this.state.AllContent || [])
      .filter((content) => content.BotMsg === botMsgId)
      .forEach((content) => {
        if (content.Type == "Text")
          this.state.TextContents.push({ ...content });
        if (content.Type == "Image")
          this.state.ImageContents.push({ ...content });
        if (content.Type == "File")
          this.state.FileContents.push({ ...content });
        if (content.Type == "Video")
          this.state.VideoContents.push({ ...content });
        if (content.Type == "Audio")
          this.state.AudioContents.push({ ...content });
      });

    this.setState({
      TextContents: this.state.TextContents,
      ImageContents: this.state.ImageContents,
      FileContents: this.state.FileContents,
      VideoContents: this.state.VideoContents,
      AudioContents: this.state.AudioContents,
    });
  };

  load_contents = (botMsgId) => {
    var url = config.Backend + "/botMsg/showContents?";
    if (!(cookies.get("Id") === undefined)) {
      url += "&CurrentUserId=" + cookies.get("Id").toString();
    }
    url += "&Id=" + botMsgId;
    this.setState({ Wait: ++this.state.Wait });

    jQuery.getJSON(
      url,
      function (res) {
        this.setState({ Wait: --this.state.Wait });

        if (!res.Error) {
        } else {
          alert(JSON.stringify(res));
        }
      }.bind(this)
    );
  };

  setupGroupMsgs() {
    const groupId = this.props.match.params.id;

    this.setState(
      {
        BotMsgs: this.state.AllMsgs.filter(
          (botMsg) => botMsg.Group === groupId || botMsg.Group._id === groupId
        ),
        Group: groupId,
      },
      () => {
        this.setState({ hideMsgName: true }, () =>
          this.setState({ hideMsgName: false })
        );
        this.setState({ hideDefaultLink: true }, () =>
          this.setState({ hideDefaultLink: false })
        );
      }
    );
  }

  setupBotMsg(botMsg) {
    this.setupAllMsgs([
      ...this.state.AllMsgs.filter((msg) => msg._id !== botMsg._id),
      {
        ...botMsg,
        Group: this.props.groups.find((group) => group._id === botMsg.Group),
      },
    ]);
  }

  setupAllMsgs(allMsgs) {
    this.setState(
      {
        AllMsgs: allMsgs,
      },
      () => {
        this.setupGroupMsgs();
      }
    );
  }

  loadAllContent(botMsgs) {

    if (botMsgs.length === 0) {
      this.setState({
        AllContent: [],
      });
    } else {
      func
        .getData("botMsg/showAllContent", {
          Ids: botMsgs.map((botMsg) => botMsg._id),
          CurrentUserId: cookies.get("Id").toString(),
        })
        .then(
          (allContent) => {
            this.setState({
              AllContent: allContent,
            });
          }
        );
    }
  }

  loadBotMsgs() {
    let url = config.Backend + "/users/all_msgs?";
    if (!(cookies.get("Id") === undefined)) {
      url += "&CurrentUserId=" + cookies.get("Id").toString();
    }
    url += "&BotId=" + cookies.get("BotId").toString();

    this.setState({ Wait: ++this.state.Wait });

    jQuery.getJSON(
      url,
      function (res) {
        this.setState({ Wait: --this.state.Wait });

        if (!res.Error) {
          this.setupAllMsgs(res);
          this.loadAllContent(res);
          this.hideAllPopups();
        }
      }.bind(this)
    );


  }

  loadSendedMsgs = () => {
    func
      .getData("bot/sended_messages", {
        BotId: cookies.get("BotId").toString(),
      })
      .then(
        (msgs) => {
          this.setState({
            SendedMsgs: msgs,
          });
        }
      );
  };

  componentDidUpdate(prevProps) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.setupGroupMsgs();
    }
  }

  componentDidMount() {
    func.getData("crm/tables").then(
      (tables) => {
        this.setState({ Tables: tables });
      }
    );

    if (!this.props.groups) {
      this.loadGroups();
    }

    this.loadBotMsgs();

    if (config.botIsFullyLoad(this.props.bot)) {
      this.setState({ Bot: this.props.bot });
      if (
        !config.checkRights(
          cookies.get("Id").toString(),
          this.props.bot,
          "Build"
        )
      ) {
        config.accessDenied(cookies.get("Id").toString(), this.props.bot);
      }
    } else {
      var url = config.Backend + "/bot/findById?";
      if (!(cookies.get("Id") === undefined)) {
        url += "CurrentUserId=" + cookies.get("Id").toString();
      }
      url += "&Id=" + cookies.get("BotId").toString();
      this.setState({ Wait: ++this.state.Wait });

      jQuery.getJSON(
        url,
        function (res) {
          this.setState({ Wait: --this.state.Wait });

          if (!res.Error) {
            this.setState({ Bot: res });
            if (
              !config.checkRights(cookies.get("Id").toString(), res, "Build")
            ) {
              config.accessDenied(cookies.get("Id").toString(), res);
            }
          }
        }.bind(this)
      );
    }
  }

  handleInputChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  loadGroups = () => {
    var url = config.Backend + "/bot/groups?";
    if (!(cookies.get("Id") === undefined)) {
      url += "&CurrentUserId=" + cookies.get("Id").toString();
    }
    url += "&Id=" + cookies.get("BotId").toString();

    jQuery.getJSON(
      url,
      function (res) {
        if (!res.Error) {
          this.props.addGroupsToStore({
            groups: res,
            botId: cookies.get("BotId").toString(),
          });
        }
      }.bind(this)
    );
  };

  RightBar() {
    /*

     */
    return (
      <div className="overflow_main_bot">
        <LeftPanel User={this.props.user} />
        <div className="overflow_main_bot_center">{this.Canva()}</div>
        <div className="rightPanel">
          <Scrollbars style={{ height: "95%" }}>
            <div className="right_container_content">{this.Right()}</div>
          </Scrollbars>
        </div>
      </div>
    );
  }

  Right() {
    if (this.state.BotMsg != "-1" && !this.state.ShowDeletebotMsgPopup) {
      return this.EditPanel();
    } else {
      return this.RightGroup();
    }
  }

  wantRenameGroup(id) {
    this.hideAllPopups();
    this.setState({ ShowRenameGroupPopup: true });
    this.setState({ Group: id });
  }

  wantDeleteGroup(id) {
    this.hideAllPopups();
    this.setState({ ShowDeleteGroupPopup: true });
    this.setState({ Group: id });
  }

  chooseGroup(id) {
    //var stateObj = { id: id  };
    //window.history.pushState(stateObj, "page", window.location.origin + '/chat/build/'+id);
    //window.location.href = (window.location.origin + '/chat/build/' + id);
    this.props.history.push("/chat/build/" + id);
  }

  RightGroup() {
    var s = this.state;
    var u = this.props.user;
    var b = s.Bot;

    const groups = (this.props.groups || []).map((group) => (
      <li key={group._id}>
        <div className="nameBlock">
          <span onClick={() => this.chooseGroup(group._id)}>{group.Name}</span>
        </div>
      </li>
    ));

    return (
      <div>
        <div className="right_group">
          <div className="topnav_left_navigator">
            <a
              className="chat_prev"
              onClick={() => this.props.history.push("/chat/build")}
            >
              {dict.odidtiyk}
            </a>
          </div>
          <div className="right_add_message_block">
            <button onClick={this.addBotMsg}>{dict.sxvtxixk}</button>
          </div>

          {u && u.Tariff && u.Tariff.EnableConditionBLock ? (
            <div className="right_add_condition_block">
              <button onClick={() => this.addBotMsg(true)}>
                {dict.bhlztzrv + " " + dict.jbtfzziv}
              </button>
            </div>
          ) : null}
          {u && u.Tariff && u.Tariff.EnableApiBLock ? (
            <div className="right_add_api_block">
              <button onClick={() => this.addBotMsg(false, "Api")}>API</button>
            </div>
          ) : null}
          {u && u.Tariff && u.Tariff.EnableProcessBLock ? (
            <div className="right_add_variable_block">
              <button onClick={() => this.addBotMsg(false, "Process")}>
                {dict.sjxnbmmx}
              </button>
            </div>
          ) : null}
          {u && u.Tariff && u.Tariff.CRM ? (
            <div className="right_add_add_crm">
              <button onClick={() => this.addBotMsg(false, "CRM")}>
                {dict.jlwtbsvl}
              </button>
            </div>
          ) : null}
          {u && u.Tariff && u.Tariff.CRM ? (
            <div className="right_add_add_crm">
              <button onClick={() => this.addBotMsg(false, "CRMoutput")}>
                {dict.eedgbgzb}
              </button>
            </div>
          ) : null}
          {b && b.BindTo != "Alisa" ? (
            <div className="right_add_rtc">
              <button onClick={() => this.addBotMsg(false, "RTC")}>
                {dict.lblvtecg}
              </button>
            </div>
          ) : null}
          {b && b.BindTo !== 'WhatsApp' ? (
            <div className="right_add_coordinate">
              <button onClick={() => this.addBotMsg(false, "Location")}>
                {dict.hlucdloa}
              </button>
            </div>
          ) : null}
          {b &&
          b.BindTo == "Telegram" &&
          u &&
          u.Tariff &&
          u.Tariff.EnableDeepLinkBLock ? (
            <div className="right_add_deep_link">
              <button onClick={() => this.addBotMsg(false, "DeepLink")}>
                {dict.dkaltvqq}
              </button>
            </div>
          ) : null}
          {b &&
          b.BindTo == "Telegram" &&
          u &&
          u.Tariff &&
          u.Tariff.EnableTelegramPaymentBLock ? (
            <div className="right_add_payment">
              <button onClick={() => this.addBotMsg(false, "Telegram Payment")}>
                {dict.pay_block_name_telegram}
              </button>
            </div>
          ) : null}
          <ul className="rightNav">{groups}</ul>
        </div>
      </div>
    );
  }

  /*{End Right group}*/
  handleClick(e, obj, disable, value) {
    obj.condition = disable ? false : true;
    if (value) {
      if (obj.Value === "" && e.type == "blur") {
        obj.condition = false;
      }
    } else {
      if (obj.Key === "" && e.type == "blur") {
        obj.condition = false;
      }
    }
    this.setState({
      ["BotMsgs"]: this.state["BotMsgs"],
    });
  }

  /*{Right edit panel}*/
  addRowToApi = (arr, i, isConst, e, isExtension = false) => {
    e.preventDefault();
    this.state.BotMsgs[i].Api[arr].push({
      Key: "",
      Value: "",
      Const: isConst,
      isExtension,
      condition: false,
    });
    this.setState({ BotMsgs: this.state.BotMsgs });
  };

  returnToMainMode = (i) => {
    this.state.BotMsgs[i].Api.Save = this.state.BotMsgs[i].Api.Save.map(save => {
      save.Const = false;

      return save;
    });
  }

  onCheckSaveFileFromApi = (i, e) => {
    const SaveFile = e.target.checked;
    this.state.BotMsgs[i].Api.SaveFile = SaveFile;

    if (SaveFile) {
      this.state.BotMsgs[i].Api.Unzip = false;
      this.state.BotMsgs[i].Api.ParseXmlFile = false;
      // must be only one Context param for save file url
      if (this.state.BotMsgs[i].Api.Save.length) {
        this.state.BotMsgs[i].Api.Save = [
         this.state.BotMsgs[i].Api.Save[0]
        ];
      } else {
        this.state.BotMsgs[i].Api.Save.push({
          Key: "",
          Value: "",
          Const: true,
          condition: false,
        });
      }
    } else {
      this.returnToMainMode(i);
    }

    this.setState({ BotMsgs: this.state.BotMsgs });
  };

  onCheckUnzipApi = (i, e) => {
    const Unzip = e.target.checked;
    this.state.BotMsgs[i].Api.Unzip = Unzip;
    if (Unzip) {
      this.state.BotMsgs[i].Api.SaveFile = false;
      this.state.BotMsgs[i].Api.ParseXmlFile = false;
      // only const values available in unzip mode
      this.state.BotMsgs[i].Api.Save = this.state.BotMsgs[i].Api.Save.map(save => {
        save.Const = true;

        return save;
      });
    } else {
      this.returnToMainMode(i);
    }

    this.setState({ BotMsgs: this.state.BotMsgs });
  };

  onCheckParseXmlFileApi = (i, e) => {
    const ParseXmlFile = e.target.checked;
    this.state.BotMsgs[i].Api.ParseXmlFile = ParseXmlFile;
    if (ParseXmlFile) {
      this.state.BotMsgs[i].Api.SaveFile = false;
      this.state.BotMsgs[i].Api.Unzip = false;
    } else {
      this.returnToMainMode(i);
    }

    this.setState({ BotMsgs: this.state.BotMsgs });
  };

  DeepLinkContent(msg_ind) {
    var s = this.state;
    var context_params = [
      <option value={""}></option>,
      <option value={"Context.Telegram.start"}>Context.Telegram.start</option>,
    ];
    var names = [];
    var table = {};
    var tables = s.Tables.map((elem, ind) => {
      const e = elem;
      const i = ind;

      if (e.Module) {
      } else {
        /* if(s.BotMsgs[msg_ind].CRMtables.length==0) {
             s.BotMsgs[msg_ind].CRMtables.push(e._id);
             this.setState({BotMsgs:s.BotMsgs});
         }*/
        if (s.BotMsgs[msg_ind].CRMtables[0] == e._id) {
          table = e;
        }
        return <option value={e._id}>{e.Name}</option>;
      }
    });
    var cols = (table.Fields || []).map((elem, ind) => {
      const e = elem;
      const i = ind;

      return <option value={e.Key}>{e.Name}</option>;
    });
    var col_input_name = "BotMsgs[" + msg_ind + "].CRMcolumn";
    var ifcol_input_name = "BotMsgs[" + msg_ind + "].CRMIfColumn";
    var evt = { target: { value: "", name: col_input_name } }; //очистка полей после выбора таблицы
    s.AllMsgs.forEach(function (e, i) {
      if (e.WaitAnswer && e.AnswerName && names.indexOf(e.AnswerName) == -1) {
        names.push(e.AnswerName);
        var param = "Context." + e.AnswerName;
        context_params.push(<option value={param}>{param}</option>);
      } else if (
        e.Type == "Api" &&
        e.Api &&
        e.Api.Save &&
        e.Api.Save.length > 0
      ) {
        var saves = e.Api.Save;
        for (var z = 0; z < saves.length; z++) {
          if (names.indexOf(saves[z].Key) == -1) {
            names.push(saves[z].Key);
            var param = "Context." + saves[z].Key;
            context_params.push(<option value={param}>{param}</option>);
          }
        }
      }
    });

    var value = (
      <div>
        <input
          className={"chat_input"}
          type={"text"}
          value={s.BotMsgs[msg_ind].DeepLinkValue}
          name={"BotMsgs[" + msg_ind + "].DeepLinkValue"}
          onChange={this.change}
        />
      </div>
    );

    switch (s.BotMsgs[msg_ind].ValueType) {
      case "random":
        value = (
          <div>
            <label htmlFor="wait_answer_build2">
              {dict.emgzzctw}
              <Link data-tip data-for="i_DLcnt" className={"ml5"} to="">
                <i className="fz15 far fa-question-circle"></i>
              </Link>
            </label>

            <ReactTooltip id="i_DLcnt" type="light">
              <span>{dict.coqgmcoe}</span>
            </ReactTooltip>
            <input
              className={"chat_input"}
              type={"number"}
              value={s.BotMsgs[msg_ind].DeepLinkNumber}
              name={"BotMsgs[" + msg_ind + "].DeepLinkNumber"}
              onChange={this.change}
            />
          </div>
        );
        break;
      case "context":
        value = (
          <div className={"wrap_filter_laber mt_5"}>
            <select
              className="chat_input"
              name={"BotMsgs[" + msg_ind + "].DeepLinkValue"}
              value={s.BotMsgs[msg_ind].DeepLinkValue}
              onChange={this.change}
            >
              {context_params}
            </select>
          </div>
        );
        break;
      case "crm":
        value = null;
        break;
    }
    return (
      <div>
        <div>

          <TooltipTitle
            idTooltip="i_DLparam"
            name={dict.idvqzbwm}
            tooltipDescription={dict.axsxmbua}
          />

          <select
            className="chat_input"
            name={"BotMsgs[" + msg_ind + "].DeepLinkParam"}
            value={s.BotMsgs[msg_ind].DeepLinkParam}
            onChange={this.change}
          >
            <option value={"start"}>start</option>

            <option value={"startgroup"}>startgroup</option>
          </select>
        </div>


          <TooltipTitle
            idTooltip="i_DLvalueType"
            name={dict.vgtmzalt}
            tooltipDescription={dict.hvowmmrt}
          />

          <select
            className="chat_input"
            name={"BotMsgs[" + msg_ind + "].ValueType"}
            value={s.BotMsgs[msg_ind].ValueType}
            onChange={this.change}
          >
            <option value={"random"}>{dict.yybcgmiy}</option>

            <option value={"static"}>{dict.wmqamsqd}</option>

            <option value={"context"}>{dict.xiywgstf}</option>

            <option value={"javascript"}>Javascript</option>

            <option value={"crm"}>{dict.ussumgji}</option>
          </select>

        <div>
          <TooltipTitle
            idTooltip="i_DLvalue"
            name={dict.mxhsgfeo}
            tooltipDescription={dict.lewewfuo}
          />

          {value}
        </div>

        {s.BotMsgs[msg_ind].ValueType == "crm" ? (
          <div>
            <span>
              {dict.fvbigjlh + " "}
              <Link to={"/crm"}>{dict.ussumgji}</Link>
              {" " + dict.rtddwhrj}
            </span>
            <select
              className={"chat_input"}
              value={s.BotMsgs[msg_ind].CRMtables[0]}
              name={"BotMsgs[" + msg_ind + "].CRMtables[0]"}
              onChange={(e) => {
                this.change(e);
                this.change(evt);
                evt.target.name = ifcol_input_name;
                this.change(evt);
              }}
            >
              <option value={""}></option>
              {tables}
            </select>
          </div>
        ) : null}
        {s.BotMsgs[msg_ind].ValueType == "crm" ? (
          <div>


            <TooltipTitle
              idTooltip="i_CRMColumn"
              name={dict.lnykglhx}
              tooltipDescription={dict.oizowkqc}
            />

            <select
              className={"chat_input"}
              value={s.BotMsgs[msg_ind].CRMcolumn}
              name={col_input_name}
              onChange={this.change}
            >
              <option value={""}></option>
              {cols}
            </select>
          </div>
        ) : null}
        {s.BotMsgs[msg_ind].ValueType == "crm" ? (
          <div>

            <TooltipTitle
              idTooltip="i_CRMColumn"
              name={dict.slndukyv}
              tooltipDescription={dict.bzwkqwkq}
            />

            <select
              className={"chat_input"}
              value={s.BotMsgs[msg_ind].DeepLinkCRMtype}
              name={"BotMsgs[" + msg_ind + "].DeepLinkCRMtype"}
              onChange={this.change}
            >
              <option value={"user"}>{dict.jeuzuqeq}</option>

              <option value={"random"}>{dict.dvdnqrgn}</option>
            </select>
          </div>
        ) : null}
        <div>


          <TooltipTitle
            idTooltip="i_DLSave"
            name="Сохранить значение параметра в Context."
            tooltipDescription={dict.chexverm}
          />

          <input
            className={"chat_input"}
            type={"text"}
            value={s.BotMsgs[msg_ind].AnswerName}
            name={"BotMsgs[" + msg_ind + "].AnswerName"}
            onChange={this.change}
          />
        </div>
        <div>


          <TooltipTitle
            idTooltip="i_DLSaveLink"
            name="Сохранить значение параметра в Context."
            tooltipDescription={dict.chexverm}
          />

          <input
            className={"chat_input"}
            type={"text"}
            value={s.BotMsgs[msg_ind].AnswerName2}
            name={"BotMsgs[" + msg_ind + "].AnswerName2"}
            onChange={this.change}
          />
        </div>
      </div>
    );
  }

  CRMOutputContent(msg_ind) {
    var s = this.state;
    if (s.Tables && s.Tables.length > 0) {
      var table = {};
      var tables = s.Tables.map((elem, ind) => {
        const e = elem;
        const i = ind;

        if (e.Module) {
        } else {
          /* if(s.BotMsgs[msg_ind].CRMtables.length==0) {
               s.BotMsgs[msg_ind].CRMtables.push(e._id);
               this.setState({BotMsgs:s.BotMsgs});
           }*/
          if (s.BotMsgs[msg_ind].CRMtables[0] == e._id) {
            table = e;
          }
          return <option value={e._id}>{e.Name}</option>;
        }
      });
      var cols = (table.Fields || []).map((elem, ind) => {
        const e = elem;
        const i = ind;

        return <option value={e.Key}>{e.Name}</option>;
      });
      var col_input_name = "BotMsgs[" + msg_ind + "].CRMcolumn";
      var ifcol_input_name = "BotMsgs[" + msg_ind + "].CRMIfColumn";
      var evt = { target: { value: "", name: col_input_name } }; //очистка полей после выбора таблицы
      var context_params = [
        <option value={""}></option>,
        <option value={"Context.Telegram.start"}>
          Context.Telegram.start
        </option>,
        <option value={"Context.Vk.start"}>
          Context.Vk.start
        </option>,
        <option value={"Context.Viber.start"}>
          Context.Viber.start
        </option>,
        <option value={"Context.Facebook.start"}>
          Context.Facebook.start
        </option>,
      ];
      var names = [];

      s.AllMsgs.forEach(function (e, i) {
        if (e.WaitAnswer && e.AnswerName && names.indexOf(e.AnswerName) == -1) {
          names.push(e.AnswerName);
          var param = "Context." + e.AnswerName;
          context_params.push(<option value={param}>{param}</option>);
        } else if (
          e.Type == "Api" &&
          e.Api &&
          e.Api.Save &&
          e.Api.Save.length > 0
        ) {
          var saves = e.Api.Save;
          for (var z = 0; z < saves.length; z++) {
            if (names.indexOf(saves[z].Key) == -1) {
              names.push(saves[z].Key);
              var param = "Context." + saves[z].Key;
              context_params.push(<option value={param}>{param}</option>);
            }
          }
        }
      });
      return (
        <div>
          <span>
            {dict.fvbigjlh + " "}
            <Link to={"/crm"}>{dict.ussumgji}</Link>
            {" " + dict.rtddwhrj}
          </span>
          <select
            className={"chat_input"}
            value={s.BotMsgs[msg_ind].CRMtables[0]}
            name={"BotMsgs[" + msg_ind + "].CRMtables[0]"}
            onChange={(e) => {
              this.change(e);
              this.change(evt);
              evt.target.name = ifcol_input_name;
              this.change(evt);
            }}
          >
            <option value={""}></option>
            {tables}
          </select>

          <TooltipTitle
            idTooltip="i_CRMColumn"
            name="Из колонки"
            tooltipDescription="Из какой колонки сохранять данные"
          />

          <select
            className={"chat_input"}
            value={s.BotMsgs[msg_ind].CRMcolumn}
            name={col_input_name}
            onChange={this.change}
          >
            <option value={""}></option>
            {cols}
          </select>

          <TooltipTitle
            idTooltip="i_CRMIfColumn"
            name={dict.hwxmcikd + " " + dict.ydmwsued}
            tooltipDescription={dict.wteuczgu}
          />

          <select
            className={"chat_input"}
            value={s.BotMsgs[msg_ind].CRMIfColumn}
            name={ifcol_input_name}
            onChange={this.change}
          >
            <option value={""}></option>
            {cols}
          </select>

          <select
            className="chat_input"
            name={"BotMsgs[" + msg_ind + "].CRMIf"}
            value={s.BotMsgs[msg_ind].CRMIf}
            onChange={this.change}
          >
            <option value={"=="}>{dict.xhiasoli}</option>
            <option value={"!="}>{dict.yufhccsg}</option>
            <option value={">"}>{dict.nkkischh}</option>
            <option value={"<"}>{dict.karsjxwh}</option>
            <option value={">="}>{dict.fwmjsbfx}</option>
            <option value={"<="}>{dict.rdgijbkx}</option>
            <option value={"== i"}>{dict.lribsmak}</option>
            <option value={"in i"}>{dict.ogvkjmgl}</option>
            <option value={"in"}>{dict.sbaornlq}</option>
            <option value={"nin i"}>{dict.bkrbjssb}</option>
            <option value={"nin"}>{dict.jmolrsjn}</option>
          </select>

          <TooltipTitle
            idTooltip="i_CRMgetCondValue"
            name="Значение"
            tooltipDescription="В расчете значения можно использовать
             Context переменные и javascript"
          />

          <div className={"wrap_filter_laber mt_5"}>
            {s.BotMsgs[msg_ind].ChooseFromContext ? (
              <select
                className="chat_input"
                name={"BotMsgs[" + msg_ind + "].CRMIfValue"}
                value={s.BotMsgs[msg_ind].CRMIfValue}
                onChange={this.change}
              >
                {context_params}
              </select>
            ) : (
              <input
                className={"chat_input"}
                type={"text"}
                value={s.BotMsgs[msg_ind].CRMIfValue}
                name={"BotMsgs[" + msg_ind + "].CRMIfValue"}
                placeholder={"Context.data"}
                onChange={this.change}
              />
            )}
          </div>

          <TooltipTitle
            idTooltip="i_CRMgetCondValueContext"
            name={dict.edqnrffe}
            tooltipDescription={dict.cxtlodkr}
          />

          <input
            id={"contex_variable"}
            type={"checkbox"}
            value={s.BotMsgs[msg_ind].ChooseFromContext}
            name={"BotMsgs[" + msg_ind + "].ChooseFromContext"}
            onChange={this.change}
          />

          <TooltipTitle
            idTooltip="i_CRMgetSave"
            name="Сохранить в Context."
            tooltipDescription="Сохраняет результат в Context переменную"
          />

          <input
            className={"chat_input"}
            type={"text"}
            value={s.BotMsgs[msg_ind].AnswerName}
            name={"BotMsgs[" + msg_ind + "].AnswerName"}
            onChange={this.change}
          />
        </div>
      );
    } else {
      return (
        <div>
          {dict.vjogekby} <Link to={"/crm"}>{dict.ussumgji}</Link>{" "}
          {dict.rtddwhrj}
        </div>
      );
    }
  }

  CRMcontent(msg_ind) {
    var s = this.state;
    if (s.Tables && s.Tables.length > 0) {
      var tables = s.Tables.map((elem, ind) => {
        const e = elem;
        const i = ind;

        if (e.Module) {
        } else {
          return (
            <div className={"wrap_filter_laber mt_5"}>
              <label>{e.Name}</label>
              <input
                type="checkbox"
                checked={s.BotMsgs[msg_ind].CRMtables.indexOf(e._id) != -1}
                onChange={(evt) => {
                  var t_ind = s.BotMsgs[msg_ind].CRMtables.indexOf(e._id);
                  //alert(t_ind);
                  if (evt.target.checked && t_ind == -1) {
                    s.BotMsgs[msg_ind].CRMtables.push(e._id);
                    this.setState({ BotMsgs: s.BotMsgs });
                  }
                  if (!evt.target.checked && t_ind != -1) {
                    s.BotMsgs[msg_ind].CRMtables.splice(t_ind, 1);
                    this.setState({ BotMsgs: s.BotMsgs });
                  }
                }}
              />
            </div>
          );
        }
      });
      return (
        <div>
          {dict.igayehaa} <Link to={"/crm"}>{dict.ussumgji}</Link>{" "}
          {dict.rtddwhrj}:{tables}
        </div>
      );
    } else {
      return (
        <div>
          {dict.vjogekby} <Link to={"/crm"}>{dict.ussumgji}</Link>{" "}
          {dict.rtddwhrj}
        </div>
      );
    }
  }

  addRowToProcess = (i, e, Global = false) => {
    e.preventDefault();
    this.state.BotMsgs[i].Process.push({
      Key: "",
      Value: "",
      Global,
      condition: false,
    });
    this.setState({ BotMsgs: this.state.BotMsgs });
  };

  LocationContent(ind) {
    //
    var s = this.state;
    var msg = s.BotMsgs[ind];
    return (
      <div>
        <div
          className="mb-2
        "
        >
          <TooltipTitle
            idTooltip="i_longitude"
            name="Долгота"
            tooltipDescription="Дробные значения писать через точку, пример: 28.12354,
            Также можно использовать значение из Context переменной, пример: {{Context.location_y}}"
          />

          <input
            type="text"
            className="chat_api"
            name={"BotMsgs[" + ind + "].Location.longitude"}
            value={msg.Location.longitude}
            onChange={this.change2}
          />
        </div>

        <TooltipTitle
          idTooltip="i_latitude"
          name="Широта"
          tooltipDescription="Дробные значения писать через точку пример: 28.12354,
          Также можно использовать значение из Context переменной пример: {{Context.location_x}} "
        />

        <input
          type="text"
          className="chat_api"
          name={"BotMsgs[" + ind + "].Location.latitude"}
          value={msg.Location.latitude}
          onChange={this.change2}
        />
      </div>
    );
  }

  TelegramPaymentContent(ind) {
    var s = this.state;
    var msg = s.BotMsgs[ind];
    return (
      <div>
        <div className={"form-group"}>
          <TooltipTitle
            idTooltip="i_sum"
            name="Сумма к оплате"
            tooltipDescription="В расчете значения можно использовать Context переменные и javascript"
          />

          <input
            type="text"
            className="chat_api"
            name={"BotMsgs[" + ind + "].Sum"}
            placeholder={"Context.sum+20"}
            value={msg.Sum}
            onChange={this.change}
          />
        </div>

        <div className={"form-group"}>
          <TooltipTitle
            idTooltip="i_title"
            name={dict.title}
            tooltipDescription={dict.title_info}
          />

          <input
            type="text"
            className="chat_api"
            name={"BotMsgs[" + ind + "].Title"}
            placeholder={"Заголовок товара"}
            value={msg.Title}
            onChange={this.change}
          />
        </div>

        <div className={"form-group"}>
          <TooltipTitle
            idTooltip="i_note"
            name={dict.note}
            tooltipDescription={dict.note_info}
          />

          <input
            type="text"
            className="chat_api"
            name={"BotMsgs[" + ind + "].Note"}
            placeholder={"Покупка {{Context.productName}}"}
            value={msg.Note}
            onChange={this.change}
          />
        </div>

        <div className={"form-group"}>
          <label>
            НДС
          </label>

          <input
              type="text"
              className="chat_api"
              name={"BotMsgs[" + ind + "].VatCode"}
              value={msg.VatCode}
              onChange={this.change}
          />
        </div>

        <div className={"form-group"}>
          <span className={"heading__caption_1 mt_10"}>
            <a
              target={"_blank"}
              href={"https://core.telegram.org/bots/payments#getting-a-token"}
            >
              Provider Token
            </a>
          </span>
          <input
            type="text"
            className="chat_api"
            name={"BotMsgs[" + ind + "].TelegramPaymentProviderToken"}
            placeholder={"123456789:TEST:12345"}
            value={msg.TelegramPaymentProviderToken}
            onChange={this.change}
          />
        </div>

        <Content
          handleForContent={this.handleForContent}
          Type={"LinkAfterPaymentSuccess"}
          Url={msg.LinkAfterPaymentSuccess}
          BotMsg={msg}
          BotMsgs={this.state.AllMsgs}
        />
      </div>
    );
  }

  ProcessContent(ind) {
    //управление переменными
    var s = this.state;
    var msg = s.BotMsgs[ind];
    var rows = msg.Process.map((e, i) => {
      return (
        <div className={"row__grid"}>
          <div className="col__5">
            <div
              className={
                e.condition
                  ? "inputGroup inputGroup1 focusWithText"
                  : "inputGroup inputGroup1"
              }
              onFocus={(ev) => this.handleClick(ev, e, false, 0)}
              onBlur={(ev) => this.handleClick(ev, e, false, 0)}
            >
              <input
                type="text"
                className="chat_api"
                name={"BotMsgs[" + ind + "].Process[" + i + "].Key"}
                value={e.Key}
                onChange={(ev) => {
                  this.change(ev);
                  this.handleClick(ev, e, ev.target.value == "", 0);
                }}
              />
              {e.condition || e.Key == "" ? (
                <p className={"helper helper1"}>
                  {(e.Global ? 'Global' : 'Context') + "."}
                </p>
              ) : null}
            </div>
          </div>
          <div className="col__5">
            <input
              type="text"
              className="chat_api"
              name={"BotMsgs[" + ind + "].Process[" + i + "].Value"}
              value={e.Value}
              placeholder={dict.mxhsgfeo}
              onChange={this.change}
            />
          </div>
          <div className="grid__api--delete">
            <button
              onClick={(event) => {
                event.preventDefault();
                s.BotMsgs[ind].Process.splice(i, 1);
                this.setState({ BotMsgs: s.BotMsgs });
              }}
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
      );
    });
    return (
      <div>
        <div className={"form-group"}>
          <TooltipTitle
            idTooltip="i_Process"
            name="Переменные"
            tooltipDescription="Context и Global переменные которые будут изменены.
              Слева имя переменной,справа ее новое значение.
              В расчете значения можно использовать Context и Global переменные и javascript

              (Примеры: Context.i+1 , Context.number++ ,
              !Context.ok),

              а можно использовать обычные цифры или текст
              для сохранения текста нужно обернуть его либо в одинарные либо в двойные кавычки'."
          />
          {rows}
          <div className="row__grid">
            <button
              className={"grid__btn--api col__10 grid__bl--2"}
              onClick={(e) => this.addRowToProcess(ind, e)}
            >
              {dict.bhlztzrv} {dict.swdklufb}
            </button>
            <button
                className={"grid__btn--api col__10 grid__bl--2"}
                onClick={(e) => this.addRowToProcess(ind, e, true)}
            >
              Добавить глобальную переменную
            </button>
          </div>
        </div>
      </div>
    );
  }

  delKeyboardBtn = (event, btn, row_index, btn_index) => {
    event.preventDefault();
    var s = this.state;
    var ind = 0;
    for (var i = 0; i < s.BotMsgs.length; i++) {
      if (s.BotMsgs[i]._id == s.BotMsg) {
        ind = i;
      }
    }
    s.BotMsgs[ind].Keyboard[row_index].splice(btn_index, 1);
    if (s.BotMsgs[ind].Keyboard[row_index].length == 0) {
      s.BotMsgs[ind].Keyboard.splice(row_index, 1);
    }
    if (s.BotMsgs[ind].Keyboard.length == 0) {
      s.BotMsgs[ind].Keyboard = null;
    }
    this.setState({ BotMsgs: s.BotMsgs });
  };
  addKeyboardBtn = (event, row) => {
    event.preventDefault();
    var s = this.state;

    var ind = 0;
    for (var i = 0; i < s.BotMsgs.length; i++) {
      if (s.BotMsgs[i]._id == s.BotMsg) {
        ind = i;
      }
    }

    if (s.Bot.BindTo === 'WhatsApp' && s.BotMsgs[ind].Keyboard.length === 3) {
      return alert("Ошибка(WhatsApp): Максимальное число кнопок - 3");
    }

    if (row === undefined) {
      s.BotMsgs[ind].Keyboard.push([{ text: "" }]);
    } else {
      s.BotMsgs[ind].Keyboard[row].push({ text: "" });
    }
    this.setState({ BotMsgs: s.BotMsgs });
  };

  select_period = (e) => {
    this.change(e);
    var data = {
      Bot: cookies.get("BotId"),
    };

    switch (e.target.name) {
      case "Period":
        this.load_data("SendedMsgs", "bot/time_msgs", e.target.value, data);
        break;
    }
  };

  changeTelegramKeyboardButtonOption = (e) => {
    this.change2(e);
    if (e.target.checked) {
      const e2 = {
        target: {
          checked: false,
          name: e.target.name,
        },
      };

      if (e2.target.name.indexOf("contact") !== -1) {
        e2.target.name = e2.target.name.replace("contact", "location");
      } else {
        e2.target.name = e2.target.name.replace("location", "contact");
      }
      this.change2(e2);
    }
  };

  getApiSaveValuePlaceholder = (msg, e) => {
    if (msg.Api.SaveFile) {
      return '.xml';
    }

    if (e.Const) {
      if (msg.Api.Unzip) {
        return e.isExtension ? '.xml' : 'filename.xml';
      }

      return dict.jtxnloae;
    }

    return dict.dnjffczm;
  };

  EditPanel() {
    var s = this.state;
    let state = this.state;
    var link = "";
    var ind = 0;

    for (var i = 0; i < state.BotMsgs.length; i++) {
      if (this.state.BotMsgs[i]._id === state.BotMsg) {
        link = state.BotMsgs[i].DefaultLink;
        ind = i;
      }
    }
    const msg = s.BotMsgs[ind];

    const txtContents = this.state.TextContents.map((content, i) => {
      return (
        <Content
          Index={i}
          key={content._id}
          handleForContent={this.handleForContent}
          Text={content.Text}
          Type={content.Type}
          Bot={this.state.Bot}
          Msg={msg}
          File={content.File}
          Url={content.Url}
          onDelete={this.deleteContent}
          Link={content.Link}
          typingTime={content.Time}
        />
      );
    });

    const imgContents = state.ImageContents.map((content, i) => {
      return (
        <Content
          Index={i}
          key={content._id}
          handleForContent={this.handleForContent}
          onDelete={this.deleteContent}
          File={content.File}
          Url={content.Url}
          Text={content.Text}
          Type={content.Type}
          Link={content.Link}
          typingTime={content.Time}
        />
      );
    });

    const productImage = state.ProductImage ? (
      <Content
        Index={0}
        key={"productImage"}
        handleForContent={this.handleForContent}
        onDelete={this.deleteContent}
        File={state.ProductImage.File}
        Url={state.ProductImage.Url}
        Text={state.ProductImage.Text}
        Type={state.ProductImage.Type}
        Link={state.ProductImage.Link}
      />
    ) : null;

    const fileContents = state.FileContents.map((content, i) => (
      <Content
        Index={i}
        key={content._id}
        handleForContent={this.handleForContent}
        onDelete={this.deleteContent}
        File={content.File}
        Url={content.Url}
        Text={content.Text}
        Type={content.Type}
        Link={content.Link}
        FileName={content.FileName || 'test.png'}
        typingTime={content.Time}
      />
    ));

    const videoContents = state.VideoContents.map((content, i) => {
      return (
        <Content
          Index={i}
          key={content._id}
          handleForContent={this.handleForContent}
          onDelete={this.deleteContent}
          Text={content.Text}
          Type={content.Type}
          File={content.File}
          Url={content.Url}
          Link={content.Link}
          typingTime={content.Time}
        />
      );
    });

    const audioContents = state.AudioContents.map((content, i) => {
      return (
        <Content
          Index={i}
          key={content._id}
          handleForContent={this.handleForContent}
          onDelete={this.deleteContent}
          Text={content.Text}
          Type={content.Type}
          File={content.File}
          Url={content.Url}
          Link={content.Link}
          typingTime={content.Time}
        />
      );
    });

    const btnContents = this.state.BotMsgs[ind].Condition
      ? null
      : this.state.BtnContents.map(
          function (content, i) {
            return (
              <Content
                Index={i}
                key={content.key || content._id}
                handleForContent={this.handleForContent}
                Bot={this.state.Bot}
                BtnUrl={content.Url}
                BtnType={content.Type}
                onDelete={this.deleteContent}
                Text={content.Name}
                Type={"Btn"}
                BtnPosition={content.Position}
                id={content._id}
                Url={content.Redirect}
                BotMsg={this.state.BotMsg}
                BotMsgs={this.state.AllMsgs}
                typingTime={content.Time}
              />
            );
          }.bind(this)
        );

    const condContents =
      this.state.BotMsgs[ind].Condition && this.state.CondContents
        ? this.state.CondContents.map(
            function (content, i) {
              return (
                <Content
                  Index={i}
                  key={content._id}
                  handleForContent={this.handleForContent}
                  SimpleMode={content.SimpleMode}
                  SimpleAttr={content.SimpleAttr}
                  SimpleCond={content.SimpleCond}
                  SimpleValue={content.SimpleValue}
                  BtnUrl={content.Url}
                  BtnType={content.Type}
                  onDelete={this.deleteContent}
                  Text={content.Name}
                  Type={"Cond"}
                  id={content._id}
                  Url={content.Redirect}
                  Cond={content.Condition}
                  CondRedirect={content.Redirect}
                  BotMsg={this.state.BotMsg}
                  BotMsgs={this.state.AllMsgs}
                  Bot={this.state.Bot}
                  typingTime={content.Time}
                />
              );
            }.bind(this)
          )
        : null;

    const defaultLink = (
      <Content
        handleForContent={this.handleForContent}
        Type={"DefaultLink"}
        Url={link}
        key={"defaultLink"}
        BotMsg={this.state.BotMsg}
        BotMsgs={this.state.AllMsgs}
      />
    );

    //                    <label htmlFor="enable_filter_2">Send data to api right after message was received</label>
    const isBasicMessageBlock = msg && !msg.Condition && !msg.Type
    const showWaitAnswer = isBasicMessageBlock;
    const showTelegramMarkdown = isBasicMessageBlock && s.Bot && s.Bot.BindTo == "Telegram";
    const showImageAndTextInOneMessage = isBasicMessageBlock &&
        s.Bot && s.Bot.BindTo == "Telegram" && !!imgContents.length;
    const showWaitCRM =
      this.props.user &&
      isBasicMessageBlock &&
      this.props.user &&
      this.props.user.Tariff &&
      this.props.user.Tariff.CRM &&
      false;
    const showText = isBasicMessageBlock;
    const showKeyboard = isBasicMessageBlock && msg.Keyboard;

    if (!msg.Api && msg.Type == "Api") {
      msg.Api = {
        ReqType: "Get",
        Url: "",
        Headers: [],
        Field: [],
        Save: [],
        SaveFile: false,
        Unzip: false,
      };
    }
    if (msg.Api) {
      //                             onBlur={(ev)=>this.handleClick(ev,e)}

      var hdrs = msg.Api.Headers.map((e, i) => {
        return (
          <div className={"row__grid"}>
            <div className="col__5">
              <input
                type="text"
                className="chat_api"
                name={"BotMsgs[" + ind + "].Api.Headers[" + i + "].Key"}
                placeholder={dict.bvyrfovw}
                value={e.Key}
                onChange={this.change}
              />
            </div>

            <div className="col__5">
              {/*<input type="text" className="chat_api " name={"BotMsgs["+ind+"].Api.Headers["+i+"].Value"}*/}
              {/*placeholder="Context" value={e.Value} onChange={this.change}/>*/}

              <div
                className={
                  e.condition
                    ? "inputGroup inputGroup1 focusWithText"
                    : "inputGroup inputGroup1"
                }
                onFocus={(ev) => this.handleClick(ev, e, false, 1)}
                onBlur={(ev) => this.handleClick(ev, e, false, 1)}
              >
                <input
                  type="text"
                  className="chat_api "
                  name={"BotMsgs[" + ind + "].Api.Headers[" + i + "].Value"}
                  value={e.Value}
                  onChange={(ev) => {
                    this.change(ev);
                    this.handleClick(ev, e, ev.target.value == "", 1);
                  }}
                  placeholder={e.Const ? dict.jtxnloae : ""}
                />
                {!e.Const && (e.condition || e.Value == "") ? (
                  <p className={"helper helper1"}>{"Context."}</p>
                ) : null}
              </div>
            </div>

            {/*Здесь был текст "Это константа*/}
            {/*<button className={''} onClick={(event)=>{event.preventDefault();*/}
            {/*s.BotMsgs[ind].Api.Headers.splice(i,1);this.setState({BotMsgs:s.BotMsgs})}}>Удалить</button>*/}
            <div className="grid__api--delete">
              <button
                onClick={(event) => {
                  event.preventDefault();
                  s.BotMsgs[ind].Api.Headers.splice(i, 1);
                  this.setState({ BotMsgs: s.BotMsgs });
                }}
                type="button"
                className="close"
                data-dismiss="alert"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
        );
      });
      var saves = msg.Api.Save.map((e, i) => {
        return (
          <div className={"row__grid"}>
            <div className="col__5">
              <div
                className={
                  e.condition
                    ? "inputGroup inputGroup1 focusWithText"
                    : "inputGroup inputGroup1"
                }
                onFocus={(ev) => this.handleClick(ev, e, false, 0)}
                onBlur={(ev) => this.handleClick(ev, e, false, 0)}
              >
                <input
                  type="text"
                  className="chat_api"
                  name={"BotMsgs[" + ind + "].Api.Save[" + i + "].Key"}
                  value={e.Key}
                  onChange={(ev) => {
                    this.change(ev);
                    this.handleClick(ev, e, ev.target.value == "", 0);
                  }}
                />
                {e.condition || e.Key == "" ? (
                  <p className={"helper helper1"}>{"Context."}</p>
                ) : null}
              </div>
            </div>
            <div className="col__5">
              <input
                type="text"
                className="chat_api"
                name={"BotMsgs[" + ind + "].Api.Save[" + i + "].Value"}
                value={e.Value}
                placeholder={this.getApiSaveValuePlaceholder(msg, e)}
                onChange={this.change}
              />
            </div>
            <div className="grid__api--delete">
              <button
                onClick={(event) => {
                  event.preventDefault();
                  s.BotMsgs[ind].Api.Save.splice(i, 1);
                  this.setState({ BotMsgs: s.BotMsgs });
                }}
                type="button"
                className="close"
                data-dismiss="alert"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            {/*<button className={''} onClick={(event)=>{event.preventDefault();*/}
            {/*s.BotMsgs[ind].Api.Save.splice(i,1);this.setState({BotMsgs:s.BotMsgs})}}>Удалить</button>*/}
          </div>
        );
      });

      var fields = msg.Api.Field.map((e, i) => {
        return (
          <div className={"row__grid"}>
            <div className="col__5">
              <input
                type="text"
                className="chat_api"
                name={"BotMsgs[" + ind + "].Api.Field[" + i + "].Key"}
                placeholder={dict.bvyrfovw}
                value={e.Key}
                onChange={this.change}
              />
            </div>

            <div className="col__5">
              <div
                className={
                  e.condition
                    ? "inputGroup inputGroup1 focusWithText"
                    : "inputGroup inputGroup1"
                }
                onFocus={(ev) => this.handleClick(ev, e, false, 1)}
                onBlur={(ev) => this.handleClick(ev, e, false, 1)}
              >
                <input
                  type="text"
                  className="chat_api"
                  name={"BotMsgs[" + ind + "].Api.Field[" + i + "].Value"}
                  value={e.Value}
                  onChange={(ev) => {
                    this.change(ev);
                    this.handleClick(ev, e, ev.target.value == "", 1);
                  }}
                  placeholder={e.Const ? dict.jtxnloae : ""}
                />
                {!e.Const && (e.condition || e.Value == "") ? (
                  <p className={"helper helper1"}>{"Context."}</p>
                ) : null}
              </div>
            </div>

            {/*<button  onClick={(event)=>{event.preventDefault();*/}
            {/*s.BotMsgs[ind].Api.Field.splice(i,1);this.setState({BotMsgs:s.BotMsgs})}}>Удалить</button>*/}
            <div className="grid__api--delete">
              <button
                onClick={(event) => {
                  event.preventDefault();
                  s.BotMsgs[ind].Api.Field.splice(i, 1);
                  this.setState({ BotMsgs: s.BotMsgs });
                }}
                type="button"
                className="close"
                data-dismiss="alert"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
        );
      });
    }
    var main_block_info = dict.standart_block_info;
    switch (msg.Type) {
      case "Api":
        main_block_info = dict.api_block_info;
        break;
      case "CRM":
        main_block_info = dict.crm_block_info;
        break;
      case "CRMoutput":
        main_block_info = dict.crmo_block_info;
        break;
      case "DeepLink":
        main_block_info = dict.DL_block_info;
        break;
      case "RTC":
        main_block_info = dict.rtc_block_info;
        break;
      case "Process":
        main_block_info = dict.var_block_info;
        break;
      case "Location":
        main_block_info = dict.loc_block_info;
        break;
      case "Telegram Payment":
        main_block_info = dict.pay_block;
        break;
    }
    if (msg.Condition) {
      main_block_info = dict.cond_block_info;
    }
    if (msg.Keyboard) {
      var keyboard_buttons = msg.Keyboard.map((elem, ind2) => {
        const e = elem;
        const i = ind2;

        var row_btns = e.map((elem2, ind3) => {
          const e2 = elem2;
          const i2 = ind3;
          return (
            <div className={"row__grid w-100 mb10"}>
              <input
                className={"input_form w-100 mb_2"}
                type={"text"}
                name={
                  "BotMsgs[" + ind + "].Keyboard[" + i + "][" + i2 + "].text"
                }
                onChange={this.change2}
                value={e[i2].text}
              />

              <div className={"row__grid w-100"}>
                {s.Bot.BindTo === "Vk" ? (
                  <div className="mb_2 col__8">
                    <div className={"select__block--keyboard"}>
                      <select
                        className={"input_form"}
                        name={
                          "BotMsgs[" +
                          ind +
                          "].Keyboard[" +
                          i +
                          "][" +
                          i2 +
                          "].vk_type"
                        }
                        onChange={this.change2}
                        value={e[i2].vk_type}
                      >
                        <option></option>
                        <option value={"primary"}>Primary #5181B8</option>
                        <option value={"default"}>Default #FFFFFF</option>
                        <option value={"negative"}>Negative #E64646</option>
                        <option value={"positive"}>Positive #4BB34B</option>
                      </select>
                    </div>
                  </div>
                ) : null}

                {s.Bot.BindTo === "Telegram" ? (
                  <div className="mb_2 col__8">
                    <div className={"select__block--keyboard"}>


                      <div className={"wrap_filter_laber"}>

                        <TooltipTitle
                          idTooltip="i_request_phone"
                          name="Запросить телефон"
                          tooltipDescription={dict.request_phone_info}
                        />

                        <input
                          type="checkbox"
                          name={
                            "BotMsgs[" +
                            ind +
                            "].Keyboard[" +
                            i +
                            "][" +
                            i2 +
                            "].request_contact"
                          }
                          onChange={this.changeTelegramKeyboardButtonOption}
                          checked={e[i2].request_contact || false}
                        />
                      </div>

                      <div className={"wrap_filter_laber"}>

                        <TooltipTitle
                          idTooltip="i_request_phone"
                          name={dict.request_location}
                          tooltipDescription={dict.request_location_info}
                        />

                        <input
                          type="checkbox"
                          name={
                            "BotMsgs[" +
                            ind +
                            "].Keyboard[" +
                            i +
                            "][" +
                            i2 +
                            "].request_location"
                          }
                          onChange={this.changeTelegramKeyboardButtonOption}
                          checked={e[i2].request_location || false}
                        />
                      </div>
                    </div>
                  </div>
                ) : null}

                <div className="col__2 justify__content--end row__grid">
                  <div style={{ width: "100%" }}>
                    <button
                      style={{ width: "100%" }}
                      className={"btn__all--small"}
                      onClick={(event) => this.delKeyboardBtn(event, e, i, i2)}
                    >
                      <i className="fas fa-trash-alt" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          );
        });

        return (
          <div className={"row__grid mb5 pl borderR mb5"}>
            {row_btns}

            {s.Bot.BindTo !== 'WhatsApp' && (
                <button
                    className={"btn__all--small"}
                    onClick={(event) => this.addKeyboardBtn(event, i)}
                >
                  <i className="fas fa-plus" />
                </button>
            )}
          </div>
        );
      });
    }

    const showSaveFileOption = msg.Api &&
        (['WhatsApp', 'Telegram'].includes(s.Bot.BindTo) || msg.Api.SaveFile)
        && !msg.Api.Unzip && !msg.Api.ParseXmlFile;

    const showUnzipOption = msg.Api && ['Telegram'].includes(s.Bot.BindTo)
        && !msg.Api.SaveFile && !msg.Api.ParseXmlFile;

    const showParseXmlFileOption = msg.Api && ['Telegram'].includes(s.Bot.BindTo)
        && !msg.Api.SaveFile && !msg.Api.Unzip;

    return (
      <div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            this.saveBotMsg(this.state.BotMsg);
          }}
        >
          <div className="mb-3">
            <TooltipTitle
              idTooltip="i_WhatDo"
              name="Что делает этот блок"
              place="bottom"
              tooltipDescription={main_block_info}
            />
          </div>

          <TooltipTitle
            idTooltip="i_Name"
            name="Имя"
            tooltipDescription={`${dict.clunyxda} ${dict.izdflbct}`}
          />

          <input
            className="borderR chat_input mb10"
            id="rightName"
            type="text"
            onChange={this.handleInputChange}
            value={this.state.BotMsgName}
            name="BotMsgName"
          />

          <div className="wrap_filter_laber">
            {this.state.BotMsgs[ind] ? (
              <TooltipTitle
                idTooltip="enable_filter_2"
                name={dict.raartyoz}
                tooltipDescription={`${dict.awkyyvmy} ${dict.vcxxlmod} ${dict.hrmgynvf}`}
              />
            ) : null}

            {this.state.BotMsgs[ind] ? (
              <input
                id="enable_filter_2"
                type={"checkbox"}
                checked={this.state.BotMsgs[ind].Start}
                onChange={this.changeStart}
              />
            ) : null}
          </div>
          {msg.Type === "Api" ? (
            <div className="wrap_filter_laber">
              <TooltipTitle
                idForInput="i_WaitApi"
                name={dict.yhfuxnsf}
                tooltipDescription={`${dict.quuctsxo} ${dict.zkchxano}`}
              />

              <input
                id="service_out"
                type={"checkbox"}
                checked={msg.WaitApi}
                name={"BotMsgs[" + ind + "].WaitApi"}
                onChange={this.change}
              />
            </div>
          ) : null}

          {msg.Type === "CRM" ? (
            <div className="wrap_filter_laber">
              <TooltipTitle
                idForInput="i_CRMexist"
                name="Дополнить запись о пользователе"
                tooltipDescription={`${dict.exist_crm_order_d1} ${dict.exist_crm_order_d2}`}
              />

              <input
                id="i_CRMexist"
                type={"checkbox"}
                checked={msg.CRMaddToExistRow}
                name={"BotMsgs[" + ind + "].CRMaddToExistRow"}
                onChange={this.change}
              />
            </div>
          ) : null}

          <div className="wrap_filter_laber">
            {showWaitAnswer ? (
              <TooltipTitle
                idForInput="i_WaitAnswer"
                name={dict.yokwtfih}
                tooltipDescription={`${dict.nwrcxdcj} ${dict.kdijadmk} (Context.${msg.AnswerName} ${dict.swdklufb} ${dict.frfexhoc}`}
              />
            ) : null}
            {showWaitAnswer ? (
              <input
                id="i_WaitAnswer"
                type={"checkbox"}
                checked={this.state.BotMsgs[ind].WaitAnswer}
                onChange={this.changeWaitAnswer}
              />
            ) : null}
          </div>

          {showTelegramMarkdown ? (
            <div className="wrap_filter_laber">

              <TooltipTitle
                name="Включить"
                idTooltip="i_TelegramMarkdown"
                isLink={
                  <a
                    href={"https://core.telegram.org/bots/api#markdown-style"}
                    target={"_blank"}>
                  markdown
                </a>}

                tooltipDescription={dict.telegram_markdown_info}

              />

              <input
                id="i_TelegramMarkdown"
                type={"checkbox"}
                checked={this.state.BotMsgs[ind].TelegramMarkdown}
                onChange={this.changeTelegramMarkdown}
              />
            </div>
          ) : null}

          {showImageAndTextInOneMessage ? (
              <div className="wrap_filter_laber">
                <label className="styles_tooltip_name__2Cl">
                  Картинка и текст в одном сообщении
                </label>

                <input
                    id="i_ImageAndTextInOneMessage"
                    type={"checkbox"}
                    checked={!!this.state.BotMsgs[ind].ImageAndTextInOneMessage}
                    onChange={this.changeImageAndTextInOneMessage}
                />
              </div>
          ) : null}

          <div className="wrap_filter_laber">
            {showWaitCRM ? (
              <label htmlFor="save_crm">{dict.rglvmgbv}</label>
            ) : null}
            {showWaitCRM ? (
              <input
                id="save_crm"
                type={"checkbox"}
                checked={msg.CRM}
                name={"BotMsgs[" + ind + "].CRM"}
                onChange={this.change}
              />
            ) : null}
          </div>

          {this.state.BotMsgs[ind] && this.state.BotMsgs[ind].WaitAnswer ? (
            <div>
              <div className={"wrap_filter_laber"}>

                <TooltipTitle
                  idForInput="i_Trim"
                  name="Обрезать пробелы по краям"
                  tooltipDescription="Когда бот получит ответ пользователя,
                  перед сохранением он обрежет пробелы по краям"
                />

                <input
                  id="i_Trim"
                  type={"checkbox"}
                  name={"BotMsgs[" + ind + "].Trim"}
                  checked={this.state.BotMsgs[ind].Trim}
                  onChange={this.change}
                />
              </div>

              <label className="mt_15" htmlFor="param">
                <span>Context</span>{" "}
              </label>
              <input
                className="borderR chat_input mb6"
                id="param"
                type="text"
                onChange={this.changeAnswerName}
                value={this.state.BotMsgs[ind].AnswerName}
              />
            </div>
          ) : null}
          {showKeyboard ? (
            <div>

              <TooltipTitle
                idForInput="i_Keyboard"
                name="Клавиатура"
                tooltipDescription="В telegram и whatsapp несовместима с обычными кнопками.
                У названий кнопок клавиатуры есть ограничение на длину
                символов(примерно 20 русских букв или 40 латинских букв,цифр)"
              />

              {keyboard_buttons}
              <button
                className={"btn__all--small mt_5"}
                onClick={(e) => this.addKeyboardBtn(e)}
              >
                <i className="fas fa-plus"/>
              </button>
            </div>
          ) : null}
          {showKeyboard && s.Bot.BindTo === 'Telegram' && (
              <div>
                <div className={"wrap_filter_laber"}>

                  <TooltipTitle
                      idForInput="i_OneTimeKeyboard"
                      name="Скрыть клавиатуру после ответа"
                      tooltipDescription="Когда пользователь использует один из ответов
                       предложенных в клавиатуре,клавиатуре будет скрыта"
                  />

                  <input
                      id="i_OneTimeKeyboard"
                      type={"checkbox"}
                      name={"BotMsgs[" + ind + "].OneTimeKeyboard"}
                      checked={this.state.BotMsgs[ind].OneTimeKeyboard}
                      onChange={this.change}
                  />
                </div>
              </div>
          )}
          {showText ? txtContents : null}
          {msg &&
          !(msg.Condition && true) &&
          !s.hideDefaultLink &&
          msg.Type != "RTC" ? (
            <div className="test">{defaultLink}</div>
          ) : null}
          {msg.Type == "Location" ? this.LocationContent(ind) : null}
          {msg.Type == "Telegram Payment"
            ? this.TelegramPaymentContent(ind)
            : null}
          {msg.Type == "CRM" ? this.CRMcontent(ind) : null}
          {msg.Type == "CRMoutput" ? this.CRMOutputContent(ind) : null}
          {msg.Type == "DeepLink" ? this.DeepLinkContent(ind) : null}
          {msg.Type == "Process" ? this.ProcessContent(ind) : null}
          {msg.Api ? (
            <div>
              <TooltipTitle
                idTooltip="i_ApiUrl"
                name={dict.drlometr}
                tooltipDescription={`${dict.egtfhezt}`}
              />

              <input
                type="text"
                className="chat_input"
                name={"BotMsgs[" + ind + "].Api.Url"}
                placeholder="url"
                value={msg.Api.Url}
                onChange={this.change}
              />

              <TooltipTitle
                idTooltip="i_ApiReqType"
                name={dict.cvotwwbt}
                tooltipDescription={`${dict.ijgzhtus}`}
              />

              <select
                className="chat_input"
                name={"BotMsgs[" + ind + "].Api.ReqType"}
                value={msg.Api.ReqType}
                onChange={this.change}
              >
                <option value="Get">get</option>
                <option value="Post">post</option>
              </select>

              <div className={"form-group"}>

                <TooltipTitle
                  idTooltip="i_ApiHeaders"
                  name="Заголовки"
                  tooltipDescription="Заголовки которые будут переданы в http запрос.
                  Слева название заголовка,справа значение заголовка."
                />

                {hdrs}
                <div className="row__grid">
                  <button
                    className={"grid__btn--api col__5 grid__br--2"}
                    onClick={(e) => this.addRowToApi("Headers", ind, false, e)}
                  >
                    {dict.gssxwuii}
                  </button>
                  <button
                    className={"grid__btn--api col__5 grid__bl--2"}
                    onClick={(e) => this.addRowToApi("Headers", ind, true, e)}
                  >
                    {dict.tzhgiyco}
                  </button>
                </div>
              </div>

              <div className={"form-group"}>

                <TooltipTitle
                  idTooltip="i_ApiParams"
                  name="Параметры"
                  tooltipDescription="Параметры которые будут переданы в http запрос.
                  Слева название параметра,справа значение параметра."
                />

                {fields}

                <div className="row__grid">
                  <button
                    className={"grid__btn--api col__5 grid__br--2"}
                    onClick={(e) => this.addRowToApi("Field", ind, false, e)}
                  >
                    {dict.gssxwuii}
                  </button>
                  <button
                    className={"grid__btn--api col__5 grid__bl--2"}
                    onClick={(e) => this.addRowToApi("Field", ind, true, e)}
                  >
                    {dict.tzhgiyco}
                  </button>
                </div>
              </div>

              <div className={"form-group"}>
                <span className={"heading__caption_1"
                + ((!showSaveFileOption && !showUnzipOption) ? ' mb20' : '')}>
                  <TooltipTitle
                    idTooltip="i_ApiSaves"
                    name="Сохранение результатов"
                    tooltipDescription={`Данные которые вы получите от ответа на http запрос(должны быть в формате json)
                    вы можете сохранить в Context переменные.
                      Слева имя Context переменной,справа путь к данным которые хотите сохранить.
                      В описании пути можно использовать Context переменные например так:
                    [{{Context.i}}].Result.Text если
                    в Context.i сохранено значение 0 мы получим такой путь [0].Result.Text). `}
                  />
                </span>

                {showSaveFileOption && (
                    <div className={"wrap_filter_laber mb20"}>
                      <TooltipTitle
                          idForInput="i_SaveFile"
                          name="Сохранить ссылку на файл из ответа"
                          tooltipDescription="Если ответ апи запроса содержит файл, ссылка
                      на него будет сохранена в Context переменную. Вы также можете указать расширение в котором хотите сохранить файл"
                      />

                      <input
                          id="i_SaveFile"
                          type={"checkbox"}
                          checked={this.state.BotMsgs[ind].Api.SaveFile || false}
                          onChange={e => this.onCheckSaveFileFromApi(ind, e)}
                      />
                    </div>
                )}

                {showUnzipOption && (
                    <div className={"wrap_filter_laber mb20"}>
                      <TooltipTitle
                          idForInput="i_Unzip"
                          name="Извлечь и сохранить файлы из архива"
                          tooltipDescription="Если ответ апи запроса содержит архив с файлами, ссылки
                      на выбранные файлы будут сохранены в Context переменные"
                      />

                      <input
                          id="i_Unzip"
                          type={"checkbox"}
                          checked={this.state.BotMsgs[ind].Api.Unzip || false}
                          onChange={e => this.onCheckUnzipApi(ind, e)}
                      />
                    </div>
                )}

                {showParseXmlFileOption && (
                    <div className={"wrap_filter_laber mb20"}>
                      <TooltipTitle
                          idForInput="i_ParseXmlFile"
                          name="Извлечь и сохранить данные из xml файла"
                          tooltipDescription="Если ответ апи запроса содержит xml файл, данные
                      будут преобразованы в json формат и сохранены в Context переменные.
                       Вам нужно указать пути к данным таким же образом как и при работе с json ответом"
                      />

                      <input
                          id="i_ParseXmlFile"
                          type={"checkbox"}
                          checked={this.state.BotMsgs[ind].Api.ParseXmlFile || false}
                          onChange={e => this.onCheckParseXmlFileApi(ind, e)}
                      />
                    </div>
                )}

                {saves}

                {!this.state.BotMsgs[ind].Api.SaveFile && (
                    <div className="row__grid">
                      {this.state.BotMsgs[ind].Api.Unzip && (
                          <button className={'grid__btn--api col__10 grid__bl--2'} onClick={(e)=>this.addRowToApi("Save",ind,true,e)}>
                            Добавить название файла
                          </button>
                      )}
                      {this.state.BotMsgs[ind].Api.Unzip && (
                          <button className={'grid__btn--api col__10 grid__bl--2'} onClick={(e)=>this.addRowToApi("Save",ind,true,e, true)}>
                            Добавить расширение файла
                          </button>
                      )}
                      {!this.state.BotMsgs[ind].Api.Unzip && (
                          <button
                              className={"grid__btn--api col__10 grid__bl--2"}
                              onClick={(e) => this.addRowToApi("Save", ind, false, e)}
                          >
                            {dict.gssxwuii}
                          </button>
                      )}
                  </div>
                )}
              </div>
            </div>
          ) : null}
          <div>{imgContents}</div>
          {productImage}
          {fileContents}

          <div className="right_video mt_c15">{videoContents}</div>
          <div className="audio"> {audioContents}</div>

          {btnContents}
          {condContents}

          {this.AddItems()}
        </form>
      </div>
    );
  }

  AddItems() {
    var b = this.state.Bot ? this.state.Bot.BindTo : "-";
    var cond = false;
    var type = false;
    for (var i = 0; i < this.state.BotMsgs.length; i++) {
      if (this.state.BotMsg == this.state.BotMsgs[i]._id) {
        cond = this.state.BotMsgs[i].Condition;
        type = this.state.BotMsgs[i].Type;
      }
    }
    var showBtn = !cond && !type;
    var showVideo = !(b == "Ok" || b === 'WhatsApp' || b == "-") && !cond && !type;
    var showAudio = !(b == "Ok" || b === 'WhatsApp' || b == "-") && !cond && !type;
    var showFile = (b == "Vk" || b === "Telegram" || !b) && !cond && !type;
    var showImage = (b !== 'WhatsApp') && !cond && !type;
    var showProductImage = type === "Telegram Payment";
    var showKeyboard =
      (b == "Telegram" || b == "Vk" || b == "FB" || b === 'WhatsApp' || !b) && !cond && !type;
    return (
      <div>
        {!type ? (
          <TooltipTitle
            idTooltip="i_addItems"
            name="Добавить элементы"
            tooltipDescription="Дополнительные элементы которые будут отправлены вместе с сообщением."
          />
        ) : null}

        <div className="list_button mt_5 mb5">
          {showVideo ? (
            <div>
              {/*  <input type="file" accept="video/*" onChange={this.addVideoContent} onClick={this.clearInput} className="form-control-file text-primary add_video"  data-title={dict.dawkvfla}/>*/}
              <label
                htmlFor={"video"}
                className="ant-upload ant-upload-select ant-upload-select-text mr2"
              >
                <span className="ant-upload">
                  <input
                    id={"video"}
                    style={{ display: "none" }}
                    type="file"
                    accept="video/*"
                    onChange={this.addVideoContent}
                    onClick={this.clearInput}
                  />

                  <span className="ant-btn">
                    <i className="fas fa-video"></i>
                    <span>
                      <font style={{ verticalAlignment: "inherit" }}>
                        <font style={{ verticalAlignment: "inherit" }}>
                          {dict.dawkvfla}
                        </font>
                      </font>
                    </span>
                  </span>
                </span>
              </label>
            </div>
          ) : null}

          {showAudio ? (
            <div>
              {/*<input type="file" accept="audio/*" onChange={this.addAudioContent} onClick={this.clearInput}  className="form-control-file text-primary " data-title={dict.ezuzsdrs} />*/}
              <label
                htmlFor={"audio"}
                className="ant-upload ant-upload-select ant-upload-select-text mr2"
              >
                <span className="ant-upload">
                  <input
                    style={{ display: "none" }}
                    id={"audio"}
                    type="file"
                    accept="audio/*"
                    onChange={this.addAudioContent}
                    onClick={this.clearInput}
                  />
                  <span className="ant-btn">
                    <i className="fas fa-file-audio" />
                    <span>
                      <font style={{ verticalAlignment: "inherit" }}>
                        <font style={{ verticalAlignment: "inherit" }}>
                          {dict.ezuzsdrs}
                        </font>
                      </font>
                    </span>
                  </span>
                </span>
              </label>
            </div>
          ) : null}

          {showImage ? (
            <div>
              {/*  <input type="file" accept="image/*" onChange={this.addImageContent} onClick={this.clearInput}   className="form-control-file text-primary " data-title={dict.cwxfkdos} />*/}

              <label
                htmlFor={"image"}
                className="ant-upload ant-upload-select ant-upload-select-text mr2"
              >
                <span className="ant-upload">
                  <input
                    style={{ display: "none" }}
                    id={"image"}
                    type="file"
                    accept="image/*"
                    onChange={this.addImageContent}
                    onClick={this.clearInput}
                  />
                  <span className="ant-btn">
                    <i className="fas fa-image"></i>
                    <span>
                      <font style={{ verticalAlignment: "inherit" }}>
                        <font style={{ verticalAlignment: "inherit" }}>
                          {dict.cwxfkdos}
                        </font>
                      </font>
                    </span>
                  </span>
                </span>
              </label>
            </div>
          ) : null}

          {showProductImage ? (
            <div>
              <label
                htmlFor={"image"}
                className="ant-upload ant-upload-select ant-upload-select-text mr2"
              >
                <span className="ant-upload">
                  <input
                    style={{ display: "none" }}
                    id={"image"}
                    type="file"
                    accept="image/*"
                    onChange={this.loadProductImage}
                    onClick={this.clearInput}
                  />
                  <span className="ant-btn">
                    <i className="fas fa-image" />
                    <span>
                      <font style={{ verticalAlignment: "inherit" }}>
                        <font style={{ verticalAlignment: "inherit" }}>
                          {dict.cwxfkdos}
                        </font>
                      </font>
                    </span>
                  </span>
                </span>
              </label>
            </div>
          ) : null}
        </div>
        <div className="list_button p0">
          {showFile ? (
            <div>
              {/* <input type="file" onChange={this.addFileContent} onClick={this.clearInput}  className="form-control-file text-primary " data-title={dict.icklsgwu} />*/}
              <label
                htmlFor={"File"}
                className="ant-upload ant-upload-select ant-upload-select-text mr2"
              >
                <span className="ant-upload">
                  <input
                    style={{ display: "none" }}
                    id={"File"}
                    type="file"
                    onChange={this.addFileContent}
                    onClick={this.clearInput}
                  />
                  <span className="ant-btn">
                    <i className="far fa-file"></i>
                    <span>
                      <font style={{ verticalAlignment: "inherit" }}>
                        <font style={{ verticalAlignment: "inherit" }}>
                          {dict.icklsgwu}
                        </font>
                      </font>
                    </span>
                  </span>
                </span>
              </label>
            </div>
          ) : null}

          {showBtn ? (
            <div>
              {/* <span className="form-control-file text-primary " data-title={dict.arezkgyu}  onClick={this.addBtnContent} >{dict.arezkgyu}</span>*/}

              <label
                className="ant-upload ant-upload-select ant-upload-select-text mr2"
                onClick={this.addBtnContent}
              >
                <span className="ant-upload">
                  <span className="ant-btn">
                    <span>
                      <font style={{ verticalAlignment: "inherit" }}>
                        <font style={{ verticalAlignment: "inherit" }}>
                          {dict.arezkgyu}
                        </font>
                      </font>
                    </span>
                  </span>
                </span>
              </label>
            </div>
          ) : null}
          {cond ? (
            <div className="form-group inputDnD">
              {/*    <span className="form-control-file text-primary " data-title={dict.cbnmskkf}  onClick={this.addCondContent} >{dict.cbnmskkf}</span>*/}
              <label
                className="ant-upload ant-upload-select ant-upload-select-text mr2"
                onClick={this.addCondContent}
              >
                <span className="ant-upload">
                  <span className="ant-btn">
                    <span>
                      <font style={{ verticalAlignment: "inherit" }}>
                        <font style={{ verticalAlignment: "inherit" }}>
                          {dict.cbnmskkf}
                        </font>
                      </font>
                    </span>
                  </span>
                </span>
              </label>
            </div>
          ) : null}
          {showKeyboard ? (
            <div>
              <div>
                <label
                  className="ant-upload ant-upload-select ant-upload-select-text mr2"
                  onClick={this.addKeyboard}
                >
                  <span className="ant-upload">
                    <span className="ant-btn">
                      <span>
                        <font style={{ verticalAlignment: "inherit" }}>
                          <i className="far fa-keyboard"></i>
                          <font style={{ verticalAlignment: "inherit" }}>
                            {dict.smgbhjnw}
                          </font>
                        </font>
                      </span>
                    </span>
                  </span>
                </label>
              </div>
            </div>
          ) : null}
        </div>

        <div className="form_group_right_panel mt_5">
          <button type={"submit"} className="btnSave">
            {dict.gufqkjeg}
          </button>

          <button
            onClick={() =>
              this.setState({ BotMsg: "-1" }, () =>
                this.setState({
                  TextContents: [],
                  ImageContents: [],
                  FileContents: [],
                  AudioContents: [],
                  VideoContents: [],
                })
              )
            }
            className="btnCansel"
          >
            {dict.tkkxrjuh}
          </button>
        </div>
      </div>
    );
  }

  /*{End edit panel}*/

  copyMsg = (msg) => {
    var id = msg._id;
    var url = config.Backend + "/botMsg/copy?";
    if (!(cookies.get("Id") === undefined)) {
      url += "CurrentUserId=" + cookies.get("Id").toString();
    }
    url += "&Id=" + id;
    this.setState({ Wait: ++this.state.Wait });

    jQuery.getJSON(
      url,
      function (res) {
        this.setState({ Wait: --this.state.Wait });
        if (!res.Error) {
          this.setupBotMsg(res.BotMsg);
          this.addContentToAllContent(res.Content);
        } else {
          alert(JSON.stringify(res));
        }
      }.bind(this)
    );
  };

  Canva() {
    const msgs = this.state.BotMsgs.map((msg, i) => (
      <BotMsg
        x={msg.Pos.split("*")[0]}
        y={msg.Pos.split("*")[1]}
        key={msg._id}
        _id={msg._id}
        Name={msg.Name}
        Index={i}
        BotMsg={msg}
        onDelete={() => this.wantDeleteBotMsg(msg._id)}
        onCopy={() => {
          this.copyMsg(msg);
        }}
        loading={!this.state.AllContent}
        onEdit={() => this.chooseBlock(msg._id)}
        t={this.t}
        hideMsgName={this.state.hideMsgName}
        parentProps={this.props}
      />
    ));

    return (
      <SvgComponent
        dragLink={this.dragLink}
        isGroups={false}
        Bot={this.state.Bot}
        childrens={msgs}
        arr={this.state.BotMsgs}
        Refresh={this.refreshBotMsgs}
      />
    );
  }

  /*{End Canva block}*/

  refreshBotMsgs(id, newPos) {
    for (var i = 0; i < this.state.BotMsgs.length; i++) {
      if (id == this.state.BotMsgs[i]._id) {
        this.state.BotMsgs[i].Pos = newPos;
      }
    }
  }

  /*{End Setting RightBat}*/

  render() {
    var caption = "";
    for (var i = 0; i < (this.props.groups || []).length; i++) {
      if (this.props.groups[i]._id == this.state.Group) {
        caption += this.props.groups[i].Name;
        //caption+= "(#"+this.state.Group + ")";
      }
    }

    return (
      <div className={"test_pro"}>
        <CSSTransitionGroup
          transitionName="example"
          transitionEnterTimeout={500}
          transitionLeaveTimeout={500}
        >
          {this.state.Wait != 0 ? this.Load() : null}
        </CSSTransitionGroup>

        <PopupAll />

        <TopPanel Group={caption} />

        {this.RightBar()}

        {this.state.ShowDeleteGroupPopup ? (
          <Popup
            text={"Вы уверены??"}
            Yes={this.deleteGroup}
            No={() => {
              this.setState({ ShowDeleteGroupPopup: false });
            }}
          />
        ) : null}

        {this.state.ShowDeletebotMsgPopup ? (
          <Popup
            text={"Вы уверены?"}
            Yes={this.deleteBotMsg}
            No={() => {
              this.setState({ ShowDeletebotMsgPopup: false });
              this.setState({ BotMsg: "-1" });
            }}
          />
        ) : null}
        {this.state.ShowRenameGroupPopup ? (
          <Popup
            text={"Введите новое имя"}
            Yes={this.deleteBotMsg}
            Bot={this.state.Bot}
            Group={this.state.Group}
            RenameGroup={true}
            Hide={this.hideAllPopups}
            chooseGroup={this.chooseGroup}
            Name={this.getGroupName()}
            No={() => {
              this.setState({ ShowRenameGroupPopup: false });
            }}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
    groups: state.bots.groups[cookies.get("BotId").toString()],
    bot: (state.bots.bots || []).find(
      (bot) => bot._id === cookies.get("BotId").toString()
    ),
  };
};
const mapDispatchToProps = {
  addGroupsToStore: setGroups,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(chatBuild));
