import React from 'react';

class Tariffs extends React.Component {
  render() {
    var p = this.props;
    var tariff = p.elem;
    var i = p.ind;

    var onChangeHandler = (e) => p.onChangeHandler(e, i);
    var onDeleteHandler = () => p.onDeleteHandler(tariff._id, i);

    return (
      <tr>
        <td>
          <input
            className={"chat_input"}
            placeholder={"Название"}
            type={"text"}
            name={"Name"}
            value={tariff.Name}
            onChange={onChangeHandler}/>
        </td>

        <td>
          <input
            className={"chat_input"}
            placeholder={"Цена"}
            type={"number"}
            name={"Price"}
            value={tariff.Price}
            onChange={onChangeHandler}/>
        </td>

        <td>
          <input
            className={"chat_input"}
            placeholder={"Кол-во ботов"}
            type={"number"}
            name={"Cnt"}
            value={tariff.Cnt}
            onChange={onChangeHandler}/>
        </td>

        <td>
          <input
            className={"chat_input"}
            placeholder={"Длительность"}
            type={"text"}
            name={"Days"}
            value={tariff.Days}
            onChange={onChangeHandler}/>
        </td>

        <td>
          <input
            className={"chat_input"}
            type={"number"}
            name={"DayLimit"}
            value={tariff.DayLimit}
            onChange={onChangeHandler}/>
        </td>

        <td>
          <div className="wrap_filter_laber">
            <input
              type={"checkbox"}
              name={"Start"}
              checked={tariff.Start}
              onChange={onChangeHandler}/>
            {tariff.Deleted ? <span>Помечен для удаления</span> : null}
          </div>
        </td>

        <td>
          <div className="wrap_filter_laber">
            <input
              type={"checkbox"}
              name={"CRM"}
              checked={tariff.CRM}
              onChange={onChangeHandler}/>
          </div>
        </td>

        <td>
          <div className="wrap_filter_laber">
            <input
              type={"checkbox"}
              name={"Module"}
              checked={tariff.Module}
              onChange={onChangeHandler}/>
          </div>
        </td>

        <td>
          <div className="wrap_filter_laber">
            <input
              type={"checkbox"}
              name={"Copy"}
              checked={tariff.Copy}
              onChange={onChangeHandler}/>
          </div>
        </td>

        <td>
          <div className="wrap_filter_laber">
            <input
              type={"checkbox"}
              name={"EnableShare"}
              checked={tariff.EnableShare}
              onChange={onChangeHandler}/>
          </div>
        </td>

        <td>
          <div className="wrap_filter_laber">
            <input
              type={"checkbox"}
              name={"EnableBroadcast"}
              checked={tariff.EnableBroadcast}
              onChange={onChangeHandler}/>
          </div>
        </td>

        <td>
          <div className="wrap_filter_laber">
            <input
              type={"checkbox"}
              name={"EnableApiBLock"}
              checked={tariff.EnableApiBLock}
              onChange={onChangeHandler}/>
          </div>
        </td>

        <td>
          <div className="wrap_filter_laber">
            <input
              type={"checkbox"}
              name={"EnablePaymentBLock"}
              checked={tariff.EnablePaymentBLock}
              onChange={onChangeHandler}/>
          </div>
        </td>

        <td>
          <div className="wrap_filter_laber">
            <input
              type={"checkbox"}
              name={"EnableTelegramPaymentBLock"}
              checked={tariff.EnableTelegramPaymentBLock}
              onChange={onChangeHandler}/>
          </div>
        </td>

        <td>
          <div className="wrap_filter_laber">
            <input
              type={"checkbox"}
              name={"EnableConditionBLock"}
              checked={tariff.EnableConditionBLock}
              onChange={onChangeHandler}/>
          </div>
        </td>

        <td>
          <div className="wrap_filter_laber">
            <input
              type={"checkbox"}
              name={"EnableProcessBLock"}
              checked={tariff.EnableProcessBLock}
              onChange={onChangeHandler}/>
          </div>
        </td>

        <td>
          <div className="wrap_filter_laber">
            <input
              type={"checkbox"}
              name={"EnableDeepLinkBLock"}
              checked={tariff.EnableDeepLinkBLock}
              onChange={onChangeHandler}/>
          </div>
        </td>

        <td>
          <button className={"btn__all--small"} onClick={onDeleteHandler}>
            <i className="fas fa-trash-alt"></i>
          </button>
        </td>
      </tr>
    );
  }

}

export default Tariffs;
