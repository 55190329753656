import React from 'react';
import TextareaAutosize from 'react-autosize-textarea';
import TransComponent from '../../../TransComponent.js';

class FaqQuestion extends TransComponent {

  constructor(props) {
    super(props);
  }

  componentDidMount () {
    this.t_load();
  }

  render() {
    var p = this.props;
    var e = p.elem;
    var i = p.ind;

    var onChangeFaqQuestionHandler = (event) => p.onChangeFaqQuestionHandler(event, i);
    var onDeleteQuestionHandler = () => p.onDeleteQuestionHandler(e, i, "questions");
    var onChangeFaqAnswerHandler = (event) => p.onChangeFaqAnswerHandler(event, i);
    return (
      <div>
        <div className="row__grid form-group form-group bd pd-t-30 pd-b-20 pd-x-20">
          <div className="col__10">
            <div className="form-group">
              <span className={"fw__panel"}>{this.t("Вопрос")}</span>

              <div className="row__grid w-100">
                <div className="col__9">
                  <input className={"grid__input"} type={"text"} value={e.quest}
                         onChange={onChangeFaqQuestionHandler}/>
                </div>

                <div className="col__1">
                  <button className={"btn__all--small w-100"} style={{
                    borderLeft: "none"
                  }} onClick={onDeleteQuestionHandler}>
                    <i className="fas fa-trash-alt"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className={"w-100"}>
            <span className={"fw__panel"}>{this.t("Ответ")}</span>

            <div className="row__grid form-group">
              <div className="col__10">
                <TextareaAutosize className="chat_input mt_5" rows={3} maxRows={99}
                  value={e.answer} name="Headers" onChange={onChangeFaqAnswerHandler}>
                </TextareaAutosize>
              </div>
            </div>
          </div>
        </div>
      </div>);
  }

}

export default FaqQuestion;
