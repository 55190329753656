import React from 'react';
import TransComponent from '../../../TransComponent.js';

class RegOrder extends TransComponent {

  constructor(props) {
    super(props);
  }

  componentDidMount () {
    this.t_load();
  }

  render() {
    var p = this.props;
    var e = p.elem;
    var i = p.ind;

    var onShowDetailsHandler = () => p.onShowDetailsHandler(i);
    return (<tr>
      <td>{e.date}</td>

      <td>{e.time}</td>

      <td className={"tc"}>
        <button className={"btn__all--small"} onClick={onShowDetailsHandler}>
          {this.t("Показать")}
        </button>
      </td>
    </tr>);
  }

}

export default RegOrder;
