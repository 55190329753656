import React from 'react';
import TransComponent from '../../../TransComponent.js';
import SelectPlus from 'react-select-plus';
import ReactTooltip from 'react-tooltip';

class Reply extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount () {
    this.t_load();
  }

  render () {
    var p = this.props;
    var elem = p.elem;
    var ind = p.ind;
    var i = p.i;

    var options = [{
      label: '-',
      value: '-'
    }];

    var msgs = p.AllMsgs;

    for (var k in msgs) {
      var ind2 = -1;
      for (var j in options) {
        if (options[j].label == (msgs[k].Group.Name + '(#' + msgs[k].Group._id + ')')) {
          ind2 = j;
        }
      }

      if (ind2 == -1) {
        options.push({
          label: msgs[k].Group.Name + '(#' + msgs[k].Group._id + ')',
          options: [{
            label: msgs[k].Name + '(#' + msgs[k]._id + ')',
            value: msgs[k]._id
          }]
        });
      }

      else {
        options[ind2].options.push({
          label: msgs[k].Name + '(#' + msgs[k]._id + ')',
          value: msgs[k]._id
        });
      }
    }

    var onDeleteBlockHandler = () => p.onDeleteBlockHandler(i, ind);

    var onChangeReplyLinkHandler = (val) => p.onChangeReplyLinkHandler(i, ind, val);

    return (
      <div className={"row__grid grow_page conditions  mt_15 pt_15 mb15"}>
        <div className="pl w-100">
          <div className="row__grid">
            <button type="button" className="close" onClick={onDeleteBlockHandler}>
              <span aria-hidden="true">×</span>
            </button>
          </div>

          <div className="form-group mt_10">
            <label> {this.t("Если сообщение содержит значение")}
              <span data-tip data-for="i_value" className={"ml5"}>
                  <i className="fz15 far fa-question-circle"></i>
              </span>
            </label>

            <ReactTooltip id="i_value" type="light">
            <span>
            {this.t("Если исходящее сообщение содержит значение(регистр не учитывается) " +
              "вызовется этот блок обработки.")}
            </span>
            </ReactTooltip>

            <input
              className={"chat_input"}
              type={"text"}
              name={"Bot.Replies[" + i + "].Objs[" + ind + "].Value"}
              value={elem.Value}
              onChange={p.onChangeAndSaveHandler}/>
          </div>

          <div className="row__grid form-group">
            <label>{this.t("Сохранить его в переменную Context.")}
              <span
                data-tip data-for="i_context"
                className={"ml5"}>
                  <i className="fz15 far fa-question-circle"></i>
                </span>
            </label>

            <ReactTooltip id="i_context" type="light">
                <span>
                  {this.t("Сохраняет найденное значение в переменную контекста.")}
                  <br/>
                  {this.t("Напише имя переменной без Context.")}
                </span>
            </ReactTooltip>

            <input
              className={"chat_input"}
              type={"text"}
              placeholder={this.t("оставьте поле пустым если не хотите сохранять")}
              name={"Bot.Replies[" + i + "].Objs[" + ind + "].Param"} value={elem.Param}
              onChange={p.onChangeAndSaveHandler}/>
          </div>

          <div className={"w-100 form-group"}>
            <label>{this.t("Отправить сообщение")}
              <span data-tip data-for="i_reply_link" className={"ml5"}>
                  <i className="fz15 far fa-question-circle"></i>
              </span>
            </label>

            <div className={"row__grid aling-items-center"}>
              <ReactTooltip id="i_reply_link" type="light">
                  <span>
                    {this.t("Ссылка на сообщение которое нужно отправить после" +
                      " выполнения условия.")}
                  </span>
              </ReactTooltip>

              <SelectPlus
                className={"w-100"}
                value={elem.Link}
                placeholder={this.t("Выбрать")}
                onChange={onChangeReplyLinkHandler}
                options={options}/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Reply;
