import React from 'react';
import jQuery from 'jquery';
import AdminLeft from '../AdminLeft';
import {Scrollbars} from 'react-custom-scrollbars';
import Cookies from 'universal-cookie';
import Tariff from "./Tariff";
import {Link} from "react-router-dom";
import { config } from '../../config.js';

const cookies = new Cookies();

class Tariffs extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      User: {},
      Tariffs: []
    };

  }

  del = (id, i) => {
    if (id != -1) {
      var url = config.Backend + "/tariffs/delete?";
      url += '&CurrentUserId=' + cookies.get('Id');
      url += '&Id=' + id;

      jQuery.getJSON(url, function (res) {
        if (!res.Error) {
          this.state.Tariffs.splice(i, 1);
          this.setState({
            Tariffs: this.state.Tariffs
          });
        }
        else {
          alert(JSON.stringify(res));
        }
      }.bind(this));
    }
    else {
      this.state.Tariffs.splice(i, 1);
      this.setState({
        Tariffs: this.state.Tariffs
      });
    }
  }

  add = () => {
    this.state.Tariffs.push({
      _id: -1,
      Name: 'Новый тариф',
      Price: 500,
      Cnt: 1,
      Days: '1M',
      Start: false,
      Deleted: false,
      CRM: false,
      Module: false,
      Copy: false,
      EnableShare: false,
      EnableBroadcast: false,
      EnableApiBLock: false,
      EnablePaymentBLock: false,
      EnableTelegramPaymentBLock: false,
      EnableConditionBLock: false,
      EnableProcessBLock: false,
      EnableDeepLinkBLock: false,
    });
    this.setState({
      Tariffs: this.state.Tariffs
    });
  }

  save = () => {
    var a = this.state.Tariffs;
    for (var i = 0; i < a.length; i++) {
      var url = config.Backend + '/tariffs/';
      if (a[i]._id == -1) {
        url += 'add?';
      }
      else {
        url += 'edit?';
      }
      for (var key in a[i]) {
        if (key == '_id') {
          url += '&Id=' + a[i][key];
        }
        else {
          url += '&' + key + '=' + a[i][key];
        }
      }
      url += '&CurrentUserId=' + cookies.get('Id');
      jQuery.getJSON(url, function (res) {
        if (!res.Error) {
        }
        else {
          alert(JSON.stringify(res));
        }
      }.bind(this));
    }
    alert('Изменения успешно сохранены');
  }

  onChangeHandler = (event, i) => {
    this.state.Tariffs[i][event.target.name] = event.target.type != 'checkbox' ?
      event.target.value : (event.target.checked ? 1 : 0);
    this.setState({
      Tariffs: this.state.Tariffs,
    });
  }

  load = () => {
    var url = config.Backend + '/users/findById?';
    if (!(cookies.get('Id') === undefined)) {
      url += 'Id=' + cookies.get('Id').toString();
    }
    jQuery.getJSON(url, function (res) {
      if (!res.Error) {
        if (res.Type == 'Admin') {
          window.location.replace(window.location.origin + '/login');
        }
        this.setState({
          User: res
        }, () => {
          var url = config.Backend + '/tariffs/all?';
          if (!(cookies.get('Id') === undefined)) {
            url += '&CurrentUserId=' + cookies.get('Id').toString();
          }
          jQuery.getJSON(url, function (res) {
            if (!res.Error) {
              this.setState({
                Tariffs: res
              });
            } else {
              alert(JSON.stringify(res));
            }
          }.bind(this));
        });
      } else {
        window.location.replace(window.location.origin + '/login');
      }
    }.bind(this));
  }

  componentDidMount () {
    this.load();
  }

  TList () {
    var tariffs;
    if (this.state.Tariffs) {
      tariffs = this.state.Tariffs.map((elem, ind) =>
        <Tariff elem={elem} ind={ind} onChangeHandler={this.onChangeHandler}
          onDeleteHandler={this.del}/>);
    } else {
      tariffs = null;
    }
    return (
      <tbody>{tariffs}</tbody>
    );
  }

  AdminTop () {
    return (
      <div className="admin_top">
        <div className="admin_logo_top">
          <Link to="/admin/main"></Link>
        </div>
      </div>
    );
  }

  render () {
    return (
      <div>
        <div className="b-page">
          <div className="b-page__row">
            <AdminLeft/>

            <div className="b-page__content-wrap pl" style={{
              height: "calc(100vh)"
            }}>
              <Scrollbars style={{
                height: "100%"
              }}>
                <table className={"table table-bordered mt_15"}>
                  <thead>
                  <tr className={"table_f"}>
                    <th style={{
                      'min-width': '150px'
                    }}>Название</th>

                    <th style={{
                      'min-width': '100px'
                    }}>Цена</th>

                    <th style={{
                      'min-width': '100px'
                    }}>Кол-во ботов</th>

                    <th>Длительность</th>

                    <th>Сообщений в день(-1 если нет ограничений)</th>

                    <th>Стартовый тариф</th>

                    <th>ЦРМ</th>

                    <th>Модули</th>

                    <th>Копирование</th>

                    <th>Общий доступ</th>

                    <th>Рассылка</th>

                    <th>Блок Api</th>

                    <th>Блок оплаты</th>

                    <th>Блок Telegram оплаты</th>

                    <th>Условный блок</th>

                    <th>Блок управления переменными</th>

                    <th>Deep Link блок</th>

                    <th>Удалить</th>
                  </tr>
                  </thead>

                  {this.TList()}
                </table>

                <button className={"btn_all bg_shadow"} onClick={this.save}>
                  Сохранить изменения
                </button>

                <button className={"btn_all bg_shadow"} onClick={this.add}>
                  Добавить новый тариф
                </button>
              </Scrollbars>
            </div>
          </div>
        </div>
      </div>
    );
  }

}

export default Tariffs;


