import React from 'react';

class Promocode extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    var p = this.props;
    var x = p.elem;
    var i = p.ind;

    var onSaveHandler = () => p.onSaveHandler(x, i);
    var onDeleteHandler = () => p.onDeleteHandler(x, i);

    return (
      <tr>
        <td className={"table_center"}>
          <input type={"text"} value={x.Code} onChange={p.onChangeHandler}
                 name={"Promocode[" + i + "].Code"}/>
        </td>

        <td className={"table_center"}>
          <input type={"number"} value={x.Sum} onChange={p.onChangeHandler}
                 name={"Promocode[" + i + "].Sum"}/>
        </td>

        <td className={"table_center"}>
          <button className={"btn__all--small bg_shadow"} onClick={onSaveHandler}>
            Сохранить
          </button>

          <button className={"btn__all--small bg_shadow"} onClick={onDeleteHandler}>
            Удалить
          </button>
        </td>
      </tr>
    );
  };

}

export default Promocode;
