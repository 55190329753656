import React from 'react';


class UserTableRow extends React.Component {

  constructor(props) {
    super(props);
  }


  render () {
    var p = this.props;
    var u = p.elem;
    var ind = p.ind;
    var lastAction = new Date(u.Last).toLocaleString();
    var firstAction = new Date(u.First).toLocaleString();


    return (
      <tr key={u._id}>
        <td>{u.name}</td>

        <td>{lastAction}</td>

        <td>{firstAction}</td>

        {p.showConversion ?
        <td>{u.Conversion.toFixed(0) + '%'}</td>
          : null}
      </tr>
    );
  };

}

export default UserTableRow;
