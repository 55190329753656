import React, { Component, Fragment } from "react";
import jQuery from "jquery";
import Cookies from "universal-cookie";
import Draggable, { DraggableCore } from "react-draggable"; // Both at the same time
import { Scrollbars } from "react-custom-scrollbars";
import TextareaAutosize from "react-autosize-textarea";
import SvgComponent from "./SvgComponent";
import LeftPanel from "../../LeftPanel";
import TopPanel from "../../TopPanel/TopPanel";
import Popup from "../../../components/Popup/Popup";
import Tags from "./Tags";
import Tooltip from "material-ui/Tooltip";
import PopupAll from "../../PopupAll";
import ReactTooltip from "react-tooltip";
import TransComponent from "../../../TransComponent.js";
import { Popover, Button, Spin } from "antd";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { successUser } from "../../../modules/User/actions";
import {
  addGroup,
  deleteGroup,
  editBotSuccess,
  editGroup,
  setGroups,
} from "../../../modules/Bots/actions";
import { config } from "../../../config.js";

const cookies = new Cookies();
var func = require("../../../func.js");

var dict = {
  yadomita:
    "Подпишитесь на нашу группу вконтакте и пройдите видео курс по работе с системой.",
  wwybzxjt: "Также вы всегда будете в курсе последних новинок нашей системы.",
  xfzkmxwy: "Подписатся",
  uthrzvfd: "Поменять имя группы",
  mhwbmvkd: "Редактировать",
  lbulgcef: "Копировать",
  gkdtmngi: "Удалить",
  tajngnqo: "Добавить группу",
  qwzfmadh: "Новое правило",
  zdnygajj: "Заново",
  dcfmwmwj: "Завершить обучение",
  nhuggdfx: "Нажмите на кнопку",
  kbxuwdkc: "Новое правило позволяет боту понять",
  fkjcggal: "на какое сообщение пользователя реагировать",
  rmehwggq: "Введите слово",
  voiuugqw: "Привет",
  idfcwjdn: "и нажмите Enter",
  axkjujjn:
    "Здесь надо указывать ключевые слова, на которые будет реагировать бот",
  vgxeqqmr: "Старайтесь обрезать окончания и писать только важные слова",
  hvmvulfr:
    "и выражения - так бот сможет лучше понять, что от него хочет пользователь.",
  yygkvlkt: "А теперь попробуйте ввести",
  wmiruwas: "Здравствуйте",
  xicbvwhd: "и нажать Enter",
  uskdutnu: "Как видите вы можете добавлять множество слов и выражений",
  mxrrcrdi: "Введите ответное действие бота например",
  leonsrji: "Чем могу помочь?",
  gvgfcymg: "А теперь нажмите на кнопку",
  ttltsyfh: "Протестировать бота",
  qnvzcovz: "и напишите ему",
  ziagsisx: "нажмите кнопку",
  details: "Подробнее",
};

class PopupSubscribe extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ShowPopularButtonsPopup: true };
  }

  close = () => {
    func.getData("users/edit_new", { FirstVisit: false }).then(
      (ok) => {},
      (e) => {
        alert(JSON.stringify(e));
      }
    );
    this.setState({ ShowPopularButtonsPopup: false });
  };

  PopularBlockPopup() {
    return (
      <div className="preloader_bots">
        <div className="preloader_bots_2 popupW">
          <div className="analyse_content" style={{ height: "16rem" }}>
            <Scrollbars
              autoHide
              autoHideTimeout={1000}
              autoHideDuration={200}
              style={{ height: "100%" }}
            >
              <div className="analyse_content_2" style={{ textAlign: "left" }}>
                <p className={"fz16 mt_15 fw_menu"}>{dict.yadomita}</p>
                <p>{dict.wwybzxjt}</p>
                <a
                  target={"_blank"}
                  href="https://vk.com/botkitsru"
                  className={"btn__all--small bg_shadow"}
                >
                  {dict.xfzkmxwy}
                </a>
                {/*    <hr/>
                                <p className={'fz16 mt_15 fw_menu'}>Также вы можете пройти платный курс по работе с системой с преподавателем.</p>
                                <button className={'btn__all--small bg_shadow'}>Зарегистрироваться на курс</button>*/}
              </div>
            </Scrollbars>

            <div className="close_popup_btn">
              <button
                className={"btn_tchatbot close"}
                onClick={this.close}
                type="button"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    var s = this.state;
    var p = this.props;
    if (p.User && p.User.FirstVisit) {
      return (
        <div>{s.ShowPopularButtonsPopup ? this.PopularBlockPopup() : null}</div>
      );
    } else {
      return null;
    }
  }
}

class Group extends Component {
  constructor(props) {
    super(props);
    this.state = {
      x: +this.props.x,
      y: +this.props.y,
      _id: this.props._id,
    };

    this.t =
      this.props.t ||
      ((x) => {
        return x;
      });
  }

  render() {
    return (
      <foreignObject className={"canva-block"} width="220" height="120">
        <div xmlns="http://www.w3.org/1999/xhtml">
          <div>
            <h2 className="title">
              <a>{this.props.Name}</a>
              <Tooltip
                enterDelay={300}
                id="tooltip-controlled"
                leaveDelay={300}
                onClose={this.handleTooltipClose}
                onOpen={this.handleTooltipOpen}
                open={this.state.open}
                placement="top"
                title={dict.uthrzvfd}
              >
                <span
                  onClick={this.props.onRename}
                  className="canva-block_edit"
                ></span>
              </Tooltip>
            </h2>

            <div className="canva_btn">
              <div className="canva_btn_overflow">
                <button onClick={this.props.onEdit} className="Canva_btn_edit">
                  {dict.mhwbmvkd}
                </button>

                <Tooltip
                  enterDelay={300}
                  id="tooltip-controlled"
                  leaveDelay={300}
                  onClose={this.handleTooltipClose}
                  onOpen={this.handleTooltipOpen}
                  open={this.state.open}
                  placement="bottom"
                  title={dict.lbulgcef}
                >
                  <button
                    onClick={this.props.onCopy}
                    className="btn__all--small"
                  >
                    <i className="far fa-clone"></i>
                  </button>
                </Tooltip>

                <Tooltip
                  enterDelay={300}
                  id="tooltip-controlled"
                  leaveDelay={300}
                  onClose={this.handleTooltipClose}
                  onOpen={this.handleTooltipOpen}
                  open={this.state.open}
                  placement="bottom"
                  title={dict.gkdtmngi}
                >
                  <button
                    aria-label="Delete"
                    onClick={this.props.onDelete}
                    className="btn__all--small"
                  >
                    <i className="fas fa-trash-alt"></i>
                  </button>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      </foreignObject>
    );
  }
}

class Groups extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Group: "-",
      AllMsgs: [],
      TextContents: [],
      BtnContents: [],
      ShowDeleteGroupPopup: false,
      ShowRenameGroupPopup: false,
      ShowGroupsList: false,
      Wait: 0,
      close: true,
      stripBg: "",
      TagsPage: 0,
      TagsPageLimit: 10,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInputFileChange = this.handleInputFileChange.bind(this);
    this.addGroup = this.addGroup.bind(this);
    this.deleteGroup = this.deleteGroup.bind(this);
    this.renameGroup = this.renameGroup.bind(this);
    this.wantDeleteGroup = this.wantDeleteGroup.bind(this);
    this.wantRenameGroup = this.wantRenameGroup.bind(this);
    this.getGroupName = this.getGroupName.bind(this);
    this.hideAllPopups = this.hideAllPopups.bind(this);
    this.groupIsSelected = this.groupIsSelected.bind(this);
    this.refreshGroupPos = this.refreshGroupPos.bind(this);
    this.AiEdit = this.AiEdit.bind(this);
    this.addRule = this.addRule.bind(this);
    this.onDeleteLink = this.onDeleteLink.bind(this);
    this.onDeleteTag = this.onDeleteTag.bind(this);
    this.onAddTag = this.onAddTag.bind(this);

    this.NewBtn = this.NewBtn.bind(this);
    this.Close = this.Close.bind(this);
    this.refreshTutorial = func.refreshTutorial.bind(this);
  }

  loadBotMsgs = () => {
    func.getData(
      'users/all_msgs',
      {
        BotId: cookies.get('BotId').toString(),
        Attributes: ['Name', '_id', 'Group', 'Type', 'Condition']
      }
    ).then(
      res => {
        if (!res.Error) {
          this.setState({
            AllMsgs: res,
          });
        }
      }
    );
  };

  onDeleteTag(i, ti) {
    var url = config.Backend + "/bot/edit?";
    if (!(cookies.get("Id") === undefined)) {
      url += "&CurrentUserId=" + cookies.get("Id").toString();
    }
    url += "&Id=" + cookies.get("BotId").toString();
    url += "&EditRule=1";
    url += "&DeleteTag=1";
    url += "&Index=" + i;
    url += "&TagIndex=" + ti;

    this.setState({ Wait: ++this.state.Wait });
    jQuery.getJSON(
      url,
      function (res) {
        this.setState({ Wait: --this.state.Wait });

        if (!res.Error) {
          this.props.editBotInStore(res);
        } else {
          alert(JSON.stringify(res));
        }
      }.bind(this)
    );
  }

  onAddTag(i, tag) {
    var url = config.Backend + "/bot/edit?";
    if (!(cookies.get("Id") === undefined)) {
      url += "&CurrentUserId=" + cookies.get("Id").toString();
    }
    url += "&Id=" + cookies.get("BotId").toString();
    url += "&EditRule=1";
    url += "&Tag=" + tag;
    url += "&Index=" + i;

    this.props.bot.AiLinks[i].Tags = [
      ...([] || this.props.bot.AiLinks[i].Tags),
      tag,
    ];
    this.props.editBotInStore(this.props.bot);

    if (
      tag.toUpperCase() == "ПРИВЕТ" ||
      tag.toUpperCase() == "START" ||
      tag.toUpperCase() == "HI"
    ) {
      this.setTutorialStep(2);
    } else {
      this.setTutorialStep(3);
    }

    jQuery.getJSON(url);
  }

  onDeleteLink(i) {
    const { TagsPageLimit, TagsPage } = this.state;
    const { bot } = this.props;
    const tagsCount = (bot ? bot.AiLinks.length : 0) - 1;
    const finalPage = Math.ceil(tagsCount / TagsPageLimit) - 1;

    var url = config.Backend + "/bot/edit?";
    if (!(cookies.get("Id") === undefined)) {
      url += "&CurrentUserId=" + cookies.get("Id").toString();
    }
    url += "&Id=" + cookies.get("BotId").toString();
    url += "&DeleteRule=1";
    url += "&Index=" + i;
    this.setState({ Wait: ++this.state.Wait });

    jQuery.getJSON(
      url,
      function (res) {
        this.setState({ Wait: --this.state.Wait });
        if (!res.Error) {
          this.props.editBotInStore(res);

          if (TagsPage > finalPage) {
            this.prevTagsPage();
          }
        } else {
          alert(JSON.stringify(res));
        }
      }.bind(this)
    );
  }

  linkChanged = (i, newLink, type) => {
    var url = config.Backend + "/bot/edit?";
    if (!(cookies.get("Id") === undefined)) {
      url += "&CurrentUserId=" + cookies.get("Id").toString();
    }
    url += "&Id=" + cookies.get("BotId").toString();
    url += "&EditRule=1";
    url += "&Index=" + i;
    url += "&Link=" + newLink;
    url += "&Type=" + type;
    this.setState({ Wait: ++this.state.Wait });

    jQuery.getJSON(
      url,
      function (res) {
        this.setState({ Wait: --this.state.Wait });

        if (!res.Error) {
          this.setTutorialStep(4);
          this.props.editBotInStore(res);
        } else {
          alert(JSON.stringify(res));
        }
      }.bind(this)
    );
  };

  setTutorialStep = (step) => {
    var t = func.get_tutorial(this.props.user.Tutorial, "Groups");

    if (t && t.Step === step - 1) {
      this.refreshTutorial("Groups", step);
    }
  };

  addRule() {
    const { TagsPageLimit, TagsPage } = this.state;
    const { bot } = this.props;
    const tagsCount = (bot ? bot.AiLinks.length : 0) + 1;
    const finalPage = Math.ceil(tagsCount / TagsPageLimit) - 1;

    var url = config.Backend + "/bot/edit?";
    if (!(cookies.get("Id") === undefined)) {
      url += "&CurrentUserId=" + cookies.get("Id").toString();
    }

    url += "&Id=" + cookies.get("BotId").toString();
    url += "&AddRule=1";
    this.setState({ Wait: ++this.state.Wait });

    jQuery.getJSON(
      url,
      function (res) {
        this.setState({ Wait: --this.state.Wait });

        if (!res.Error) {
          this.props.editBotInStore(res);
          this.setTutorialStep(1);

          if (TagsPage !== finalPage) {
            this.setTagsPage(finalPage);
          }
        } else {
          alert(JSON.stringify(res));
        }
      }.bind(this)
    );
  }

  finishTutorial = () => {
    this.setState({ Wait: ++this.state.Wait });

    func
      .getData("users/edit_new", { Tutorial: { Place: "Groups", Step: 99 } })
      .then(
        (u) => {
          this.setState({ Wait: --this.state.Wait });

          this.props.editUserInStore(u);
        },
        (e) => {
          this.setState({ Wait: --this.state.Wait });

          alert(JSON.stringify(e));
        }
      );
  };
  AiEdit() {
    this.setState({ ShowGroupsList: false });
  }

  renameGroup(id, Name) {
    var url = config.Backend + "/group/edit?";
    if (!(cookies.get("Id") === undefined)) {
      url += "&CurrentUserId=" + cookies.get("Id").toString();
    }
    url += "&Bot=" + cookies.get("BotId").toString();
    url += "&Id=" + id;
    url += "&Name=" + Name;

    for (var i = 0; i < this.props.groups.length; i++) {
      if (this.props.groups[i]._id === id) {
        this.props.groups[i].Name = Name;
        this.props.editGroupInStore({
          group: this.props.groups[i],
          botId: cookies.get("BotId").toString(),
        });
      }
    }

    jQuery.getJSON(url);
    this.hideAllPopups();
  }

  refreshGroupPos(id, newPos) {
    for (var i = 0; i < this.props.groups.length; i++) {
      if (id === this.props.groups[i]._id) {
        this.props.groups[i].Pos = newPos;
      }
    }

    if (id === "-1") {
      this.props.bot.AiPos = newPos;
      this.props.editBotInStore(this.props.bot);
    }
  }

  handleInputFileChange(event) {
    this.setState({ Image: event.target.files[0] });
  }

  hideAllPopups() {
    this.setState({ ShowRenameGroupPopup: false });
    this.setState({ ShowDeleteGroupPopup: false });
  }

  getGroupName() {
    for (var i = 0; i < this.props.groups.length; i++) {
      if (this.props.groups[i]._id == this.state.Group) {
        return this.props.groups[i].Name;
      }
    }
  }

  wantRenameGroup(id) {
    this.hideAllPopups();
    this.setState({ ShowRenameGroupPopup: true });
    this.setState({ Group: id });
  }

  wantDeleteGroup(id) {
    this.hideAllPopups();
    this.setState({ ShowDeleteGroupPopup: true });
    this.setState({ Group: id });
  }

  loadGroups = () => {
    var url = config.Backend + "/bot/groups?";
    if (!(cookies.get("Id") === undefined)) {
      url += "&CurrentUserId=" + cookies.get("Id").toString();
    }
    url += "&Id=" + cookies.get("BotId").toString();
    this.setState({ Wait: ++this.state.Wait });

    jQuery.getJSON(
      url,
      function (res) {
        this.setState({ Wait: --this.state.Wait });

        if (!res.Error) {
          this.props.addGroupsToStore({
            groups: res,
            botId: cookies.get("BotId").toString(),
          });
        } else {
          alert(JSON.stringify(res));
        }
      }.bind(this)
    );
  };

  componentDidUpdate(prevProps) {
    if (this.props.bot && !prevProps.bot) {
      if (
        !config.checkRights(
          cookies.get("Id").toString(),
          this.props.bot,
          "Build"
        )
      ) {
        config.accessDenied(cookies.get("Id").toString(), this.props.bot);
      }
    }
  }

  componentDidMount() {

    if (this.props.bot) {
      if (
        !config.checkRights(
          cookies.get("Id").toString(),
          this.props.bot,
          "Build"
        )
      ) {
        config.accessDenied(cookies.get("Id").toString(), this.props.bot);
      }
    }

    if (this.props.groups) {
      /*
             обходное решение для исправления бага:

             когда из блок схемы переходишь на рассылку,
             возвращаешься обратно в блок схемы видно только один блок(все блоки скучковались в одном месте)
            */

      // rerender
      this.setState({});
    } else {
      this.loadGroups();
    }

    this.loadBotMsgs();
  }

  handleInputChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  RightBar() {
    return (
      <div className="overflow_main_bot">
        <LeftPanel User={this.props.user} />
        <div className="overflow_main_bot_center">
          {this.props.bot ? this.Canva() : null}
        </div>
        <div className="rightPanel">
          <Scrollbars style={{ height: "95%" }}>
            <div className="right_container_content">{this.Right()}</div>
          </Scrollbars>
        </div>
      </div>
    );
  }

  Right() {
    if (!this.state.ShowGroupsList) return this.EditPanel();
    else return this.RightGroup();
  }

  addGroup() {
    var url = config.Backend + "/group/add?";
    if (!(cookies.get("Id") === undefined)) {
      url += "&CurrentUserId=" + cookies.get("Id").toString();
    }
    url += "&Bot=" + cookies.get("BotId").toString();
    url +=
      "&Pos=" +
      Math.floor(Math.random() * 200) +
      "*" +
      Math.floor(Math.random() * 200);
    url += "&Name=Group";
    this.setState({ Wait: ++this.state.Wait });

    jQuery.getJSON(
      url,
      function (res) {
        this.setState({ Wait: --this.state.Wait });

        if (!res.Error) {
          this.props.addGroupToStore({
            group: res.Group,
            botId: cookies.get("BotId").toString(),
          });
        }
      }.bind(this)
    );
  }

  deleteGroup() {
    var url = config.Backend + "/group/delete?";
    if (!(cookies.get("Id") === undefined)) {
      url += "&CurrentUserId=" + cookies.get("Id").toString();
    }
    url += "&Bot=" + cookies.get("BotId").toString();
    url += "&Id=" + this.state.Group;

    var index = 0;
    for (var i = 0; i < this.props.groups.length; i++) {
      if (this.props.groups[i]._id === this.state.Group) {
        index = i;
      }
    }

    this.props.deleteGroupFromStore({
      group: this.props.groups[index],
      botId: cookies.get("BotId").toString(),
    });
    this.setState({ ShowDeleteGroupPopup: false });

    jQuery.getJSON(
      url,
      function (res) {
        if (!res.Error) {
          this.props.editBotInStore(res);
        }
      }.bind(this)
    );
  }

  chooseGroup = (id) => {
    var path = "/chat/build/";
    this.props.history.push(path + id);
  };

  RightGroup() {
    const groups = (this.props.groups || []).map((group) => (
      <li
        key={group._id}
        className={this.groupIsSelected(group._id) ? "dred" : "button_dred"}
      >
        <div className="nameBlock">
          <span onClick={() => this.chooseGroup(group._id)}>{group.Name}</span>
        </div>
        <div className="nameIcon">
          <i
            className="RenameGroup"
            onClick={() => this.wantRenameGroup(group._id)}
          />
          <i
            className="DeleteEdit"
            onClick={() => this.wantDeleteGroup(group._id)}
          />
        </div>
      </li>
    ));

    return (
      <div>
        <div className="right_group">
          <ul className="rightNav">{groups}</ul>
          <div className="addGroupBlock">
            <button onClick={this.addGroup} className="addGroup">
              <span className="addGroupIcon"></span>
              {dict.tajngnqo}
            </button>
          </div>
        </div>
      </div>
    );
  }

  groupIsSelected(id) {
    return this.state.Group === id;
  }

  beginTagsPage = () => {
    this.setState({
      TagsPage: 0
    });
  }

  prevTagsPage = () => {
    this.setState({
      TagsPage: Math.max(this.state.TagsPage - 1, 0 )
    });
  }

  nextTagsPage = () => {
    this.setState({
      TagsPage: this.state.TagsPage + 1
    });
  }

  setTagsPage = tagsPage => {
    this.setState({
      TagsPage: tagsPage
    });
  }

  endTagsPage = () => {
    const { TagsPageLimit, TagsPage } = this.state;
    const { bot } = this.props;
    const tagsCount = bot ? bot.AiLinks.length : 0;

    const finalPage = Math.ceil(tagsCount / TagsPageLimit) - 1;

    this.setState({
      TagsPage: finalPage
    });
  }

  EditPanel() {
    const { TagsPage, TagsPageLimit } = this.state;
    const { bot, groups } = this.props;
    const AiLinks = bot ? bot.AiLinks : [];
    const tagsCount = AiLinks.length;

    const finalPage = Math.ceil(tagsCount / TagsPageLimit) - 1;
    const showPagination = finalPage > 0;

    var links;
    if (groups) {
      const offset = TagsPage * TagsPageLimit;
      links = [];

      AiLinks.forEach((l, index) => {
        if (index >= offset && index < (offset + TagsPageLimit)) {
          // в Tags обязательно передать оригинальный индекс тэга из массива AiLinks
          links.push(
              <Tags
                  key={l._id}
                  onDeleteLink={this.onDeleteLink}
                  Link={l.Link}
                  Type={l.Type}
                  onAddTag={this.onAddTag}
                  onDeleteTag={this.onDeleteTag}
                  AllMsgs={this.state.AllMsgs}
                  AiLink={l}
                  Index={index}
                  linkChanged={this.linkChanged}
                  Groups={this.props.groups}
              />
          );
        }
      });
    } else {
      links = null;
    }
    return (
      <div>
        <div className="EditAddBlock">
          {showPagination && (
              <Fragment>
                Текущая страница {TagsPage + 1}

                <div className="form_group_right_panel form-group">
                  {TagsPage > 0 && (
                      <button className="btnCansel" onClick={this.beginTagsPage}>
                        В начало
                      </button>
                  )}
                  {TagsPage > 0 && (
                      <button className="btnCansel" onClick={this.prevTagsPage}>
                        Назад
                      </button>
                  )}
                  {TagsPage < finalPage && (
                      <button className="btnCansel" onClick={this.nextTagsPage}>
                        Вперед
                      </button>
                  )}
                  {TagsPage < finalPage && (
                      <button className="btnCansel" onClick={this.endTagsPage}>
                        В конец
                      </button>
                  )}
                </div>
              </Fragment>
          )}
          <div className="form_group_right_panel form-group">
            <button className="btnSave" onClick={this.addRule}>
              {dict.qwzfmadh}
            </button>
            <button onClick={this.addGroup} className="btnCansel">
              {dict.tajngnqo}
            </button>
          </div>
        </div>
        {links}

        {showPagination && (
            <Fragment>
              Текущая страница {TagsPage + 1}

              <div className="form_group_right_panel form-group">
                {TagsPage > 0 && (
                    <button className="btnCansel" onClick={this.beginTagsPage}>
                      В начало
                    </button>
                )}
                {TagsPage > 0 && (
                    <button className="btnCansel" onClick={this.prevTagsPage}>
                      Назад
                    </button>
                )}
                {TagsPage < finalPage && (
                    <button className="btnCansel" onClick={this.nextTagsPage}>
                      Вперед
                    </button>
                )}
                {TagsPage < finalPage && (
                    <button className="btnCansel" onClick={this.endTagsPage}>
                      В конец
                    </button>
                )}
              </div>
            </Fragment>
        )}

        <Button onClick={this.addRule}>Добавить правило</Button>
      </div>
    );
  }

  repair = (id) => {
    var url = config.Backend + "/group/repair?";
    if (!(cookies.get("Id") === undefined)) {
      url += "CurrentUserId=" + cookies.get("Id").toString();
    }
    url += "&Id=" + id;

    jQuery.getJSON(
      url,
      function (res) {
        this.setState({ Wait: --this.state.Wait });

        if (!res.Error) {
          this.props.addGroupToStore({
            group: res.Group,
            botId: cookies.get("BotId").toString(),
          });
        } else {
          alert(JSON.stringify(res));
        }
      }.bind(this)
    );
  };

  copyGroup = (id) => {
    var url = config.Backend + "/group/copy?";
    if (!(cookies.get("Id") === undefined)) {
      url += "CurrentUserId=" + cookies.get("Id").toString();
    }
    url += "&Id=" + id;
    url +=
      "&Pos=" +
      Math.floor(Math.random() * 200) +
      "*" +
      Math.floor(Math.random() * 200);
    this.setState({ Wait: ++this.state.Wait });

    jQuery.getJSON(
      url,
      function (res) {
        if (!res.Error) {
          setTimeout(() => this.repair(res.Group._id), 1000);
        } else {
          alert(JSON.stringify(res));
        }
      }.bind(this)
    );
  };

  Canva() {
    const ai = (
      <foreignObject className={"test canva-block"} width={120} height={120}>
        <div style={{ width: "100%", height: "100%" }}>
          <div className="canva_t">
            <span>AI</span>
          </div>
        </div>
      </foreignObject>
    );

    const groups = (this.props.groups || []).map((g) => (
      <Group
        key={g._id}
        x={g.Pos.split("*")[0]}
        y={g.Pos.split("*")[1]}
        _id={g._id}
        Name={g.Name}
        t={this.t}
        onDelete={() => this.wantDeleteGroup(g._id)}
        onRename={() => this.wantRenameGroup(g._id)}
        onEdit={() => this.chooseGroup(g._id)}
        onCopy={() => this.copyGroup(g._id)}
        parentProps={this.props}
      />
    ));

    return (
      <SvgComponent
        dragLink={() => {}}
        Bot={this.props.bot}
        Ai={ai}
        childrens={groups}
        arr={this.props.groups || []}
        isGroups={true}
        Refresh={this.refreshGroupPos}
      />
    );
  }

  /*{End Canva block}*/

  /*{End Setting RightBat}*/

  NewBtn() {
    this.setState((prevState) => ({
      show: !prevState.show,
    }));
  }

  Close() {
    this.setState((prevState) => ({
      show: !prevState.show,
      close: !prevState.close,
    }));
  }

  PopupManualBlock() {
    var t = func.get_tutorial(this.props.user.Tutorial, "Groups");
    var popup = {};

    if (t.Step === 3) {
      popup = {
        right: "-50px",
        top: "261px",
      };
    }

    if (t.Step === 4) {
      popup = {
        right: "-138px",
        top: "24px",
      };
    }

    const bind_to = this.props.bot ? this.props.bot.BindTo : "";

    return (
      <div className={this.state.show ? "popupTest" : "popupTest1"}>
        <div
          style={popup}
          className={
            this.state.show
              ? "manual__popup popup_vs u-pad-y-xxl u-relative"
              : "manual__popup  u-pad-y-xxl u-relative"
          }
        >
          <div className={this.Close && "manual__btn__close"}>
            <div className="close_popup_btn">
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={this.Close}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
          </div>

          <div className="content_manual_popup">
            <button
              className={"btn__all--small mr5"}
              onClick={() => this.refreshTutorial("Groups", 0)}
            >
              {dict.zdnygajj}
            </button>

            <button
              className={"btn__all--small"}
              onClick={() => this.refreshTutorial("Groups", 99)}
            >
              {dict.dcfmwmwj}
            </button>
          </div>

          {t.Step === 0 ? (
            <div className="content_manual_popup">
              <p>
                {dict.nhuggdfx}{" "}
                <span className={"fw_menu"}>"{dict.qwzfmadh}"</span>
              </p>
              <hr />
              <p>
                {dict.kbxuwdkc},<br /> {dict.fkjcggal}.
              </p>
            </div>
          ) : null}
          {t.Step === 1 ? (
            <div className="content_manual_popup">
              <p>
                {dict.rmehwggq}{" "}
                <span className={"fw_menu"}>
                  {bind_to === "Telegram" ? "start" : dict.voiuugqw}
                </span>{" "}
                {dict.idfcwjdn}
              </p>
              <hr />
              <p>
                {dict.axkjujjn}. <br />
                {dict.vgxeqqmr} <br />
                {dict.hvmvulfr}
              </p>
            </div>
          ) : null}

          {t.Step === 2 ? (
            <div className="content_manual_popup">
              <p>
                {dict.yygkvlkt}{" "}
                <span className={"fw_menu"}>{dict.wmiruwas}</span>{" "}
                {dict.xicbvwhd}
              </p>
              <hr />
              <p>{dict.uskdutnu}</p>
            </div>
          ) : null}

          {t.Step === 3 ? (
            <div className="content_manual_popup">
              <p>
                {dict.mxrrcrdi}{" "}
                <span className={"fw_menu"}>{dict.leonsrji}</span>
              </p>
            </div>
          ) : null}
          {t.Step === 4 ? (
            <div className="content_manual_popup">
              <p>
                {dict.gvgfcymg}{" "}
                <span className={"fw_menu"}>{dict.ttltsyfh}</span> <br />{" "}
                {dict.qnvzcovz}
                {bind_to === "Telegram"
                  ? " start(" + dict.ziagsisx + " start)"
                  : " " + dict.voiuugqw}
              </p>
            </div>
          ) : null}
        </div>
      </div>
    );
  }

  /*Нужно менять высоту и позицию кнопки взависимости от сообщения контента */
  // менять высоту и позицию кнопки нужно не только для кнопки и для popup тоже
  render() {
    var t = func.get_tutorial((this.props.user || {}).Tutorial, "Groups");
    var btnHelp = {
      top: "107px",
    };

    if (t) {
      if ([1, 2].indexOf(t.Step) !== -1) {
        // это позиция кнопки для  1 2
        btnHelp = {
          top: "197px",
        };
      } else if (t.Step === 3) {
        btnHelp = {
          top: "351px",
        };
      } else if (t.Step === 4) {
        btnHelp = {
          top: "15px",
          right: "237px",
        };
      }
    }

    return (
      <div>
        {/*{this.state.Wait!==0 ? this.Load() : null}*/}
        <TopPanel />
        {/*<PopupSubscribe User={this.props.user}/>*/}
        <PopupAll />

        {this.RightBar()}
        {t && t.Step < 50 ? this.PopupManualBlock() : null}
        {t && t.Step < 50 ? (
          <button
            style={btnHelp}
            className={"manual__btn"}
            onClick={this.NewBtn}
          ></button>
        ) : null}

        {this.state.ShowDeleteGroupPopup ? (
          <Popup
            text={"Вы уверены?"}
            Yes={this.deleteGroup}
            No={() => {
              this.setState({ ShowDeleteGroupPopup: false });
            }}
          />
        ) : null}
        {this.state.ShowRenameGroupPopup ? (
          <Popup
            text={"Введите новое имя"}
            Bot={this.props.bot}
            Group={this.state.Group}
            RenameGroup={true}
            Rename={this.renameGroup}
            chooseGroup={this.chooseGroup}
            Name={this.getGroupName()}
            No={() => {
              this.setState({ ShowRenameGroupPopup: false });
            }}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
    groups: state.bots.groups[cookies.get("BotId").toString()],
    bot: (state.bots.bots || []).find(
      (bot) => bot._id === cookies.get("BotId").toString()
    ),
  };
};
const mapDispatchToProps = {
  addGroupsToStore: setGroups,
  editBotInStore: editBotSuccess,
  addGroupToStore: addGroup,
  editGroupInStore: editGroup,
  deleteGroupFromStore: deleteGroup,
  editUserInStore: successUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Groups));
