import React from 'react';
import jQuery from 'jquery';
import AdminLeft from '../AdminLeft';
import {Scrollbars} from 'react-custom-scrollbars';
import Cookies from 'universal-cookie';
import DatePicker from 'react-date-picker';
import DatePicker2 from 'react-datepicker';
import moment from 'moment';
import Moment from 'react-moment';
import 'moment/locale/ru';
import User from "./User";
import {Link} from "react-router-dom";
import { config } from '../../config.js';

const cookies = new Cookies();
var func = require('../../func.js');


class MyComponent extends React.Component {
  render() {
    return (
      <Moment locale='ru'></Moment>
    );
  }
}

class Users extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      User: {},
      Users: [],
      Page: 0,
      Limit: 50,
      Templates: [],
      Template: '',
      SearchField: 'Name',
      Search: '',
      Filter: 'Name',
      Order: 'asc',
      Timezone: 'user',
      Time: '12:30',
      Date: this.makePureDate(),
      startDate: moment(),
      endDate: moment().add(7, 'days'),
      locale: moment.locale('ru-RU'),
      Start: 1,
      End: 2,
      QuestionsPopup: -1
    };

    this.onChangeHandler = config.change.bind(this);
  }

  makePureDate = (date) => {
    var now = date ? new Date(date) : new Date;
    now.setHours(0);
    now.setMinutes(now.getMinutes() - now.getTimezoneOffset() - now.getMinutes());
    now.setSeconds(0);
    now.setMilliseconds(0);
    return now;
  };

  onUserChangeHandler = (event, i) => {
    this.state.Users[i][event.target.name] = event.target.type != 'checkbox' ?
      event.target.value : (event.target.checked ? 1 : 0);
    this.setState({
      Users: this.state.Users,
    });
  };

  onAllChangeHandler = (event) => {
    for (var i in this.state.Users) {
      this.state.Users[i].Select = event.target.checked ? 1 : 0;
    }
    this.setState({
      Users: this.state.Users,
    });
  };

  go = () => {
    this.load(this.state.Page);
  };

  onBeginHandler = () => {
    this.state.Page = 0;
    this.setState({
      Page: this.state.Page
    });
    this.load(this.state.Page);
  };

  onPrevHandler = () => {
    this.state.Page--;
    this.setState({
      Page: this.state.Page
    });
    this.load(this.state.Page);
  };

  onNextHandler = () => {
    this.state.Page++;
    this.setState({
      Page: this.state.Page
    });
    this.load(this.state.Page);
  };

  send = (all, to) => {
    jQuery.ajax({
      method: 'POST',
      contentType: 'application/json',
      url: config.Backend + '/admintext/send',
      success: function (res) {
        if (!res.Error) {
          alert('Письмо было отправлено!');
        } else {
          alert(JSON.stringify(res));
        }
      }.bind(this),
      data: JSON.stringify({
        Id: cookies.get('Id'),
        Template: this.state.Template,
        All: all,
        To: to
      })
    });
  };

  prepareSend = (all) => {
    if (all == 0) {
      var to = '';
      for (var i in this.state.Users) {
        if (this.state.Users[i].Select == 1 && this.state.Users[i].Email) {
          to += this.state.Users[i].Email + ', ';
        }
      }
      if (to) {
        this.send(all, to.substr(0, to.length - 2));
      }
      else {
        alert('Ни один пользователь не был выбран или же были выбраны только ' +
          'пользователи без email');
      }
    }
    else if (all == 1) {
      this.send(all, '');
    }
  }

  prepareShedule = (all) => {
    if (all == 0) {
      var to = '';
      for (var i in this.state.Users) {
        if (this.state.Users[i].Select == 1 && this.state.Users[i].Email) {
          to += this.state.Users[i].Email + ', ';
        }
      }
      if (to) {
        this.sheduleSend(all, to.substr(0, to.length - 2));
      }
      else {
        alert('Ни один пользователь не был выбран или же были выбраны только пользователи ' +
          'без email');
      }
    }
    else if (all == 1) {
      this.sheduleSend(all, '');
    }
  };

  sheduleSend = (all, to) => {
    var s = this.state;
    func.getData('admintext/schedule_add', {
      Template: s.Template,
      To: to,
      Date: s.Date,
      Timezone: s.Timezone,
      Time: s.Time,
      TimezoneOffset: new Date().getTimezoneOffset()
    }).then(ok => {
      window.location.href = window.location.origin + '/admin/schedule';
    }, e => {
      alert(JSON.stringify(e));
    });
  };

  enter = (e, i) => {
    var exp = new Date();
    exp.setDate(exp.getDate() + 3);
    cookies.set('Id', e._id, {
      path: '/',
      expires: exp
    });
    if (e.Type == 'Admin') {
      window.location.replace(window.location.origin + '/dashboard');
    } else if (e.Type == 'Super') {
      window.location.replace(window.location.origin + '/admin/main');
    }
  };

  handleChange = ({startDate, endDate}) => {
    startDate = startDate || this.state.startDate;
    endDate = endDate || this.state.endDate;

    if (startDate.isAfter(endDate)) {
      endDate = startDate
    }
    this.setState({startDate, endDate});
  };

  onChangeStartHandler = (startDate) => this.handleChange({startDate});

  onChangeEndHandler = (endDate) => this.handleChange({endDate});

  load = (page) => {
    var s = this.state;
    var url = config.Backend + '/users/findById?';
    if (!(cookies.get('Id') === undefined)) {
      url += 'Id=' + cookies.get('Id').toString();
    }
    jQuery.getJSON(url, function (res) {
      if (!res.Error) {
        if (res.Type == 'Admin') {
          window.location.replace(window.location.origin + '/login');
        }
        this.setState({
          User: res
        }, () => {
          var url = config.Backend + '/users/forSuper_l?Page=' + page + '&Limit='
            + this.state.Limit + '&Filter='
            + this.state.Filter + '&Order=' + this.state.Order + '&SearchField=' + s.SearchField
            + '&Search=' + s.Search + '&Start=' + s.startDate.toDate().valueOf() + '&End='
            + s.endDate.toDate().valueOf();
          if (s.SearchField == 'Bots') {
            url += '&Start=' + s.Start + '&End=' + s.End;
          }
          if (!(cookies.get('Id') === undefined)) {
            url += '&Id=' + cookies.get('Id').toString();
          }
          jQuery.getJSON(url, function (res) {
            if (!res.Error) {
              this.setState({
                Users: res
              });
            } else {
              alert(JSON.stringify(res));
            }
          }.bind(this));

          url = config.Backend + '/admintext/templates?Id=' + cookies.get('Id');
          jQuery.getJSON(url, function (res) {
            if (!res.Error) {
              this.setState({
                Templates: res
              });
              if (res.length > 0 && !this.state.Template) {
                this.setState({
                  Template: res[0]._id
                });
              }
            } else {
              alert(JSON.stringify(res));
            }
          }.bind(this));
        });
      } else {
        window.location.replace(window.location.origin + '/login');
      }
    }.bind(this));
  }

  componentDidMount() {
    this.load(0);
  }

  onShowDetailsHandler = (i) => {
    this.setState({
      QuestionsPopup: i
    });
  };

  TList() {
    var users;
    if (this.state.Users) {
      users = this.state.Users.map((elem, ind) =>
        <User elem={elem} ind={ind} onUserChangeHandler={this.onUserChangeHandler}
          onSaveHandler={this.save} onEnterHandler={this.enter} onDeleteHandler={this.onDeleteHandler}
          onChangeHandler={this.onChangeHandler} Users={this.state.Users}
          onShowDetailsHandler={this.onShowDetailsHandler}/>);
    } else {
      users = null;
    }
    return (
      <tbody>{users}</tbody>
    );
  }

  Close = () => {
    this.setState(prevState => ({
      show: !prevState.show,
      close: !prevState.close
    }));
  }

  onHidePopupHandler = () => {
    this.setState({
      QuestionsPopup: -1
    });
  }

  onChangeDateHandler = (e) => {
    this.state.Date = this.makePureDate(e);
    this.setState({
      Date: this.state.Date
    });
  };

  save = (u, i) => {
    var s = this.state;
    func.getData('users/edit_new', {
      Id: u._id,
      PaymentBlock: s.Users[i].PaymentBlock
    }).then((ok) => {
      alert('Изменения сохранены!');
    }, e => {
      alert(JSON.stringify(e));
    });
  };

  onDeleteHandler = (u, i) => {
    var s = this.state;
    func.getData('users/delete', {
      Id: s.User._id,
      DeleteId: u._id
    }).then((ok) => {
      s.Users.splice(i, 1);
      this.setState({
        Users: s.Users
      });
    }, e => {
      alert(JSON.stringify(e));
    });
  };

  SendEmail() {
    var templates = this.state.Templates.map(function (elem, ind) {
      const t = elem;
      const i = ind;
      return (
        <option value={t._id}>{t.Name || "Безымянный"}</option>
      );
    }.bind(this));

    var onSendChooseHandler = () => this.prepareSend(0);

    var onSendAllHandler = () => this.prepareSend(1);

    var onSheduleChooseHandler = () => this.prepareShedule(0);

    var onSheduleAllHandler = () => this.prepareShedule(1);

    return (
      <div className={"bd pd-t-30 pd-b-20 pd-x-20 form-group"}>
        <div className="form-group">
          <label>Выберите Шаблон письма</label>

          <select className={"chat_input"} value={this.state.Template} name={"Template"}
                  onChange={this.onChangeHandler}>
            {templates}
          </select>
        </div>

        <div className="row form-group">
          <div className="col-sm-3">
            <button className={"w-100 btn__all--small mr2"} onClick={onSendChooseHandler}>
              Отправить выбранным
            </button>
          </div>

          <div className="col-sm-3">
            <button className={"w-100 btn__all--small"} onClick={onSendAllHandler}>
              Отправить всем(всем что есть в бд)
            </button>
          </div>

          <div className="col-sm-3">
            <button className={"w-100 btn__all--small"} onClick={onSheduleChooseHandler}>
              Отправить в определенное время(выбранным)
            </button>
          </div>

          <div className="col-sm-3">
            <button className={"w-100 btn__all--small"} onClick={onSheduleAllHandler}>
              Отправить в определенное время(всем)
            </button>
          </div>
        </div>

        <div className="form-group row">
          <div className="col-sm-3">
            <DatePicker className="bred"
                        onChange={this.onChangeDateHandler}
                        value={this.state.Date}/>
          </div>

          <div className="schedule_input_1 col-sm-3">
            <input
              className="chat_input"
              type="text"
              name={"Time"}
              placeholder="HH:mm"
              value={this.state.Time}
              onChange={this.onChangeHandler}/>
          </div>

          <div className="col-sm-3">
            <select
              className="chat_input schedule_input_1 borderR"
              value={this.state.Timezone}
              name={"Timezone"}
              onChange={this.onChangeHandler}>
              <option value="user">Часовой пояс пользователя</option>

              <option value="-12">UTC -12:00</option>

              <option value="-11">UTC -11:00</option>

              <option value="-10">UTC -10:00</option>

              <option value="-9">UTC -9:00</option>

              <option value="-8">UTC -8:00</option>

              <option value="-7">UTC -7:00</option>

              <option value="-6">UTC -6:00</option>

              <option value="-5">UTC -5:00</option>

              <option value="-4">UTC -4:00</option>

              <option value="-3">UTC -3:00</option>

              <option value="-2">UTC -2:00</option>

              <option value="-1">UTC -1:00</option>

              <option value="0">UTC +0:00</option>

              <option value="1">UTC +1:00</option>

              <option value="2">UTC +2:00</option>

              <option value="3">UTC +3:00</option>

              <option value="4">UTC +4:00</option>

              <option value="5">UTC +5:00</option>

              <option value="6">UTC +6:00</option>

              <option value="7">UTC +7:00</option>

              <option value="8">UTC +8:00</option>

              <option value="9">UTC +9:00</option>

              <option value="10">UTC +10:00</option>

              <option value="12">UTC +11:00</option>

              <option value="12">UTC +12:00</option>
            </select>
          </div>
        </div>
      </div>
    );
  }

  filter = (field) => {
    var s = this.state;
    if (s.Filter == field) {
      this.setState({
          Order: s.Order == 'asc' ? 'desc' : 'asc'
        },
        () => this.load(s.Page));
    } else {
      this.setState({
        Filter: field
      }, () => this.load(s.Page));
    }
  };

  ModulePopup() {
    var s = this.state;
    var u = s.Users[s.QuestionsPopup];
    var utm = u.UTM || {};
    var rows = [];
    for (var k in utm) {
      /*var val = "";
      utm[k].forEach(e=>{
          val+=e+'';
      })*/
      rows.push(
        <tr>
          <td>{k}</td>

          <td>{JSON.stringify(utm[k])}</td>
        </tr>
      );
    }

    return (
      <div className="preloader_bots">
        <div className="preloader_bots_2 popupW">
          <div className="analyse_content">
            <Scrollbars
              style={{
                height: "100%"
              }}>
              <div className="analyse_content_2">
                <table className={"table table-bordered"}>
                  <thead>
                  <tr className={"table_f"}>
                    <th>Метка</th>

                    <th>Значение</th>
                  </tr>
                  </thead>

                  <tbody>
                  {rows}
                  </tbody>
                </table>

                {/*<button onClick={this.onHidePopupHandler}>Close</button>*/}
              </div>
            </Scrollbars>
            <div className="close_popup_btn">
              <button onClick={this.onHidePopupHandler} type="button" className="close"
                      aria-label="Close">
                <span aria-hidden="true">&times;</span></button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  onFilterNameHandler = () => this.filter('Name');

  onFilterEmailHandler = () => this.filter('Email');

  onFilterPhoneHandler = () => this.filter('Phone');

  onFilterLastActionHandler = () => this.filter('LastAction');

  onFilterLastBotActionHandler = () => this.filter('LastBotAction');

  onFilterCreatedAtHandler = () => this.filter('created_at');

  onFilterBotsHandler = () => this.filter('Bots');

  onFilterTariffHandler = () => this.filter('Tariff');

  onFilterTariffEndHandler = () => this.filter('TariffEnd');

  onFilterPaymentBlockHandler = () => this.filter('PaymentBlock');

  render () {
    var s = this.state;
    var search = (
      <div className="form-group row">
        <label className="col-sm-5 col-form-label">Содержит строку:</label>

        <div className="col-sm-7">
          <input type={"text"} className={"chat_input"} value={s.Search} name={"Search"}
                 onChange={this.onChangeHandler}/>
        </div>
      </div>
    );

    if (['LastAction', 'LastBotAction', 'TariffEnd', 'created_at'].indexOf(s.SearchField) != -1) {
      search = (
        <div className={"row form-group"}>
          <div className="col-sm-6">
            <div className="row">
              <label className="col-sm-5 col-form-label fw__panel">От</label>

              <div className="col-sm-7">
                <DatePicker2
                  selected={this.state.startDate}
                  selectsStart
                  placeholderText="Select a date"
                  onChange={this.onChangeStartHandler}/>
              </div>
            </div>
          </div>

          <div className="col-sm-6">
            <div className="row">
              <label className={"col-sm-5 col-form-label fw__panel"}>До</label>

              <div className="col-sm-7">
                <DatePicker2
                  selected={this.state.endDate}
                  selectsEnd
                  placeholderText="Select a date"
                  onChange={this.onChangeEndHandler}/>
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (s.SearchField == 'Bots') {
      search = (
        <div className="form-group row">
          <label className="col-sm-5 col-form-label">От:</label>

          <div className="col-sm-7">
            <input
              type={"number"}
              className={"chat_input"}
              value={s.Start}
              name={"Start"}
              onChange={this.onChangeHandler}/>
          </div>

          <label className="col-sm-5 col-form-label">До:</label>

          <div className="col-sm-7">
            <input
              type={"number"}
              className={"chat_input"}
              value={s.End}
              name={"End"}
              onChange={this.onChangeHandler}/>
          </div>
        </div>
      );
    }

    return (
      <div>
        <div className="b-page">
          <div className="b-page__row">
            <AdminLeft/>

            <div className="b-page__content-wrap" style={{
              height: "calc(100vh)"
            }}>
              <Scrollbars style={{
                height: "100%"
              }}>
                <div className="pl">
                  <div className="form-group">
                    <Link to="/register">Создать аккаунт</Link>
                  </div>
                  <div className={"mt_20"}>
                    <div className={"bd pd-t-30 pd-b-20 pd-x-20 form-group"}>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group row">
                            <label htmlFor="staticEmail" className="col-sm-5 col-form-label">
                              Страница
                            </label>

                            <div className="col-sm-7">
                              <input
                                className={"chat_input"}
                                type={"number"}
                                value={this.state.Page}
                                name={"Page"}
                                onChange={this.onChangeHandler}/>
                            </div>
                          </div>
                        </div>

                        <div className="col-sm-6">
                          <div className="form-group row">
                            <label htmlFor="staticEmail" className="col-sm-5 col-form-label">
                              Кол-во записей на страницу
                            </label>

                            <div className="col-sm-7">
                              <input
                                className={"chat_input"}
                                type={"number"}
                                value={this.state.Limit}
                                name={"Limit"}
                                onChange={this.onChangeHandler}/>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group row">
                            <label htmlFor="staticEmail" className="col-sm-5 col-form-label">
                              Поиск по полю:
                            </label>

                            <div className="col-sm-7">
                              <select
                                className={"chat_input"}
                                value={s.SearchField}
                                name={"SearchField"}
                                onChange={this.onChangeHandler}>
                                <option value={"Name"}>Имя</option>

                                <option value={"Email"}>Email</option>

                                <option value={"Phone"}>Телефон</option>

                                <option value={"LastAction"}>Последняя активность</option>

                                <option value={"LastBotAction"}>Последняя активность бота</option>

                                <option value={"created_at"}>Дата регистрации</option>

                                <option value={"Bots"}>Кол-во Ботов</option>

                                <option value={"Tariff"}>Тариф</option>

                                <option value={"TariffEnd"}>Дата окончания тарифа</option>
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="col-sm-6">
                          {search}
                        </div>
                      </div>

                      <div className="form-group">
                        <button className={"btn__all--small"} onClick={this.go}>
                          Ручной переход
                        </button>
                      </div>
                    </div>

                    {this.state.Templates.length > 0 ? this.SendEmail()
                      : "Чтобы отправить email нужно сначала создать шаблон письма"}
                  </div>

                  <table className={"table table-bordered mt_25"}>
                    <thead>
                    <tr className={"table_f"}>
                      <th>
                        <input type={"checkbox"} name={"Select"}
                               onChange={this.onAllChangeHandler}/>
                      </th>

                      <th>Id</th>

                      <th>
                        <span
                          style={{
                            cursor: "pointer"
                          }}
                          onClick={this.onFilterNameHandler}>
                          Имя
                          {s.Filter == "Name" ? (s.Order == "desc" ?
                            <i className="fz12 fas fa-chevron-down"></i> :
                            <i className="fz12 fas fa-chevron-up"></i>) : ""}
                        </span>
                      </th>

                      <th>
                        <span style={{
                          cursor: "pointer"
                        }} onClick={this.onFilterEmailHandler}>Email
                          {s.Filter == "Email" ? (s.Order == "desc" ?
                            <i className="fz12 fas fa-chevron-down"></i>
                            : <i className="fz12 fas fa-chevron-up"></i>) : ""}</span>
                      </th>

                      <th>
                        <span style={{
                          cursor: "pointer"
                        }} onClick={this.onFilterPhoneHandler}>
                          Телефон
                          {s.Filter == "Phone" ? (s.Order == "desc" ?
                            <i className="fz12 fas fa-chevron-down"></i>
                            : <i className="fz12 fas fa-chevron-up"></i>) : ""}</span>
                      </th>

                      <th>
                        <span style={{
                          cursor: "pointer"
                        }} onClick={this.onFilterLastActionHandler}>
                          Последняя активность
                          {s.Filter == "LastAction" ? (s.Order == "desc" ?
                            <i className="fz12 fas fa-chevron-up"></i>
                            : <i className="fz12 fas fa-chevron-up"></i>) : ""}</span>
                      </th>

                      <th>
                        <span style={{
                          cursor: "pointer"
                        }} onClick={this.onFilterLastBotActionHandler}>
                          Последняя активность бота
                          {s.Filter == "LastBotAction" ? (s.Order == "desc" ?
                            <i className="fz12 fas fa-chevron-down"></i>
                            : <i className="fz12 fas fa-chevron-up"></i>) : ""}</span>
                      </th>

                      <th>
                        <span style={{
                          cursor: "pointer"
                        }} onClick={this.onFilterCreatedAtHandler}>
                          Дата регистрации
                          {s.Filter == "created_at" ? (s.Order == "desc" ?
                            <i className="fz12 fas fa-chevron-down"></i>
                            : <i className="fz12 fas fa-chevron-up"></i>) : ""}</span>
                      </th>

                      <th>
                        <span style={{
                          cursor: "pointer"
                        }} onClick={this.onFilterBotsHandler}>Кол-во ботов
                          {s.Filter == "Bots" ? (s.Order == "desc" ?
                            <i className="fz12 fas fa-chevron-down"></i>
                            : <i className="fz12 fas fa-chevron-up"></i>) : ""}</span>
                      </th>

                      <th>
                        <span style={{
                          cursor: "pointer"
                        }} onClick={this.onFilterTariffHandler}>Текущий тариф
                          {s.Filter == "Tariff" ? (s.Order == "desc" ?
                            <i className="fz12 fas fa-chevron-down"></i>
                            : <i className="fz12 fas fa-chevron-up"></i>) : ""}</span>
                      </th>

                      <th>
                        <span style={{
                          cursor: "pointer"
                        }} onClick={this.onFilterTariffEndHandler}>Истекает
                          {s.Filter == "TariffEnd" ? (s.Order == "desc" ?
                            <i className="fz12 fas fa-chevron-down"></i>
                            : <i className="fz12 fas fa-chevron-up"></i>) : ""}</span>
                      </th>

                      <th>
                        UTM
                      </th>

                      {/*<th>*/}
                      {/*  <span style={{*/}
                      {/*    cursor: "pointer"*/}
                      {/*  }} onClick={this.onFilterPaymentBlockHandler}>Блок Оплаты*/}
                      {/*    {s.Filter == "PaymentBlock" ? (s.Order == "desc" ?*/}
                      {/*      <i className="fz12 fas fa-chevron-down"></i>*/}
                      {/*      : <i className="fz12 fas fa-chevron-up"></i>) : ""}</span>*/}
                      {/*</th>*/}

                      <th></th>
                    </tr>
                    </thead>

                    {this.TList()}
                  </table>

                  {this.state.Page > 0 ?
                    <button className={"btn__all--small"} onClick={this.onBeginHandler}>
                      В начало
                    </button> : null}

                  {this.state.Page > 0 ?
                    <button className={"btn__all--small"} onClick={this.onPrevHandler}>
                      Назад
                    </button> : null}

                  <button className={"btn__all--small"} onClick={this.onNextHandler}>
                    Вперед
                  </button>
                </div>
              </Scrollbars>
            </div>
          </div>
        </div>

        {s.QuestionsPopup != -1 ? this.ModulePopup() : null}
      </div>
    );
  }

}

export default Users;

