import React from "react";
import styles from './styles.module.scss';

export const Button = (props) => {

  const redirect = () => {
    return window.open(props.link)
  }

  return (
    <button onClick={redirect} className={styles.button}>Протестировать бота</button>
  )
}