import React, { Component } from "react";
import jQuery from "jquery";
import Cookies from "universal-cookie";
import Audio from "../../AudioPlayerClass.js";
import { Player } from "video-react";
import Gallery from "react-grid-gallery";
import { Scrollbars } from "react-custom-scrollbars";
import ReactTooltip from "react-tooltip";
import TextareaAutosize from "react-autosize-textarea";
import TransComponent from "../../../../../TransComponent.js";
import SelectPlus from "react-select-plus";
import { Link } from "react-router-dom";
import { config } from "../../../../../config.js";
import { Button } from "../../../../../components/Button/Button";
import MessageBlock from "../Content/components/MessageBlock/MessageBlock";
import { InputCheckCharacter } from "../../../../../components/InputCheckCharacter/InputCheckCharacter";
import { TooltipTitle } from "../../../../../components/TooltipTitle/TooltipTitle";
import { prepareLinkAllBlocksOptions, getData } from "../../../../../func";

const cookies = new Cookies();
var dict = {
  hywcarmz: "Скачать",
  smbaxuoj: "Текст кнопки",
  wosemyvj: "Не должен быть пустым.",
  jdtixoyc: "Ссылка на сообщение",
  uxzsmozc: "Сообщение которое будет вызвано после нажатия на кнопку.",
  menrhibv: "Ссылка",
  cvwomxuq: "Ссылка на веб-ресурс",
  gyydhzcw:
    "Включите данную опцию если вам нужно что-бы кнопка вела на веб-ресурс а не на сообщение бота.",
  vertical_horizontal_label: "Включить в горизонтальный ряд",
  vertical_horizontal_info:
    "Все кнопки с данной опцией и находящиеся рядом будут обьеденены в горизонтальный ряд кнопок." +
    "Только для Telegram и WhatsApp.",
  amskmvtn: "Если",
  qijohvom:
    "Если выполнится условие то бот отправит сообщение на которое указывает ссылка.",
  zqefwcvm: "В условии можно использовать javascript и Context переменные.",
  txnlhnyr: "пример",
  bedzwnzt:
    "Для сравнения с текстом оберните его в двойные или одинарные кавычки",
  zvynhaes: "Простой режим",
  dtxqwaid:
    "Переключает условие в простой режим,у него ограниченные взоможности однако его проще освоить.",
  ejmximcf: "Равно",
  kiemwdti: "Не равно",
  iluqidoi: "Больше",
  rzduwgwg: "Меньше",
  vekaigyh: "Больше или равно",
  hfrwvgxj: "Меньше или равно",
  stmuujrx: "Равно(без учета регистра)",
  whfsvjic: "Входит в строку(без учета регистра)",
  juujulql: "Входит в строку",
  ulcivltl: "Не входит в строку(без учета регистра)",
  maasdloq: "Не входит в строку",
  maasdlo2: "Пользователь подписан на канал",
  cwrkvwwb: "Сообщение которое будет вызвано если выполнится условие.",
  gfioswyn: "Ссылка на группу",
  atgbvtle:
    "Группа стартовое сообщение которой будет вызвано после нажатия на кнопку.",
  qhlksrrr: "Время ввода",
  zbtzkrir: "Кол-во секунд в течении которых бот будет печатать сообщение.",
  tkansyqs: "Текст сообщения",
  baglkyts: "Можно использовать Context переменные",
  baglkyts2: "Нельзя использовать Context переменные",
  zwotsojy: "Вы заказали",
  ddvmkiwu: "В telegram можно добавлять команды в сообщение",
  exsqriui: "Команды бота",
  kgtykxlg: "Текст сообщения...",
  ibomrzrg: "Вставить имя",
  rkhgkvhz: "Вставить Context переменную",
  vmqurcqz: "Ссылка по умолчанию",
  vmqureqz: "Ссылка после успешной оплаты",
  hoyazctx:
    "После того как бот напечатает это сообщение он вызовет сообщение на которое ведет эта ссылка.",
};

let st;

class Content extends TransComponent {
  constructor(props) {
    super(props);
    this.state = {
      Field: this.props.Field,
      ReqType: this.props.ReqType,
      Type: this.props.Type,
      Text: this.props.Text,
      typingTime: +this.props.typingTime,
      Link: this.props.Link,
      Index: this.props.Index,
      myFile: this.props.File,
      Macros: this.props.Macros,
      Url: this.props.Url,
      BtnUrl: this.props.BtnUrl,
      BtnType: this.props.BtnType,
      Headers: this.props.Headers,
      Save: this.props.Save,
      Cond: this.props.Cond,
      CondRedirect: this.props.CondRedirect,
      SimpleMode: this.props.SimpleMode,
      SimpleAttr: this.props.SimpleAttr,
      SimpleCond: this.props.SimpleCond,
      SimpleValue: this.props.SimpleValue,
      BtnPosition: this.props.BtnPosition,
      checkCharacterLengthButton: 0,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInputFileChange = this.handleInputFileChange.bind(this);
    this.changeSelect = this.changeSelect.bind(this);
    this.onCheckMsg = this.onCheckMsg.bind(this);
    this.onCheckGroup = this.onCheckGroup.bind(this);
    this.isLinkType = this.isLinkType.bind(this);
    this.mustLoadFile = this.mustLoadFile.bind(this);
    this.canBeDeleted = this.canBeDeleted.bind(this);
    this.getLinkAllBlocksOptions = this.getLinkAllBlocksOptions.bind(this);
  }

  componentWillReceiveProps(props) {
    if (
      props.Type == "Video" ||
      props.Type == "Audio" ||
      props.Type == "Image" ||
      props.Type == "File" ||
      props.Type == "Product Image"
    ) {
      this.setState(
        {
          Field: props.Field,
          Index: props.Index,
          Url: props.Url,
          Link: props.Link,
          myFile: props.File,
          Headers: props.Headers,
          Save: props.Save,
          Cond: props.Cond,
          CondRedirect: props.CondRedirect,
          SimpleMode: props.SimpleMode,
          SimpleAttr: props.SimpleAttr,
          SimpleCond: props.SimpleCond,
          SimpleValue: props.SimpleValue,
        },
        () => {
          if (this.mustLoadFile()) {
            var url = config.Backend + "/content/getFile?";
            if (!(cookies.get("Id") === undefined)) {
              url += "&CurrentUserId=" + cookies.get("Id").toString();
            }
            url += "&Link=" + this.state.Link;
            this.setState({ Url: url });
          }
        }
      );
    } else {
      this.setState({
        Url: props.Url,
        Link: props.Link,
        Cond: props.Cond,
        CondRedirect: props.CondRedirect,
        SimpleMode: props.SimpleMode,
        SimpleAttr: props.SimpleAttr,
        SimpleCond: props.SimpleCond,
        SimpleValue: props.SimpleValue,
      });
    }
  }

  componentDidMount() {
    if (this.mustLoadFile()) {
      var url = config.Backend + "/content/getFile?";
      if (!(cookies.get("Id") === undefined)) {
        url += "&CurrentUserId=" + cookies.get("Id").toString();
      }
      url += "&Link=" + this.state.Link;
      this.setState({ Url: url });
    }

    if (this.props.Bot) {
      if (this.props.Bot.BindTo === "Telegram") {
        this.setState({
          checkCharacterLengthButton: 21,
        });
      } else if (this.props.Bot.BindTo === "Ok") {
        this.setState({
          checkCharacterLengthButton: 21,
        });
      } else if (this.props.Bot.BindTo === "Vk") {
        this.setState({
          checkCharacterLengthButton: 40,
        });
      } else if (this.props.Bot.BindTo === "Viber") {
        this.setState({
          checkCharacterLengthButton: 40,
        });
      }
      else if (this.props.Bot.BindTo === "FB") {
        this.setState({
          checkCharacterLengthButton: 40,
        });
      } else if (this.props.Bot.BindTo === "WhatsApp") {
        this.setState({
          checkCharacterLengthButton: 24,
        });
      }
    }
  }

  mustLoadFile() {
    const correctType =
      [
        "Text",
        "Btn",
        "BtnToGroup",
        "Api",
        "DefaultLink",
        "DefaultLinkToGroup",
        "LinkAfterPaymentSuccess",
      ].indexOf(this.state.Type) === -1;
    return correctType && this.state.Link != "-1";
  }

  isLinkType() {
    return (
      ["Btn", "DefaultLink", "LinkAfterPaymentSuccess", "Cond"].indexOf(
        this.state.Type
      ) >= 0
    );
  }

  canBeDeleted() {
    return (
      [
        "Text",
        "DefaultLink",
        "DefaultLinkToGroup",
        "LinkAfterPaymentSuccess",
      ].indexOf(this.state.Type) === -1
    );
  }

  handleInputChange(e) {
    const targetName = e.target.name

    const newState = {
      [targetName]:
          e.target.type === "checkbox"
              ? e.target.checked
              ? true
              : false
              : e.target.value,
    }

    this.setState(
      newState,
      () => {
        var s = this.state;

        this.props.handleForContent(
          s.Index,
          s.Text,
          s.typingTime,
          s.Link,
          s.myFile,
          s.Type,
          s.Url,
          s.ReqType,
          s.Field,
          this.isLinkType() ? 1 : 0,
          s.Macros,
          s.BtnType,
          s.BtnUrl,
          s.Headers,
          s.Save,
          s.Cond,
          s.CondRedirect,
          s.SimpleMode,
          s.SimpleAttr,
          s.SimpleCond,
          s.SimpleValue,
          s.BtnPosition
        );

        if (targetName === 'SimpleValue' && s.SimpleCond === 'isSubscriber') {
          clearTimeout(st);
          st = setTimeout(() => {
            if (this.state.SimpleCond === 'isSubscriber') {
              getData(config.TelegramApi + 'bot' + this.props.Bot.TelegramToken + '/getChat', {
                chat_id: s.SimpleValue,
              })
                  .then(ok => {
                    alert("Успех: Бот найден в канале/группе " + s.SimpleValue)
                  }, err => {
                    if (err.status === 403) {
                      alert("Ошибка: Бот не добавлен в канал/группу " + s.SimpleValue)
                    } else {
                      alert("Ошибка: Не найден канал/группа " + s.SimpleValue)
                    }
                  });
            }

          }, 1500)
        }
      }
    );
  }

  handleSelect(val, x) {
    this.setState(
      {
        [x]: val ? val.value : "-",
      },
      () => {
        var s = this.state;
        this.props.handleForContent(
          this.state.Index,
          this.state.Text,
          this.state.typingTime,
          this.state.Link,
          this.state.myFile,
          this.state.Type,
          this.state.Url,
          this.state.ReqType,
          this.state.Field,
          this.isLinkType() ? 1 : 0,
          this.state.Macros,
          this.state.BtnType,
          this.state.BtnUrl,
          this.state.Headers,
          this.state.Save,
          this.state.Cond,
          this.state.CondRedirect,
          s.SimpleMode,
          s.SimpleAttr,
          s.SimpleCond,
          s.SimpleValue,
          s.BtnPosition
        );
      }
    );
  }

  onCheckGroup(e) {
    this.setState(
      { BtnType: e.target.checked ? "openUrl" : "postBack" },
      () => {
        var s = this.state;

        this.props.handleForContent(
          this.state.Index,
          this.state.Text,
          this.state.typingTime,
          this.state.Link,
          this.state.myFile,
          this.state.Type,
          this.state.Url,
          this.state.ReqType,
          this.state.Field,
          this.isLinkType() ? 1 : 0,
          this.state.Macros,
          this.state.BtnType,
          this.state.BtnUrl,
          this.state.Headers,
          this.state.Save,
          this.state.Cond,
          this.state.CondRedirect,
          s.SimpleMode,
          s.SimpleAttr,
          s.SimpleCond,
          s.SimpleValue,
          s.BtnPosition
        );
      }
    );
  }

  onCheckMsg(e) {
    this.setState(
      { BtnType: e.target.checked ? "openUrl" : "postBack" },
      this.callHandler
    );
  }

  onCheckHorizontalHandler = (e) => {
    this.setState(
      {
        BtnPosition: e.target.checked ? "Horizontal" : "Vertical",
      },
      this.callHandler
    );
  };

  callHandler = () => {
    var s = this.state;

    this.props.handleForContent(
      this.state.Index,
      this.state.Text,
      this.state.typingTime,
      this.state.Link,
      this.state.myFile,
      this.state.Type,
      this.state.Url,
      this.state.ReqType,
      this.state.Field,
      this.isLinkType() ? 1 : 0,
      this.state.Macros,
      this.state.BtnType,
      this.state.BtnUrl,
      this.state.Headers,
      this.state.Save,
      this.state.Cond,
      this.state.CondRedirect,
      s.SimpleMode,
      s.SimpleAttr,
      s.SimpleCond,
      s.SimpleValue,
      s.BtnPosition
    );
  };

  changeSelect(e) {
    if (e) {
      this.setState(
        {
          Field: e.value,
        },
        () => {
          var s = this.state;

          this.props.handleForContent(
            this.state.Index,
            this.state.Text,
            this.state.typingTime,
            this.state.Link,
            this.state.myFile,
            this.state.Type,
            this.state.Url,
            this.state.ReqType,
            this.state.Field,
            this.isLinkType() ? 1 : 0,
            this.state.Macros,
            this.state.BtnType,
            this.state.BtnUrl,
            this.state.Headers,
            this.state.Save,
            this.state.Cond,
            this.state.CondRedirect,
            s.SimpleMode,
            s.SimpleAttr,
            s.SimpleCond,
            s.SimpleValue,
            s.BtnPosition
          );
        }
      );
    } else {
      this.state.Field = "";
    }
  }

  handleInputFileChange(event) {
    this.setState({ myFile: event.target.files[0] }, () => {
      this.props.handleForContent(
        this.state.Index,
        this.state.Text,
        this.state.typingTime,
        this.state.Link,
        this.state.myFile,
        this.state.Type,
        this.state.Url,
        this.state.ReqType,
        this.state.Field,
        0,
        this.state.Macros
      );
    });
  }

  UploadImage() {
    const IMAGES = [
      {
        src: this.state.Url,
        thumbnail: this.state.Url,
        thumbnailWidth: 250,
        thumbnailHeight: 100,
        isSelected: false,
        caption: "",
      },
    ];
    return <Gallery images={IMAGES} />;
  }

  UploadFile() {
    return (
      <div className="form-group">
        <a className={"btn_all "} href={this.state.Url}>
          {dict.hywcarmz + ' ' + this.props.FileName}
        </a>
      </div>
    );
  }

  ApiBlock() {
    return (
      <div className="api_block">
        <input
          type="text"
          className="chat_input"
          name="Url"
          placeholder="url"
          value={this.state.Url}
          onChange={this.handleInputChange}
        />
        <select
          className="chat_input"
          name="ReqType"
          value={this.state.ReqType}
          onChange={this.handleInputChange}
        >
          <option value="Get">get</option>
          <option value="Post">post</option>
        </select>
        <TextareaAutosize
          className="api_textarea border_textarea_ mt_5 pl"
          rows={3}
          maxRows={99}
          value={this.state.Headers}
          name="Headers"
          onChange={this.handleInputChange}
        />
        <TextareaAutosize
          className="api_textarea border_textarea_ mt_5 pl"
          rows={3}
          maxRows={99}
          value={this.state.Field}
          name="Field"
          onChange={this.handleInputChange}
        />
        <TextareaAutosize
          className="api_textarea border_textarea_ mt_5 pl"
          rows={3}
          maxRows={99}
          value={this.state.Macros}
          name="Macros"
          onChange={this.handleInputChange}
        />
        <TextareaAutosize
          className="api_textarea border_textarea_ mt_5 pl"
          placeholder={"Context.param1=user.name,Context.param2=user.age"}
          rows={3}
          maxRows={99}
          value={this.state.Save}
          name="Save"
          onChange={this.handleInputChange}
        />
      </div>
    );
  }

  componentDidUpdate() {}

  BtnBlock() {
    var options = [];
    var msgs = JSON.parse(JSON.stringify(this.props.BotMsgs));
    var bind = this.props.Bot.BindTo;

    var ok = false;
    var s = this.state;
    for (var i = 0; i < this.props.BotMsgs.length; i++) {
      if (this.props.BotMsgs[i]._id == this.props.BotMsg) {
        msgs.splice(i, 1);
      } else if (this.state.Url == this.props.BotMsgs[i]._id) {
        ok = true;
      }
    }

    if (!ok && this.props.id.length < 3) {
      for (var i = 0; i < this.props.BotMsgs.length; i++) {
        if (this.props.BotMsgs[i]._id != this.props.BotMsg) {
          this.state.Url = this.props.BotMsgs[i]._id;
        }
      }

      this.props.handleForContent(
        this.state.Index,
        this.state.Text,
        this.state.typingTime,
        this.state.Link,
        this.state.myFile,
        this.state.Type,
        this.state.Url,
        this.state.ReqType,
        this.state.Field,
        0,
        this.state.Macros,
        this.state.BtnType,
        this.state.BtnUrl,
        this.state.Headers,
        this.state.Save,
        this.state.Cond,
        this.state.CondRedirect,
        s.SimpleMode,
        s.SimpleAttr,
        s.SimpleCond,
        s.SimpleValue,
        s.BtnPosition
      );
    }
    for (var i in msgs) {
      var ind = -1;
      for (var j in options) {
        if (
          options[j].label ==
          msgs[i].Group.Name + "(#" + msgs[i].Group._id + ")"
        ) {
          ind = j;
        }
      }
      if (ind == -1) {
        options.push({
          label: msgs[i].Group.Name + "(#" + msgs[i].Group._id + ")",
          options: [
            {
              label: msgs[i].Name + "(#" + msgs[i]._id + ")",
              value: msgs[i]._id,
            },
          ],
        });
      } else {
        options[ind].options.push({
          label: msgs[i].Name + "(#" + msgs[i]._id + ")",
          value: msgs[i]._id,
        });
      }
    }

    let textLength =
      this.state.checkCharacterLengthButton - this.state.Text.length;
    let isLengthMoreTextLength =
      this.state.Text.length > this.state.checkCharacterLengthButton
        ? true
        : false;

    return (
      <div className="api_block">
        <TooltipTitle
          idTooltip="i_BtnText"
          name={dict.smbaxuoj}
          tooltipDescription="Не должен быть пустым. У telegram есть ограничение на кол-во
           символов (20 для русских(и прочих не латинских алфавитов) букв,40 для остальных)."
        />

        <InputCheckCharacter
          type="text"
          name="Text"
          textLength={textLength}
          value={this.state.Text}
          onChange={this.handleInputChange}
          isLengthMoreTextLength={isLengthMoreTextLength}
        />

        {this.state.BtnType === "postBack" ? (
          <div>
            <TooltipTitle
              idTooltip="i_BtnRedirect"
              name={dict.jdtixoyc}
              tooltipDescription={dict.uxzsmozc}
            />

            <SelectPlus
              name="Url"
              value={this.state.Url}
              onChange={(val) => this.handleSelect(val, "Url")}
              options={options}
              clearable={false}
              pageSize={3}
              autosize={true}
            />
          </div>
        ) : (
          <div>
            <TooltipTitle
              idTooltip="i_BtnUrl"
              name={dict.jdtixoyc}
              tooltipDescription={dict.cvwomxuq}
            />

            <input
              className="chat_input"
              type="text"
              value={this.state.BtnUrl}
              name="BtnUrl"
              onChange={this.handleInputChange}
            />
          </div>
        )}

        {bind !== 'WhatsApp' && (
        <div className="wrap_filter_laber">
          <TooltipTitle
            idTooltip="i_BtnUrlCheckbox"
            name={dict.cvwomxuq}
            tooltipDescription={dict.gyydhzcw}
          />

          <input
            id="enable_filter"
            type="checkbox"
            checked={this.state.BtnType == "openUrl"}
            name={"BtnType"}
            onChange={this.onCheckMsg}
          />
        </div>)}

        {(bind === 'Telegram' || bind === 'WhatsApp') ? (
          <div className="wrap_filter_laber">
            <TooltipTitle
              idTooltip="i_BtnUrlCheckbox"
              name={dict.vertical_horizontal_label}
              tooltipDescription={dict.vertical_horizontal_info}
            />

            <input
              id="enable_filter"
              type="checkbox"
              checked={this.state.BtnPosition == "Horizontal"}
              name={"BtnPosition"}
              onChange={this.onCheckHorizontalHandler}
            />
          </div>
        ) : null}
      </div>
    );
  }

  CondBlock() {
    let options = [];
    var msgs = JSON.parse(JSON.stringify(this.props.BotMsgs));

    var ok = false;

    for (var i = 0; i < this.props.BotMsgs.length; i++) {
      if (this.props.BotMsgs[i]._id === this.props.BotMsg) {
        msgs.splice(i, 1);
      } else if (this.state.CondRedirect === this.props.BotMsgs[i]._id) {
        ok = true;
      }
    }

    if (!ok && this.props.id.length < 3) {
      for (var i = 0; i < this.props.BotMsgs.length; i++) {
        if (this.props.BotMsgs[i]._id !== this.props.BotMsg) {
          this.state.CondRedirect = this.props.BotMsgs[i]._id;
        }
      }

      this.props.handleForContent(
        this.state.Index,
        this.state.Text,
        this.state.typingTime,
        this.state.Link,
        this.state.myFile,
        this.state.Type,
        this.state.Url,
        this.state.ReqType,
        this.state.Field,
        0,
        this.state.Macros,
        this.state.BtnType,
        this.state.BtnUrl,
        this.state.Headers,
        this.state.Save,
        this.state.Cond,
        this.state.CondRedirect
      );
    }

    for (var i in msgs) {
      var ind = -1;

      for (var j in options) {
        if (
          options[j].label ===
          msgs[i].Group.Name + "(#" + msgs[i].Group._id + ")"
        ) {
          ind = j;
        }
      }

      if (ind === -1) {
        options.push({
          label: msgs[i].Group.Name + "(#" + msgs[i].Group._id + ")",
          options: [
            {
              label: msgs[i].Name + "(#" + msgs[i]._id + ")",
              value: msgs[i]._id,
            },
          ],
        });
      } else {
        options[ind].options.push({
          label: msgs[i].Name + "(#" + msgs[i]._id + ")",
          value: msgs[i]._id,
        });
      }
    }

    var context_params = [
      <option value="" />,
      <option value={"Context.Telegram.start"}>Context.Telegram.start</option>,
      <option value={"Context.Vk.start"}>Context.Vk.start</option>,
      <option value={"Context.Viber.start"}>Context.Viber.start</option>,
      <option value={"Context.Facebook.start"}>Context.Facebook.start</option>
    ];

    var names = [];
    var context_params_options2 = [];

    this.props.BotMsgs.forEach(function (e, i) {
      if (e.WaitAnswer && e.AnswerName && names.indexOf(e.AnswerName) === -1) {
        names.push(e.AnswerName);

        var param = "Context." + e.AnswerName;
        context_params_options2.push({
          label: param,
          value: param,
        });

        context_params.push(<option value={param}>{param}</option>);
      } else if (
        e.Type === "Api" &&
        e.Api &&
        e.Api.Save &&
        e.Api.Save.length > 0
      ) {
        var saves = e.Api.Save;

        for (var z = 0; z < saves.length; z++) {
          if (names.indexOf(saves[z].Key) === -1) {
            names.push(saves[z].Key);

            var param = "Context." + saves[z].Key;
            context_params_options2.push({ label: param, value: param });
            context_params.push(<option value={param}>{param}</option>);
          }
        }
      }
    });

    return (
      <div className="api_block">
        <TooltipTitle
          idTooltip="i_if"
          name="Если"
          tooltipDescription={
            "Если выполнится условие то бот отправит сообщение на которое указывает ссылка." +
            " В условии можно использовать javascript и Context переменные. " +
            "Пример: Context.age>=18 && Context.age<40 Для сравнения с текстом оберните его в двойные или одинарные кавычки" +
            'Пример: Context.name=="Иван'
          }
        />

        <div className="wrap_filter_laber">
          <TooltipTitle
            idTooltip="i_simple"
            name={dict.zvynhaes}
            tooltipDescription={dict.dtxqwaid}
          />

          <input
            type="checkbox"
            id={"i_simple"}
            name="SimpleMode"
            checked={this.state.SimpleMode}
            onChange={this.handleInputChange}
          />
        </div>

        {this.state.SimpleMode ? (
          <div>
            {this.state.SimpleCond !== 'isSubscriber' && <select
              className="chat_input"
              name="SimpleAttr"
              value={this.state.SimpleAttr}
              onChange={this.handleInputChange}
            >
              {context_params}
            </select>}

            <select
              className="chat_input"
              name="SimpleCond"
              value={this.state.SimpleCond}
              onChange={this.handleInputChange}
            >
              <option value={"=="}>{dict.ejmximcf}</option>
              <option value={"!="}>{dict.kiemwdti}</option>
              <option value={">"}>{dict.iluqidoi}</option>
              <option value={"<"}>{dict.rzduwgwg}</option>
              <option value={">="}>{dict.vekaigyh}</option>
              <option value={"<="}>{dict.hfrwvgxj}</option>
              <option value={"== i"}>{dict.stmuujrx}</option>
              <option value={"in i"}>{dict.whfsvjic}</option>
              <option value={"in"}>{dict.juujulql}</option>
              <option value={"nin i"}>{dict.ulcivltl}</option>
              <option value={"nin"}>{dict.maasdloq}</option>
              <option value={"isSubscriber"}>{dict.maasdlo2}</option>
            </select>

            <input
              value={this.state.SimpleValue}
              name="SimpleValue"
              placeholder={this.state.SimpleCond === 'isSubscriber' ? '@channelusername или chat_id' : ''}
              onChange={this.handleInputChange}
            />
          </div>
        ) : (
          <div>
            <input
              type="text"
              placeholder={'Context.product=="Футболка" && Context.size=="L"'}
              className="chat_input"
              name="Cond"
              value={this.state.Cond}
              onChange={this.handleInputChange}
            />
          </div>
        )}

        <TooltipTitle
          idTooltip="i_if_link"
          name={dict.jdtixoyc}
          tooltipDescription={dict.cwrkvwwb}
        />

        <SelectPlus
          name="CondRedirect"
          value={this.state.CondRedirect}
          onChange={(val) => this.handleSelect(val, "CondRedirect")}
          options={options}
          clearable={false}
        />
      </div>
    );
  }

  BtnBlockToGroup() {
    var bind = this.props.Bot.BindTo;
    var s = this.state;
    if (this.props.Groups && this.props.Groups.length > 0) {
      var options = this.props.Groups.map((msg) => (
        <option key={msg._id} value={msg._id}>{msg.Name + "(#" + msg._id + ")"}</option>
      ));
      var ok = false;
      for (var i = 0; i < this.props.Groups.length; i++) {
        if (this.state.Url == this.props.Groups[i]._id) {
          ok = true;
        }
      }
      if (!ok) {
        this.state.Url = this.props.Groups[0]._id;
        this.props.handleForContent(
          this.state.Index,
          this.state.Text,
          this.state.typingTime,
          this.state.Link,
          this.state.myFile,
          this.state.Type,
          this.state.Url,
          this.state.ReqType,
          this.state.Field,
          0,
          this.state.Macros,
          this.state.BtnType,
          this.state.BtnUrl,
          this.state.Headers,
          this.state.Save,
          this.state.Cond,
          this.state.CondRedirect,
          s.SimpleMode,
          s.SimpleAttr,
          s.SimpleCond,
          s.SimpleValue,
          s.BtnPosition
        );
      }
    }

    let textLength =
      this.state.checkCharacterLengthButton - this.state.Text.length;
    let isLengthMoreTextLength =
      this.state.Text.length > this.state.checkCharacterLengthButton
        ? true
        : false;

    return (
      <div className="api_block">
        <TooltipTitle
          idTooltip="i_BtnText"
          name={dict.smbaxuoj}
          tooltipDescription="Не должен быть пустым. У telegram есть ограничение на кол-во символов
           (20 для русских(и прочих не латинских алфавитов) букв,40 для остальных)."
        />

        <InputCheckCharacter
          type="text"
          name="Text"
          textLength={textLength}
          value={this.state.Text}
          onChange={this.handleInputChange}
          isLengthMoreTextLength={isLengthMoreTextLength}
        />

        {this.state.BtnType == "postBack" ? (
          <div>
            <TooltipTitle
              idTooltip="i_BtnRedirect"
              name="Ссылка на группу"
              tooltipDescription="Cтартовое сообщение которой будет вызвано после нажатия на кнопку."
            />

            <select
              className="chat_input"
              name="Url"
              value={this.state.Url}
              onChange={this.handleInputChange}
            >
              {options}
            </select>
          </div>
        ) : (
          <div>
            <TooltipTitle
              idTooltip="i_BtnUrl"
              name="Ссылка"
              tooltipDescription="Ссылка на веб-ресурс"
            />

            <input
              className="chat_input"
              type="text"
              value={this.state.BtnUrl}
              name={"BtnUrl"}
              onChange={this.handleInputChange}
            />
          </div>
        )}
        {bind !== 'WhatsApp' && (
            <div className="wrap_filter_laber">
          <TooltipTitle
            idTooltip="i_BtnUrlCheckbox"
            name={dict.cvwomxuq}
            tooltipDescription={dict.gyydhzcw}
          />

          <input
            id="enable_filter_1"
            className=" border_textarea_"
            type="checkbox"
            checked={this.state.BtnType == "openUrl"}
            name={"BtnType"}
            disabled={this.props.BotMsg && this.props.BotMsg.SendToChannels}
            onChange={this.onCheckGroup}
          />
        </div>)}

        {(bind === 'Telegram' || bind === 'WhatsApp') ? (
          <div className="wrap_filter_laber">
            <TooltipTitle
              idTooltip="i_BtnUrlCheckbox"
              name={dict.vertical_horizontal_label}
              tooltipDescription={dict.vertical_horizontal_info}
            />

            <input
              id="i_BtnUrlCheckbox"
              type="checkbox"
              checked={this.state.BtnPosition == "Horizontal"}
              name={"BtnPosition"}
              onChange={this.onCheckHorizontalHandler}
            />
          </div>
        ) : null}
      </div>
    );
  }

  /*{upload video}*/
  UploadVideo() {
    return (
      <Player>
        <source src={this.state.Url} />
      </Player>
    );
  }

  /*{End upload video}*/

  /*{ upload audio}*/
  UploadAudio() {
    var key = "";
    var chars = [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "z",
      "x",
      "c",
      "v",
      "b",
      "n",
      "m",
      "a",
      "s",
      "d",
      "f",
      "g",
      "h",
      "j",
      "k",
      "l",
      "q",
      "w",
      "e",
      "r",
      "t",
      "y",
      "u",
      "i",
      "o",
      "p",
    ];
    for (var i = 0; i < 12; i++) {
      var x = Math.floor(Math.random() * (chars.length - 1));
      key += chars[x];
    }
    var id = key + "AudioPlayerId";

    return (
      <div className="uploadAudio">
        <Audio id={id} src={this.state.Url} />
      </div>
    );
  }

  /*{End upload audio}*/

  MessageBlock() {
    // this.props.BotMsg в конструкторе это _id, в рассылке - полный объект
    // this.props.Msg есть только в текстовом контенте в конструкторе
    return (
      <MessageBlock
        broadcast={this.props.Broadcast}
        handleInputChange={this.handleInputChange}
        bot={this.props.Bot.BindTo}
        BotMsg={this.props.Broadcast ? this.props.BotMsg : this.props.Msg}
        ImageAndTextInOneMessage={this.props.ImageAndTextInOneMessage}
        state={this.state}
      />
    );
  }

  getLinkAllBlocksOptions() {
    var msgs = JSON.parse(JSON.stringify(this.props.BotMsgs));
    var current = this.props.BotMsg;
    for (var i = 0; i < this.props.BotMsgs.length; i++) {
      if (this.props.BotMsgs[i]._id == current) {
        msgs.splice(i, 1);
      }
    }

    return prepareLinkAllBlocksOptions(msgs);
  }

  LinkAfterPaymentSuccessBlock() {
    return (
      <div className="api_block">
        <TooltipTitle
          idTooltip="i_DefaultLinkBlock"
          name={dict.vmqureqz}
          tooltipDescription={dict.hoyazуtx}
        />

        <SelectPlus
          name="Url"
          value={this.state.Url}
          onChange={(val) => this.handleSelect(val, "Url")}
          options={this.getLinkAllBlocksOptions()}
        />
      </div>
    );
  }

  DefaultLinkBlock() {
    return (
      <div className="api_block">
        <TooltipTitle
          idTooltip="i_LinkAfterPaymentSuccessBlock"
          name="Ссылка по умолчанию"
          tooltipDescription={
            dict.hoyazctx +
            "Однако если вы включите опцию " +
            '"Подождать ответа", то бот сначала дождется ответа\' ' +
            "пользователя а затем вызовет следующее сообщение."
          }
        />

        <SelectPlus
          name="Url"
          value={this.state.Url}
          onChange={(val) => this.handleSelect(val, "Url")}
          options={this.getLinkAllBlocksOptions()}
        />
      </div>
    );
  }

  DefaultLinkBlockToGroup() {
    var options = this.props.Groups.map((g) => (
      <option key={g._id} value={g._id}>
        {g.Name + "(#" + g._id + ")"}
      </option>
    ));
    return (
      <div className="api_block">
        <TooltipTitle
          idTooltip="i_DefaultLinkBlock"
          name="Ссылка по умолчанию"
          tooltipDescription={
            "После того как бот напечатает это сообщение он вызовет стартовое сообщение группы " +
            'на которую ведет эта ссылка. Однако если вы включите опцию "Подождать ответа", то бот сначала дождется ответа\' +\n' +
            '" пользователя а затем вызовет следующее сообщение.'
          }
        />

        <select
          className="chat_input"
          name="Url"
          value={this.props.Url ? this.props.Url : "-"}
          onChange={this.handleInputChange}
        >
          <option value="-">-</option>
          {options}
        </select>
      </div>
    );
  }

  render() {
    return (
      <div className="container2 mt_c15">
        {this.props.Type === "Text" ? this.MessageBlock() : null}
        {this.props.Type === "Video" ? this.UploadVideo() : null}
        {this.props.Type === "Audio" ? this.UploadAudio() : null}
        {this.props.Type === "Image" ? this.UploadImage() : null}
        {this.props.Type === "Product Image" ? this.UploadImage() : null}
        {this.props.Type === "File" ? this.UploadFile() : null}
        {this.props.Type === "Api" ? this.ApiBlock() : null}
        {this.props.Type === "Btn" ? this.BtnBlock() : null}
        {this.props.Type === "Cond" ? this.CondBlock() : null}
        {this.props.Type === "BtnToGroup" ? this.BtnBlockToGroup() : null}
        {this.props.Type === "DefaultLink" ? this.DefaultLinkBlock() : null}
        {this.props.Type === "LinkAfterPaymentSuccess"
          ? this.LinkAfterPaymentSuccessBlock()
          : null}
        {this.props.Type === "DefaultLinkToGroup"
          ? this.DefaultLinkBlockToGroup()
          : null}

        {this.canBeDeleted() ? (
          <div className="button_delete_block">
            <span
              className="right_delete_icon"
              onClick={() =>
                this.props.onDelete(this.props.Index, this.state.Type)
              }
            ></span>
          </div>
        ) : null}
      </div>
    );
  }
}

export default Content;
