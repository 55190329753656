import React from 'react';
import jQuery from 'jquery';
import Cookies from 'universal-cookie';
import TopPanel from '../../TopPanel/TopPanel';
import {Scrollbars} from 'react-custom-scrollbars';
import PopupAll from '../../PopupAll';
import TransComponent from '../../../TransComponent.js';
import { config } from '../../../config.js';

const cookies = new Cookies();
var func = require('../../../func.js');
var Networks = ['Vk', 'Telegram', 'Viber', 'FB'];
var defaultFrameBots = {};
Networks.forEach(soc => {
  defaultFrameBots[soc] = {
    Bot: '',
    Groups: [],
    Group: '',
    BotMsgs: [],
    BotMsg: '',
    Additional: '',
    Enable: false
  };
});

class Widget extends TransComponent {
  constructor(props) {

    super(props);

    this.state = {
      User: null,
      FrameBots: defaultFrameBots,
      Bots: [],
      Image: '',
      Policy: '',
      CustomText: '',
      ButtonText: '',
      ButtonColor: '#070058',
      Wait: 0
    };

    this.onChangeHandler = config.change.bind(this);
    this.loadBots = func.loadBots.bind(this);
    this.loadGroups = func.loadGroups.bind(this);
    this.loadGroupMsgs = func.loadGroupMsgs.bind(this);
  }

  load = () => {
    var url = config.Backend + '/users/findById?';
    if ( !(cookies.get('Id') === undefined) ) {
      url += 'Id=' + cookies.get('Id').toString();
    }
    this.setState({
      Wait: ++this.state.Wait
    });

    jQuery.getJSON(url, function (res) {
      this.setState({
        Wait: --this.state.Wait
      });

      if ( !res.Error ) {
        this.setState({
          User: res
        });
        this.loadBots();
      } else {
        window.location.replace(window.location.origin + '/login');
      }
    }.bind(this));
  };

  componentDidMount() {
    this.t_load();
    this.load();
  }

  onEnableFrameBotHandler = (e) => {
    var s = this.state;
    s.FrameBots[e.target.getAttribute('soc')].Enable = e.target.checked;
    this.setState({
      FrameBots: s.FrameBots
    });
  };

  onChangeBotHandler = (e) => {
    var s = this.state;
    var id = e.target.value;
    var soc = e.target.getAttribute('soc');
    var bot = s.FrameBots[soc];
    var options = {
      setup: false
    };

    this.loadGroups(id, (groups) => {
      bot.Bot = id;
      bot.Groups = groups;
      bot.BotMsg = '';
      bot.Group = '';
      s.Bots.forEach(b => {
        if (b._id == id) {
          if (soc == 'Vk') {
            bot.Additional = b.VkPage;
          } else if (soc == 'Telegram') {
            bot.Additional = b.TelegramName;
          } else if (soc == 'Viber') {
            bot.Additional = b.ViberUri;
          } else if (soc == 'FB') {
            bot.Additional = b.Page;
          }
        }
      });
      this.setState({
        FrameBots: s.FrameBots
      });
    }, options);
  };

  onChangeGroupHandler = (e) => {
    var s = this.state;
    var id = e.target.value;
    var bot = s.FrameBots[e.target.getAttribute('soc')];
    var options = {
      setup: false
    };

    this.loadGroupMsgs(bot.Bot, id, (msgs) => {
      bot.Group = id;
      bot.BotMsgs = msgs;
      bot.BotMsg = '';
      this.setState({
        FrameBots: s.FrameBots
      });
    }, options);
  };

  onChangeBotMsgHandler = (e) => {
    var s = this.state;
    var id = e.target.value;
    s.FrameBots[e.target.getAttribute('soc')].BotMsg = id;
    this.setState({
      FrameBots: s.FrameBots
    });
  };

  FrameCodeGenerator() {
    var s = this.state;

    var bots = {};
    for (var soc in s.FrameBots) {
      var bot = s.FrameBots[soc];

      if (bot.Enable && bot.Bot) {
        bots[soc] = {
          BotId: bot.Bot,
          MsgId: bot.BotMsg,
          Additional: bot.Additional
        };
      }
    }

    var query = '?bots=' + encodeURIComponent(JSON.stringify(bots))
      + '&image=' + s.Image + '&policy=' + s.Policy + '&text=' + s.CustomText;

    var src = config.FrontHtml + '/widget.html' + query;
    var script_src = config.FrontHtml + '/widget.js';

    var code = '<script src="' + script_src + '" widget="' + src + '"' +
      ' buttonText="' + s.ButtonText + '" buttonColor="' + s.ButtonColor + '"' +
      ' type="text/javascript"></script>';

    return (<div className={'form-group bd pd-t-30 pd-b-20 pd-x-20'}>
      <label>
        {this.t("Скопируйте код фрейма и вставьте на страницу своего сайта")}
      </label>

      <div className="form-group">
        <textarea className={'chat_input'} value={code}>

          </textarea>
      </div>

      <label>
        {this.t("Как это будет выглядеть")}
      </label>

      <div className="form-group bd pd-t-30 pd-b-20 pd-x-20">
        <iframe  className={'widget_inflame'} src={src}>
          Ваш браузер не поддерживает плавающие фреймы!
        </iframe>
      </div>
    </div>);
  }

  MainContent() {
    var s = this.state;

    var frame_bots = [];
    for (var soc in s.FrameBots) {

      var bots = s.Bots.map(b => {
        if ( b.BindTo == soc ) {
          return (<option value={b._id}>
            {b.Name}
          </option>);
        }
      });

      var bot = s.FrameBots[soc];

      var choose_messages = null;

      if (['Vk', 'Viber'].indexOf(soc) != -1) {
        var msgs_options = bot.BotMsgs.map(m => {
          return (<option value={m._id}>
            {m.Name}
          </option>);
        });

        var groups_options = bot.Groups.map(g => {
          return (<option value={g._id}>
            {g.Name}
          </option>);
        });

        choose_messages = (<div className={"form-group bd pd-t-30 pd-b-20 pd-x-20"}>
          <label>
            {this.t('Выберите группу которая содержит нужное сообщение')}
          </label>

          <div className="form-group">
            <select className={"chat_input"} value={bot.Group} onChange={this.onChangeGroupHandler} soc={soc}>
              <option value=""></option>

              {groups_options}
            </select>
          </div>

          <label>
            {this.t('Выберите сообщение которое будет отправлено')}
          </label>

          <div className="form-group">
            <select className={"chat_input"} value={bot.BotMsg} onChange={this.onChangeBotMsgHandler} soc={soc}>
              <option value=""></option>

              {msgs_options}
            </select>
          </div>

        </div>);
      }

      frame_bots.push(<li className={'mb10'}>

        <div className={'row__grid grid__align--center'}>

          <span className={soc}></span>

          <div className="row__grid grid__align--center">
            <span className={'ml5 mr5'}>Включить соцсеть</span>

            <input type={"checkbox"} checked={bot.Enable} soc={soc}
                   onChange={this['onEnableFrameBotHandler']}/>
          </div>
        </div>

        {bot.Enable ?
          <div>
            <div className={'mt_10'}>
              <label>
                {this.t('Выберите бота')}
              </label>

              <select className={'chat_input'} value={bot.Bot} onChange={this.onChangeBotHandler} soc={soc}>
                <option value=""></option>
                {bots}
              </select>
            </div>

            {choose_messages}

          </div> : null}
      </li>);
    }

    return (
      <div className="col-sm-12">
        {s.User ?
          <div className="mt_25">
            <div className="mt_5 form-group bd pd-t-30 pd-b-20 pd-x-20">
              <input className={"chat_input"} type={"Text"} value={s.Image} name={"Image"}
                placeholder={this.t("ссылка на изображение")} onChange={this.onChangeHandler}/>

              <input className={"chat_input"} type={"Text"} value={s.Policy} name={"Policy"}
                placeholder={this.t("ссылка на правила использования")} onChange={this.onChangeHandler}/>

              <textarea className={'chat_input'} value={s.CustomText} name={"CustomText"}
                placeholder={this.t("Произвольный текст")} onChange={this.onChangeHandler}>
              </textarea>

              <textarea className={'chat_input'} value={s.ButtonText} name={"ButtonText"}
                placeholder={this.t("Название кнопки показа виджета")} onChange={this.onChangeHandler}>
              </textarea>

              <label>
                {this.t("Цвет кнопки показа виджета")}
              </label>

              <div>
                <input type={"color"} value={s.ButtonColor}
                       name={"ButtonColor"} onChange={this.onChangeHandler}/>
              </div>
            </div>

            <ul className={'mt_5 form-group bd pd-t-30 pd-b-20 pd-x-20'}>
              {frame_bots}
            </ul>

            {this.FrameCodeGenerator()}
          </div> : null}
      </div>
    );
  }

  render() {
    var s = this.state;

    return (
      <div>
        {this.state.Wait != 0 ? this.Load() : null}

        <TopPanel/>

        <PopupAll/>

        <div className={'broad_main'}>
          <Scrollbars style={{
            height: "100%"
          }}>

            <div className="overlay_chart">
                {this.MainContent()}
            </div>

          </Scrollbars>
        </div>
      </div>);
  }

}

export default Widget;
