import React from "react";
import jQuery from "jquery";
import Cookies from "universal-cookie";
import FacebookLogin from "react-facebook-login";
import TransComponent from "../../TransComponent.js";
import { Alert, Spin } from "antd";
import { CSSTransitionGroup } from "react-transition-group";
import { Link, withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import { signInRequest } from "../../modules/Login";
import { config } from "../../config.js";
const cookies = new Cookies();

class LoginForm extends TransComponent {
  constructor(props) {
    super(props);
    this.state = {
      Email: "",
      Email_valid: true,
      Password: "",
      Password_valid: true,
      Response_txt: "",
      Error: false,
      Type: "",
    };

    this.onChangeHandler = config.onChangeHandler.bind(this);
  }

  vk = () => {
    window.open(
      "https://oauth.vk.com/authorize?client_id=" +
        config.VkAppId +
        "&redirect_uri=" +
        config.Frontend +
        "/login" +
        "&response_type=token&scope=email&v=" +
        config.VkApiV +
        "&state=vk"
    );
  };

  vkLongToken = () => {
    window.open(
      "https://oauth.vk.com/authorize?client_id=" +
        config.VkAppId +
        "&redirect_uri=" +
        config.Frontend +
        "/vk" +
        "&response_type=code&scope=photos,video,audio,offline,groups,email" +
        "&state=log"
    );
  };

  xor = (ai) => {
    var f = "";
    for (var c = 0; c < ai.length; c++) {
      f += String.fromCharCode(ai.charCodeAt(c) ^ (1 + ((ai.length - c) % 32)));
    }
    return f;
  };

  ok = () => {
    window.open(
      "https://connect.ok.ru/oauth/authorize?client_id=" +
        config.OkAppId +
        "&redirect_uri=" +
        config.Frontend +
        "/login" +
        "&response_type=token&scope=GET_EMAIL" +
        "&state=ok"
    );
  };

  facebook = (res) => {
    const Email_filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (res.id !== undefined) {
      var url =
        config.Backend +
        "/fbLogin?" +
        "&Name=" +
        res.name +
        "&accessToken=" +
        res.accessToken +
        "&Fb_id=" +
        res.id;
      if (res.email !== undefined) {
        url += "&Email=" + res.email;
      } else if (Email_filter.test(this.state.Email)) {
        url += "&Email=" + this.state.Email;
      }
      jQuery.getJSON(
        url,
        function (res2) {
          if (res2.Error) {
            this.setState({
              Error: true,
              Response_txt: res2.Error,
            });
          } else {
            this.setState({
              Error: false,
              Response_txt: res2.Ok,
            });
            var exp = new Date();
            exp.setDate(exp.getDate() + 3);
            cookies.set("Id", res2.User._id, {
              path: "/",
              expires: exp,
            });
            if (res2.User.Type === "Admin") {
              this.props.history.push("/dashboard");
            } else if (res2.User.Type == "Super") {
              this.props.history.push("/admin/main");
            }
          }
        }.bind(this)
      );
    }
  };

  componentDidMount() {
    const params = new URLSearchParams(this.props.location.hash.substr(1));
    const user_id = params.get("user_id");
    const email = params.get("email");
    const token = params.get("access_token");
    const secret = params.get("session_secret_key");
    const state = params.get("state");
    if (state === "vk" && user_id && email) {
      var url =
        config.Backend +
        "/soc?" +
        "&Email=" +
        email +
        "&Vk=" +
        user_id +
        "&Type=Admin" +
        "&Name=" +
        email;
      if (!(cookies.get("Id") === undefined)) {
        url += "&Id=" + cookies.get("Id").toString();
      }

      jQuery.getJSON(
        url,
        function (res) {
          if (res.Error) {
            this.setState({
              Error: true,
              Response_txt: res.Error,
            });
          } else {
            this.setState({
              Error: false,
              Response_txt: res.Ok,
            });
            cookies.set("Id", res.User._id, {
              path: "/",
            });
            if (res.User.Type !== "") {
              if (res.User.Type === "Admin") {
                this.props.history.push("/dashboard");
              } else if (res.User.Type === "Super") {
                this.props.history.push("/admin/main");
              }
            }
          }
        }.bind(this)
      );
    } else if (state === "ok") {
      var MD5 = function (s) {
        function L(k, d) {
          return (k << d) | (k >>> (32 - d));
        }
        function K(G, k) {
          var I, d, F, H, x;
          F = G & 2147483648;
          H = k & 2147483648;
          I = G & 1073741824;
          d = k & 1073741824;
          x = (G & 1073741823) + (k & 1073741823);
          if (I & d) {
            return x ^ 2147483648 ^ F ^ H;
          }
          if (I | d) {
            if (x & 1073741824) {
              return x ^ 3221225472 ^ F ^ H;
            } else {
              return x ^ 1073741824 ^ F ^ H;
            }
          } else {
            return x ^ F ^ H;
          }
        }
        function r(d, F, k) {
          return (d & F) | (~d & k);
        }
        function q(d, F, k) {
          return (d & k) | (F & ~k);
        }
        function p(d, F, k) {
          return d ^ F ^ k;
        }
        function n(d, F, k) {
          return F ^ (d | ~k);
        }
        function u(G, F, aa, Z, k, H, I) {
          G = K(G, K(K(r(F, aa, Z), k), I));
          return K(L(G, H), F);
        }
        function f(G, F, aa, Z, k, H, I) {
          G = K(G, K(K(q(F, aa, Z), k), I));
          return K(L(G, H), F);
        }
        function D(G, F, aa, Z, k, H, I) {
          G = K(G, K(K(p(F, aa, Z), k), I));
          return K(L(G, H), F);
        }
        function t(G, F, aa, Z, k, H, I) {
          G = K(G, K(K(n(F, aa, Z), k), I));
          return K(L(G, H), F);
        }
        function e(G) {
          var Z;
          var F = G.length;
          var x = F + 8;
          var k = (x - (x % 64)) / 64;
          var I = (k + 1) * 16;
          var aa = Array(I - 1);
          var d = 0;
          var H = 0;
          while (H < F) {
            Z = (H - (H % 4)) / 4;
            d = (H % 4) * 8;
            aa[Z] = aa[Z] | (G.charCodeAt(H) << d);
            H++;
          }
          Z = (H - (H % 4)) / 4;
          d = (H % 4) * 8;
          aa[Z] = aa[Z] | (128 << d);
          aa[I - 2] = F << 3;
          aa[I - 1] = F >>> 29;
          return aa;
        }
        function B(x) {
          var k = "",
            F = "",
            G,
            d;
          for (d = 0; d <= 3; d++) {
            G = (x >>> (d * 8)) & 255;
            F = "0" + G.toString(16);
            k = k + F.substr(F.length - 2, 2);
          }
          return k;
        }
        function J(k) {
          k = k.replace(/rn/g, "n");
          var d = "";
          for (var F = 0; F < k.length; F++) {
            var x = k.charCodeAt(F);
            if (x < 128) {
              d += String.fromCharCode(x);
            } else {
              if (x > 127 && x < 2048) {
                d += String.fromCharCode((x >> 6) | 192);
                d += String.fromCharCode((x & 63) | 128);
              } else {
                d += String.fromCharCode((x >> 12) | 224);
                d += String.fromCharCode(((x >> 6) & 63) | 128);
                d += String.fromCharCode((x & 63) | 128);
              }
            }
          }
          return d;
        }
        var C = Array();
        var P, h, E, v, g, Y, X, W, V;
        var S = 7,
          Q = 12,
          N = 17,
          M = 22;
        var A = 5,
          z = 9,
          y = 14,
          w = 20;
        var o = 4,
          m = 11,
          l = 16,
          j = 23;
        var U = 6,
          T = 10,
          R = 15,
          O = 21;
        s = J(s);
        C = e(s);
        Y = 1732584193;
        X = 4023233417;
        W = 2562383102;
        V = 271733878;
        for (P = 0; P < C.length; P += 16) {
          h = Y;
          E = X;
          v = W;
          g = V;
          Y = u(Y, X, W, V, C[P + 0], S, 3614090360);
          V = u(V, Y, X, W, C[P + 1], Q, 3905402710);
          W = u(W, V, Y, X, C[P + 2], N, 606105819);
          X = u(X, W, V, Y, C[P + 3], M, 3250441966);
          Y = u(Y, X, W, V, C[P + 4], S, 4118548399);
          V = u(V, Y, X, W, C[P + 5], Q, 1200080426);
          W = u(W, V, Y, X, C[P + 6], N, 2821735955);
          X = u(X, W, V, Y, C[P + 7], M, 4249261313);
          Y = u(Y, X, W, V, C[P + 8], S, 1770035416);
          V = u(V, Y, X, W, C[P + 9], Q, 2336552879);
          W = u(W, V, Y, X, C[P + 10], N, 4294925233);
          X = u(X, W, V, Y, C[P + 11], M, 2304563134);
          Y = u(Y, X, W, V, C[P + 12], S, 1804603682);
          V = u(V, Y, X, W, C[P + 13], Q, 4254626195);
          W = u(W, V, Y, X, C[P + 14], N, 2792965006);
          X = u(X, W, V, Y, C[P + 15], M, 1236535329);
          Y = f(Y, X, W, V, C[P + 1], A, 4129170786);
          V = f(V, Y, X, W, C[P + 6], z, 3225465664);
          W = f(W, V, Y, X, C[P + 11], y, 643717713);
          X = f(X, W, V, Y, C[P + 0], w, 3921069994);
          Y = f(Y, X, W, V, C[P + 5], A, 3593408605);
          V = f(V, Y, X, W, C[P + 10], z, 38016083);
          W = f(W, V, Y, X, C[P + 15], y, 3634488961);
          X = f(X, W, V, Y, C[P + 4], w, 3889429448);
          Y = f(Y, X, W, V, C[P + 9], A, 568446438);
          V = f(V, Y, X, W, C[P + 14], z, 3275163606);
          W = f(W, V, Y, X, C[P + 3], y, 4107603335);
          X = f(X, W, V, Y, C[P + 8], w, 1163531501);
          Y = f(Y, X, W, V, C[P + 13], A, 2850285829);
          V = f(V, Y, X, W, C[P + 2], z, 4243563512);
          W = f(W, V, Y, X, C[P + 7], y, 1735328473);
          X = f(X, W, V, Y, C[P + 12], w, 2368359562);
          Y = D(Y, X, W, V, C[P + 5], o, 4294588738);
          V = D(V, Y, X, W, C[P + 8], m, 2272392833);
          W = D(W, V, Y, X, C[P + 11], l, 1839030562);
          X = D(X, W, V, Y, C[P + 14], j, 4259657740);
          Y = D(Y, X, W, V, C[P + 1], o, 2763975236);
          V = D(V, Y, X, W, C[P + 4], m, 1272893353);
          W = D(W, V, Y, X, C[P + 7], l, 4139469664);
          X = D(X, W, V, Y, C[P + 10], j, 3200236656);
          Y = D(Y, X, W, V, C[P + 13], o, 681279174);
          V = D(V, Y, X, W, C[P + 0], m, 3936430074);
          W = D(W, V, Y, X, C[P + 3], l, 3572445317);
          X = D(X, W, V, Y, C[P + 6], j, 76029189);
          Y = D(Y, X, W, V, C[P + 9], o, 3654602809);
          V = D(V, Y, X, W, C[P + 12], m, 3873151461);
          W = D(W, V, Y, X, C[P + 15], l, 530742520);
          X = D(X, W, V, Y, C[P + 2], j, 3299628645);
          Y = t(Y, X, W, V, C[P + 0], U, 4096336452);
          V = t(V, Y, X, W, C[P + 7], T, 1126891415);
          W = t(W, V, Y, X, C[P + 14], R, 2878612391);
          X = t(X, W, V, Y, C[P + 5], O, 4237533241);
          Y = t(Y, X, W, V, C[P + 12], U, 1700485571);
          V = t(V, Y, X, W, C[P + 3], T, 2399980690);
          W = t(W, V, Y, X, C[P + 10], R, 4293915773);
          X = t(X, W, V, Y, C[P + 1], O, 2240044497);
          Y = t(Y, X, W, V, C[P + 8], U, 1873313359);
          V = t(V, Y, X, W, C[P + 15], T, 4264355552);
          W = t(W, V, Y, X, C[P + 6], R, 2734768916);
          X = t(X, W, V, Y, C[P + 13], O, 1309151649);
          Y = t(Y, X, W, V, C[P + 4], U, 4149444226);
          V = t(V, Y, X, W, C[P + 11], T, 3174756917);
          W = t(W, V, Y, X, C[P + 2], R, 718787259);
          X = t(X, W, V, Y, C[P + 9], O, 3951481745);
          Y = K(Y, h);
          X = K(X, E);
          W = K(W, v);
          V = K(V, g);
        }
        var i = B(Y) + B(X) + B(W) + B(V);
        return i.toLowerCase();
      };
      var sig = MD5(
        "application_key=" +
          config.OkAppKey +
          "fields=uid,email,nameformat=jsonmethod=users.getCurrentUser" +
          secret
      );
      var url =
        config.OkApi +
        "?application_key=" +
        config.OkAppKey +
        "&fields=uid,email,name" +
        "&format=json" +
        "&method=users.getCurrentUser" +
        "&sig=" +
        sig +
        "&access_token=" +
        token;

      jQuery.getJSON(
        url,
        function (res) {
          var url =
            config.Backend +
            "/soc?" +
            "&Email=" +
            res.email +
            "&Vk=" +
            res.uid +
            "&Type=Admin" +
            "&Name=" +
            res.name;
          const params = new URLSearchParams(this.props.location.search);
          const key = params.get("key"); //реферальный ключ
          if (key) {
            url += "&Key=" + key;
          }
          if (!(cookies.get("Id") === undefined)) {
            url += "&Id=" + cookies.get("Id").toString();
          }
          jQuery.getJSON(
            url,
            function (res) {
              if (res.Error) {
                this.setState({
                  Response_txt: res.Error,
                  Error: true,
                });
              } else {
                this.setState({
                  Error: false,
                  Response_txt: res.Ok,
                });
                cookies.set("Id", res.User._id, {
                  path: "/",
                });
                if (res.User.Type !== "") {
                  if (res.User.Type === "Admin") {
                    this.props.history.push("/dashboard");
                  } else if (res.User.Type === "Super") {
                    this.props.history.push("/admin/main");
                  }
                }
              }
            }.bind(this)
          );
        }.bind(this)
      );
    }
  }

  onSubmitForm = (e) => {
    const profile = this.props.profile;
    this.props.dispatch(signInRequest(this.state.Email, this.state.Password));
  };

  resetPass = (e) => {
    e.preventDefault();
    window.location.replace(window.location.origin + "/reset");
  };

  onChangeHandler = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  Response() {
    const danger = this.state.Error ? (
      <div className={"form__error"}>
        <Alert message={this.t(this.state.Response_txt)} type="error" />
      </div>
    ) : (
      <div className={"form__error none"}>
        <Alert message={this.t(this.state.Response_txt)} type="error" />
      </div>
    );
    return (
      <div className="form-group">
        <CSSTransitionGroup
          transitionName="example"
          transitionEnterTimeout={500}
          transitionLeaveTimeout={500}
        >
          {danger}
        </CSSTransitionGroup>
      </div>
    );
  }

  render() {
    const data = this.props;
    if (data.success) {
      var exp = new Date();
      exp.setDate(exp.getDate() + 3);
      cookies.set("Id", data.profile.id, { path: "/", expires: exp });

      if (data.profile.Type === "Admin") {
        this.props.history.push("/dashboard");
      } else if (data.profile.Type === "Super") {
        this.props.history.push("/admin/main");
      }
    }

    const { emailPassword, emailData } = this.state;
    const danger = this.props.errorLogin ? (
      <div className="mb10">
        <Alert message={this.props.errorMessage} type="warning" />
      </div>
    ) : (
      ""
    );

    return (
      <div className="background_comein loginPage">
        <div className="form_block_login">
          <div className="login">
            <div className="block_img">
              <Link to="/">
                <img src="/img/logo-botkits.svg" />
              </Link>
            </div>

            <div className="login_block_form">
              <p>{this.t("У вас еще нет аккаунта?")}</p>

              <Link to="/register">{this.t("Создать аккаунт")}</Link>
            </div>
            {this.Response()}

            {danger}
            <div className="form-group">
              <input
                type="text"
                placeholder={this.t("Email")}
                value={emailData}
                name="Email"
                onChange={this.onChangeHandler}
              />
            </div>

            <div className="form-group">
              <input
                type="Password"
                placeholder={this.t("Пароль")}
                value={emailPassword}
                name="Password"
                onChange={this.onChangeHandler}
              />

              <Link to="/reset" className="forgot_password">
                {this.t("Забыли пароль?")}
              </Link>
            </div>

            <button
              disabled={this.props.loading ? true : false}
              style={{
                display: "flex",
                justifyContent: "center",
                color: this.props.loading ? "#1890ff" : "white",
                backgroundColor: this.props.loading ? "white" : "#219d57",
              }}
              onClick={this.onSubmitForm}
              className="btn_login"
            >
              {this.props.loading ? (
                <div>
                  <Spin size={"small"} />
                  <span className="ml5">Идёт загрузка...</span>
                </div>
              ) : (
                "Войти"
              )}
            </button>
          </div>

          <div className="login_social">
            <div className="social_block">
              <button
                className="social_item login_vk"
                onClick={this.vkLongToken}
              />

              <button className="social_item login_ok" onClick={this.ok} />

              <FacebookLogin
                appId={config.FbAppId}
                autoLoad={false}
                fields="name,email,picture"
                cssClass="social_item btn_facebook"
                icon="fa-facebook "
                reAuthenticate={true}
                textButton={""}
                scope={
                  "pages_show_list,pages_manage_metadata,pages_read_engagement,pages_read_user_content,email,pages_messaging," +
                  "pages_messaging_subscriptions,public_profile"
                }
                returnScopes={true}
                tag={"button"}
                callback={this.facebook}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.login.loading,
    errorMessage: state.login.errorMessage,
    errorLogin: state.login.error,
    success: state.login.success,
    profile: state.login.ok.User,
  };
};

export default compose(connect(mapStateToProps)(withRouter(LoginForm)));
