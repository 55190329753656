import React from 'react';
import jQuery from 'jquery';
import Cookies from 'universal-cookie';
import TopPanel from '../../TopPanel/TopPanel';
import TransComponent from '../../../TransComponent.js';
import UrlTableRow from './UrlTableRow.js';
import ReactTooltip from 'react-tooltip';
import {Scrollbars} from 'react-custom-scrollbars';
import {Link} from "react-router-dom";
import { config } from '../../../config.js';

const cookies = new Cookies();
var func = require('../../../func.js');

class Url extends TransComponent {

  constructor(props) {
    super(props);
    this.state = {
      User: {},
      Urls: [],
      Url: '',
      Wait: 0
    };

    this.onChangeHandler = config.change.bind(this);
  }

  componentDidMount () {
    this.t_load();
    this.load();
  }

  load = () => {
    var url = config.Backend + '/users/findById?';
    if (!(cookies.get('Id') === undefined)) {
      url += 'Id=' + cookies.get('Id').toString();
    }
    this.setState({
      Wait: ++this.state.Wait
    });

    jQuery.getJSON(url, function (res) {
      this.setState({
        Wait: --this.state.Wait
      });

      if (!res.Error) {
        this.setState({
          User: res
        }, () => {
          this.setState({
            Wait: ++this.state.Wait
          });
          func.shortUrlApiCall('all').then(urls => {
            this.setState({
              Wait: --this.state.Wait,
              Urls: urls
            });
          }, err => {
            this.setState({
              Wait: --this.state.Wait
            });
          });
        });
      } else {
        window.location.replace(window.location.origin + '/login');
      }
    }.bind(this));
  };

  onAddUrlHandler = () => {
    var s =this.state;

    this.setState({
      Wait: ++this.state.Wait
    });
    func.shortUrlApiCall('add', {
      Url: s.Url
    }).then(url => {
      s.Urls.push(url);

      this.setState({
        Wait: --this.state.Wait,
        Urls: s.Urls
      });
    }, err => {
      this.setState({
        Wait: --this.state.Wait
      });
    });
  };

  onDeleteHandler = (elem, ind) => {
    var s =this.state;

    this.setState({
      Wait: ++this.state.Wait
    });
    func.shortUrlApiCall('delete', {
      ShortUrlId: elem._id
    }).then(url => {
      s.Urls.splice(ind, 1);

      this.setState({
        Wait: --this.state.Wait,
        Urls: s.Urls
      });
    }, err => {
      this.setState({
        Wait: --this.state.Wait
      });
    });
  };

  render() {
    var s =this.state;
    var urls = s.Urls.map((elem,ind) =>
      <UrlTableRow domen={config.ShortUrlApi} elem={elem} ind={ind}
        onDeleteHandler={this.onDeleteHandler}/>);

    return (
      <div>
        {this.state.Wait != 0 ? this.Load() : null}
        <TopPanel/>
        <div className="grow_main_tariffs">
          <Scrollbars style={{
            height: "100%"
          }}>
            <div className="col-sm-12 overlay_chart">
              <div className="form-group">
                <button onClick={this.onAddUrlHandler} className={"btn__all--small"}>
                  {this.t("Добавление новой ссылки")}
                </button>

                <label htmlFor="wait_answer_build2">
                  <Link data-tip data-for="i_DLcnt" className={"ml5"} to="">
                    <i class="fz15 far fa-question-circle"></i>
                  </Link>
                </label>

                <ReactTooltip id="i_DLcnt" type="light">
                  <span>{this.t("С помощью этой системы вы можете регистрировать кол-во переходов по нужной вам ссылке")}</span>
                </ReactTooltip>

                <input type="text" onChange={this.onChangeHandler} name={"Url"} value={s.Url}
                  placeholder={this.t("Ссылка")} className={"chat_input"}/>
              </div>

              <table class="table">
                <thead>
                <tr class="table_f">
                  <th className={"table_center"}>{this.t('Оригинальня ссылка')}</th>
                  <th className={"table_center"}>{this.t('Ссылка считающая переходы')}</th>
                  <th className={"table_center"}>{this.t('Кол-во переходов')}</th>
                  <th className={"table_center"}></th>
                </tr>
                </thead>

                <tbody>
                {urls}
                </tbody>
              </table>
            </div>
          </Scrollbars>
        </div>
      </div>
    );
  }

}

export default Url;
